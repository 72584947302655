import isNil from 'lodash/isNil';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    companyWithdrawModal,
    fetchInvestorEscrowAccountInfo,
} from '../../../modules/App/AppActions';
import {
    getInvestorEscrowAccountInfo,
    getUserInvestorOrganizationId,
} from '../../../modules/App/AppReducer';
import { priceFormatNoRounding } from '../../../util/utility';
import WithdrawModal from '../../WithdrawModal';
import styles from '../styles/WithdrawFunds.module.scss';

const { balanceBox, withdraw, details } = styles;

const WithdrawFunds = () => {
    const dispatch = useDispatch();
    const investorEscrowAccountInfo = useSelector((state) => getInvestorEscrowAccountInfo(state));
    const investorOrgId = useSelector(getUserInvestorOrganizationId);

    useEffect(() => {
        dispatch(fetchInvestorEscrowAccountInfo(investorOrgId));
    }, []);

    if (!investorEscrowAccountInfo || isNil(investorEscrowAccountInfo.user_id)) return null;

    return (
        <div className={balanceBox}>
            <span className="fs-12">Account Balance</span>
            <div className="fs-16 mt-2 mb-2">
                ₹&nbsp;
                {investorEscrowAccountInfo?.balance !== 'NA'
                    ? priceFormatNoRounding(investorEscrowAccountInfo?.balance, 2)
                    : 'NA'}
            </div>
            <div className="fs-10">
                <button className={withdraw} onClick={() => dispatch(companyWithdrawModal(true))}>
                    Withdraw
                </button>

                <Link to="/investor/finances/statements" className={`${details} ml-2`}>
                    Details
                </Link>
            </div>
            <WithdrawModal type="investor" escrowAccountInfo={investorEscrowAccountInfo} />
        </div>
    );
};

export default WithdrawFunds;
