import clsx from 'clsx';
import { BANK_NAME, ACCOUNT_HOLDER_NAME, ACCOUNT_NUMBER, IFSC } from '../AddBankAccounts';
import styles from '../AddBankAccounts.module.scss';

function AddBankCard({ handleChange, bankDetails, banks, needSpaceFromTop = false }: any) {
    return (
        <div
            className={clsx(styles.AddBankCard, {
                [styles.needSpaceFromTop]: needSpaceFromTop,
            })}
        >
            <div className="row">
                <div className={`${styles.FormDiv} col-md-6`}>
                    <select
                        className={clsx(styles.FormSelect, {
                            [styles.FormSelectValue]: !!bankDetails[BANK_NAME],
                        })}
                        id={BANK_NAME}
                        value={bankDetails[BANK_NAME]}
                        onChange={(e) => handleChange(BANK_NAME, e.target.value)}
                        required
                    >
                        <option value="" disabled hidden>
                            Name of Bank
                        </option>
                        {banks.map((item: any, index: any) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                    <br />
                    <label
                        className={styles.FormLabel}
                        style={{ marginLeft: '6%' }}
                        htmlFor={BANK_NAME}
                    >
                        Bank Name
                    </label>
                </div>
                <div className={`col-md-6`}>
                    <div className={styles.FormDiv}>
                        <input
                            className={styles.FormInput}
                            id={ACCOUNT_HOLDER_NAME}
                            type="text"
                            value={bankDetails[ACCOUNT_HOLDER_NAME]}
                            onChange={(e) => handleChange(ACCOUNT_HOLDER_NAME, e.target.value)}
                            required
                        />
                        <br />
                        <label className={styles.FormLabel} htmlFor={ACCOUNT_HOLDER_NAME}>
                            Account holder name
                        </label>
                    </div>
                </div>
            </div>

            <div className="row mb-2">
                <div className="col-md-6">
                    <div className={styles.FormDiv}>
                        <input
                            className={styles.FormInput}
                            id={ACCOUNT_NUMBER}
                            type="text"
                            pattern="[0-9]*"
                            value={bankDetails[ACCOUNT_NUMBER]}
                            onChange={(e) =>
                                (e.target.validity.valid || e.target.value === '') &&
                                handleChange(ACCOUNT_NUMBER, e.target.value)
                            }
                            required
                        />
                        <br />
                        <label className={styles.FormLabel} htmlFor={ACCOUNT_NUMBER}>
                            Account number
                        </label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={styles.FormDiv}>
                        <input
                            className={styles.FormInput}
                            id={IFSC}
                            type="text"
                            value={bankDetails[IFSC]}
                            onChange={(e) => handleChange(IFSC, e.target.value)}
                            required
                        />
                        <br />
                        <label className={styles.FormLabel} htmlFor={IFSC}>
                            IFSC code
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBankCard;
