import { DRAWER_HEADINGS } from '../../DrawerConsts';

const ALL_TERM_SHEETS = [
    'Term sheet',
    'Terms',
    'MF',
    'Master Facility Agreement',
    'SL',
    'Sanction Letter',
    'No-Dues certificate',
    'NDC',
    'NOC',
    '<Investor Name>',
];
const REPAYMENT_ACCOUNTING = ['Repayment Schedule', 'Accounting'];
const ALL_TRANSACTION_DOCUMENTS = [
    'MFA',
    'Master Facility Agreement',
    'SL',
    'Sanction Letter',
    'No-Dues certificate',
    'NDC',
    'NOC',
    '<Investor Name>',
    'drawdown',
    'drawdown letter',
];
const DIGITAL_AC_STMT = ['Escrow statement', 'Escrow'];
const INVOICES = ['Recur invoice'];
const TDS_DEDUC = ['Tax', 'deduction'];
const TDS_REFUND = ['Tax', 'refund', 'filling'];
const DRAW_CAPITAL = [
    'Draw',
    'Trade',
    'Trading',
    'Raise capital',
    'Listing',
    'List',
    'Tradebook',
    'Historical Capital Raises',
    'Capital',
    '<Customer Name>',
];
const REPAYMENTS = [
    'Paid Amount',
    'Paid delayed Amount',
    'Delayed Amount',
    'Due Amount',
    'Overdue Amount',
    'Payment',
    'Repayment',
    'Scheduled Repayments',
    'Scheduled Payments',
    'Recent Payments',
    'Repayment Due',
    'Scheduled Collections',
];
const HELP_CENTER = [
    'Help',
    'Queries',
    'Help Center',
    'Contact us',
    'Connect with Partner',
    'Need Help',
    'Schedule a call',
];
const CUSTOMER_SUOPPORT = ['Customer Support', 'Contact Us', 'Support'];
const NOTIFICATIONS = ['Notifications', 'Recent Notifications', 'Recent Update'];
const BANK_ACS = ['Your Account', 'Bank Accounts', 'Team'];
const TRADEBOOK_ALL_DRAWDOWNS = [
    'Trade',
    'trade book',
    'trade details',
    'drawdown',
    'drawdown letter',
    'No-Dues certificate',
    'NDC',
    'NOC',
    'TRADEBOOK',
];
const BAL_CONFIRMATION = ['Balance', 'Confirmation'];

export interface ObjectItem {
    keywords: string[];
    redirection: string;
    drawer?: string;
    resultName: string;
    externalNavigation?: string;
}

export const NOTIFICATION_SEARCHHISTORY = 'searchHistory';

export const ALL_DRAWDOWNS = 'All Drawdowns';
export const ALL_TERM_SHEETS_RESULTNAME = 'All Term Sheets';
export const TRANSACTION_DOCUMENTS = 'Transaction Documents';
export const TDS_REFUND_RESULTNAME = 'TDS Refund';

export const metDataMappingRedirection: ObjectItem[] = [
    {
        keywords: ALL_TERM_SHEETS,
        redirection: '/company/recur-products?type=swift',
        // drawer: DRAWER_HEADINGS.ALL_TERMS_SHEET,
        resultName: ALL_TERM_SHEETS_RESULTNAME,
    },
    {
        keywords: REPAYMENT_ACCOUNTING,
        redirection: '/company/finances/reports',
        resultName: 'Repayments Accounting',
    },
    {
        keywords: ALL_TRANSACTION_DOCUMENTS,
        redirection: '/company/finances/reports',
        drawer: DRAWER_HEADINGS.TRANSACTION_DOCUMENTS,
        resultName: TRANSACTION_DOCUMENTS,
    },
    // {
    //     keywords: DIGITAL_AC_STMT,
    //     redirection: '/company/finances/reports',
    //     drawer: DRAWER_HEADINGS.DIGITAL_ACCOUNT_STATEMENT,
    //     resultName: 'Digital Account Statement',
    // },
    {
        keywords: INVOICES,
        redirection: '/company/finances/reports',
        drawer: DRAWER_HEADINGS.INVOICES,
        resultName: 'Invoices',
    },
    {
        keywords: TDS_DEDUC,
        redirection: '/company/customer-support',
        resultName: 'TDS Deposit',
    },
    {
        keywords: TDS_REFUND,
        redirection: '/company/finances/reports',
        drawer: DRAWER_HEADINGS.TDS_REFUND,
        resultName: TDS_REFUND_RESULTNAME,
    },
    {
        keywords: DRAW_CAPITAL,
        redirection: '/company/trade',
        resultName: 'Draw Capital',
    },
    {
        keywords: REPAYMENTS,
        redirection: '/company/finances/transactions',
        resultName: 'Repayments',
    },
    {
        keywords: HELP_CENTER,
        redirection: '',
        externalNavigation: 'https://www.recurclub.com/faq',
        resultName: 'Help Center',
    },
    {
        keywords: CUSTOMER_SUOPPORT,
        redirection: '/company/customer-support',
        resultName: 'Customer Support',
    },
    {
        keywords: NOTIFICATIONS,
        redirection: '',
        drawer: DRAWER_HEADINGS.NOTIFICATION,
        resultName: 'Notifications',
    },
    {
        keywords: BANK_ACS,
        redirection: '',
        drawer: DRAWER_HEADINGS.BANK_ACCOUNTS,
        resultName: 'Your Bank Accounts',
    },
    {
        keywords: TRADEBOOK_ALL_DRAWDOWNS,
        redirection: '/company/finances/reports',
        drawer: DRAWER_HEADINGS.ALL_DRAWDOWNS,
        resultName: ALL_DRAWDOWNS,
    },
    {
        keywords: BAL_CONFIRMATION,
        redirection: '/company/finances/reports',
        drawer: '',
        resultName: 'Balance Confirmation Report',
    },
];
