export const OPEN = 'Open';
export const INVOICING = {
    key: 'onboarding_stage_data_invoicing',
    label: 'Invoicing',
};
export const MIS = {
    key: 'onboarding_stage_data_financials_mis',
    label: 'Provisional Financials',
};
export const DEBT_SCHEDULE = {
    key: 'Debt Schedule',
    label: 'Debt Schedule',
};
export const ADD_BANK = {
    key: 'is_add_bank_account',
    label: 'It’s Mandatory to add all account & investments',
};
export const ADDITIONAL_REQ_DOCS = {
    key: 'additionalRequiredDocuments',
    label: 'Additional Required Documents',
};
export const GST_FETCH = {
    key: 'GST Fetch',
    label: 'GST Fetch',
};

export const CONNECTED = 'connected';
export const UPLOADED = 'uploaded';
export const ADDITIONAL_DOCS = 'additional_docs';
export const MUTUAL_FUNDS = 'mutualFunds';
export const SUBMITTED = 'Submitted';
