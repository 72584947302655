import { Box, Avatar, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import styles from './MobileNavbarHeader.module.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '../../../App/AppActions';
import { useNavigate } from 'react-router-dom';
import { USER_TYPE_INVESTEE } from '../../../../enums/appEnums';
import { getUserName } from '../../../App/AppReducer';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2)',
            opacity: 0,
        },
    },
}));

const MobileNavbarHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userName = useSelector(getUserName);
    const [anchorEl, setAnchorEl] = useState(null);

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: '#BED1EA',
                color: '#006EFF',
                width: 30, // Set the width to 30px
                height: 30, // Set the height to 30px
                fontSize: '12px',
                fontWeight: '600',
            },
            children: `${name?.split(' ')?.[0].substring(0, 1).toUpperCase()}${
                name?.split(' ')?.[1]?.length > 0
                    ? name?.split(' ')?.[1]?.substring(0, 1).toUpperCase()
                    : ''
            }`,
        };
    }

    const handleDropdownOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDropdownClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        const authInstance = window?.gapi?.auth2?.getAuthInstance();
        if (!authInstance) {
            dispatch(logOutUser(navigate, USER_TYPE_INVESTEE));
            return;
        }
        authInstance
            .signOut()
            .then(() => {
                dispatch(logOutUser(navigate, USER_TYPE_INVESTEE));
            })
            .catch(() => {
                dispatch(logOutUser(navigate, USER_TYPE_INVESTEE));
            });
    };

    const handleLogout = () => {
        logout();
        handleDropdownClose();
    };

    return (
        <Box className={styles.mobileNavHeader}>
            <Box>
                <img
                    src="/assets/recur-logo-black.svg"
                    alt="recur-logo"
                    width={'110px'}
                    height={'45px'}
                />
            </Box>
            <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                onClick={handleDropdownOpen}
            >
                <Avatar {...stringAvatar(userName)} />
            </StyledBadge>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleDropdownClose()}
                sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 4 } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem sx={{ py: 2, fontWeight: 600, color: '#000000' }} onClick={handleLogout}>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default MobileNavbarHeader;
