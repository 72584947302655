// grab the Mixpanel factory
import Mixpanel from 'mixpanel-browser';
import { PRODUCTION } from '../enums/appEnums';

const init_token = process.env.REACT_APP_MIX_PANEL_INIT_ID ?? '2dacb798b8f428c5d0035a2d41f447df';

// initialize mixpanel client configured to communicate over https
Mixpanel.init(init_token, {
    ignore_dnt: true,
    track_pageview: 'full-url',
});

export { Mixpanel };
