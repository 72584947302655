export const BANK_ENTITIES = {
    BANK_NAME: 'bank_name',
    ACCOUNT_HOLDER_NAME: 'account_holder_name',
    ACCOUNT_NUMBER: 'account_number',
    IFSC_CODE: 'ifsc_code',
};

export const ADD_BANK_CASES = {
    OPEN: 'open',
    FILLED: 'filled',
    CONNECTED: 'connected',
    FAILED_TO_CONNECT: 'failed_to_connect',
    UPLOADING: 'uploading',
    UPLOADED: 'uploaded',
};

export const finboxBankStatementMonths = 11;
