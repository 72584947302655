import Cookies from 'universal-cookie';
import zohoLogo from '../../../../../assets/UploadDataJourney/zoho-logo.svg';
import tallyLogo from '../../../../../assets/UploadDataJourney/tally-logo.svg';
import csvLogo from '../../../../../assets/UploadDataJourney/csv-logo.svg';
import { isEmpty } from 'lodash';
import { CONNECTED, UPLOAD } from '../components/AddBankAccounts/AddBankAccounts';
import { UPLOAD_STAGE } from './constants';
// import { CONNECTED, UPLOADING } from './components/BankAccounts/constants/consts';

const cookies = new Cookies();

export const INTEGRATION_TYPES = {
    ZOHO: 'zoho',
    TALLY: 'tally',
    CSV: 'csv',
};

export const allIntegrations = [
    INTEGRATION_TYPES.ZOHO,
    INTEGRATION_TYPES.TALLY,
    // INTEGRATION_TYPES.CSV,
];

export const INTEGRATION_LOGOS = {
    [INTEGRATION_TYPES.ZOHO]: zohoLogo,
    [INTEGRATION_TYPES.TALLY]: tallyLogo,
    [INTEGRATION_TYPES.CSV]: csvLogo,
};

export const handleIntegration = (subStage: number, logoName: string, isOnboarding: boolean) => {
    let Redirect_URI = isOnboarding
        ? process.env.REACT_APP_ZOHO_REDIRECT_URI_ONBOARDING
        : process.env.REACT_APP_ZOHO_REDIRECT_URI_DATA_VAULT;
    if (isOnboarding) {
        Redirect_URI +=
            subStage === 1 ? `?misIntegrations=${logoName}` : `?invoicingIntegrations=${logoName}`;
    }
    if (subStage === 1)
        cookies.set('misIntegrations', logoName, { expires: new Date(Date.now() + 5 * 60 * 1000) });
    else
        cookies.set('invoicingIntegrations', logoName, {
            expires: new Date(Date.now() + 5 * 60 * 1000),
        });
    const link = document.createElement('a');
    if (logoName === 'zoho') {
        link.href = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoInvoice.invoices.READ,ZohoBooks.invoices.READ,ZohoBooks.creditnotes.READ,ZohoBooks.customerpayments.READ,ZohoBooks.bills.READ,ZohoBooks.vendorpayments.READ,ZohoBooks.banking.READ,ZohoBooks.accountants.READ,ZohoBooks.settings.READ,ZohoSubscriptions.customers.READ,ZohoSubscriptions.subscriptions.READ,ZohoSubscriptions.invoices.READ,ZohoSubscriptions.creditnotes.READ,ZohoSubscriptions.products.READ,ZohoSubscriptions.plans.READ,ZohoSubscriptions.addons.READ,ZohoSubscriptions.coupons.READ,ZohoSubscriptions.payments.READ,ZohoSubscriptions.settings.READ,ZohoBooks.reports.READ&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&response_type=code&redirect_uri=${Redirect_URI}&access_type=offline&&prompt=Consent`;
    }
    document.body.appendChild(link);
    link.click();
};

export const checkIfStatementIsFetched = ({
    finboxIds,
    files,
    accountNo,
    monthlyViewData,
    stage,
}: {
    finboxIds: any;
    files: any;
    accountNo: string;
    monthlyViewData?: any;
    stage?: string;
}) => {
    if (!isEmpty(finboxIds) && accountNo in finboxIds && !isEmpty(finboxIds[accountNo]))
        return CONNECTED;
    if (stage === UPLOAD_STAGE.DATA_VAULT) {
        if (monthlyViewData?.availableMonthsCount === 24) return CONNECTED;
    }
    if (!isEmpty(files) && accountNo in files && !isEmpty(files[accountNo])) return UPLOAD;
    return '';
};
