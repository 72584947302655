import { lazy } from 'react';

export const LoginPageLazy = lazy(() => import('./modules/Investee/pages/LoginPage/LoginPage'));

export const SignUpPageLazy = lazy(() => import('./modules/Investee/pages/SignupPage/SignupPage'));

export const ResetPasswordPageLazy = lazy(() =>
    import('./modules/Investee/pages/ResetPasswordPage/ResetPasswordPage'),
);
export const SignupGetStartedLazy = lazy(() =>
    import('./modules/Investee/pages/GetStarted/SignupGetStarted'),
);

export const InsidersPageLazy = lazy(() =>
    import('./modules/Investee/pages/InsidersPage/InsidersPage'),
);
export const GetStartedPageLazy = lazy(() =>
    import('./modules/Investee/pages/GetStartedPageNew/GetStartedPage'),
);

export const RecurProductsPageLazy = lazy(() =>
    import('./modules/Investee/pages/RecurProducts/RecurProducts'),
);

export const CompliancesPageLazy = lazy(() =>
    import('./modules/Investee/pages/CompliancePage/ComplianceTable'),
);

export const InvestorDashboardLazy = lazy(() =>
    import('./modules/Investor/pages/DashboardPage/InvestorDashboard'),
);

export const OtpScreenLazy = lazy(() =>
    import('./modules/Investee/pages/OtpScreen/InvesteeOtpScreen.jsx'),
);
export const InvestorOtpLazy = lazy(() =>
    import('./modules/Investee/pages/OtpScreen/InvestorOtpScreen.jsx'),
);

export const InvesteeDashboardPageLazy = lazy(() =>
    import('./modules/Investee/pages/DashboardPage/DashboardPage.tsx'),
);

export const InvesteeTradePageLazy = lazy(() =>
    import('./modules/Investee/pages/InvesteeTrade/InvesteeTrade'),
);
export const InvesteeLimitOverviewPageLazy = lazy(() =>
    import(
        './modules/Investee/pages/DashboardPage/components/SanctionedLimitPage/LimitOverviewPage'
    ),
);
export const CibilConfirmationScreenLazy = lazy(() =>
    import('./modules/Investee/pages/CibilConfirmationScreen/CibilConfirmationScreen'),
);

export const InvesteeTradebookPageLazy = lazy(() =>
    import('./modules/Investee/pages/TradebookPage/TradebookPage.jsx'),
);
export const PayOutTransactionsLazy = lazy(() =>
    import('./modules/Investee/pages/FinancesPage/components/Transactions/Transactions'),
);
export const TransactionsOverviewLazy = lazy(() =>
    import('./modules/Investee/pages/FinancesPage/TransactionsOverview'),
);
export const StatementsLazy = lazy(() =>
    import('./modules/Investee/pages/FinancesPage/Statements'),
);
export const ReportsPageLazy = lazy(() =>
    import('./modules/Investee/pages/ReportsPage/ReportsPage'),
);
export const InvoicesLazy = lazy(() => import('./modules/Investee/pages/FinancesPage/Invoices'));
export const TdsRefundLazy = lazy(() =>
    import('./modules/Investee/pages/FinancesPage/components/Statements/components/TdsRefund'),
);

export const SignedDocumentsLazy = lazy(() =>
    import('./modules/Investee/pages/DataVault/components/SignedDocuments/SignedDocuments'),
);
export const DataVaultPageLazy = lazy(() => import('./modules/Investee/pages/DataVault/DataVault'));

export const RecurCheckoutComponentLazy = lazy(() =>
    import('./modules/Investee/pages/RecurCheckout/RecurCheckout'),
);
export const RecurCheckoutPageLazy = lazy(() =>
    import('./modules/Investee/pages/RecurCheckout/RecurCheckoutPage'),
);
export const RecurProPageLazy = lazy(() =>
    import('./modules/Investee/pages/RecurPro/RecurProPage'),
);

export const KarzaGstLazy = lazy(() => import('./modules/Investee/pages/KarzaGst/index'));

export const NotificationPageLazy = lazy(() =>
    import('./modules/Investee/pages/NotificationPage/NotificationPage'),
);
export const ManageProfileBankAccountLazy = lazy(() =>
    import('./modules/Investee/pages/ManageProfile/NewBankAccount'),
);
export const NewTeamMembersLazy = lazy(() =>
    import('./modules/Investee/pages/ManageProfile/NewTeamMembers'),
);
export const NewUserProfileLazy = lazy(() =>
    import('./modules/Investee/pages/ManageProfile/NewUserProfile'),
);
export const NewKycDocsLazy = lazy(() =>
    import('./modules/Investee/pages/ManageProfile/NewKycDocs'),
);

export const InvesteeForgotPasswordPageLazy = lazy(() =>
    import('./modules/Investee/pages/ForgotPasswordPage/ForgotPasswordPage'),
);

export const TradebookFiltersLazy = lazy(() =>
    import('./modules/Investee/pages/TradebookPage/components/TradebookFilters'),
);

export const InvesteeOnboardingPageLazy = lazy(() =>
    import('./modules/Investee/pages/InvesteeOnboardingNew/index'),
);

export const InvesteeAddOnLimitLazy = lazy(() =>
    import('./modules/Investee/pages/AddOnLimitPage/AddOnLimitPage'),
);

// investor -->>> ROUTES
export const InvestorNewDealsPageLazy = lazy(() =>
    import('./modules//Investor/pages/NewDeals/NewDeals'),
);
export const InvestorDocVerificationPageLazy = lazy(() =>
    import('./modules/Investor/pages/NewDeals/DocVerification'),
);
export const InvestorPortfolioPageLazy = lazy(() =>
    import('./modules/Investor/pages/PortfolioPage/PortfolioPage'),
);
export const InvestorProfilePageLazy = lazy(() =>
    import('./modules/Investor/pages/ProfilePage/ProfilePage'),
);
export const InvestorHelpCenterPageLazy = lazy(() =>
    import('./modules/Investor/pages/HelpCenterPage/HelpCenterPage'),
);
export const InvestorMarketPageLazy = lazy(() =>
    import('./modules/Investor/pages/MarketPage/MarketPage'),
);
export const CompanyDetailViewLazy = lazy(() =>
    import('./modules/Investor/pages/MarketPage/components/CompanyDetailView'),
);
export const TermsOfTradeLazy = lazy(() => import('./components/Certificate/TermsOfTrade'));
export const AutoInvestPageLazy = lazy(() =>
    import('./modules/Investor/pages/AutoInvestPage/AutoInvestPage'),
);
export const AutoInvestCreatePageLazy = lazy(() =>
    import('./modules/Investor/pages/AutoInvestPage/components/AutoInvestCreatePage'),
);
export const AutoInvestCreatePagePECLazy = lazy(() =>
    import('./modules/Investor/pages/AutoInvestPage/components/AutoInvestCreatePagePEC'),
);
export const InvestorDashboardPageLazy = lazy(() =>
    import('./modules/Investor/pages/DashboardPage/DashboardPage'),
);

export const InvestorForgotPasswordPageLazy = lazy(() =>
    import('./modules/Investor/pages/ForgotPasswordPage/ForgotPasswordPage'),
);

export const InvestorResetPasswordPageLazy = lazy(() =>
    import('./modules/Investor/pages/ResetPasswordPage/ResetPasswordPage'),
);

export const VerifyEmailPageLazy = lazy(() =>
    import('./modules/Investor/pages/LoginPage/components/VerifyEmail'),
);

export const InvestorLoginPageLazy = lazy(() =>
    import('./modules/Investor/pages/LoginPage/LoginPage'),
);

export const InvestorSignUpLazy = lazy(() =>
    import('./modules/Investor/pages/SignupPage/SignupPage'),
);

export const InvestorRequestSubmittedPageLazy = lazy(() =>
    import('./modules/Investor/pages/LoginPage/components/RequestSubmitted'),
);

export const InvestorCounterSignPageLazy = lazy(() =>
    import('./modules/Investor/pages/CounterSign/CounterSign'),
);

export const InvestorVerifyEmailPageLazy = lazy(() =>
    import('./modules/Investor/pages/LoginPage/components/VerifyEmail'),
);

export const InvestorTransactionsDrawdownsLazy = lazy(() =>
    import('./modules/Investor/pages/FinancesPage/components/Drawdowns/Drawdowns'),
);

export const InvestorTransactionsPaymentsLazy = lazy(() =>
    import('./modules/Investor/pages/FinancesPage/components/Payments/Payments'),
);

export const InvestorStatementsLazy = lazy(() =>
    import('./modules/Investor/pages/FinancesPage/components/AccountStatementPage'),
);

export const InvestorTransactionsOverviewLazy = lazy(() =>
    import('./modules/Investor/pages/FinancesPage/TransactionsOverview'),
);

export const InvestorReportsLazy = lazy(() =>
    import('./modules/Investor/pages/ReportsPage/ReportsPage'),
);

export const InvestorInvoicesLazy = lazy(() =>
    import('./modules/Investor/pages/FinancesPage/components/Invoices'),
);

export const InvestorTdsDepositLazy = lazy(() =>
    import('./modules/Investor/pages/FinancesPage/components/TdsDeposit/TdsDeposit'),
);

export const VerifyContactPage = lazy(() =>
    import('./modules/Investee/pages/VerifyContactPage/VerifyContactPage.tsx'),
);
export const CustomerSupportDrawerLazy = lazy(() =>
    import('./modules/Investee/components/CustomerSupportDrawer/CustomerSupportDrawer'),
);
export const TdsRefundDrawer = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/TdsRefund/TdsRefundDrawer'
    ),
);
export const TdsDepositDrawer = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/TdsDepositCalculation/TdsDepositCalculation'
    ),
);
export const CustomerSupportPageLazy = lazy(() =>
    import('./modules/Investee/pages/CustomerSupportPage/CustomerSupportPage'),
);

export const GlobalNeedInfoLazy = lazy(() =>
    import('./modules/Investee/pages/GlobalNeedInfo/GlobalNeedInfo'),
);

export const CollectionDrawerLazy = lazy(() =>
    import('./modules/Investor/pages/DashboardPage/CollectionDrawer/CollectionDrawer'),
);
export const CustomerRequestsLazy = lazy(() =>
    import('./modules/Investor/pages/CustomerRequests/CustomerRequests'),
);
export const BalanceConfirmationDrawerLazy = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/BalanceConfirmation/BalanceConfirmation'
    ),
);
export const BalanceConfirmationViewOnlyDrawerLazy = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/BalanceConfirmation/Component/BalanceConfirmationViewOnly/BalanceConfirmationViewOnly'
    ),
);
export const CustomerRequestsDrawerWrapperLazy = lazy(() =>
    import(
        './modules/Investor/pages/CustomerRequests/CustomerRequestsDrawerWrapper/CustomerRequestsDrawerWrapper'
    ),
);

export const BalanceConfirmationInvDrawerLazy = lazy(() =>
    import(
        './modules/Investor/pages/CustomerRequests/BalanceConfirmationInv/BalanceConfirmationInv'
    ),
);

export const NewTicketDrawerLazy = lazy(() =>
    import('./modules/Investee/components/CustomerSupportDrawer/Components/NewTicket/NewTicket'),
);

export const StatementOfAccountDrawerLazy = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/StatementOfAccount/StatementOfAccount'
    ),
);
export const StatementOfAccountViewOnlyDrawerLazy = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/StatementOfAccount/Components/StatementOfAccountViewOnly/StatementOfAccountViewOnly'
    ),
);
export const StatementOfAccountInvDrawerLazy = lazy(() =>
    import('./modules/Investor/pages/CustomerRequests/StatementOfAccountInv/StatementOfAccountInv'),
);
export const OtherTicketDrawerLazy = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/OtherTicket/OtherTicket'
    ),
);
export const OtherTicketViewOnlyDrawerLazy = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/OtherTicket/Components/OtherTicketViewOnly/OtherTicketViewOnly'
    ),
);
export const NoDuesCertificateDrawerLazy = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/NoDuesCertificate/NoDuesCertificate'
    ),
);
export const NoDuesCertificateViewOnlyDrawerLazy = lazy(() =>
    import(
        './modules/Investee/components/CustomerSupportDrawer/Components/NoDuesCertificate/Components/NoDuesCertificateViewOnly/NoDuesCertificateViewOnly'
    ),
);
export const NoDuesCertificateInvDrawerLazy = lazy(() =>
    import('./modules/Investor/pages/CustomerRequests/NoDuesCertificateInv/NoDuesCertificateInv'),
);
export const TotalRepaymentPageLazy = lazy(() =>
    import('./modules/Investee/pages/TotalRepaymentPage/TotalRepaymentPage'),
);

export const FundingCalculatorLazy = lazy(() =>
    import('./modules/Investee/pages/FundingCalculator/FundingCalculator'),
);
