import Cookies from 'universal-cookie';
import { NOTIFICATION_SEARCHHISTORY } from '../components/SearchBoxWithDropDown/searchConsts';
const cookies = new Cookies();

const isJson = (item) => {
    item = typeof item !== 'string' ? JSON.stringify(item) : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === 'object' && item !== null) {
        return true;
    }

    return false;
};

const storage = {
    html5StorageEnabled: window.localStorage && window.sessionStorage,
    // html5StorageEnabled: false,
    appStoragePrefix: 'recur_',

    set: (name, value, isForSession) => {
        if (storage.html5StorageEnabled) {
            if (isForSession) {
                sessionStorage.setItem(storage.appStoragePrefix + name, value);
            } else {
                localStorage.setItem(storage.appStoragePrefix + name, value);
            }
        } else {
            if (isForSession) {
                cookies.set(storage.appStoragePrefix + name, value, { expires: 0 });
            } else cookies.set(storage.appStoragePrefix + name, value);
        }
    },

    setObject: (name, value, isForSession) => {
        let validateValue = isJson(value);
        if (validateValue) {
            if (storage.html5StorageEnabled) {
                if (isForSession) {
                    sessionStorage.setItem(storage.appStoragePrefix + name, JSON.stringify(value));
                    document.cookie = storage.appStoragePrefix + name + '=' + JSON.stringify(value);
                } else {
                    localStorage.setItem(storage.appStoragePrefix + name, JSON.stringify(value));
                }
            } else {
                if (isForSession) {
                    if (JSON.stringify(value).length > 4000)
                        console.error('Cookie is not set as cookie length too long...');
                    else
                        cookies.set(storage.appStoragePrefix + name, JSON.stringify(value), {
                            expires: 0,
                        });
                } else {
                    if (JSON.stringify(value).length > 4000)
                        console.error('Cookie is not set as cookie length too long...');
                    else cookies.set(storage.appStoragePrefix + name, JSON.stringify(value));
                }
            }
        } else {
            console.error(value + ' is not a JSON object');
        }
    },
    // getter for the set key value pair
    get: (name) => {
        if (storage.html5StorageEnabled) {
            const sessionValue = sessionStorage.getItem(storage.appStoragePrefix + name);
            if (sessionValue != null) {
                return sessionValue;
            }
            return localStorage.getItem(storage.appStoragePrefix + name);
        } else {
            return cookies.get(storage.appStoragePrefix + name);
        }
    },

    getCookie: (name) => {
        return cookies.get(name);
    },

    //getter for a set object
    getObject: (name) => {
        if (storage.html5StorageEnabled) {
            const sessionValue = sessionStorage.getItem(storage.appStoragePrefix + name);
            if (sessionValue != null) {
                if (isJson(sessionValue)) return JSON.parse(sessionValue);
                else console.error(sessionValue + ' is not a JSON object');
            }
            const localValue = localStorage.getItem(storage.appStoragePrefix + name);
            if (localValue != null) {
                if (isJson(localValue)) return JSON.parse(localValue);
                else console.error(localValue + ' is not a JSON object');
            }
        } else {
            const cookieValue = cookies.get(storage.appStoragePrefix + name);
            if (cookieValue != null) {
                if (isJson(cookieValue)) return JSON.parse(cookieValue);
                else console.error(cookieValue + ' is not a JSON object');
            }
        }
    },
    //removing a name
    remove: (name, isForSession, isForLocal) => {
        if (storage.html5StorageEnabled) {
            if (isForSession && isForLocal) {
                sessionStorage.removeItem(storage.appStoragePrefix + name);
                localStorage.removeItem(storage.appStoragePrefix + name);
                document.cookie =
                    storage.appStoragePrefix + name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            } else if (isForSession) sessionStorage.removeItem(storage.appStoragePrefix + name);
            else localStorage.removeItem(storage.appStoragePrefix + name);
        } else {
            document.cookie =
                storage.appStoragePrefix + name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    },
    removeAllCookies: () => {
        let removeAllcookies = document.cookie.split(';');
        if (removeAllcookies.length > 0) {
            for (let i = 0; i < removeAllcookies.length; i++) {
                let removeCookie = removeAllcookies[i].trim();
                let eqPos = removeCookie.indexOf('=');
                let name = eqPos > -1 ? removeCookie.slice(0, eqPos) : removeCookie;
                // double check for empty space in last for search history
                if (
                    name.includes(NOTIFICATION_SEARCHHISTORY) &&
                    name.trim() !== NOTIFICATION_SEARCHHISTORY
                ) {
                    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                }
            }
        }
    },

    removeCookieByName: (name) => {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },

    //show all the storage objects set in application context
    show: () => {
        if (sessionStorage.length > 0) {
            for (let i = 0; i < sessionStorage.length; i++) {
                const key = sessionStorage.key(i);
                const value = sessionStorage[key];
                if (key.search(storage.appStoragePrefix) !== -1) {
                    console.info(key, ' : ', value);
                }
            }
        }

        if (localStorage.length > 0) {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                const value = localStorage[key];
                if (key.search(storage.appStoragePrefix) !== -1) {
                    console.info(key, ' : ', value);
                }
            }
        }
        var getAllcookies = document.cookie.split(';');
        if (getAllcookies.length > 0) {
            for (let i = 0; i < getAllcookies.length; i++) {
                let getCookie = getAllcookies[i];
                if (getCookie.search(storage.appStoragePrefix) !== -1) console.info(getCookie);
            }
        }
    },
    flush: (isForSession) => {
        if (storage.html5StorageEnabled) {
            if (sessionStorage.length > 0) sessionStorage.clear();

            if (!isForSession) {
                if (localStorage.length > 0) {
                    // Keys to preserve
                    const keysToPreserve = [
                        'mobileInviteModalClosed',
                        'device',
                        // add more keys to preserve
                    ];

                    // Get all keys from localStorage
                    const allKeys = Object.keys(localStorage);

                    // Iterate over the keys and clear them except for the ones to preserve
                    allKeys.forEach((key) => {
                        if (!keysToPreserve.includes(key)) {
                            localStorage.removeItem(key);
                        }
                    });
                }
            }
            storage.removeAllCookies();
        } else {
            storage.removeAllCookies();
        }
    },
    flushOLD: (isForSession) => {
        if (storage.html5StorageEnabled) {
            if (sessionStorage.length > 0) {
                for (let j = 0; j < sessionStorage.length; j++) {
                    const key = sessionStorage.key(j);
                    if (key.search(storage.appStoragePrefix) !== -1) {
                        sessionStorage.removeItem(key);
                        //console.info(`${key} removed`)
                    }
                    j--;
                }
            }

            if (!isForSession) {
                let junkCount = 0,
                    breakCount = 0;
                while (localStorage.length > 0) {
                    for (let j = localStorage.length - 1; j >= 0; j--) {
                        const key = localStorage.key(j);
                        if (key.search(storage.appStoragePrefix) !== -1) {
                            localStorage.removeItem(key);
                            //console.info(`${key} removed`)
                        } else junkCount++;
                    }
                    if (junkCount > 0) breakCount++;

                    if (breakCount >= 2) break;
                }
                /*
                                if (localStorage.length > 0) {
                                        for (let j = 0; j < localStorage.length; j++) {
                                                const key = localStorage.key(j)
                                                if (key.search(storage.appStoragePrefix) !== -1) {
                                                        localStorage.removeItem(key)
                                                        //console.info(`${key} removed`)
                                                }
                                                j--;
                                        }
                                }
                                */
                // localStorage.clear()
            }
        } else {
            let removeAllcookies = document.cookie.split(';');
            if (removeAllcookies.length > 0) {
                for (let i = 0; i < removeAllcookies.length; i++) {
                    let removeCookie = removeAllcookies[i];
                    let eqPos = removeCookie.indexOf('=');
                    let name = eqPos > -1 ? removeCookie.substr(0, eqPos) : removeCookie;
                    if (name.search(storage.appStoragePrefix) !== -1) {
                        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                        //console.info(removeCookie+" removed");
                    }
                }
            }
        }
    },
};
export default storage;
