import axios from 'axios';
import { CustomRequest } from '../../../../../../../../common/common';
import { API_URL, UW_API } from '../../../../../../../../util/apiCaller';
import { CATEGORIES } from '../uploadDocUtils';
import { UPLOAD_STAGE } from '../../../constants';

// const GET = 'GET';
const POST = 'POST';

export const getSingedUrlApi = (data: any) => {
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${API_URL}/api/investeeonboarding/s3/sign`,
        data: data,
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};

export const uploadFileApi = (url: string, formData: any) => {
    return fetch(url, {
        method: POST,
        body: formData,
    });
};

export const updateMetadataApi = (input: any, data: any, isOnboarding: boolean): any => {
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${API_URL}/api/investeeOnboarding/${input._id}/${
            isOnboarding ? 'v3/' : ''
        }metadata-update?stage=${input.stage}&substage=${input.sub_stage}&path=${input.path}`,
        data: data,
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};

export const initiateFileIngestionApi = (data: any, category: string) => {
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${UW_API}/${category === CATEGORIES.INVOICING ? 'invoices' : 'mis'}/upload`,
        data: data,
        isAuthRequired: true,
        timeout: 120000,
    };
    if (category === CATEGORIES.DEBT_SCHEDULE)
        axiosPayload.url = `${UW_API}/fileUpload/process/debtSchedule`;
    return axios(axiosPayload);
};

export const deleteFileApi = (input: any, data: any, isOnboarding: boolean) => {
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${API_URL}/api/investee-onboarding/${input.investeeOnboardingId}/${
            isOnboarding ? 'v3/' : ''
        }delete-file?path=${input.path}`,
        data: data,
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};

export const updateMetaDataForDebtSchedule = (tag: boolean, onboardingId: string, path: string) => {
    let url = '';
    if (path === UPLOAD_STAGE.ONBOARDING)
        url = `investeeOnboarding/${onboardingId}/v3/metadata-update?stage=stage2&substage=debtSchedule&path=${path}`;
    else
        url = `investeeOnboarding/${onboardingId}/metadata-update?stage=stage2&substage=debtSchedule&path=${path}`;
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${API_URL}/api/${url}`,
        data: {
            noDebtSchedules: tag,
        },
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};
