import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../../../../../util/utility';
import { companyInviteModal } from '../../../../../App/AppActions';
import {
    getCompanyInviteModalState,
    getUserDetails,
    getUserInvesteeId,
    getUserInvesteeOrganizationId,
    getUserInvestorId,
    getUserInvestorOrganizationId,
} from '../../../../../App/AppReducer';
import {
    addNewInvestorMember,
    fetchInvestorTeamMembers,
} from '../../../../../Investor/InvestorActions';
import { addNewMember } from '../../../../InvesteeActions';
import { getTeamMemberInvitingState } from '../../../../InvesteeReducers';
import styles from './styles/Modal.module.scss';
import '../../../../../../css/Modal.scss';
import { EMAIL_REGEX } from '../../../../../../enums/appEnums';
import CountryCodesDropdown from '../../../../components/CountryCodesDropdown/CountryCodesDropdown';
import { JobRoleDropDown } from '../../../../components/JobRoleDropdown/JobRoleDropDown';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useOrientation, useWindowSize } from 'react-use';
import { Box, Grid, Typography } from '@mui/material';

export default function NewInviteTeamMemberModal({ roleObjectMap, invitableRoles, isTransparent }) {
    const {
        TeamMember,
        SendInvite,
        SelectMemberRoleEmpty,
        SelectMemberRoleSelected,
        InputFilled,
        InputEmpty,
        Label,
    } = styles;
    const dispatch = useDispatch();
    const { width } = useWindowSize();
    const modalOpen = useSelector(getCompanyInviteModalState);
    const investee_id = useSelector(getUserInvesteeId);
    const [countryCode, setCountryCode] = useState(null);
    const investee_organization_id = useSelector(getUserInvesteeOrganizationId);
    const is_inviting_new_member = useSelector(getTeamMemberInvitingState);
    const isUserInvestor = useSelector(getUserDetails)?.is_investor;
    const investor_id = useSelector(getUserInvestorId);
    const investor_organization_id = useSelector(getUserInvestorOrganizationId);
    const orientation = useOrientation();

    const [newMember, setNewMember] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        country_code: '',
        inviteRole: '',
        designation: '',
    });
    const [jobRole, setJobRole] = useState('');
    const [otherJobRole, setOtherJobRole] = useState('');

    useEffect(() => {
        if (jobRole) {
            if (jobRole === 'Others' && otherJobRole) {
                setNewMember({
                    ...newMember,
                    designation: otherJobRole,
                });
            } else {
                setNewMember({
                    ...newMember,
                    designation: jobRole,
                });
            }
        }
    }, [jobRole, otherJobRole]);
    useEffect(() => {
        if (countryCode?.callingCode?.length > 0) {
            setNewMember({
                ...newMember,
                country_code: countryCode?.callingCode,
                countryId: countryCode?.countryId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    useEffect(() => {
        if (!isUserInvestor) return true;
    }, [isUserInvestor]);

    useEffect(() => {
        if (width <= 1025 && orientation.type.includes('landscape')) {
            toggleModal(false);
        }
    }, [width, orientation]);

    const handleChange = (keyName, keyValue) => {
        let obj = { ...newMember };
        obj[keyName] = keyValue?.trim();
        setNewMember(obj);
    };

    const handleValidationTeamMember = (member) => {
        let isValid = true;
        let message = '';
        if (
            !member.firstName &&
            !member.lastName &&
            !member.email &&
            !member.contactNumber &&
            !member.inviteRole
        ) {
            message = 'Please fill all the details';
            isValid = false;
        } else if (!member.firstName || !member.firstName?.trim()?.length) {
            message = 'First name cannot be empty';
            isValid = false;
        } else if (!isUserInvestor && !member.inviteRole) {
            message = 'Role cannot be empty';
            isValid = false;
        } else if (!member.email) {
            message = 'Email cannot be empty';
            isValid = false;
            // eslint-disable-next-line no-useless-escape
        } else if (!member.email.match(EMAIL_REGEX)) {
            message = 'Email not valid';
            isValid = false;
        } else if (!member.designation.trim()) {
            message = 'Job Title cannot be empty';
            isValid = false;
        } else if (!member.contactNumber.trim()) {
            message = 'Contact number cannot be empty';
            isValid = false;
        } else if (member.contactNumber.length !== 10) {
            message = 'Please enter 10 digits contact number';
            isValid = false;
        } else if (!member.contactNumber.match(/^[0-9]+$/)) {
            message = 'Invalid contact number';
            isValid = false;
        }
        return {
            isValid,
            message,
        };
    };

    const handleClearData = () => {
        setNewMember({
            email: '',
            name: '',
            designation: '',
            contactNumber: '',
            country_code: '91',
            inviteRole: '',
        });
    };

    const inviteTeamMember = () => {
        const check = handleValidationTeamMember(newMember);
        if (check.isValid) {
            if (isUserInvestor) {
                dispatch(addNewInvestorMember(investor_id, newMember)).then(() => {
                    dispatch(fetchInvestorTeamMembers(investor_organization_id));
                    handleClearData();
                });
            } else {
                dispatch(
                    addNewMember(investee_id, investee_organization_id, newMember, handleClearData),
                );
            }
        } else {
            showNotification('error', check.message);
        }
    };

    const toggleModal = (val) => {
        if (!val) {
            handleClearData();
        }
        dispatch(companyInviteModal(val));
        if (width <= 1025) localStorage.setItem('mobileInviteModalClosed', true);
    };

    const TeamInviteHTML = (
        <div className={TeamMember}>
            <div>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        columnGap: '1rem',
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        {newMember?.firstName && <div className={Label}>First name</div>}
                        <input
                            placeholder="First Name"
                            className={!newMember?.firstName ? InputEmpty : InputFilled}
                            value={newMember && newMember.firstName ? newMember.firstName : ''}
                            onChange={(e) => handleChange('firstName', e.target.value)}
                            type="text"
                            id="name"
                            name="name"
                            required
                        />
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        {newMember?.lastName && <div className={Label}>Last name</div>}
                        <input
                            placeholder="Last Name"
                            className={!newMember?.lastName ? InputEmpty : InputFilled}
                            value={newMember && newMember.lastName ? newMember.lastName : ''}
                            onChange={(e) => handleChange('lastName', e.target.value)}
                            type="text"
                            id="name"
                            name="name"
                            required
                        />
                    </Box>
                </Box>
                <Box sx={{ width: '100%' }}>
                    {newMember?.designation && (
                        <div style={{ marginBottom: '-0.8rem' }} className={Label}>
                            Job role
                        </div>
                    )}
                    <JobRoleDropDown
                        otherJobRole={otherJobRole}
                        setOtherJobRole={setOtherJobRole}
                        jobRole={jobRole}
                        setJobRole={setJobRole}
                        className={'InviteTeamMember'}
                        otherClassName={'InviteTeamMemberOthers'}
                    />
                </Box>

                {newMember?.email && <div className={Label}>Email</div>}
                <Box sx={{ width: '100%' }}>
                    <input
                        placeholder="Email"
                        className={!newMember?.email ? InputEmpty : InputFilled}
                        value={newMember?.email}
                        onChange={(e) => handleChange('email', e.target.value)}
                        type="email"
                        id="email"
                        name="email"
                        required
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        columnGap: '1rem',
                    }}
                >
                    <Box sx={{ width: '20%' }}>
                        <CountryCodesDropdown
                            isModal
                            setCountryCode={setCountryCode}
                            style={{ width: '100%', margin: '12px 0px' }}
                        />
                    </Box>
                    <Box sx={{ width: '80%' }}>
                        {newMember?.contactNumber && <div className={Label}>Phone no.</div>}
                        <input
                            placeholder="Phone No."
                            className={!newMember?.contactNumber ? InputEmpty : InputFilled}
                            value={newMember?.contactNumber}
                            onChange={(e) => handleChange('contactNumber', e.target.value)}
                            type="number"
                            id="contactNumber"
                            name="contactNumber"
                            required
                        />
                    </Box>
                </Box>
                <div className="mb-2"></div>
            </div>
            <div
                style={
                    isTransparent
                        ? {
                              backgroundColor: '#EAEAEA',
                              padding: '20px 2rem 1.5rem 2rem',
                              margin: '0 -1.25rem',
                          }
                        : {
                              padding: '20px 2rem 1.5rem 2rem',
                              backgroundColor: ' #EAEAEA',
                              margin: '0 -1.25rem',
                          }
                }
            >
                {!isUserInvestor && (
                    <>
                        {newMember?.inviteRole && <div className={Label}>Access</div>}

                        <select
                            defaultValue={newMember?.inviteRole}
                            onChange={(e) =>
                                handleChange('inviteRole', roleObjectMap[e.target.value].id)
                            }
                            className={
                                !newMember?.inviteRole
                                    ? SelectMemberRoleEmpty
                                    : SelectMemberRoleSelected
                            }
                            placeholder="Select Access"
                            id="inviteRole"
                            name="inviteRole"
                            required
                        >
                            <option disabled key={'1'} value="">
                                Select Access Type
                            </option>
                            {!isEmpty(invitableRoles) &&
                                invitableRoles.map((item, index) => (
                                    <option key={index} value={item}>
                                        {roleObjectMap[item]['display_name']}
                                    </option>
                                ))}
                        </select>
                    </>
                )}

                <button
                    onClick={() => inviteTeamMember()}
                    disabled={is_inviting_new_member}
                    className={SendInvite}
                >
                    {is_inviting_new_member ? 'Sending Invite' : 'Send Invite'}
                </button>
            </div>
        </div>
    );

    return (
        <>
            <Dialog
                maxWidth={'xs'}
                open={modalOpen}
                onClose={() => toggleModal(false)}
                aria-labelledby="responsive-dialog-title"
                slotProps={{
                    backdrop: {
                        style: {
                            backgroundColor: 'rgba(0,0,0,0.4)',
                        },
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                rowGap: '0.5rem',
                            }}
                        >
                            <Typography
                                variant="body1"
                                fontSize={'1rem'}
                                color={'#1C1C1C'}
                                fontWeight={600}
                            >
                                {'Invite Member'}
                            </Typography>
                            <Typography
                                variant="body2"
                                fontSize={'0.625rem'}
                                color={'#000000'}
                                fontWeight={600}
                            >
                                {
                                    'Adding Finance / Accounting Managers will help you complete application 70% faster.'
                                }
                            </Typography>
                        </Box>
                        <button type="button" className="close" onClick={() => toggleModal(false)}>
                            <span>
                                <img src="/assets/clear.png" alt=""></img>
                            </span>
                        </button>
                    </Box>
                </DialogTitle>
                <DialogContent
                    sx={{
                        paddingBottom: '0',
                    }}
                >
                    {TeamInviteHTML}
                </DialogContent>
            </Dialog>
        </>
    );
}
