import { mergeByProperty } from '../../util/calculateTermUtil';
import {
    FETCHING_INVESTOR_TEAM_MEMBERS,
    FETCH_INVESTOR_TEAM_MEMBERS,
    ADDING_NEW_INVESTOR_MEMBER,
    ADD_NEW_INVESTOR_MEMBER,
    FETCH_INVESTOR_TRANSACTIONS,
    FETCHING_INVESTOR_TRANSACTIONS,
    FETCHING_INVESTOR_INVESTBOOK_CONTRACTS,
    FETCHING_DRAWDOWN_ORDERS,
    FETCH_DRAWDOWN_ORDERS,
    FETCH_INVESTOR_INVESTBOOK_CONTRACTS,
    FETCH_INVESTBOOK_COUNT_DATA,
    FETCHING_INVESTBOOK_COUNT_DATA,
    FETCHING_INVESTOR_CART,
    ADDING_TO_INVESTOR_CART,
    ADD_TO_INVESTOR_CART,
    REMOVING_TO_INVESTOR_CART,
    REMOVE_TO_INVESTOR_CART,
    PLACING_TO_INVESTOR_CART,
    PLACE_TO_INVESTOR_CART,
    FETCHING_INVESTOR_CONTRACTS,
    FETCH_INVESTOR_CONTRACTS,
    FETCH_INVESTOR_CART,
    FETCHING_INVESTEE_CONTRACTS,
    FETCH_INVESTEE_CONTRACTS,
    FETCHING_INVESTEE_CONTRACTS_FAILED,
    PLACING_ORDER_FOR_INVESTEE,
    PLACED_ORDER_OF_INVESTEE,
    SET_MARKET_COMPANY_VIEW,
    ADDED_CART_OF_INVESTEE,
    ADDING_CART_FOR_INVESTEE,
    SET_MARKET_CONTRACT_VIEW,
    FETCH_REPAYMENT_SCHEDULE,
    FETCHING_REPAYMENT_SCHEDULE,
    CLEAR_MARKET_CONTRACTS_ON_COMPANY_CHANGE,
    FETCHING_INVESTOR_ACCOUNT_STATEMENT,
    FETCH_INVESTOR_ACCOUNT_STATEMENT,
    RESET_INVESTEE_CONTRACTS,
    RESET_INVESTOR_CONTRACTS,
    RESET_INVESTOR_INVESTBOOK_CONTRACTS,
    RESET_INVESTOR_TANSACTIONS,
    FETCH_ALL_AUTOINVEST,
    FETCH_INVESTOR_DRAWDOWNS,
    FETCHING_INVESTOR_DRAWDOWNS,
    FETCHING_OVERVIEW_DATA,
    FETCH_OVERVIEW_DATA,
    FETCH_UNDERWRITER_DETAILS_FOR_DEALS,
    SET_REPAYMENT_STATUS_FILTER,
    FETCH_DEAL_MANAGER_DETAILS_FOR_DEBT_DEALS,
    FETCH_INVESTOR_OPS_DETAILS,
    FETCHING_DEAL_MANAGER,
    FETCH_DEAL_MANAGER_SUCCESS,
    FETCH_DEAL_MANAGER_FAILURE,
} from './InvestorActions';

const initialState = {
    is_fetching_members: true,
    members: [],
    dashboardStats: [],
    is_fetching_open_contracts: false,
    open_contracts: [],
    open_contracts_total_count: 0,
    trade_contracts: [],
    is_fetching_tradebook_contracts: false,
    isFetchingDrawdownOrders: false,
    drawdownOrders: {
        id: '',
        list: [],
    },
    tradebook_contracts: [],
    platform_faqs: [],
    cart_contracts: [],
    cart_contracts_total_count: 0,
    is_fetching_investee_contracts: false,
    investee_contracts: [],
    investeeContractPageNum: 1,
    investeeContractPageSize: 50,
    investeeContractTotalCount: 0,
    is_placing_order_for_investee: false,
    investee_placed_contracts: [],
    marketCompanyView: 'contracts', // [contracts, cart, order],
    is_adding_cart_for_investee: false,
    investee_cart_contracts: [],
    anyChange: false, // used to update the UI only
    investee_order: null,
    investedContractsTotalCount: 0,
    invested_contracts: [],
    investedContractsPageSize: 50,
    investedContractsPageNum: 1,
    isFetchingInvestorDrawdowns: false,
    investorDrawdowns: [],
    investorDrawdownsTotalCount: 0,
    isFetchingInvestorPayments: false,
    investorPayments: [],
    investorPaymentsTotalCount: 0,
    is_fetching_account_statement: false,
    investor_account_statement: [],
    account_statement_total_records: 0,
    repayment_schedule_on_hover: [],
    autoinvest: [],
    autoinvest_total_count: 0,
    is_fetching_autoinvest: false,
    companyDetailPageNum: 1,
    companyDetailPageSize: 200,
    isFetchingFinancesOverviewData: false,
    total_recently_completed_drawdowns_amount: null,
    monthly_completed_payments: [],
    recently_completed_drawdowns: [],
    monthly_payments_graph: [],
    monthly_drawdowns_graph: [],
    monthly_scheduled_payments: [],
    total_upcoming_scheduled_payments_amount: [],
    underwriterDetailsforDeals: {},
    dealManagerDetailsForDebtDeals: {},
    investorOpsDetailsforDeals: null,
    isFetchingDealManager: false,
    dealManagerData: null,
    dealManagerError: null,
};

const investorReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_UNDERWRITER_DETAILS_FOR_DEALS:
            return {
                ...state,
                underwriterDetailsforDeals: action.underwriterDetailsforDeals,
            };

        case FETCH_DEAL_MANAGER_DETAILS_FOR_DEBT_DEALS:
            return {
                ...state,
                dealManagerDetailsForDebtDeals: action.dealManagerDetailsForDebtDeals,
            };

        case FETCHING_INVESTOR_TEAM_MEMBERS:
            return {
                ...state,
                is_fetching_members: action.is_fetching_members,
            };

        case ADD_NEW_INVESTOR_MEMBER:
            let members = state.members;
            if (action.member) {
                members.push(action.member);
            }
            return {
                ...state,
                is_inviting_new_member: action.is_inviting_new_member,
                members: members,
                is_adding_investor_member_failed: action.is_adding_investor_member_failed,
            };

        case ADDING_NEW_INVESTOR_MEMBER:
            return {
                ...state,
                is_inviting_new_member: action.is_inviting_new_member,
                is_adding_investor_member_failed: action.is_adding_investor_member_failed,
            };

        case FETCH_INVESTOR_TEAM_MEMBERS:
            return {
                ...state,
                is_fetching_members: action.is_fetching_members,
                members: action.members,
            };

        case RESET_INVESTOR_TANSACTIONS:
            if (action.isFiltering) {
                return {
                    ...state,
                    investor_transactions: [],
                };
            }
            return state;

        case FETCH_INVESTOR_TRANSACTIONS:
            return {
                ...state,
                isFetchingInvestorPayments: action.isFetchingInvestorPayments,
                investorPayments: action?.investorPayments ?? [],
                investorPaymentsTotalCount: action?.investorPaymentsTotalCount ?? 0,
            };

        case FETCHING_INVESTOR_TRANSACTIONS:
            return {
                ...state,
                isFetchingInvestorPayments: action.isFetchingInvestorPayments,
            };

        case FETCH_INVESTOR_DRAWDOWNS:
            return {
                ...state,
                isFetchingInvestorDrawdowns: action.isFetchingInvestorDrawdowns,
                investorDrawdowns: action?.investorDrawdowns ?? [],
                investorDrawdownsTotalCount: action?.investorDrawdownsTotalCount ?? 0,
            };

        case FETCHING_INVESTOR_DRAWDOWNS:
            return {
                ...state,
                isFetchingInvestorDrawdowns: action.isFetchingInvestorDrawdowns,
            };

        case RESET_INVESTOR_INVESTBOOK_CONTRACTS:
            if (action.isFiltering) {
                return {
                    ...state,
                    invested_contracts: [],
                };
            }
            return state;

        case FETCH_INVESTOR_INVESTBOOK_CONTRACTS:
            const invested_contracts = state.invested_contracts;
            return {
                ...state,
                is_fetching_investor_investbook_contracts:
                    action.is_fetching_investor_investbook_contracts,
                invested_contracts:
                    invested_contracts === undefined || invested_contracts.length === 0
                        ? action.invested_contracts
                        : mergeByProperty(invested_contracts, action.invested_contracts, '_id'),
                investedContractsTotalCount: action.investedContractsTotalCount,
            };

        case FETCHING_INVESTOR_INVESTBOOK_CONTRACTS:
            return {
                ...state,
                is_fetching_investor_investbook_contracts:
                    action.is_fetching_investor_investbook_contracts,
                investeeContractPageNum: action.investeeContractPageNum,
            };
        case FETCHING_DRAWDOWN_ORDERS: {
            return {
                ...state,
                isFetchingDrawdownOrders: action.data.isFetchingDrawdownOrders,
            };
        }

        case FETCH_DRAWDOWN_ORDERS: {
            return {
                ...state,
                drawdownOrders: action.data.drawdownOrders,
                isFetchingDrawdownOrders: action.data.isFetchingDrawdownOrders,
            };
        }
        case FETCH_INVESTBOOK_COUNT_DATA:
            return {
                ...state,
                is_fetching_investbook_count_data: action.is_fetching_investbook_count_data,
                investbookCountData: action.investbookCountData,
            };

        case FETCHING_INVESTBOOK_COUNT_DATA:
            return {
                ...state,
                is_fetching_investbook_count_data: action.is_fetching_investbook_count_data,
            };

        case FETCH_INVESTOR_CART:
            return {
                ...state,
                is_fetching_cart_contracts: action.is_fetching_cart_contracts,
                cart_contracts: action.cart_contracts,
                cart_contracts_total_count: action.cart_contracts_total_count,
            };

        case FETCHING_INVESTOR_CART:
            return {
                ...state,
                is_fetching_cart_contracts: action.is_fetching_cart_contracts,
            };

        case ADD_TO_INVESTOR_CART:
            return {
                ...state,
                is_adding_cart_contracts: action.is_adding_cart_contracts,
                cart_contracts: action.cart_contracts,
            };

        case ADDING_TO_INVESTOR_CART:
            return {
                ...state,
                is_adding_cart_contracts: action.is_adding_cart_contracts,
            };

        case REMOVE_TO_INVESTOR_CART:
            const cart_contracts = state.cart_contracts;
            const index = cart_contracts.findIndex(
                (item) => item._id === action.investorwishlist_id,
            );
            if (index !== -1) {
                cart_contracts.splice(index, 1);
            }
            return {
                ...state,
                is_removing_cart_contracts: action.is_removing_cart_contracts,
                cart_contracts: cart_contracts,
                cart_contracts_total_count: action.cart_contracts_total_count,
                // anyChange: !state.anyChange
            };

        case REMOVING_TO_INVESTOR_CART:
            return {
                ...state,
                is_removing_cart_contracts: action.is_removing_cart_contracts,
            };

        case PLACE_TO_INVESTOR_CART:
            return {
                ...state,
                is_placing_order: action.is_placing_order,
            };

        case PLACING_TO_INVESTOR_CART:
            return {
                ...state,
                is_placing_order: action.is_placing_order,
            };

        case CLEAR_MARKET_CONTRACTS_ON_COMPANY_CHANGE:
            return {
                ...state,
                open_contracts: [],
            };

        case FETCHING_INVESTOR_CONTRACTS:
            return {
                ...state,
                is_fetching_open_contracts: action.is_fetching_open_contracts,
            };

        case RESET_INVESTOR_CONTRACTS:
            if (action.isFiltering) {
                return {
                    ...state,
                    open_contracts: [],
                };
            }
            return state;

        case FETCH_INVESTOR_CONTRACTS:
            const open_contracts = state.open_contracts;
            return {
                ...state,
                is_fetching_open_contracts: action.is_fetching_open_contracts,
                open_contracts:
                    action.pageNum === 1
                        ? [...action.open_contracts]
                        : mergeByProperty(open_contracts, action.open_contracts, '_id'),
                open_contracts_total_count: action.open_contracts_total_count,
                companyDetailPageNum: action.pageNum,
            };

        case FETCHING_INVESTEE_CONTRACTS:
            return {
                ...state,
                is_fetching_investee_contracts: action.is_fetching_investee_contracts,
            };

        case RESET_INVESTEE_CONTRACTS:
            if (action.isFiltering) {
                return {
                    ...state,
                    investee_contracts: [],
                };
            }
            return state;

        case FETCH_INVESTEE_CONTRACTS:
            const contracts = state.investee_contracts;
            return {
                ...state,
                is_fetching_investee_contracts: action.is_fetching_investee_contracts,
                investee_contracts:
                    contracts === undefined || contracts.length === 0
                        ? action.investee_contracts
                        : mergeByProperty(contracts, action.investee_contracts, '_id'),
                investeeContractPageNum: action.investeeContractPageNum + 1,
                investeeContractTotalCount: action.investeeContractTotalCount,
                investeeContractTotalInvestableArr: action.investeeContractTotalInvestableArr,
            };

        case FETCHING_INVESTEE_CONTRACTS_FAILED:
            return {
                ...state,
                is_fetching_investee_contracts: action.is_fetching_investee_contracts,
            };

        case FETCHING_INVESTOR_ACCOUNT_STATEMENT:
            return {
                ...state,
                is_fetching_account_statement: action.is_fetching_account_statement,
            };

        case FETCH_INVESTOR_ACCOUNT_STATEMENT:
            const investor_account_statement = action.isReseting
                ? action.investor_account_statement
                : mergeByProperty(
                      state.investor_account_statement,
                      action.investor_account_statement,
                      'txn_id',
                  );
            return {
                ...state,
                is_fetching_account_statement: action.is_fetching_account_statement,
                investor_account_statement: investor_account_statement,
                account_statement_total_records: action.account_statement_total_records,
            };

        case PLACING_ORDER_FOR_INVESTEE:
            return {
                ...state,
                is_placing_order_for_investee: action.is_placing_order_for_investee,
            };
        case PLACED_ORDER_OF_INVESTEE:
            return {
                ...state,
                investee_placed_contracts: action.investee_placed_contracts,
                is_placing_order_for_investee: action.is_placing_order_for_investee,
                cart_contracts_total_count: action.cart_contracts_total_count,
                investee_order: action.order,
            };
        case ADDING_CART_FOR_INVESTEE:
            return {
                ...state,
                is_adding_cart_for_investee: action.is_adding_cart_for_investee,
            };
        case ADDED_CART_OF_INVESTEE:
            return {
                ...state,
                investee_cart_contracts: action.investee_cart_contracts,
                is_adding_cart_for_investee: action.is_adding_cart_for_investee,
                cart_contracts: action.cart_contracts,
                cart_contracts_total_count: action.cart_contracts_total_count,
            };
        case SET_MARKET_COMPANY_VIEW:
            return {
                ...state,
                marketCompanyView: action.marketCompanyView,
            };

        case SET_MARKET_CONTRACT_VIEW:
            return {
                ...state,
                marketContractView: action.marketContractView,
            };
        case FETCH_REPAYMENT_SCHEDULE:
            if (action.dispatchedOnHover) {
                return {
                    ...state,
                    repayment_schedule_on_hover: action.repayment_schedule,
                };
            } else {
                return {
                    ...state,
                    repayment_schedule: action.repayment_schedule,
                };
            }
        case FETCHING_REPAYMENT_SCHEDULE:
            return {
                ...state,
                is_fetching_repayment_schedule: action.is_fetching_repayment_schedule,
            };

        case FETCH_ALL_AUTOINVEST:
            return {
                ...state,
                autoinvest: action.autoinvest,
                autoinvest_total_count: action.autoinvest_total_count,
                is_fetching_autoinvest: action.is_fetching_autoinvest,
            };

        case FETCHING_OVERVIEW_DATA:
            return {
                ...state,
                isFetchingFinancesOverviewData: action.isFetchingFinancesOverviewData,
            };

        case FETCH_OVERVIEW_DATA:
            return {
                ...state,
                aggregateRepaymentDetails: action.aggregate_repayment_details,
                previousRepaymentDates: action.previous_repayment_dates,
                repaymentDateRange: action.repayment_date_range,
                monthlyCompletedPayments: action.monthly_completed_payments,
                recentlyCompletedDrawdowns: action.recently_completed_drawdowns,
                monthlyPaymentsGraph: action.monthly_payments_graph,
                monthlyDrawdownsGraph: action.monthly_drawdowns_graph,
                totalRecentlyCompletedDrawdownsAmount:
                    action.total_recently_completed_drawdowns_amount,
                isFetchingFinancesOverviewData: action.isFetchingFinancesOverviewData,
                monthlyScheduledPayments: action.monthly_scheduled_payments,
                totalUpcomingScheduledPaymentsAmount:
                    action.total_upcoming_scheduled_payments_amount,
            };
        case SET_REPAYMENT_STATUS_FILTER:
            return {
                ...state,
                investorRepaymentFilter: action.investor_repayment_filter,
            };
        case FETCH_INVESTOR_OPS_DETAILS:
            return {
                ...state,
                investorOpsDetailsforDeals: action.investorOpsDetailsforDeals,
            };
        case FETCHING_DEAL_MANAGER:
            return {
                ...state,
                dealManagerData: null,
                dealManagerError: null,
            };
        case FETCH_DEAL_MANAGER_SUCCESS:
            return {
                ...state,
                dealManagerData: action.payload,
            };
        case FETCH_DEAL_MANAGER_FAILURE:
            return {
                ...state,
                dealManagerError: action.error,
            };
        default:
            return state;
    }
};

export default investorReducer;

// selectors
export const getTeamMembers = (state) => state.investor.members;
export const getTeamMemberLoadingState = (state) => state.investor.is_fetching_members;
export const getIsAddingTeamMemberFailed = (state) =>
    state.investor.is_adding_investor_member_failed;
export const getDashboardStats = (state) => state.investor.dashboardStats;
export const getTradeContracts = (state) => state.investor.trade_contracts;
export const getTradeBookContracts = (state) => state.investor.tradebook_contracts;
export const getisFetchingTransactions = (state) => state.investor.isFetchingInvestorPayments;
export const getInvestorTransactions = (state) => state.investor.investorPayments;
export const getInvestorTransactionsTotalCount = (state) =>
    state.investor.investorPaymentsTotalCount;
export const getFetchingInvestbookstate = (state) =>
    state.investor.is_fetching_investor_investbook_contracts;
export const getInvestbookContracts = (state) => state.investor.invested_contracts;
export const getInvestbookCountData = (state) => state.investor.investbookCountData;
export const getDrawdownOrders = (state) => state.investor.drawdownOrders;
export const getIsFetchingDrawdownOrders = (state) => state.investor.isFetchingDrawdownOrders;
export const getCartContractsLoadingState = (state) => state.investor.is_fetching_cart_contracts;
export const getCartContractsAddingState = (state) => state.investor.is_adding_cart_contracts;
export const getCartContractsRemovingState = (state) => state.investor.is_removing_cart_contracts;
export const getOrderPlacementState = (state) => state.investor.is_placing_order;
export const getCartContracts = (state) => state.investor.cart_contracts;
export const getCartContractsTotalCount = (state) => state.investor.cart_contracts_total_count;
export const getOpenContractLoadingState = (state) => state.investor.is_fetching_open_contracts;
export const getInvesteeContractLoadingState = (state) =>
    state.investor.is_fetching_investee_contracts;
export const getInvesteeContractPageNum = (state) => state.investor.investeeContractPageNum;
export const getInvesteeContractPageSize = (state) => state.investor.investeeContractPageSize;
export const getInvesteeContractTotalCount = (state) => state.investor.investeeContractTotalCount;
export const getInvesteeContractTotalInvestableArr = (state) =>
    state.investor.investeeContractTotalInvestableArr;
export const getOpenContracts = (state) => state.investor.open_contracts;
export const getOpenContractsTotalCount = (state) => state.investor.open_contracts_total_count;
export const getInvestorMonthlyScheduledPayments = (state) =>
    state.investor.monthlyScheduledPayments;
export const getInvestorMonthlyScheduledPaymentsGraph = (state) =>
    state.investor.monthly_scheduled_payments_graph;
export const getInvestorMonthlyCompletedPayouts = (state) =>
    state.investor.monthly_completed_payouts;
export const getInvestorRecentInvestments = (state) => state.investor.investor_payouts;
export const getInvesteeContracts = (state) => state.investor.investee_contracts;
export const getMarketCompanyView = (state) => state.investor.marketCompanyView;
export const getMarketContractView = (state) => state.investor.marketContractView;
export const getInvesteeOrderPlacedLoadingState = (state) =>
    state.investor.is_placing_order_for_investee;
export const getInvesteeCartLoadingState = (state) => state.investor.is_adding_cart_for_investee;
export const getInvesteePlacedOrders = (state) => state.investor.investee_placed_contracts;
export const getInvesteeCartItems = (state) => state.investor.investee_cart_contracts;
export const getInvesteeOrder = (state) => state.investor.investee_order;
export const checkAnyChange = (state) => state.investor.anyChange;
export const getRepaymentSchedule = (state) => state.investor.repayment_schedule;
export const getInvestedContractsTotalCount = (state) => state.investor.investedContractsTotalCount;
export const getInvestedContractsPageSize = (state) => state.investor.investedContractsPageSize;
export const getInvestedContractsPageNum = (state) => state.investor.investedContractsPageNum;
export const getIfInvestorIsFetchingAccountStatement = (state) =>
    state.investor.is_fetching_account_statement;
export const getInvestorAccountStatement = (state) => state.investor.investor_account_statement;
export const getInvestorAccountStatementTotalRecords = (state) =>
    state.investor.account_statement_total_records;
export const getInvestorRepaymentScheduleOnHover = (state) =>
    state.investor.repayment_schedule_on_hover;
export const getAutoInvest = (state) => state.investor.autoinvest;
export const getAutoInvestCount = (state) => state.investor.autoinvest_total_count;
export const getAutoInvestIsFetching = (state) => state.investor.is_fetching_autoinvest;
export const getCompanyDetailPageNum = (state) => state.investor.companyDetailPageNum;
export const getCompanyDetailPageSize = (state) => state.investor.companyDetailPageSize;
export const getAllCompanies = (state) => state.investor.investee_contracts;
export const getInvestorMonthlyCompletedPayments = (state) =>
    state.investor.monthlyCompletedPayments;
export const getInvestorRecentlyCompletedDrawdowns = (state) =>
    state.investor.recentlyCompletedDrawdowns;
export const getInvestorMonthlyPaymentsGraph = (state) => state.investor.monthlyPaymentsGraph;
export const getInvestorMonthlyDrawdownsGraph = (state) => state.investor.monthlyDrawdownsGraph;
export const getTotalRecentlyCompletedDrawdownsAmount = (state) =>
    state.investor.totalRecentlyCompletedDrawdownsAmount;
export const getTotalUpcomingScheduledPaymentsAmount = (state) =>
    state.investor.totalUpcomingScheduledPaymentsAmount;
export const getIfFetchingFinancesOverviewData = (state) =>
    state.investee.isFetchingFinancesOverviewData;
export const getUnderwriterDetailsforDeals = (state) => state.investor.underwriterDetailsforDeals;
export const getAggregateRepaymentDetails = (state) => state.investor.aggregateRepaymentDetails;
export const getInvestorRepaymentDateRange = (state) => state.investor.repaymentDateRange;
export const getPreviousRepaymentDates = (state) => state.investor.previousRepaymentDates;
export const getInvestorRepaymentFilters = (state) => state.investor.investorRepaymentFilter;
export const getDealManagerDetailsForDebtDeals = (state) =>
    state.investor.dealManagerDetailsForDebtDeals;
export const getInvestorOpsDetails = (state) => state.investor.investorOpsDetailsforDeals;
export const getDealManagerData = (state) => state.investor.dealManagerData;
