export default {
    MuiToggleButtonGroup: {
        styleOverrides: {
            root: {
                borderRadius: 8,
            },
        },
    },
    MuiToggleButton: {
        styleOverrides: {
            root: {
                borderRadius: 8,
            },
        },
    },
};
