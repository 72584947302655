import { useEffect, useState } from 'react';
import styles from './Widget.module.scss';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    orgStatusLockedModal,
    toggleOnboardingReapplicationModal,
    triggerDrawer,
} from '../../../App/AppActions';
import { DRAWER_HEADINGS } from '../../../../DrawerConsts';
import { blockWidgetStep, CATEGORIES, DATA_LABELS, KYC_LABELS } from './utils';
import {
    ADD_ON_LIMIT_STATUS,
    INVESTEE_ORG_LOCKED,
    NO_BID_ADDON_DESCRIPTION,
    SHOW_NOTIFICATION_STATUS,
    WIDGETS,
} from '../../../../enums/appEnums';
import { ROUTES_MAP, SIDEBAR_LABELS } from '../../../../components/Sidebar/consts/consts';
import {
    getEmail,
    getInvesteeOrganizationInfo,
    getUserInvesteeOrganizationId,
    getWidgetData,
} from '../../../App/AppReducer';
import { mixPanelTrack, showNotification } from '../../../../util/utility';
import { triggerAddOnLimitModal } from '../../InvesteeActions';
import { getAddOnLimitStatus } from '../../InvesteeReducers';

export default function Widget() {
    const ICONS = {
        cellGrey: 'cell-grey',
        cellBlack: 'cell-black',
        cellBlue: 'cell-blue',
        rightArrowBlack: 'black-right-arrow',
        rightArrowBlue: 'blue-right-arrow',
        lockGrey: 'lock-grey',
        tickGreen: 'widget-green-tick',
        exclamationOrange: 'widget-orange-exclamation',
    };
    const MAIN_STATUSES = {
        START_NOW: 'start now',
        UPLOAD_DATA: 'upload data',
        NEED_DATA_INFO: 'need data info',
        UNDER_REVIEW: 'under review',
        UPLOAD_KYC: 'upload kyc',
        BIDDING_ONGOING: 'bidding ongoing',
        ACCEPT_TERMS: 'accept terms',
        SIGN_SANCTION: 'sign sanction',
        SETUP_COLLECTIONS: 'setup collections',
        APPROVED: 'approved',
        NO_BIDS: 'no bids',
    };
    const CELLS = [1, 2, 3, 4, 5];
    const {
        CursorDefault,
        MainContainer,
        MainContainerSelected,
        LabelWithStatus,
        Label,
        StartNow,
        UploadData,
        NeedDataInfo,
        UnderReview,
        UploadKyc,
        BiddingOngoing,
        AcceptTerms,
        SignSanction,
        SetupCollections,
        Approved,
        NoBids,
        ProgressContainer,
        HoveredWidgetContainer,
        DataContainer,
        KycContainer,
        ImageContainer,
        InProcess,
        OrangeColor,
        GreenColor,
        GreyColor,
        BlackColor,
        BlueColor,
        WhiteBG,
        BlueBG,
        GreyBG,
        HorizontalLine,
    } = styles;

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(false);
    const [isMiniContainerHovered, setIsMiniContainerHovered] = useState<string | null>(null);
    const [isSelected, setIsSelected] = useState<string | null>(null);
    const email = useSelector(getEmail);
    const investeeOrgId = useSelector(getUserInvesteeOrganizationId);
    const investeeOrgStatus = useSelector(getInvesteeOrganizationInfo)?.status;
    const widget = useSelector(getWidgetData);
    const addOnLimitStatus = useSelector(getAddOnLimitStatus);
    const {
        label,
        status,
        dataCoverage,
        kycCoverage,
        dataStatus,
        kycStatus,
        kycStatusText,
        isKycLocked,
        applicationStatus,
    } = widget ?? {
        label: 'Application Widget',
        status: 'Start now',
        dataCoverage: 0,
        kycCoverage: 0,
        dataStatus: 'Upload Data',
        kycStatus: 'Upload KYC',
        isKycLocked: true,
        applicationStatus: 'Open',
    };
    const inProcess = applicationStatus === 'Submitted';
    const isApplicationApproved = applicationStatus === 'Approved';
    const progress = Math.round(((dataCoverage ?? 0) + (kycCoverage ?? 0)) / 2);

    useEffect(() => {
        if (
            location.pathname === ROUTES_MAP[SIDEBAR_LABELS.ONBOARDING] ||
            location.pathname === ROUTES_MAP[SIDEBAR_LABELS.ADD_ON_LIMIT]
        ) {
            if (!isSelected) setIsSelected(CATEGORIES.DATA);
        } else if (location.pathname === ROUTES_MAP[SIDEBAR_LABELS.LIMIT_DOCUMENTS]) {
            if (!isSelected) setIsSelected(CATEGORIES.KYC);
        } else {
            if (isMiniContainerHovered) setIsMiniContainerHovered(null);
            if (isSelected) setIsSelected(null);
        }
    }, [location.pathname]);

    const getStatusClassName = (status: string) => {
        switch (status.toLowerCase()) {
            case MAIN_STATUSES.START_NOW:
                return StartNow;
            case MAIN_STATUSES.UPLOAD_DATA:
                return UploadData;
            case MAIN_STATUSES.NEED_DATA_INFO:
                return NeedDataInfo;
            case MAIN_STATUSES.UNDER_REVIEW:
                return UnderReview;
            case MAIN_STATUSES.UPLOAD_KYC:
                return UploadKyc;
            case MAIN_STATUSES.BIDDING_ONGOING:
                return BiddingOngoing;
            case MAIN_STATUSES.ACCEPT_TERMS:
                return AcceptTerms;
            case MAIN_STATUSES.SIGN_SANCTION:
                return SignSanction;
            case MAIN_STATUSES.SETUP_COLLECTIONS:
                return SetupCollections;
            case MAIN_STATUSES.APPROVED:
                return Approved;
            case MAIN_STATUSES.NO_BIDS:
                return NoBids;
        }
    };

    const getDataContainerClassName = () => {
        let className = `${DataContainer}`;
        if (
            inProcess ||
            (isApplicationApproved &&
                dataStatus !== DATA_LABELS.ACCEPT_TERMS &&
                dataStatus !== DATA_LABELS.SIGN_SANCTION_DOCS &&
                dataStatus !== DATA_LABELS.SETUP_COLLECTIONS)
        )
            className += ` ${CursorDefault}`; // the case where step1 in widget is blocked
        else className += ` cp`; // the case where step1 in widget is not blocked
        if (dataStatus === DATA_LABELS.NO_INVESTOR_BIDS) {
            // the case where application is waitlisted
            return (
                className +
                ` ${isMiniContainerHovered === CATEGORIES.DATA ? WhiteBG : GreyBG} ${OrangeColor}`
            );
        }
        if (
            isApplicationApproved &&
            dataStatus !== DATA_LABELS.ACCEPT_TERMS &&
            dataStatus !== DATA_LABELS.SIGN_SANCTION_DOCS &&
            dataStatus !== DATA_LABELS.SETUP_COLLECTIONS
        )
            // the case where step1 needs to be blocked with green color since this step is done and is approved by Admin
            return (
                className +
                ` ${isMiniContainerHovered === CATEGORIES.DATA ? WhiteBG : GreyBG} ${GreenColor}`
            );
        if (inProcess)
            // the case where user can not do anything, so this step will be blocked for later
            return (
                className +
                ` ${isMiniContainerHovered === CATEGORIES.DATA ? WhiteBG : GreyBG} ${GreyColor}`
            );
        if (isMiniContainerHovered === CATEGORIES.DATA || isSelected === CATEGORIES.DATA)
            // the case where step1 is selected or hovered
            return className + ` ${BlueBG} ${BlueColor}`;
        return className + ` ${WhiteBG} ${BlackColor}`;
    };

    const getKycContainerClassName = () => {
        let className = `${KycContainer}`;
        if (isKycLocked || kycStatus === KYC_LABELS.RE_APPLY_IN_SOMETIME)
            className += ` ${CursorDefault}`; // the case where step2 in widget is blocked
        else className += ` cp`; // the case where step2 in widget is not blocked
        if (isKycLocked || kycStatus === KYC_LABELS.RE_APPLY_IN_SOMETIME)
            // the case where user can not do anything, so this step will be blocked for later
            return (
                className +
                ` ${isMiniContainerHovered === CATEGORIES.KYC ? WhiteBG : GreyBG} ${GreyColor}`
            );
        if (isMiniContainerHovered === CATEGORIES.KYC || isSelected === CATEGORIES.KYC)
            // the case where step2 is selected or hovered
            return className + ` ${BlueBG} ${BlueColor}`;
        return className + ` ${WhiteBG} ${BlackColor}`;
    };

    const roundOffCoverage = (percentage: number) => {
        if (percentage >= 100) return 100;
        if (percentage >= 80) return 80;
        if (percentage >= 60) return 60;
        if (percentage >= 40) return 40;
        if (percentage >= 20) return 20;
        return 0;
    };

    const getLeftIconName = (category: string, number: number) => {
        const numberOfCellWithPercentMap = {
            0: 0,
            20: 1,
            40: 2,
            60: 3,
            80: 4,
            100: 5,
        };
        const { cellGrey, cellBlack, cellBlue } = ICONS;
        if (category === CATEGORIES.DATA) {
            if (
                inProcess ||
                isApplicationApproved ||
                dataStatus === DATA_LABELS.ACCEPT_TERMS ||
                dataStatus === DATA_LABELS.SIGN_SANCTION_DOCS ||
                dataStatus === DATA_LABELS.SETUP_COLLECTIONS ||
                dataStatus === DATA_LABELS.NO_INVESTOR_BIDS
            )
                // the case where we won't show any cells
                return;
            const numberOfFilledPillsForData =
                numberOfCellWithPercentMap[roundOffCoverage(dataCoverage ?? 0)];
            if (number <= numberOfFilledPillsForData) {
                if (isMiniContainerHovered === CATEGORIES.DATA || isSelected === CATEGORIES.DATA)
                    // the case where we will show blue cells coz this step is either selected or hovered
                    return cellBlue;
                return cellBlack; // the case where we will show black cells coz this step is neither selected nor hovered
            }
            return cellGrey; // the case where we will show grey cells coz this step is not even 20% done
        }
        // KYC Case starts
        if (blockWidgetStep(widget, CATEGORIES.KYC)) return; // the case where we won't show any cells
        const numberOfFilledPillsForKyc =
            numberOfCellWithPercentMap[roundOffCoverage(kycCoverage ?? 0)];
        if (number <= numberOfFilledPillsForKyc) {
            if (isMiniContainerHovered === CATEGORIES.KYC || isSelected === CATEGORIES.KYC)
                // the case where we will show blue cells coz this step is either selected or hovered
                return cellBlue;
            return cellBlack; // the case where we will show black cells coz this step is neither selected nor hovered
        }
        return cellGrey; // the case where we will show grey cells coz this step is not even 20% done
    };

    const getDataRightIconName = () => {
        const { tickGreen, exclamationOrange, rightArrowBlue, rightArrowBlack } = ICONS;
        if (inProcess) return; // application submitted case
        if (dataStatus === DATA_LABELS.NO_INVESTOR_BIDS) return exclamationOrange; // waitlisted case
        if (
            isApplicationApproved &&
            dataStatus !== DATA_LABELS.ACCEPT_TERMS &&
            dataStatus !== DATA_LABELS.SIGN_SANCTION_DOCS &&
            dataStatus !== DATA_LABELS.SETUP_COLLECTIONS
        )
            return tickGreen; // application approved case
        if (isMiniContainerHovered === CATEGORIES.DATA || isSelected === CATEGORIES.DATA)
            // hovered or selected case
            return rightArrowBlue;
        return rightArrowBlack; // normal case when neither hovered nor selected
    };

    const getKycRightIconName = () => {
        const { lockGrey, rightArrowBlue, rightArrowBlack } = ICONS;
        if (kycStatus.toUpperCase() === KYC_LABELS.RE_APPLY_IN_SOMETIME.toUpperCase()) return; // waitlisted case
        if (isKycLocked) return lockGrey; // kyc locked case
        if (isMiniContainerHovered === CATEGORIES.KYC || isSelected === CATEGORIES.KYC)
            // hovered or selected case
            return rightArrowBlue;
        return rightArrowBlack; // normal case when neither hovered nor selected
    };

    const handleDataContainerClick = () => {
        if (!blockWidgetStep(widget, CATEGORIES.DATA)) {
            if (dataStatus === DATA_LABELS.ACCEPT_TERMS)
                navigate(ROUTES_MAP[SIDEBAR_LABELS.LIMIT_DOCUMENTS], {
                    state: { acceptTerms: true },
                });
            else if (dataStatus === DATA_LABELS.SIGN_SANCTION_DOCS)
                navigate(ROUTES_MAP[SIDEBAR_LABELS.LIMIT_DOCUMENTS], {
                    state: { limitDocs: true },
                });
            else if (dataStatus === DATA_LABELS.SETUP_COLLECTIONS)
                navigate(ROUTES_MAP[SIDEBAR_LABELS.LIMIT_DOCUMENTS], {
                    state: { setupCollection: true },
                });
            else
                navigate(
                    `/company/${
                        label === WIDGETS.APPLICATION_WIDGET ? 'onboarding' : 'add-on-limit'
                    }`,
                );
        }
    };

    const handleRequestAddOn = () => {
        if (investeeOrgStatus === INVESTEE_ORG_LOCKED) {
            dispatch(orgStatusLockedModal(true));
            return;
        }
        dispatch(triggerAddOnLimitModal(true));
    };

    const handleKycContainerClick = () => {
        if (!isKycLocked && kycStatus !== KYC_LABELS.RE_APPLY_IN_SOMETIME) {
            if (kycStatus === KYC_LABELS.VIEW_APPROVED_LIMIT) {
                //dispatch(triggerDrawer(DRAWER_HEADINGS.ALL_TERMS_SHEET));
                navigate('/company/recur-products?type=swift');
                setIsSelected(null);
            } else if (kycStatus === KYC_LABELS.RE_APPLY) {
                if (addOnLimitStatus === ADD_ON_LIMIT_STATUS.ALLOWED_TO_REQUEST)
                    handleRequestAddOn();
                else dispatch(toggleOnboardingReapplicationModal(true));
                setIsSelected(null);
            } else navigate('/company/get-started');
        }
    };

    const onDataSectionClick = () => {
        if (!blockWidgetStep(widget, CATEGORIES.DATA)) {
            setIsSelected(CATEGORIES.DATA);
            handleDataContainerClick();
            mixPanelTrack({
                id: investeeOrgId,
                trackUserObj: {
                    email,
                    status,
                    progressPercentage: progress,
                    sec1Status: dataStatus,
                    sec1Progress: dataCoverage,
                    sec2Status: kycStatus,
                    sec2Progress: kycCoverage,
                },
                trackCategoryName: `${label} - section 1 click`,
            });
        }
    };

    const onKycSectionClick = () => {
        if (!isKycLocked && kycStatus !== KYC_LABELS.RE_APPLY_IN_SOMETIME) {
            setIsSelected(CATEGORIES.KYC);
            handleKycContainerClick();
            mixPanelTrack({
                id: investeeOrgId,
                trackUserObj: {
                    email,
                    status,
                    progressPercentage: progress,
                    sec1Status: dataStatus,
                    sec1Progress: dataCoverage,
                    sec2Status: kycStatus,
                    sec2Progress: kycCoverage,
                },
                trackCategoryName: `${label} - section 2 click`,
            });
        }
    };

    if (!isHovered)
        return (
            <div
                id={MainContainer}
                className={isSelected ? MainContainerSelected : ''}
                onMouseOver={() => setIsHovered(true)}
            >
                <div className={LabelWithStatus}>
                    <div className={Label}>{label.replace(' ' + WIDGETS.WIDGET, '')}</div>
                    <div className={getStatusClassName(status ?? '')}>
                        <div>{status}</div>
                    </div>
                </div>
                {kycStatus !== KYC_LABELS.RE_APPLY_IN_SOMETIME && (
                    <div className={ProgressContainer}>
                        <CircularProgressbar
                            value={progress}
                            text={`${progress}%`}
                            strokeWidth={21}
                            styles={{
                                path: {
                                    stroke: '#4CB71A',
                                    transition: 'stroke-dashoffset 0.1s ease 0s',
                                    strokeLinecap: 'butt',
                                },
                                trail: {
                                    stroke: '#ecedf1',
                                    strokeLinecap: 'butt',
                                    transition: 'stroke-dashoffset 0.1s ease 0s',
                                },
                                text: {
                                    fill: '#000000',
                                },
                            }}
                        />
                    </div>
                )}
            </div>
        );
    return (
        <div className={HoveredWidgetContainer} onMouseLeave={() => setIsHovered(false)}>
            <div
                className={getDataContainerClassName()}
                onMouseOver={() => setIsMiniContainerHovered(CATEGORIES.DATA)}
                onMouseLeave={() => setIsMiniContainerHovered(null)}
                onClick={onDataSectionClick}
            >
                <div>{dataStatus}</div>
                <div className={ImageContainer}>
                    {!(
                        inProcess ||
                        isApplicationApproved ||
                        dataStatus === DATA_LABELS.ACCEPT_TERMS ||
                        dataStatus === DATA_LABELS.SIGN_SANCTION_DOCS ||
                        dataStatus === DATA_LABELS.SETUP_COLLECTIONS ||
                        dataStatus === DATA_LABELS.NO_INVESTOR_BIDS
                    ) && (
                        <div>
                            {CELLS.map((number) => (
                                <img
                                    className="r-mr-2"
                                    src={`/assets/${getLeftIconName(CATEGORIES.DATA, number)}.svg`}
                                    alt=""
                                />
                            ))}
                        </div>
                    )}
                    {inProcess ? (
                        <span className={InProcess}>(in process)</span>
                    ) : (
                        <div>
                            <img
                                className="r-ml-3"
                                src={`/assets/${getDataRightIconName()}.svg`}
                                alt=""
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={HorizontalLine} />
            <div
                className={getKycContainerClassName()}
                onMouseOver={() => setIsMiniContainerHovered(CATEGORIES.KYC)}
                onMouseLeave={() => setIsMiniContainerHovered(null)}
                onClick={onKycSectionClick}
            >
                <div>{kycStatusText ?? kycStatus}</div>
                <div className={ImageContainer}>
                    {!blockWidgetStep(widget, CATEGORIES.KYC) && (
                        <div>
                            {CELLS.map((number) => (
                                <img
                                    className="r-mr-2"
                                    src={`/assets/${getLeftIconName(CATEGORIES.KYC, number)}.svg`}
                                    alt=""
                                />
                            ))}
                        </div>
                    )}
                    {kycStatus !== KYC_LABELS.RE_APPLY_IN_SOMETIME && (
                        <div>
                            <img
                                className="r-ml-3"
                                src={`/assets/${getKycRightIconName()}.svg`}
                                alt=""
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
