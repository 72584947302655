import _ from 'lodash';
import moment from 'moment';
import { priceFormat, priceFormatNoRounding } from '../util/utility';

const CustomTooltip = ({ active, payload, label, isPie, isUpcoming }) => {
    const renderContent = () => {
        if (isPie) {
            const [obj1, obj2, obj3, obj4] = isPie;
            return (
                <>
                    <div className="fs10 clr29">Total Contract Traded (100%)</div>
                    <div className="fs14 fw6">
                        <i className="fa fa-rupee-sign"></i>{' '}
                        {priceFormat(obj1.value + obj2.value + obj3.value + obj4.value)}
                    </div>
                    <div className="hr"></div>
                    <div className="fs10 clr29">
                        Trade Discount{' '}
                        {!_.isNil(obj1.percent) && `(${Number(obj1.percent).toFixed(2)}%)`}
                    </div>
                    <div className="fs14 clr30 fw6">
                        - <i className="fa fa-rupee-sign"></i> {priceFormat(obj1.value)}
                    </div>
                    <div className="fs10  clr29 mt-2">
                        Financial Advisory Fees{' '}
                        {!_.isNil(obj2.percent) && `(${Number(obj2.percent).toFixed(2)}%)`}
                    </div>
                    <div className="fs14  clr33 mb-2 fw6">
                        - <i className="fa fa-rupee-sign"></i> {priceFormat(obj2.value)}
                    </div>
                    <div className="fs10 clr29">
                        GST credits{' '}
                        {!_.isNil(obj3.percent) && `(${Number(obj3.percent).toFixed(2)}%)`}
                    </div>
                    <div className="fs14 fw6" style={{ color: '#0E6625' }}>
                        - <i className="fa fa-rupee-sign"></i> {priceFormat(obj3.value)}
                    </div>
                    <div className="hr"></div>
                    <div className="fs10 clr29">Total Received</div>
                    <div className="fs14 clr32 fw6">
                        + <i className="fa fa-rupee-sign"></i> {priceFormat(obj4.value)}
                    </div>
                </>
            );
        } else if (isUpcoming) {
            return (
                <div
                    className={`fs-10`}
                    style={{
                        width: '160px',
                        height: '120px',
                        background: '#FFFFFF',
                        borderRadius: '4px',
                        opacity: 1,
                        boxShadow: '1px 1px 1px 1px #888888',
                    }}
                >
                    <div className="text-grey26 r-p-4">{`${label} `}</div>
                    <div style={{ borderTop: '1px solid #e8e3e3' }}></div>
                    <br></br>
                    <div className="display-f r-pl-4">
                        <div
                            className="w-20"
                            style={{ width: '10px', height: '10px', background: '#D3B3FF' }}
                        ></div>
                        <div className="w-40" style={{ margin: '0 3px 0 3px', fontSize: '9px' }}>
                            {' '}
                            Scheduled{' '}
                        </div>
                        <div style={{ fontSize: '9px' }}>
                            <i className="fa fa-rupee-sign"></i>{' '}
                            {priceFormat(payload[0]?.payload?.scheduled_amount)}
                        </div>
                    </div>
                    <div className="display-f r-pl-4">
                        <div
                            className="w-20"
                            style={{ width: '10px', height: '10px', background: '#FFCCBC' }}
                        ></div>
                        <div className="w-40" style={{ margin: '0 3px 0 3px', fontSize: '9px' }}>
                            {' '}
                            Overdue till now {'   '}
                        </div>
                        <div style={{ fontSize: '9px' }}>
                            <i className="fa fa-rupee-sign"></i>{' '}
                            {priceFormat(payload[0]?.payload?.overdue_amount)}
                        </div>
                    </div>
                    <br></br>
                    <div style={{ borderTop: '1px solid #e8e3e3' }}></div>
                    <div className="display-f r-p-4 ">
                        <div className="w-50">Total</div>
                        <div className="w-50">
                            {priceFormat(payload[0]?.value + payload[1]?.value)}
                        </div>
                        {/* <div>₹ {priceFormat(repayment.interest + repayment.principal)}</div> */}
                    </div>
                </div>
            );
        }
        return (
            <div
                className={`fs-12`}
                style={{
                    width: '160px',
                    height: '130px',
                    background: '#FFFFFF',
                    borderRadius: '4px',
                    boxShadow: '1px 1px 1px 1px #888888',
                    fontWeight: 900,
                }}
            >
                <div className="text-grey26 r-p-4">{`${moment(label, 'MMM YY').format(
                    'MMM YYYY',
                )} `}</div>
                <div style={{ borderTop: '1px solid #e8e3e3' }}></div>
                <br></br>
                <div className="display-f r-pl-4">
                    <div
                        className="w-20"
                        style={{ width: '10px', height: '10px', background: '#e1f7ff' }}
                    ></div>
                    <div className="w-40" style={{ margin: '0 3px 0 3px', fontSize: '10px' }}>
                        {' '}
                        Scheduled{' '}
                    </div>
                    <div style={{ fontSize: '10px' }}>
                        <i className="fa fa-rupee-sign"></i>{' '}
                        {priceFormat(payload[0]?.payload?.scheduled)}
                    </div>
                </div>
                <div className="display-f r-pl-4">
                    <div
                        className="w-20"
                        style={{ width: '10px', height: '10px', background: '#FFCCBC' }}
                    ></div>
                    <div className="w-40" style={{ margin: '0 3px 0 3px', fontSize: '10px' }}>
                        {' '}
                        Overdue {'   '}
                    </div>
                    <div style={{ fontSize: '10px' }}>
                        <i className="fa fa-rupee-sign"></i>{' '}
                        {priceFormat(payload[0]?.payload?.overdue)}
                    </div>
                </div>
                <br></br>
                <div style={{ borderTop: '1px solid #e8e3e3' }}></div>
                <div className="display-f r-p-4 ">
                    <div className="w-50">Total</div>
                    <div className="w-50">
                        <i className="fa fa-rupee-sign"></i>{' '}
                        {priceFormat(payload[0]?.payload?.totalAmount)}
                    </div>
                    {/* <div>₹ {priceFormat(repayment.interest + repayment.principal)}</div> */}
                </div>
            </div>
        );
    };

    if (active && payload && payload.length) {
        return <div>{renderContent()}</div>;
    }

    return null;
};

export default CustomTooltip;
