import _ from 'lodash';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import callApi from '../../util/apiCaller';
import {
    API_STATUS,
    CERBERUS_STATUS_CODES,
    DAYJS_DATE_FORMAT,
    PRODUCTION,
    ROUTE,
    SHOW_NOTIFICATION_STATUS,
    USER_TYPE_INVESTEE,
    USER_TYPE_INVESTOR,
} from '../../enums/appEnums';
import storage from '../../util/storageService';
import {
    closeHubspotLiveChat,
    mixPanelTrack,
    dealChestUserRedirect,
    showNotification,
    isEnvProd,
    generateRequestId,
} from '../../util/utility';
import { Mixpanel } from '../../util/mixpanel';
import {
    getBanks,
    getCompanySidebarConfigTab,
    getSecondaryRcmData,
    getUserInfo,
    fetchWidgetData,
    loginHelper,
    getCounterSignUserApi,
    getApplicationStr,
} from '../Investee/common/apiHelpers';
import { INVESTOR } from '../Investee/pages/InvesteeOnboardingNew/components/BankAccounts/constants/consts';
import {
    callAptToFetchCapitalAvailableData,
    updateTallyConnectorStatus,
} from '../../common/ApiContainer';
import { getDocVerificationDeals } from '../Investee/common/apiHelpers';
import { EMAIL_NOT_VERIFIED_MESSAGE } from '../../constants/consts';
import { fetchInvestorTeamMembers } from '../Investor/InvestorActions';
import { fetchUpcomingPaymentDetails } from '../Investee/utility/utility';

export const UPDATING_INVESTEE_ORGANIZATION_INFO = 'UPDATING_INVESTEE_ORGANIZATION_INFO';
export const UPDATED_INVESTEE_ORGANIZATION_INFO = 'UPDATED_INVESTEE_ORGANIZATION_INFO';
export const ATTEMPT_TO_LOGIN = 'ATTEMPT_TO_LOGIN';
export const OPEN_COMPANY_HELP_MODAL = 'OPEN_COMPANY_HELP_MODAL';
export const OPEN_COMPANY_ADD_BANK_MODAL = 'OPEN_COMPANY_ADD_BANK_MODAL';
export const OPEN_COMPANY_INVITE_MODAL = 'OPEN_COMPANY_INVITE_MODAL';
export const OPEN_INVESTOR_HELP_MODAL = 'OPEN_INVESTOR_HELP_MODAL';
export const OPEN_INVESTOR_INVITE_MODAL = 'OPEN_INVESTOR_INVITE_MODAL';
export const OPEN_COMPANY_WITHDRAW_MODAL = 'OPEN_COMPANY_WITHDRAW_MODAL';
export const OPEN_COMPANY_DEPOSIT_MODAL = 'OPEN_COMPANY_DEPOSIT_MODAL';
export const OPEN_COMPANY_INTEGRATION_MODAL = 'OPEN_COMPANY_INTEGRATION_MODAL';
export const OPEN_COMPANY_UPDATE_DATA_MODAL = 'OPEN_COMPANY_UPDATE_DATA_MODAL';
export const TOGGLE_ONBOARDING_REAPPLICATION_MODAL = 'TOGGLE_ONBOARDING_REAPPLICATION_MODAL';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const ATTEMPT_TO_LOGOUT = 'ATTEMPT_TO_LOGOUT';

export const CHANGE_USER_DATA = 'CHANGE_USER_DATA';
export const CHANGE_INVESTEE_DATA = 'CHANGE_INVESTEE_DATA';

export const CHANGE_INVESTOR_DATA = 'CHANGE_INVESTOR_DATA';

export const UPDATING_INVESTEE_INFO = 'UPDATING_INVESTEE_INFO';
export const UPDATE_INVESTEE_INFO = 'UPDATE_INVESTEE_INFO';

export const UPDATING_INVESTOR_INFO = 'UPDATING_INVESTOR_INFO';
export const UPDATE_INVESTOR_INFO = 'UPDATE_INVESTOR_INFO';

export const FETCHING_PLATFORM_FAQS = 'FETCHING_PLATFORM_FAQS';
export const FETCH_PLATFORM_FAQS = 'FETCH_PLATFORM_FAQS';

export const FETCHING_USER_PROFILE = 'FETCHING_USER_PROFILE';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_USER_INVITABLE_ROLES = 'FETCH_USER_INVITABLE_ROLES';
export const FETCHING_USER_INVITABLE_ROLES = 'FETCHING_USER_INVITABLE_ROLES';

export const FETCH_INVESTOR_PROFILE = 'FETCH_INVESTOR_PROFILE';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export const FETCHING_BANK_LIST = 'FETCHING_BANK_LIST';
export const FETCH_BANK_LIST = 'FETCH_BANK_LIST';

export const FETCHING_USER_ESCROW_ACCOUNT_DETAILS = 'FETCHING_USER_ESCROW_ACCOUNT_DETAILS';
export const FETCH_USER_ESCROW_ACCOUNT_DETAILS = 'FETCH_USER_ESCROW_ACCOUNT_DETAILS';

export const CREATING_USER_BANK_ACCOUNT = 'CREATING_USER_BANK_ACCOUNT';
export const USER_ADDING_BANK = 'USER_ADDING_BANK';
export const USER_ADDING_BANK_FAILED = 'USER_ADDING_BANK_FAILED';
export const FETCH_INVESTOR_ESCROW_ACCOUNT_INFO = 'FETCH_INVESTOR_ESCROW_ACCOUNT_INFO';
export const FETCHING_INVESTOR_ESCROW_ACCOUNT_INFO = 'FETCHING_INVESTOR_ESCROW_ACCOUNT_INFO';
export const FETCH_INVESTOR_DRAWDOWN_REQUESTS = 'FETCH_INVESTOR_DRAWDOWN_REQUESTS';
export const FETCHING_INVESTOR_DRAWDOWN_REQUESTS = 'FETCHING_INVESTOR_DRAWDOWN_REQUESTS';

export const FETCHING_INVESTEE_ESCROW_ACCOUNT_INFO = 'FETCHING_INVESTEE_ESCROW_ACCOUNT_INFO';
export const FETCH_INVESTEE_ESCROW_ACCOUNT_INFO = 'FETCH_INVESTEE_ESCROW_ACCOUNT_INFO';

export const SENDING_DEPOSIT_URL = 'SENDING_DEPOSIT_URL';
export const SENDING_DEPOSIT_URL_RECUR_CHECKOUT = 'SENDING_DEPOSIT_URL_RECUR_CHECKOUT';
export const FETCHING_KARZA_INVOICES = 'FETCHING_KARZA_INVOICES';
export const SET_WITHDRAW_MODAL_STATE = 'SET_WITHDRAW_MODAL_STATE';
export const WITHDRAWING_TO_BANK = 'WITHDRAWING_TO_BANK';
export const WITHDRAW_TO_BANK = 'WITHDRAW_TO_BANK';
export const SHOW_MENU_ON_RIGHT_CLICK = 'SHOW_MENU_ON_RIGHT_CLICK';

export const FETCHING_CAPITAL_PARTNER = 'FETCHING_CAPITAL_PARTNER';
export const FETCH_CAPITAL_PARTNER = 'FETCH_CAPITAL_PARTNER';
export const FETCH_AGENTS = 'FETCH_AGENTS';
export const OPEN_COMPANY_TALLY_MODAL = 'OPEN_COMPANY_TALLY_MODAL';

export const FETCH_SALES_PARTNER = 'FETCH_SALES_PARTNER';

export const OPEN_PLEASE_CONFIRM_MODAL = 'OPEN_PLEASE_CONFIRM_MODAL';

export const OPEN_ACCOUNT_STATUS_LOCKED_MODAL = 'OPEN_ACCOUNT_STATUS_LOCKED_MODAL';

export const DATA_VAULT_PENDING_STATUS = 'DATA_VAULT_PENDING_STATUS';
export const UPDATE_INVESTEE_ORGANIZATION_INFO = 'UPDATE_INVESTEE_ORGANIZATION_INFO';
export const FETCH_OPEN_WITHDRAWALS = 'FETCH_OPEN_WITHDRAWALS';

export const FETCHING_HUBSPOT_TOKEN = 'FETCHING_HUBSPOT_TOKEN';
export const FETCH_HUBSPOT_TOKEN = 'FETCH_HUBSPOT_TOKEN';

export const UPDATE_IS_APP_LOADING = 'UPDATE_IS_APP_LOADING';
export const UPDATE_PROGRESS_BAR = 'UPDATE_PROGRESS_BAR';
export const FETCH_DEALS_FOR_APPROVAL = 'FETCH_DEALS_FOR_APPROVAL';
export const FETCH_COUNTER_SIGN_USER = 'FETCH_COUNTER_SIGN_USER';
export const SET_INSIGHTS_UNLOCKED = 'SET_INSIGHTS_UNCLOCKED';
export const UPDATE_INVESTEE_SIDEBAR_CONFIG_TAB = 'UPDATE_INVESTEE_SIDEBAR_CONFIG_TAB';
export const UPDATE_INVESTEE_WIDGET_DATA = 'UPDATE_INVESTEE_WIDGET_DATA';
export const UPDATE_APPLICATION_STRENGTH = 'UPDATE_APPLICATION_STRENGTH';
export const SET_IS_TALLY_CONNECTOR = 'SET_IS_TALLY_CONNECTOR';

export const FETCHING_COLLECTION_METHODS = 'FETCHING_COLLECTION_METHODS';
export const FETCH_COLLECTION_METHODS = 'FETCH_COLLECTION_METHODS';

export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const OPEN_DRAWER = 'OPEN_DRAWER';

export const UPDATE_LATEST_ADD_ON_PATH = 'UPDATE_LATEST_ADD_ON_PATH';
export const SHOW_MOBILE_NAVHEADER = 'SHOW_MOBILE_NAVHEADER';

export const FETCH_UPCOMING_PAYMENT_DETAILS_SUCCESS = 'FETCH_UPCOMING_PAYMENT_DETAILS_SUCCESS';
export const FETCH_UPCOMING_PAYMENT_DETAILS_FAILURE = 'FETCH_UPCOMING_PAYMENT_DETAILS_FAILURE';

export const FETCH_CAPITAL_AMOUNT_SUCCESS = 'FETCH_CAPITAL_AMOUNT_SUCCESS';
export const FETCH_CAPITAL_AMOUNT_FAILURE = 'FETCH_CAPITAL_AMOUNT_FAILURE';

dayjs.extend(customParseFormat);
const { SUCCESS } = API_STATUS;

export function updateLatestAddOnPath(latestAddonPath) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_LATEST_ADD_ON_PATH,
            latestAddonPath,
        });
    };
}

export const fetchUpcomingPaymentDetailsSuccess = (data) => ({
    type: FETCH_UPCOMING_PAYMENT_DETAILS_SUCCESS,
    payload: data,
});

export const fetchUpcomingPaymentDetailsFailure = () => ({
    type: FETCH_UPCOMING_PAYMENT_DETAILS_FAILURE,
});

export const fetchCapitalAmountSuccess = (data) => ({
    type: FETCH_CAPITAL_AMOUNT_SUCCESS,
    payload: data,
});

export const fetchCapitalAmountFailure = () => ({
    type: FETCH_CAPITAL_AMOUNT_FAILURE,
});

export const fetchUpcomingPaymentsDetail = (investeeOrganizationId) => async (dispatch) => {
    try {
        const res = await callApi('investeeorganization/upcoming-payment', 'post', {
            investee_organization_id: investeeOrganizationId,
        });
        if (res && res.status === API_STATUS.SUCCESS) {
            dispatch(fetchUpcomingPaymentDetailsSuccess(res.data));
        } else {
            const message = res.data?.message ?? 'Some error occurred';
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            dispatch(fetchUpcomingPaymentDetailsFailure());
        }
    } catch (error) {
        console.error('Error fetching upcoming payment details:', error);
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Some error occurred');
        dispatch(fetchUpcomingPaymentDetailsFailure());
    }
};

export const fetchCapitalAmount = (investeeOrganizationId) => async (dispatch) => {
    try {
        const res = await callAptToFetchCapitalAvailableData(investeeOrganizationId);
        if (res && res.status === SUCCESS) {
            dispatch(fetchCapitalAmountSuccess(res.data));
        } else {
            const message = res.data?.message ?? 'Some error occurred';
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            dispatch(fetchCapitalAmountFailure());
        }
    } catch (error) {
        console.error('Error fetching capital amount:', error);
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Some error occurred');
        dispatch(fetchCapitalAmountFailure());
    }
};

export function triggerDrawer(drawerHeading, drawerData) {
    return (dispatch) => {
        dispatch({
            type: OPEN_DRAWER,
            drawerHeading,
            drawerData,
        });
    };
}

export function setIsAppLoading(val) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_IS_APP_LOADING,
            isAppLoading: val,
        });
    };
}

export function setShowProgressBar(val) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROGRESS_BAR,
            showProgressBar: val,
        });
    };
}

export function setShowMobileNavHeader(val) {
    return (dispatch) => {
        dispatch({
            type: SHOW_MOBILE_NAVHEADER,
            showMobileNavHeader: val,
        });
    };
}

export function fetchCollectionMethods(orgId) {
    return async (dispatch) => {
        dispatch({
            type: FETCHING_COLLECTION_METHODS,
            isFetchingRcmDetails: true,
        });
        const data = await getSecondaryRcmData(orgId);
        if (data) {
            dispatch({
                type: FETCH_COLLECTION_METHODS,
                rcmDetails: data,
                isFetchingRcmDetails: false,
            });
        } else {
            dispatch({
                type: FETCHING_COLLECTION_METHODS,
                isFetchingRcmDetails: false,
            });
        }
    };
}

export function fetchHubspotToken(email, firstName, lastName) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_HUBSPOT_TOKEN,
            isFetchingHubspotToken: true,
        });
        callApi(`hubspot/visitor-identification`, 'post', {
            email,
            firstName,
            lastName,
        })
            .then((res) => {
                if (res.status === SUCCESS) {
                    dispatch({
                        type: FETCH_HUBSPOT_TOKEN,
                        hubspotToken: res?.data?.token,
                        isFetchingHubspotToken: false,
                    });
                } else {
                    dispatch({
                        type: FETCHING_HUBSPOT_TOKEN,
                        isFetchingHubspotToken: false,
                    });
                    showNotification('Error', 'Some error occurred!');
                }
            })
            .catch((err) => {
                dispatch({
                    type: FETCHING_HUBSPOT_TOKEN,
                    isFetchingHubspotToken: false,
                });
                showNotification('Error', 'Some error occurred!');
            });
    };
}

export function showMenuOnRightClick(id) {
    return (dispatch) => {
        dispatch({
            type: SHOW_MENU_ON_RIGHT_CLICK,
            idOnRightClick: id,
        });
    };
}

export function fetchInvestorEscrowAccountInfo(investor_organization_id) {
    if (investor_organization_id) {
        return (dispatch) => {
            dispatch({
                type: FETCHING_INVESTOR_ESCROW_ACCOUNT_INFO,
                isFetchingInvestorEscrowAccountInfo: true,
            });
            return callApi(
                `repayments/investor-organization/${investor_organization_id}/balance-info`,
                'get',
            ).then((res) => {
                if (res.status === 'Success') {
                    if (res.data) {
                        dispatch({
                            type: FETCH_INVESTOR_ESCROW_ACCOUNT_INFO,
                            isFetchingInvestorEscrowAccountInfo: false,
                            investorEscrowAccountInfo: res.data?.data[0],
                        });
                    } else {
                        dispatch({
                            type: FETCH_INVESTOR_ESCROW_ACCOUNT_INFO,
                            isFetchingInvestorEscrowAccountInfo: false,
                            investorEscrowAccountInfo: {
                                user_id: 'NA',
                                name: 'NA',
                                balance: 'NA',
                                escrow_account: 'NA',
                                bank_name: 'NA',
                                ifsc_code: 'NA',
                            },
                        });
                    }
                } else {
                    dispatch({
                        type: FETCH_INVESTOR_ESCROW_ACCOUNT_INFO,
                        isFetchingInvestorEscrowAccountInfo: false,
                        investorEscrowAccountInfo: {
                            user_id: 'NA',
                            name: 'NA',
                            balance: 'NA',
                            escrow_account: 'NA',
                            bank_name: 'NA',
                            ifsc_code: 'NA',
                        },
                    });
                }
            });
        };
    }
}

export function fetchInvesteeEscrowAccountInfo(investee_organization_id) {
    return (dispatch) => {
        if (investee_organization_id) {
            dispatch({
                type: FETCHING_INVESTEE_ESCROW_ACCOUNT_INFO,
                isFetchingInvesteeEscrowAccountInfo: true,
            });
            return callApi(
                `repayments/investee-organization/${investee_organization_id}/balance-info`,
                'get',
            ).then((res) => {
                if (res.status === 'Success') {
                    if (res.data) {
                        dispatch({
                            type: FETCH_INVESTEE_ESCROW_ACCOUNT_INFO,
                            isFetchingInvesteeEscrowAccountInfo: false,
                            investeeEscrowAccountInfo: res.data.data[0],
                            investeeWithdrawableBalance: res.withdrawable_balance,
                        });
                    } else {
                        dispatch({
                            type: FETCH_INVESTEE_ESCROW_ACCOUNT_INFO,
                            isFetchingInvesteeEscrowAccountInfo: false,
                            investeeEscrowAccountInfo: {
                                user_id: 'NA',
                                name: 'NA',
                                balance: 'NA',
                                escrow_account: 'NA',
                                bank_name: 'NA',
                                ifsc_code: 'NA',
                            },
                        });
                    }
                } else {
                    dispatch({
                        type: FETCH_INVESTOR_ESCROW_ACCOUNT_INFO,
                        isFetchingInvestorEscrowAccountInfo: false,
                        investorEscrowAccountInfo: {
                            user_id: 'NA',
                            name: 'NA',
                            balance: 'NA',
                            escrow_account: 'NA',
                            bank_name: 'NA',
                            ifsc_code: 'NA',
                        },
                    });
                }
            });
        }
    };
}

// export function fetchCapitalPartner(a_id) {
//     return dispatch => {
//         dispatch({
//             type: FETCHING_CAPITAL_PARTNER,
//             isFetchingCapitalPartners: true
//         });
//         return callApi(`/agents/get-all-agents`, "get").then(res => {
//             if (res.status === "Success" && res.data.length > 0) {
//                 const capitalPartner = !_.isEmpty(res.data) && res.data.find(p => {
//                     return p._id === a_id
//                 });
//                 dispatch({
//                     type: FETCH_CAPITAL_PARTNER,
//                     capitalPartners: capitalPartner,
//                     isFetchingCapitalPartners: false
//                 });
//             } else {
//                 const message = res.data?.message ? res.data?.message : "Some error occurred!";
//                 showNotification("Error", message);
//                 dispatch({
//                     type: FETCHING_CAPITAL_PARTNER,
//                     isFetchingCapitalPartners: false
//                 });
//             }
//         });
//     };
// }

export function fetchAgentsData(sales_partner_id, data_specialist_id) {
    return (dispatch) => {
        dispatch({
            type: FETCH_AGENTS,
            isFetchingAgentsData: true,
        });
        return callApi(`agents/get-all-agents`, 'get').then((res) => {
            if (res.status === 'Success' && res.data.length > 0) {
                const salesPartner =
                    !_.isEmpty(res.data) &&
                    res.data.find((p) => {
                        return p._id === sales_partner_id && p.agent_type === 'sales_partner';
                    });
                const dataSpecialist =
                    !_.isEmpty(res.data) &&
                    res.data.find((p) => {
                        return p._id === data_specialist_id && p.agent_type === 'data_specialist';
                    });
                dispatch({
                    type: FETCH_AGENTS,
                    salesPartner: salesPartner,
                    dataSpecialist: dataSpecialist,
                    isFetchingAgentsData: false,
                });
            } else {
                const message = res.data?.message ? res.data?.message : 'Some error occurred!';
                showNotification('Error', message);
                dispatch({
                    type: FETCH_AGENTS,
                    isFetchingAgentsData: false,
                });
            }
        });
    };
}

// export function fetchInvesteeDataVaultPendingStatus

// export function fetchSalesPartner(sales_partner_id) {
//     return dispatch => {
//         return callApi(`sales-partner/get-all-sales-partner`, "get").then(res => {
//             if (res.status === "Success" && !_.isEmpty(res.data)) {
//                 const SalesPartner = res.data.find(sP =>  sP._id === sales_partner_id )
//                 dispatch({
//                     type: FETCH_SALES_PARTNER,
//                     salesPartners: SalesPartner
//                 });
//             } else {
//                 showNotification("Error", "Couldn't fetch Capital Partners!");
//             }
//         });
//     }
// }

export function companyWithdrawModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_COMPANY_WITHDRAW_MODAL,
            companyWithdrawModalOpen: modalOpen,
        });
    };
}

export function companyDepositModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_COMPANY_DEPOSIT_MODAL,
            companyDepositModalOpen: modalOpen,
        });
    };
}

export function withdrawModalStateChange(modal_state) {
    return (dispatch) => {
        dispatch({
            type: SET_WITHDRAW_MODAL_STATE,
            modal_state,
        });
    };
}

export function withdrawToBank(org_id, bankId, amount, type, submitted_by_obj) {
    let url = `repayments/organization/${org_id}/bank/${bankId}/request-withdraw`;
    return (dispatch) => {
        if (!(org_id && bankId)) return;
        dispatch({
            type: WITHDRAWING_TO_BANK,
            isWithdrawingToBankState: true,
        });
        return callApi(url, 'post', {
            amount: amount,
            withdraw_request_by: type,
            submitted_by: submitted_by_obj,
        }).then((res) => {
            if (res.status === 'Success') {
                dispatch(withdrawModalStateChange('Review'));
                // dispatch(fetchOpenWithdrawals(org_id));
                dispatch({
                    type: WITHDRAW_TO_BANK,
                    transactionDetails: res.request,
                });
                if (res.request.request.withdraw_request_by === 'investee') {
                    dispatch(
                        fetchInvesteeEscrowAccountInfo(
                            res.request.request.investee_organization_id,
                        ),
                    );
                } else if (res.request.request.withdraw_request_by === 'investor') {
                    dispatch(
                        fetchInvestorEscrowAccountInfo(
                            res.request.request.investor_organization_id,
                        ),
                    );
                }

                showNotification('Success', 'Withdraw Request Successful');
            } else {
                showNotification('error', "Couldn't Withdraw Amount");
            }

            dispatch({
                type: WITHDRAWING_TO_BANK,
                isWithdrawingToBankState: false,
            });
        });
    };
}

export function fetchDepositUrl(org_id, type, amount) {
    let url = '';
    if (type === 'investee') {
        url = `repayments/investee-organization/${org_id}/deposit-to-bank`;
    } else {
        url = `repayments/investor-organization/${org_id}/deposit-to-bank`;
    }
    return (dispatch) => {
        dispatch({
            type: SENDING_DEPOSIT_URL,
            is_sending_deposit_url: true,
        });
        return callApi(url, 'post', { amount: amount }).then((res) => {
            if (res.status === 'Success') {
                if (res.data.banklist) {
                    window.open(res.data.banklist.data);
                }
            } else {
                showNotification('error', 'Error generating repayment URL');
            }
            dispatch({
                type: SENDING_DEPOSIT_URL,
                is_sending_deposit_url: false,
            });
        });
    };
}

export function fetchDepositUrlForRecurCheckout(org_id, type, amount) {
    const url = `recur-checkout/investee-organization/${org_id}/deposit-to-bank`;
    return (dispatch) => {
        dispatch({
            type: SENDING_DEPOSIT_URL_RECUR_CHECKOUT,
            is_sending_deposit_url_recur_checkout: true,
        });
        return callApi(url, 'post', { amount: amount }).then((res) => {
            if (res.status === 'Success') {
                if (res.data.banklist) {
                    window.open(res.data.banklist.data);
                }
            } else {
                showNotification('error', 'Error generating repayment URL');
            }
            dispatch({
                type: SENDING_DEPOSIT_URL_RECUR_CHECKOUT,
                is_sending_deposit_url_recur_checkout: false,
            });
        });
    };
}

export function fetchInvoicesFromKarza(
    org_id,
    gstin,
    username,
    password,
    isCredSaveAllowed,
    onboarding_id,
    onSuccess,
) {
    const url = `investee-organization/${org_id}/gst-invoices-fetch`;
    const today = new Date();
    const endPeriod = dayjs(today).format('MMYYYY');
    if (process.env.REACT_APP_ENVIRONMENT !== PRODUCTION) {
        today.setMonth(today.getMonth() - 2);
    } else {
        today.setFullYear(today.getFullYear() - 2);
        today.setMonth(today.getMonth() + 1);
    }
    const startPeriod = dayjs(today).format('MMYYYY');
    const input = {
        consent: 'Y',
        startPeriod,
        endPeriod,
        returnTypes: ['gstr1', 'gstr2a'],
        additionalData: true,
        gstin: gstin,
        username: username,
        password: password,
        isCredSaveAllowed: isCredSaveAllowed,
    };
    if (onboarding_id) input.onboarding_id = onboarding_id;
    return (dispatch) => {
        dispatch({
            type: FETCHING_KARZA_INVOICES,
            is_fetching_karza_invoices: true,
        });
        return callApi(url, 'post', input).then((res) => {
            if (res.status === SUCCESS && res.data?.message !== 'Invalid Credentials') {
                if (onSuccess) onSuccess();
                showNotification('Success', 'Data fetched successfully');
            } else {
                const message = res.data?.message ?? 'Some error occured';
                if (message === 'Invalid Credentials') {
                    dispatch({
                        type: FETCHING_KARZA_INVOICES,
                        is_fetching_karza_invoices: false,
                        is_invalid_gst_credential: true,
                    });
                }
                showNotification('error', message);
            }
            dispatch({
                type: FETCHING_KARZA_INVOICES,
                is_fetching_karza_invoices: false,
            });
        });
    };
}

export function companyHelpModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_COMPANY_HELP_MODAL,
            companyHelpModalOpen: modalOpen,
        });
    };
}

export function companyAddBankModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_COMPANY_ADD_BANK_MODAL,
            companyAddBankModalOpen: modalOpen,
        });
    };
}

export function companyInviteModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_COMPANY_INVITE_MODAL,
            companyInviteModalOpen: modalOpen,
        });
    };
}

export function companyUpdateDataModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_COMPANY_UPDATE_DATA_MODAL,
            companyUpdateDataModalOpen: modalOpen,
        });
    };
}

export function companyIntegrationModal(modalOpen, type) {
    return (dispatch) => {
        dispatch({
            type: OPEN_COMPANY_INTEGRATION_MODAL,
            integrationType: type,
            companyIntegrationModalOpen: modalOpen,
        });
    };
}

export function companyTallyModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_COMPANY_TALLY_MODAL,
            companyTallyModalOpen: modalOpen,
        });
    };
}

export function orgStatusLockedModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_ACCOUNT_STATUS_LOCKED_MODAL,
            orgStatusLockedModal: modalOpen,
        });
    };
}

export function pleaseConfirmModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_PLEASE_CONFIRM_MODAL,
            pleaseConfirmModal: modalOpen,
        });
    };
}

export function investorHelpModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_INVESTOR_HELP_MODAL,
            investorHelpModalOpen: modalOpen,
        });
    };
}

export function investorInviteModal(modalOpen) {
    return (dispatch) => {
        dispatch({
            type: OPEN_INVESTOR_INVITE_MODAL,
            investorInviteModalOpen: modalOpen,
        });
    };
}

export function toggleOnboardingReapplicationModal(modalState) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_ONBOARDING_REAPPLICATION_MODAL,
            showOnboardingReapplicationModal: modalState,
        });
    };
}

export function fetchInvesteeSidebarConfigTab(userId) {
    return async (dispatch) => {
        dispatch(setIsAppLoading(true));
        const investeeSidebarConfigTab = await getCompanySidebarConfigTab({ userId });
        dispatch(setIsAppLoading(false));
        dispatch({
            type: UPDATE_INVESTEE_SIDEBAR_CONFIG_TAB,
            investeeSidebarConfigTab,
        });
    };
}

export function fetchInvesteeWidgetData(userId, isNeedToShowLoader) {
    return async (dispatch) => {
        if (isNeedToShowLoader) dispatch(setIsAppLoading(true));
        const widgetData = await fetchWidgetData({ userId });
        if (isNeedToShowLoader) dispatch(setIsAppLoading(false));
        dispatch({
            type: UPDATE_INVESTEE_WIDGET_DATA,
            widgetData,
        });
        if (widgetData?.path) dispatch(updateLatestAddOnPath(widgetData.path)); // updating latest add on path in Redux store
    };
}

export const fetchApplicationStrengthData = (orgId, identifierId, identifierType) => {
    return async (dispatch) => {
        const res = await getApplicationStr(orgId, identifierId, identifierType);
        if (res) {
            dispatch({
                type: UPDATE_APPLICATION_STRENGTH,
                applicationStrengthObj: res.applicationStrength,
            });
        }
    };
};

export function loginUser(creds, google = false, isOtp = false) {
    return async (dispatch) => {
        const myIp = window.localStorage.getItem('myIp');
        const incomingQueryLogin = window.localStorage.getItem('incomingQueryLogin');
        storage.flush();
        window.localStorage.setItem('myIp', myIp);
        window.localStorage.setItem('incomingQueryLogin', incomingQueryLogin);
        dispatch({
            type: ATTEMPT_TO_LOGIN,
            isLoggingIn: true,
        });
        if (isOtp || google) {
            if (creds.user.authToken) {
                storage.set('user_id', creds.user.userId);
                storage.set('cerberus_token', creds.user.authToken);
                storage.set('org_id', creds.user.organizationId);
                if (isEnvProd) {
                    if (
                        creds.user.userId !== null &&
                        creds.user.organizationId !== null &&
                        window.hj &&
                        typeof window.hj === 'function'
                    ) {
                        window.hj('identify', creds.user.userId, {
                            Organization_id: creds.user.organizationId,
                        });
                    }

                    const User_data = await getUserInfo(creds.user.userId);
                    const upCommingPaymentDetails = await fetchUpcomingPaymentDetails(
                        User_data.data.investeeOrganization._id,
                    );
                    const capitalAmount = await callAptToFetchCapitalAvailableData(
                        User_data.data.investeeOrganization._id,
                    );
                    const device = window.localStorage.getItem('device');
                    Mixpanel.identify(creds.user.organizationId);
                    Mixpanel.track('login', {
                        $distinct_id: creds.user.organizationId,
                        $ip_address: myIp,
                        $utm_login: incomingQueryLogin,
                        $email: creds.user.emailId,
                        $login_type: google ? ROUTE.GOOGLE : isOtp ? ROUTE.OTP : ROUTE.NORMAL,
                        $source: device,
                        $isPrimaryUser: User_data.data.investee.is_primary_user,
                        $is_Company_Active:
                            upCommingPaymentDetails?.totalOverdueAmount > 0 ||
                            upCommingPaymentDetails?.repayments.length > 0,
                        $userId: User_data.data.user._id,
                        $orgId: User_data.data.investeeOrganization._id,
                        $isDPDCustomer: upCommingPaymentDetails?.totalOverdueAmount > 0,
                        $company_Status:
                            capitalAmount?.data?.data?.drawnAmount > 0 ? 'Customer' : 'Onboarding',
                    });
                }
                if (process.env.REACT_APP_DEAL_CHEST_ROLE_ID === creds.user.roles[0]) {
                    dealChestUserRedirect(creds.user.organizationId);
                    storage.flush();
                    dispatch({
                        type: LOGIN_FAILED,
                        isAuthenticated: false,
                        isLoggingIn: false,
                    });
                    return;
                }
                if (creds.user && creds.user.isAdmin) {
                    storage.set('admin', 'ADMIN');
                }
                if (creds.user && creds.user.userType === 'Investee') {
                    storage.set('investee', 'INVESTEE');
                }
            }
            // dispatch(fetchUserProfile(creds.user.userId));
        } else {
            const res = await loginHelper(creds);
            if (res) {
                if (res.user && res?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
                    if (res.user.authToken) {
                        storage.set('user_id', res.user.userId);
                        storage.set('cerberus_token', res.user.authToken);
                        storage.set('org_id', res.user.organizationId);
                        if (isEnvProd) {
                            if (
                                res.user.userId !== null &&
                                res.user.organizationId !== null &&
                                window.hj &&
                                typeof window.hj === 'function'
                            ) {
                                window.hj('identify', res.user.userId, {
                                    Organization_id: res.user.organizationId,
                                });
                            }
                            const User_data = await getUserInfo(res.user.userId);
                            const upCommingPaymentDetails = await fetchUpcomingPaymentDetails(
                                User_data.data.investeeOrganization._id,
                            );
                            const capitalAmount = await callAptToFetchCapitalAvailableData(
                                User_data.data.investeeOrganization._id,
                            );
                            const device = window.localStorage.getItem('device');
                            Mixpanel.identify(res.user.organizationId);
                            Mixpanel.track('login', {
                                $distinct_id: res.user.organizationId,
                                $ip_address: myIp,
                                $utm_login: incomingQueryLogin,
                                $email: res.user.emailId,
                                $login_type: google
                                    ? ROUTE.GOOGLE
                                    : isOtp
                                    ? ROUTE.OTP
                                    : ROUTE.NORMAL,
                                $source: device,
                                $isPrimaryUser: User_data.data.investee.is_primary_user,
                                $is_Company_Active:
                                    upCommingPaymentDetails?.totalOverdueAmount > 0 ||
                                    upCommingPaymentDetails?.repayments.length > 0,
                                $userId: User_data.data.user._id,
                                $orgId: User_data.data.investeeOrganization._id,
                                $isDPDCustomer: upCommingPaymentDetails?.totalOverdueAmount > 0,
                                $company_Status:
                                    capitalAmount?.data?.data?.drawnAmount > 0
                                        ? 'Customer'
                                        : 'Onboarding',
                            });
                        }
                        if (process.env.REACT_APP_DEAL_CHEST_ROLE_ID === res.user.roles[0]) {
                            dealChestUserRedirect(res.user.organizationId);
                            storage.flush();
                            dispatch({
                                type: LOGIN_FAILED,
                                isAuthenticated: false,
                                isLoggingIn: false,
                            });
                            return;
                        }
                        if (res.user && res.user.isAdmin) {
                            storage.set('admin', 'ADMIN');
                        }
                        if (res.user && res.user.userType === 'Investee') {
                            storage.set('investee', 'INVESTEE');
                        }
                    }
                    if (res.user.phoneVerified === false || res.user.emailVerified === false) {
                        dispatch({
                            type: LOGIN_SUCCESS,
                            isEmailVerified: res.user.emailVerified,
                            phoneVerified: res.user.phoneVerified,
                            isAuthenticated: true,
                            user: res.user,
                            isLoggingIn: false,
                            is_fetching_user: false,
                        });
                    } else {
                        dispatch(fetchInvesteeSidebarConfigTab(res.user.userId));
                        dispatch(fetchInvesteeWidgetData(res.user.userId, true));
                        dispatch(
                            fetchUserProfile(res.user.userId, null, null, res.user.phoneVerified),
                        );
                    }
                    return true;
                } else if (
                    res?.responseData?.responseCode === CERBERUS_STATUS_CODES.FAILURE &&
                    res?.responseData?.responseMessage?.toUpperCase() ===
                        EMAIL_NOT_VERIFIED_MESSAGE.toUpperCase()
                ) {
                    showNotification('error', EMAIL_NOT_VERIFIED_MESSAGE);
                    dispatch({
                        type: LOGIN_FAILED,
                        isAuthenticated: true,
                        isLoggingIn: false,
                        isEmailVerified: false,
                        user: res.user,
                    });
                } else {
                    const message = res?.responseData?.responseMessage ?? 'Some error occured';
                    showNotification('error', message);
                    const authInstance = window.gapi.auth2.getAuthInstance();
                    if (authInstance) {
                        authInstance.signOut().then(() => {
                            dispatch({
                                type: LOGIN_FAILED,
                                isAuthenticated: false,
                                isLoggingIn: false,
                            });
                        });
                    }
                    dispatch({
                        type: LOGIN_FAILED,
                        isAuthenticated: false,
                        isLoggingIn: false,
                    });
                    return false;
                }
            } else {
                showNotification('error', 'Something went wrong!');
                dispatch({
                    type: LOGIN_FAILED,
                    isAuthenticated: false,
                    isLoggingIn: false,
                });
                return false;
            }
        }
    };
}

export function loginInvestorUser(creds, isOtp) {
    return async (dispatch) => {
        const myIp = window.localStorage.getItem('myIp');
        storage.flush();
        window.localStorage.setItem('myIp', myIp);
        dispatch({
            type: ATTEMPT_TO_LOGIN,
            isLoggingIn: true,
        });
        if (isOtp) {
            if (creds.user.authToken) {
                storage.set('user_id', creds.user.userId);
                storage.set('org_id', creds.user.organizationId);
                storage.set('cerberus_token', creds.user.authToken);
                if (creds.user && creds.user.isAdmin) {
                    storage.set('admin', 'ADMIN');
                }
                if (creds.user && creds.user.userType === INVESTOR) {
                    storage.set('investor', 'INVESTOR');
                }

                if (isEnvProd) {
                    if (
                        creds.user.userId !== null &&
                        creds.user.organizationId !== null &&
                        window.hj &&
                        typeof window.hj === 'function'
                    ) {
                        window.hj('identify', creds.user.userId, {
                            Organization_id: creds.user.organizationId,
                        });
                    }
                }
            }
            dispatch(fetchUserProfile(creds.user.userId, null, null, true)); //We're not adding phone verification for investor
            return true;
        } else {
            const res = await loginHelper(creds);
            if (res) {
                if (res.user && res?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
                    if (res.user.authToken) {
                        storage.set('user_id', res.user.userId);
                        storage.set('org_id', res.user.organizationId);
                        storage.set('cerberus_token', res.user.authToken);
                        if (res.user && res.user.isAdmin) {
                            storage.set('admin', 'ADMIN');
                        }
                        if (res.user && res.user.userType === 'Investor') {
                            storage.set('investor', 'INVESTOR');
                        }
                    }
                    dispatch(fetchUserProfile(res.user.userId, null, null, true)); //We're not adding phone verification for investor
                    if (isEnvProd) {
                        mixPanelTrack({
                            id: res?.user?.organizationId,
                            setUserObj: {
                                name: res?.user?.name,
                                $email: res?.user?.emailId,
                                $last_login_date: dayjs()
                                    .utc()
                                    .utcOffset(330)
                                    .format(DAYJS_DATE_FORMAT),
                            },
                            trackUserObj: {
                                name: res?.user?.name,
                                $email: res?.user?.emailId,
                                $login_type: ROUTE.NORMAL,
                            },
                            trackCategoryName: 'Investor Login',
                        });

                        if (
                            res.user?.userId !== null &&
                            res.user?.organizationId !== null &&
                            window.hj &&
                            typeof window.hj === 'function'
                        ) {
                            window.hj('identify', res.user.userId, {
                                Organization_id: res.user.organizationId,
                            });
                        }
                    }
                } else if (
                    res?.responseData?.responseCode === CERBERUS_STATUS_CODES.FAILURE &&
                    res?.responseData?.responseMessage?.toUpperCase() ===
                        EMAIL_NOT_VERIFIED_MESSAGE.toUpperCase()
                ) {
                    showNotification(SHOW_NOTIFICATION_STATUS.ERROR, EMAIL_NOT_VERIFIED_MESSAGE);
                    dispatch({
                        type: LOGIN_FAILED,
                        isAuthenticated: true,
                        isLoggingIn: false,
                        isEmailVerified: false,
                        user: res.user,
                    });
                } else {
                    showNotification(
                        SHOW_NOTIFICATION_STATUS.ERROR,
                        res.responseData?.responseMessage,
                    );
                    let authInstance = window.gapi.auth2.getAuthInstance();
                    if (authInstance) {
                        authInstance.signOut().then(() => {
                            dispatch({
                                type: LOGIN_FAILED,
                                isAuthenticated: false,
                                isLoggingIn: false,
                            });
                        });
                    }
                    dispatch({
                        type: LOGIN_FAILED,
                        isAuthenticated: false,
                        isLoggingIn: false,
                    });
                }
            } else {
                showNotification('error', 'Failed to Login! Invalid credentials.');
                dispatch({
                    type: LOGIN_FAILED,
                    isAuthenticated: false,
                    isLoggingIn: false,
                });
            }
        }
    };
}

export function verifyUserEmail(query) {
    return (dispatch) => {
        return callApi(`auth/verify-email${query}`, 'get').then((res) => {
            if (res.status === 'Success' && res.data && res.data.message) {
                // showNotification("Success", res.data.message);
            }
        });
    };
}

export function resendVerifyUserEmail(user_id) {
    return (dispatch) => {
        return callApi(`auth/user/${user_id}/resend-verification-email`, 'get').then((res) => {
            if (res.status === 'Success' && res.data && res.data.message) {
                showNotification('Success', res.data.message);
            } else {
                showNotification('error', 'Failed to send verification mail');
            }
        });
    };
}

export function updateInvesteeOrganization(organization) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_INVESTEE_ORGANIZATION_INFO,
            investeeOrganization: organization,
        });
    };
}

export function fetchUserProfile(user_id, otp = false, google = false, isPhoneVerified = false) {
    return async (dispatch) => {
        // if (isPhoneVerified) { // @TODO to be placed after isPhoneVerified comes false from backend
        dispatch({
            type: FETCHING_USER_PROFILE,
            is_fetching_user: true,
        });
        const res = await getUserInfo(user_id);
        if (res?.status === SUCCESS) {
            dispatch({
                type: FETCH_USER_PROFILE,
                investee: res.data.investee,
                investeeOrganization: res.data.investeeOrganization,
                investeeProfileMetrics: res.data.investeeProfileMetrics,
                investeeContractStats: res.data.investeeContractStats,
                investor: res.data.investor,
                investorOrganization: res.data.investorOrganization,
                admin: res.data.admin,
                roles: res.data.roles,
                permissions: res.data.permissions,
                is_fetching_user: false,
            });
            dispatch({
                type: LOGIN_SUCCESS,
                isAuthenticated: true,
                isEmailVerified: res.data.user.is_email_verified,
                phoneVerified: res.data.user.is_phone_verified,
                isUserApproved: res.data.user.is_user_approved,
                user: res.data.user,
                isLoggingIn: false,
            });
            return {
                isUserApproved: res?.data?.user?.is_user_approved,
                status: res?.data?.investeeOrganization?.status,
                resStatus: res?.status,
            };
        } else {
            const myIp = window.localStorage.getItem('myIp');
            storage.flush();
            window.localStorage.setItem('myIp', myIp);
            dispatch({
                type: LOGIN_FAILED,
                isAuthenticated: false,
                isLoggingIn: false,
            });
            dispatch({
                type: FETCHING_USER_PROFILE,
                is_fetching_user: false,
            });
            return null;
        }
    };
}

export function createUserBankAccount(organizationId, organizationType, userAddingBank, cb) {
    return (dispatch) => {
        dispatch({
            type: CREATING_USER_BANK_ACCOUNT,
            is_user_adding_bank: true,
        });
        return callApi(`repayments/organization/${organizationId}/create-bank-account`, 'post', {
            organization_type: organizationType,
            bank: {
                nick_name: userAddingBank.acc_nick_name,
                account_number: userAddingBank.confirm_acc_number,
                ifsc_code: userAddingBank.ifsc_code,
                bank_name: userAddingBank.name_of_bank,
                account_holder_name: userAddingBank.acc_holder_name,
                account_holder_type: userAddingBank.acc_type,
            },
        }).then((res) => {
            if (res.status === 'Success') {
                dispatch(companyAddBankModal(false));
                dispatch({
                    type: USER_ADDING_BANK,
                    is_user_adding_bank: false,
                    user_adding_account_failed: false,
                });
                cb(false);
                showNotification('success', 'Account Added');
            } else {
                cb(true);
                if (res.data.message === 'The user id field is required.')
                    showNotification('error', "EscrowPay Account doesn't exist!");
                else showNotification('error', res.data.message);
                dispatch({
                    type: USER_ADDING_BANK_FAILED,
                    is_user_adding_bank: false,
                    user_adding_account_failed: true,
                });
            }
        });
    };
}

export function fetchBankList(organizationId, organizationType) {
    return (dispatch) => {
        if (!(organizationId && organizationType)) return;
        dispatch({
            type: FETCHING_BANK_LIST,
            isFetchingBankList: true,
        });
        return getBanks({ organizationId, organizationType }).then((res) => {
            if (res?.status === 'Success') {
                dispatch({
                    type: FETCH_BANK_LIST,
                    bankList: res.data.banklist,
                    isFetchingBankList: false,
                });
            } else {
                dispatch({
                    type: FETCHING_BANK_LIST,
                    isFetchingBankList: false,
                });
            }
        });
    };
}

export function fetchUserEscrowAccountDetails(investeeOrInvestor, userId) {
    return (dispatch) => {
        if (userId) {
            dispatch({
                type: FETCHING_USER_ESCROW_ACCOUNT_DETAILS,
                is_fetching_user_escrow_account_details: true,
            });
            return callApi(
                `repayments/${investeeOrInvestor}-organization/${userId}/balance-info`,
                'get',
            ).then((res) => {
                if (res.status === 'Success') {
                    dispatch({
                        type: FETCH_USER_ESCROW_ACCOUNT_DETAILS,
                        escrowAccountDetails: res.data.data,
                        is_fetching_user_escrow_account_details: false,
                    });
                } else {
                    dispatch({
                        type: FETCHING_USER_ESCROW_ACCOUNT_DETAILS,
                        is_fetching_user_escrow_account_details: false,
                    });
                }
            });
        }
    };
}

export function fetchInvestorProfile(investor_id) {
    return (dispatch) => {
        return callApi(`investor/${investor_id}/profile/info`, 'get').then((res) => {
            if (res.status === 'Success') {
                dispatch({
                    type: FETCH_INVESTOR_PROFILE,
                    investor: res.data.investor,
                    investorOrganization: res.data.investorOrganization,
                });
            }
        });
    };
}

export function updateInvestProfileInfo(investee_id, investee, isPhoneEdited = false) {
    return async (dispatch) => {
        const userId = storage.get('user_id');
        dispatch({
            type: UPDATING_INVESTEE_INFO,
            is_updating_investee: true,
        });
        const res = await callApi(`user/${userId}/profile/update`, 'post', {
            investee: investee,
            isPhoneEdited: isPhoneEdited,
        });
        if (res.status === 'Success') {
            showNotification(
                'Success',
                isPhoneEdited ? 'Phone Number Successfully Updated' : 'Successfully updated',
            );
            dispatch({
                type: UPDATE_INVESTEE_INFO,
                is_updating_investee: false,
                investee: res.data.investee,
                user: res.data.user,
            });
            return res;
        } else {
            showNotification('error', res.data.message ?? 'Some error occurred');
            dispatch({
                type: UPDATING_INVESTEE_INFO,
                is_updating_investee: false,
            });
            return res;
        }
    };
}

export function updateInvestorProfileInfo(investor_id, investor) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_INVESTOR_INFO,
            is_updating_investor: true,
        });
        return callApi(`investor/${investor_id}/profile/update`, 'post', {
            investor: investor,
        }).then((res) => {
            if (res.status === 'Success') {
                showNotification('Success', 'Successfully updated');
                dispatch({
                    type: UPDATE_INVESTOR_INFO,
                    is_updating_investor: false,
                    investor: res.data.investor,
                });
                dispatch(fetchInvestorTeamMembers(investor?.investor_organization_id));
            } else {
                showNotification('error', 'Some error occurred');
                dispatch({
                    type: UPDATING_INVESTOR_INFO,
                    is_updating_investor: false,
                });
            }
        });
    };
}

//@TODO to check at all places and if possible remove navigate dependency
export function logOutUser(navigate, userType = USER_TYPE_INVESTEE) {
    if (!navigate) throw new Error('Please pass a valid navigate hook');
    closeHubspotLiveChat();
    const myIp = window.localStorage.getItem('myIp');
    storage.flush();
    window.localStorage.setItem('myIp', myIp);
    if (userType === USER_TYPE_INVESTOR) {
        navigate(`/investor/login`);
    } else {
        navigate(`/`);
    }
    return (dispatch) => {
        dispatch({
            type: ATTEMPT_TO_LOGOUT,
            isVerifyingToken: false,
            isAuthenticated: false,
            user: {},
            token: '',
            isLoggingOut: true,
        });
    };
}

export function updateUserModel(user) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_USER_DATA,
            user: user,
        });
    };
}

export function updateInvesteeModel(keyName, keyValue) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_INVESTEE_DATA,
            keyName: keyName,
            keyValue: keyValue,
        });
    };
}

export function updateCompanyName(companyName) {
    return (dispatch) => {
        dispatch({
            type: SET_COMPANY_NAME,
            companyName: companyName,
        });
    };
}

export function updateInvestorModel(keyName, keyValue) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_INVESTOR_DATA,
            keyName: keyName,
            keyValue: keyValue,
        });
    };
}

export function fetchPlatformFAQs(category = 'investee') {
    return (dispatch) => {
        dispatch({
            type: FETCHING_PLATFORM_FAQS,
            is_fetching_faqs: true,
        });
        return callApi(`platform_faqss/list`, 'post', {
            category: category,
        }).then((res) => {
            if (res.status === 'Success') {
                dispatch({
                    type: FETCH_PLATFORM_FAQS,
                    platform_faqs: res.data.platform_faqs,
                    is_fetching_faqs: false,
                });
            } else {
                dispatch({
                    type: FETCHING_PLATFORM_FAQS,
                    is_fetching_faqs: false,
                });
            }
        });
    };
}

export function updateInvesteeOrganizationInfo(org_id, org_info) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_INVESTEE_ORGANIZATION_INFO,
            is_updating_investee_org: true,
        });
        return callApi(`investeeorganization/${org_id}/profile/update`, 'post', {
            investeeOrganization: org_info,
        }).then((res) => {
            if (res.status === 'Success') {
                showNotification('Success', 'Successfully updated');
                dispatch({
                    type: UPDATED_INVESTEE_ORGANIZATION_INFO,
                    investeeOrganization: res.data.investeeOrganization,
                    is_updating_investee_org: false,
                });
            } else {
                showNotification('error', 'Some error occurred');
                dispatch({
                    type: UPDATING_INVESTEE_ORGANIZATION_INFO,
                    is_updating_investee_org: false,
                });
            }
        });
    };
}
export function isFetchingUserTrue(val) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_USER_PROFILE,
            is_fetching_user: val ?? true,
        });
    };
}

export function signUpSuccess(user) {
    return (dispatch) => {
        dispatch({
            type: LOGIN_SUCCESS,
            isAuthenticated: true,
            isEmailVerified: user.isVerified,
            phoneVerified: user.phoneVerified,
            user: user,
            isLoggingIn: false,
        });
        storage.set('user_id', user.userId);
        if (user.isVerified) {
            storage.set('user_id', user.userId);
            storage.set('cerberus_token', user.authToken);
            storage.set('org_id', user.organizationId);
            dispatch(fetchUserProfile(user.userId, false, true, user.phoneVerified));
        }
    };
}

export function updateInvesteeOrgWithoutApicall(investeeOrg) {
    return (dispatch) => {
        dispatch({
            type: UPDATED_INVESTEE_ORGANIZATION_INFO,
            investeeOrganization: investeeOrg,
        });
    };
}

export function fetchDataVaultPendingStatus(investeeOrgId) {
    return (dispatch) => {
        return callApi(`investee-data-vault-pending-status`, 'post', {
            investee_organization_id: investeeOrgId,
        }).then((res) => {
            if (res.status === 'Success') {
                dispatch({
                    type: DATA_VAULT_PENDING_STATUS,
                    dataVaultPendingStatus: res.dataVaultPendingStatus,
                });
            } else {
                const message = res.data?.message ? res.data?.message : 'Some error occurred!';
                showNotification('Error', message);
            }
        });
    };
}

// export function fetchOpenWithdrawals(investeeOrgId) {
//     return (dispatch) => {
//         return callApi(`investees/investeeOrganization/${investeeOrgId}/get-open-withdrawals`).then(
//             (res) => {
//                 if (res.status === 'Success') {
//                     dispatch({
//                         type: FETCH_OPEN_WITHDRAWALS,
//                         withdraws: res.data,
//                     });
//                 }
//             },
//         );
//     };
// }

export function fetchInvestorDrawdownRequests(investorOrgId) {
    const body = {
        investor_organization_id: investorOrgId,
    };
    return async (dispatch) => {
        dispatch({
            type: FETCHING_INVESTOR_DRAWDOWN_REQUESTS,
            isFetchingDrawdownRequests: true,
        });
        const response = await callApi(
            `investor/${investorOrgId}/get-drawdowns-for-approval`,
            'get',
        );
        if (
            response &&
            response.status === SUCCESS &&
            Array.isArray(response.data) &&
            response.data.length > 0
        ) {
            dispatch({
                type: FETCH_INVESTOR_DRAWDOWN_REQUESTS,
                investorDrawdowns: response.data,
            });
        }
        dispatch({
            type: FETCHING_INVESTOR_DRAWDOWN_REQUESTS,
            isFetchingDrawdownRequests: false,
        });
    };
}

export function fetchDealsForApproval(investorOrgId) {
    return async (dispatch) => {
        const response = await callApi('deals/investor/get_deals_for_approval', 'post', {
            investor_organization_id: investorOrgId,
        });
        if (
            response?.status === API_STATUS.SUCCESS &&
            Array.isArray(response.data?.deals_for_approval)
        ) {
            dispatch({
                type: FETCH_DEALS_FOR_APPROVAL,
                dealsForApproval: response.data?.deals_for_approval,
            });
            dispatch({
                type: FETCH_COUNTER_SIGN_USER,
                counterSignUserByAdmin: `(${response.data?.counterSignUserByAdmin?.contact_name})`,
            });
        } else {
            const message = response?.data?.message ?? 'Something went wrong';
            showNotification('error', message);
        }
    };
}

// Get all roles and roleId vs roleDbObject maps
export function fetchAllowedInviteRolesRedux(userPermissions) {
    return async (dispatch) => {
        dispatch({
            type: FETCHING_USER_INVITABLE_ROLES,
            isFetchingRoles: true,
        });
        callApi(`/investee_organization/get-all-roles`, 'post').then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                const roles = res.data.roles;
                let fetchedInvitableRoles = [];
                userPermissions?.forEach((permission, index) => {
                    let role =
                        permission.split(':')[1] === 'invite' ? permission.split(':')[0] : ' ';
                    if (role !== ' ' && role !== 'Co-Borrower') fetchedInvitableRoles.push(role);
                });
                let roleObjectMap = {};
                roles.forEach((item) => {
                    roleObjectMap[item._id] = item;
                    roleObjectMap[item.tag] = { id: item._id, display_name: item.display_name };
                });
                dispatch({
                    type: FETCH_USER_INVITABLE_ROLES,
                    roleObjMapRedux: roleObjectMap,
                    invitableRolesRedux: fetchedInvitableRoles,
                    isFetchingRoles: false,
                });
            } else {
                dispatch({
                    type: FETCHING_USER_INVITABLE_ROLES,
                    isFetchingRoles: false,
                });
                showNotification('error', 'Some error occured');
            }
        });
    };
}

export function unlockInsights(investeeOrgId) {
    return async (dispatch) => {
        callApi(`investeeorganizations/${investeeOrgId}/data-update`, 'POST', {
            investeeorganization: {
                is_insights_unlocked: 'Waitlisted',
                insights_unlocked_on: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            },
        }).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                showNotification('Success', 'You have been added to the waitlist!');
                dispatch({
                    type: SET_INSIGHTS_UNLOCKED,
                    isInsightsUnlocked: 'Waitlisted',
                });
            } else {
                const message = res?.data?.message || 'Some error occoured';
                showNotification('Error', message);
            }
        });
    };
}

export function updateTallyConnector(investeeOrgId, input, onSuccess, onError) {
    return async (dispatch) => {
        const res = await updateTallyConnectorStatus(investeeOrgId, input);
        if (res?.status === 200) {
            onSuccess && onSuccess();
            dispatch({
                type: SET_IS_TALLY_CONNECTOR,
                isTallyConnector: input.investeeorganization.tally_connector,
            });
        } else {
            onError && onError();
        }
    };
}

export function fetchDocDeals(currentTab, deal) {
    return async (dispatch) => {
        dispatch(setIsAppLoading(true));
        await getDocVerificationDeals(currentTab, deal.investorOrganizationId, deal.name);
        dispatch(setIsAppLoading(false));
    };
}

export function getCounterSignUser(investeeOrgId) {
    return async (dispatch) => {
        let response;
        if (investeeOrgId) {
            let requestId = generateRequestId();
            response = await getCounterSignUserApi(investeeOrgId, requestId);
        }

        if (response?.data?.counterSignUser?.contact_name) {
            dispatch({
                type: FETCH_COUNTER_SIGN_USER,
                counterSignUserByAdmin: `(${response.data?.counterSignUser?.contact_name})`,
            });
        } else {
            dispatch({
                type: FETCH_COUNTER_SIGN_USER,
                counterSignUserByAdmin: `(Not Found)`,
            });
        }
    };
}
