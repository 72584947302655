import { PRODUCTION } from '../enums/appEnums';
import * as Sentry from '@sentry/react';

export default async function initializeLogger() {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_URL,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                }),
            ],
            // Tracing
            tracesSampleRate: 0.2,
            // Session Replay
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }
}

export async function setUserInErrorLogger(email) {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        Sentry.setUser({ email });
    }
}

export async function unsetUserInErrorLogger() {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        Sentry.setUser(null);
    }
}

export async function captureMessagesViaErrorLogger(message) {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        Sentry.captureMessage(message);
    } else console.error(message);
}

export async function captureExceptionViaErrorLogger(func) {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        try {
            func();
        } catch (err) {
            Sentry.captureException(err);
        }
    }
}
