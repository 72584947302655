export const ASSET_LINKS = {
    SEARCH_ICON: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/SearchIcon.svg',
    NOTIFICATION_BELL_ICON:
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/NotificationIcon.svg',
    DOWN_ARROW_ICON: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/DownwardArrow.svg',
    INVITE_TEAMMATE_ICON:
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/Add-teammate-icon-black.svg',
    EMAIL_ICON_BLUE: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/Email_Icon_Blue.svg',
    PHONE_ICON_BLUE: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/Phone_Icon_Blue.svg',
    CROSS_ICON_GREY: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/crossBtnGrey.svg',
    CIBIL_CONSENT_SUCCESS_GREEN:
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/cibilConsentSuccessGreen.json',
    WAITLISTED_THUMBS_DOWN_GREY:
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/WaitlistedThumbsDownGrey.svg',
    WAITLISTED_THUMBS_UP_GREEN:
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/WaitlistedThumbsUpGreen.svg',
    WAITLISTED_DEAL_CHEST_ICON_BLUE:
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/WaitlistingDealChestIconBlue.svg',
    WAITLISTED_FINANCING_ICON_BLUE:
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/WaitlistingFincancingIconBlue.svg',
    WAITLISTED_INSIGHTS_ICON_BLUE:
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/info-red.svg',
    NEED_INFO: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/need-info.svg',
    DELETE_RED_BIN: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/delete-red-bin.svg',
    FLUENT_INSIGHTS: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/png/fluent-insights.png',
};

export const ICON_NAMES = {
    INFO_GREEN: 'info-icon-green',
    INFO_BLACK: 'info-icon-black',
    INFO_RED: 'info-red',
    INFO_YELLOW: 'infoYellow',
    INFO_RED_I: 'info-red-i',
    DOT_GREEN: 'dot-icon-green',
    DOT_WHITE: 'dot-icon-white',
    DOT_BLACK: 'dot-icon-black',
    DOT_GREY: 'dot-icon-grey',
    CALENDER_GRADIENT_BLUE: 'calender-icon-gradient-blue',
    EXCLAMATORY_YELLOW: 'exclamatory-icon-yellow',
    DOC_GRADIENT_BLUE: 'doc-icon-gradient-blue',
    TABLE_ROWS_GREY: 'table-rows-icon-grey',
    LOCKED_GREY: 'locked-grey',
    UNLOCKED_GREEN: 'unlocked-green',
    CAMERA_GRADIENT: 'camera-gradient',
    CROSS_BLACK: 'cross-black',
    DOWNLOAD_BLUE: 'download-blue',
    ARROW_WHITE: 'arrow-white',
    WAITLISTING_LIGHT_BULB: 'waitlisting-light-bulb',
    LOCK_ICON_GREY: 'LockIconGrey',
    PROGRESS_AUTOSAVE_GREY: 'progressAutosaveGrey',
    ONBOARDING_THUMBNAIL_IMG: 'OnboardingVideoThumbnailNew',
    VIDEO_PLAY_BUTTON: 'videoPlayButton',
    FAQ_LINK: 'FaqIcon',
    UP_ARROW_BLUE: 'UpArrowBlue',
    CUSTOMER_SUPPORT_ICON: 'CustomerSupportIcon',
    TICK_MARK_GREEN: 'TickMarkGreen',
    NEW_REQUEST_PLUS_WHITE: 'newRequestPlusWhite',
    RIGHT_ARROW_BLUE: 'RightArrowBlue',
    PURPLE_CROSS: 'purpleCross',
    INFO_ICON_ORANGE: 'infoIconOrange',
    INFO_ICON_LIGHT_GREEN: 'infoIconLightGreen',
    INFO_ICON_OLIVE: 'infoIconOlive',
    LIGHT_BULB_BLACK: 'lightBulbBlack',
    COMPLETED_CHECKBOX_GREY: 'completed-grey-checkbox',
    COMPLETED_CHECKBOX_GREEN: 'done-green-background',
    PENDING_CIRCULAR_BOX_YELLOW: 'pending-circular-yellow-box',
    NOT_COMPLETED_FADED_BOX_GREY: 'faded-non-completed-circular-box',
    NOT_COMPLETED_BOX_GREY: 'not-completed-circular-box',
    INFO_ICON_BLUE: 'infoIconBlue',
    INSIGHT_BLACK: 'insights-black',
    DEALS_BLUE: 'deals-500-blue',
    EVENTPASS_BLUE: 'event-pass-blue',
    INSIGHT_BLUE: 'Insights',
    DEALS: 'Deals',
    EVENT_PASS: 'Events%2BPass',
    EDIT_PURPLE: 'edit-purple',
    DELETE_RED: 'delete-red',
    SUMMON_AICA_STARS: 'summon-aica-stars',
    CTA_PURPLE: 'cta-purple',
    REVIEW_ICON: 'reviewApplicationBtnBlue',
};

export const ICON_TYPES = {
    PNG: 'png',
};
