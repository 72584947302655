import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './scss/MISMergePopup.scss';
import { fetchUpcomingPaymentDetails } from '../../../../../utility/utility';
import { callAptToFetchCapitalAvailableData } from '../../../../../../../common/ApiContainer';
import { mixPanelTrack } from '../../../../../../../util/utility';
import { useSelector } from 'react-redux';
import {
    getIfCurrentUserIsPrimaryUser,
    getInvesteeInfo,
    getInvesteeOrganizationInfo,
    getUserId,
} from '../../../../../../App/AppReducer';

function MISMergePopup({ showMisMergePopup, onClose, setSelectedFiles, callingApi }: any) {
    const investeeOnboardingId = useSelector(getInvesteeOrganizationInfo)?.investee_onboarded_id;
    const userId = useSelector(getUserId);
    const isPrimaryUser = useSelector(getIfCurrentUserIsPrimaryUser);
    const investeeeInfo = useSelector(getInvesteeInfo);
    const companyInfo = useSelector(getInvesteeOrganizationInfo);

    const fetchPaymentDetails = async (investeeOnboardingId: string) => {
        const res = await fetchUpcomingPaymentDetails(investeeOnboardingId);
        return res?.totalOverdueAmount > 0;
    };

    const fetchCustomerType = async (investeeOnboardingId: string) => {
        const capitalAmount = await callAptToFetchCapitalAvailableData(investeeOnboardingId);
        return capitalAmount?.data?.data?.drawnAmount > 0 ? 'Customer' : 'Onboarding';
    };

    const onContinue = async () => {
        const paymentDetails = await fetchPaymentDetails(investeeOnboardingId);
        const customerType = await fetchCustomerType(investeeOnboardingId);

        mixPanelTrack({
            id: companyInfo?._id,
            trackUserObj: {
                source: 'Add On',
                distinct_id: companyInfo?._id,
                orgId: companyInfo?._id,
                userId: userId,
                isActive: companyInfo?.is_company_active,
                isDPD: paymentDetails,
                Customer: customerType,
                isPrimaryUser: isPrimaryUser,
                'Job Role': investeeeInfo?.designation,
            },
            trackCategoryName: 'MIS',
        });
        setSelectedFiles(showMisMergePopup);
        callingApi.current = true;
        onClose();
    };

    return (
        <Modal
            show={!!showMisMergePopup}
            onHide={onClose}
            centered
            style={{ height: '100vh' }}
            dialogClassName="modal-dialog"
            animation={false}
        >
            <Modal.Header closeButton className="m-head"></Modal.Header>
            <Modal.Body>
                By proceeding with the file upload, any existing data for the overlapping months
                &nbsp;
                <span className="m-body">will be deleted</span>
                &nbsp;if you proceed.
            </Modal.Body>
            <div className="m-foot">
                <div className="Close-btn" onClick={onClose}>
                    Close
                </div>
                <div className="Proceed-btn" onClick={onContinue}>
                    Proceed
                </div>
            </div>
        </Modal>
    );
}

export default MISMergePopup;
