import React from 'react';
import PropTypes from 'prop-types';
import './button.css';

/**
 * Primary UI component for user interaction
 **/

export const Button = ({
    primary,
    primaryClass,
    secondary,
    ternary,
    style,
    size,
    label,
    withIcon,
    iconPath,
    ...props
}) => {
    const mode =
        primary || primaryClass
            ? 'storybook-button--primary'
            : secondary
            ? 'storybook-button--secondary'
            : 'storybook-button--ternary';

    return (
        <>
            <button
                type="button"
                className={['storybook-button', `storybook-button--${size}`, mode].join(' ')}
                style={style ? style : null}
                {...props}
            >
                {withIcon && (
                    <img className="mr5" style={{ width: '12px' }} src={iconPath} alt="" />
                )}
                {ternary && <img className="mr5" src="/assets/back-btn.svg" alt="" />}
                <span>{label}</span>
                {primary && <img className="ml5" src="/assets/forwd-btn.svg" alt="" />}
            </button>
        </>
    );
};

Button.propTypes = {
    /**
     * Is this the principal call to action on the page?
     */
    primary: PropTypes.bool,
    /**
     * What background color to use
     */
    backgroundColor: PropTypes.string,
    /**
     * How large should the button be?
     */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    /**
     * Button contents
     */
    label: PropTypes.string.isRequired,
    /**
     * Optional click handler
     */
    onClick: PropTypes.func,
};

Button.defaultProps = {
    backgroundColor: null,
    primary: false,
    size: 'medium',
    onClick: undefined,
};
