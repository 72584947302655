import { INVESTEE_ROUTES } from '../RoutesConst';

export const returnNavHeading = (path: string): string => {
    switch (path) {
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.FINANCES_REPORTS):
            return 'Reports';
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.TRADE_LIMITS):
            return 'Data Vault';
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.TRADE):
            return 'Draw Capital';
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.REPAYMENTS):
            return 'Repayments';
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.TRANSACTIONS_PAYMENTS):
            return 'Repayments';
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.TRANSACTIONS_PAYOUTS):
            return 'Repayments';
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.DASHBOARD):
            return '';
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.DATA_VAULT):
            return 'Data Vault';
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.COLLECTIONS):
            return 'Collections';
        case returnMatchableUrl(INVESTEE_ROUTES.PRIVATE.CUSTOMER_SUPPORT):
            return 'Customer Support';
        default:
            return '';
    }
};
const returnMatchableUrl = (path: string): string => {
    return `/company/${path}`;
};
