/**
 * Root Reducer
 */
import { combineReducers } from 'redux';
import { ATTEMPT_TO_LOGOUT } from './modules/App/AppActions';
// Import Reducers
import app from './modules/App/AppReducer';
import investee from './modules/Investee/InvesteeReducers';
import investor from './modules/Investor/InvestorReducers';
import localStorage from 'redux-persist/lib/storage';
import { persistConfig, routerReducer } from './common/store';
// Combine all reducers into one root reducer
const appReducer = (state, action) =>
    combineReducers({
        router: routerReducer,
        app,
        investee,
        investor,
    })(state, action);

const rootReducer = (state, action) => {
    if (action.type === (ATTEMPT_TO_LOGOUT || 'CLEAR_STORES')) {
        localStorage.removeItem(`persist:${persistConfig.key}`);
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export default rootReducer;
