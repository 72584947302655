import React, { DragEventHandler } from 'react';
import Spinner from './Spinner';
import styles from '../UploadCard.module.scss';
import { showNotification } from '../../../../../../../util/utility';
import { SHOW_NOTIFICATION_STATUS } from '../../../../../../../enums/appEnums';
import UploadingCloud from './UploadingCloud';
import { getInputAcceptValue } from '../utils/uploadDocUtils';
import { OTHERS, additionalOption } from '../../../../../../../constants/consts';
import DragDropUpload from '../../../../../../../components/DragDropUpload';

interface AdditionalDataUploadProps {
    category: string;
    keyName: string;
    files: any;
    provideOptionToAddData: boolean;
    description: string;
    isUploading: boolean;
    isDownloading: string | null;
    inputFileRef: any;
    mainText: string;
    mainTextOptional: string | undefined;
    otherValue: string;
    setOtherValue: Function;
    deleteFile: Function;
    downloadFile: Function;
    setDescription: Function;
    onFileChangeCapture: Function;
    setProvideOptionToAddData: Function;
    handleDrag: DragEventHandler<HTMLDivElement>;
    handleDrop: DragEventHandler<HTMLDivElement>;
    isDataVault: boolean;
    setUploadedCard: any;
}

function AdditionalDataUpload({
    category,
    keyName,
    files,
    provideOptionToAddData,
    description,
    isUploading,
    inputFileRef,
    setDescription,
    onFileChangeCapture,
    setProvideOptionToAddData,
    handleDrag,
    handleDrop,
    otherValue,
    setOtherValue,
    isDataVault,
    setUploadedCard,
}: AdditionalDataUploadProps) {
    const checkDescThenCapture = (e: any) => {
        if (description) {
            if (description === OTHERS) {
                if (otherValue) {
                    onFileChangeCapture(e);
                } else {
                    inputFileRef.current.value = '';
                    showNotification(
                        SHOW_NOTIFICATION_STATUS.ERROR,
                        'You need to enter description first!',
                    );
                }
            } else {
                onFileChangeCapture(e);
            }
        } else {
            inputFileRef.current.value = '';
            showNotification(
                SHOW_NOTIFICATION_STATUS.ERROR,
                'You need to enter description first!',
            );
        }
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedDescription = e.target.value;
        if (!selectedDescription) {
            setDescription('');
            return;
        }
        setDescription(selectedDescription);
        // Check if the selected description already exists in the uploaded files
        const existingFile = files.find((file: any) => file.description === selectedDescription);
        if (existingFile) {
            setUploadedCard(selectedDescription);
            setProvideOptionToAddData(false);
            setDescription('');
        }
    };

    return (
        <>
            {provideOptionToAddData || !files?.length ? (
                <>
                    <div className={styles.AdditionalData}>
                        <select
                            className={styles.AdditionDataModalSelect}
                            placeholder="Enter description"
                            value={description}
                            required
                            onChange={handleDescriptionChange} // Use new handler
                        >
                            <option value={''}>Select Description</option>
                            {additionalOption.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </select>
                        {description === OTHERS ? (
                            <input
                                required
                                type="text"
                                className={styles.additionDataModalInputBox}
                                placeholder="Description"
                                value={otherValue}
                                onChange={(e) => setOtherValue(e.target.value)}
                            />
                        ) : null}
                    </div>

                    <div className={styles.fullwidth}>
                        <input
                            type="file"
                            ref={inputFileRef}
                            onChangeCapture={checkDescThenCapture}
                            style={{ display: 'none' }}
                            accept={getInputAcceptValue(category, keyName)}
                            multiple={true}
                        />
                        <div
                            className={styles.styledUploadContainerSingle}
                            onClick={() => inputFileRef.current.click()}
                            onDrop={handleDrop}
                            onDragOver={handleDrag}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                        >
                            <DragDropUpload />
                        </div>
                    </div>
                </>
            ) : null}
            {isUploading && <UploadingCloud width="250px" left="122px" />}
        </>
    );
}

export default AdditionalDataUpload;
