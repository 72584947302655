import { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

export const renderTootip = (printText, tipId, place, className) => {
    const arr = typeof printText === 'string' && printText.split('\n');
    return (
        <div className="ReactTooltip">
            <ReactTooltip
                id={tipId}
                type="light"
                place={place}
                className={className}
                border={true}
                borderColor="black"
                delayShow={50}
                multiline={true}
            >
                <span className="fs10 text-left">
                    {typeof printText === 'string'
                        ? arr.map((i, key) => {
                              return (
                                  <Fragment key={key}>
                                      <div>{i}</div>
                                      {key + 1 !== arr.length && <br />}
                                  </Fragment>
                              );
                          })
                        : printText}
                </span>
            </ReactTooltip>
        </div>
    );
};
