/**
 * @Description
 * This file intended to intercept incoming request and response.
 * Main utility will to authorize the user else redirect to login page.
 * or we want the error to display api failure and sentry logs can be put here.
 */
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { USER_TYPE_INVESTEE, USER_TYPE_INVESTOR } from '../enums/appEnums';
import { logOutUser } from '../modules/App/AppActions';
import { store } from '../common/persist';
import { captureMessagesViaErrorLogger } from '../util/errorLogger';
import storage from '../util/storageService';
import { showNotification } from '../util/utility';
import { CustomRequest, summonZeusResponse } from './common';
import { NavigateFunction } from 'react-router';

declare global {
    interface Window {
        gapi: any;
    }
}
const logOutFromApp = (navigate: NavigateFunction): void => {
    let userSegment = storage.get(USER_TYPE_INVESTEE) || storage.get(USER_TYPE_INVESTOR);
    const authInstance: any = window?.gapi?.auth2?.getAuthInstance();
    if (authInstance) authInstance.signOut();
    //check this case
    if (store) store.dispatch(logOutUser(navigate, userSegment));
};

const onRequest = (
    config: CustomRequest,
    navigate: NavigateFunction,
    summonZeusParams?: summonZeusResponse,
): CustomRequest => {
    const _token = summonZeusParams?.cerberusToken ?? storage.get('cerberus_token');
    if (config.isAuthRequired) {
        // fetch token from redux (first write a Auth Reducer)
        //@TODO change this fetch from store.
        if (!_token) logOutFromApp(navigate);
        if (_token)
            config.headers = {
                ...config.headers,
                'x-cerberus-token': _token ?? '',
                'x-organization-id': summonZeusParams?.orgId ?? storage.get('org_id') ?? '',
                'x-user-id': summonZeusParams?.userId ?? storage.get('user_id') ?? '',
            };
        delete config.isAuthRequired;
    }
    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    if (error.name !== 'CanceledError') captureMessagesViaErrorLogger(error.message);
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse, navigate: NavigateFunction): AxiosResponse => {
    if (response.status === 401) {
        showNotification('Error', 'Session Timed Out');
        logOutFromApp(navigate);
    }
    return response;
};

const onResponseError = (error: AxiosError, navigate: NavigateFunction): Promise<AxiosError> => {
    if (error?.response?.status === 401) {
        showNotification('Error', 'Session Timed Out');
        logOutFromApp(navigate);
    }
    if (error.name !== 'CanceledError') captureMessagesViaErrorLogger(error.message);
    return Promise.reject(error);
};

export function applyInterceptor(
    axiosInstance: AxiosInstance,
    navigate: NavigateFunction,
    summonZeusParams?: summonZeusResponse,
): AxiosInstance {
    axiosInstance.interceptors.request.use(
        (req) => onRequest(req, navigate, summonZeusParams),
        onRequestError,
    );
    axiosInstance.interceptors.response.use(
        (res) => onResponse(res, navigate),
        (err) => onResponseError(err, navigate),
    );
    return axiosInstance;
}
