import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '../../../modules/App/AppActions';
import {
    getInvesteeOrganizationInfo,
    getInvestorOrganizationInfo,
} from '../../../modules/App/AppReducer';
import { USER_TYPE_INVESTEE, USER_TYPE_INVESTOR } from '../../../enums/appEnums';
import styles from '../styles/UsernameBtn.module.scss';
import useShowOnHoverAndClick from '../../../customHooks/useShowOnHoverAndClick';
import { useNavigate } from 'react-router';

export default function UsernameBtn({
    name,
    subRoutes,
    category,
    renderNavLinks,
    selectedSegment,
    hasSelectedAnyOption,
}) {
    const {
        SidebarUsername,
        HiddenSidebar,
        InvesteeLogout,
        Sublist,
        NameInitials,
        UserFirstName,
        UserCompany,
        TermsOfService,
        Dot,
        ItemStyle,
    } = styles;
    const LOGOUT = 'Logout';
    const [showOptions, btnProps] = useShowOnHoverAndClick(false);
    const navigate = useNavigate();

    const displayName = useSelector((state) => getInvesteeOrganizationInfo(state)?.display_name);
    const investorOrgName = useSelector((state) => getInvestorOrganizationInfo(state)?.name);

    const dispatch = useDispatch();

    const renderOptions = (item, index) => {
        if (item.name === LOGOUT) {
            return (
                <div key={index} onClick={logOut} className={InvesteeLogout}>
                    {LOGOUT}
                </div>
            );
        }
        return renderNavLinks(item, index, true);
    };

    const logOut = () => {
        const authInstance = window?.gapi?.auth2?.getAuthInstance();
        if (!authInstance) {
            dispatch(logOutUser(navigate, selectedSegment));
            return;
        }
        authInstance
            .signOut()
            .then(() => {
                dispatch(logOutUser(navigate, selectedSegment));
            })
            .catch(() => {
                dispatch(logOutUser(navigate, selectedSegment));
            });
    };

    const getNameDetails = () => {
        if (!name) {
            return {
                firstName: '',
                nameInitials: '',
            };
        }
        const fullNameArr = name.trim().split(' ');
        const firstName = fullNameArr[0];
        const lastName = fullNameArr.pop();
        const initials = firstName.charAt(0) + (lastName ? lastName.charAt(0) : '');

        return {
            firstName: firstName,
            nameInitials: initials.toUpperCase(),
        };
    };

    const { firstName, nameInitials } = getNameDetails();
    const getBgColor = () => {
        if (category === USER_TYPE_INVESTEE) {
            return hasSelectedAnyOption ? '#48506D' : '#151D3B';
        }
        if (category === USER_TYPE_INVESTOR) {
            return hasSelectedAnyOption ? '#4D4472' : '#483A81';
        }
    };

    return (
        <div>
            <div className={HiddenSidebar}>
                {showOptions && (
                    <div style={{ padding: '10px 0' }} {...btnProps}>
                        <div
                            className={Sublist}
                            style={{
                                backgroundColor:
                                    selectedSegment === 'investor' ? '#2C1459' : '#141C3D',
                            }}
                        >
                            {subRoutes.map((item, index) => renderOptions(item, index))}
                        </div>
                    </div>
                )}
                <div
                    className={SidebarUsername}
                    style={{ backgroundColor: getBgColor() }}
                    {...btnProps}
                >
                    <div className={NameInitials}>{nameInitials}</div>
                    <div style={{ lineHeight: '14px', marginTop: '5px' }}>
                        <div className={UserFirstName}>
                            <span data-bs-toggle="tooltip" data-bs-html="true" title={firstName}>
                                {firstName}
                            </span>
                        </div>
                        <div className={UserCompany}>
                            {selectedSegment === 'investor' ? investorOrgName : displayName}
                        </div>
                    </div>
                </div>

                {selectedSegment === USER_TYPE_INVESTEE && (
                    <div className={TermsOfService}>
                        <div className={ItemStyle}>
                            <a
                                href="https://www.recurclub.com/terms-of-use"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms of service
                            </a>
                        </div>
                        <div className={Dot}></div>
                        <div className={ItemStyle}>
                            <a
                                href="https://www.recurclub.com/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {' '}
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
