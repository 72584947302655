import { SHOW_NOTIFICATION_STATUS } from '../../../../../../../../enums/appEnums';
import { captureMessagesViaErrorLogger } from '../../../../../../../../util/errorLogger';
import { showNotification } from '../../../../../../../../util/utility';
import {
    deleteFileApi,
    getSingedUrlApi,
    initiateFileIngestionApi,
    updateMetadataApi,
    updateMetaDataForDebtSchedule,
    uploadFileApi,
} from './apiContainer';

export const getSignedUrl = async (input: any) => {
    try {
        const response = await getSingedUrlApi(input);
        return response.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const uploadFile = async (uploadUrl: string, data: any) => {
    const formData = new FormData();
    for (const name of Object.keys(data)) {
        formData.append(name, data[name]);
    }
    try {
        const uploadResponse = await uploadFileApi(uploadUrl, formData);
        return uploadResponse;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};

export const updateMetadata = async (input: any, data: any, isOnboarding: boolean) => {
    try {
        const response = await updateMetadataApi(input, data, isOnboarding);
        if (response?.data?.status === 'Success') return response.data;
        else {
            throw new Error("Couldn't update metadata!");
        }
    } catch (err) {
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Failed to upload, something went wrong');
        captureMessagesViaErrorLogger(err);
    }
};

export const triggerFileIngestion = async (data: any, category: string) => {
    try {
        const response = await initiateFileIngestionApi(data, category);
        return response;
    } catch (err: any) {
        captureMessagesViaErrorLogger(err);
        return err;
    }
};

export const deleteFile = async (input: any, data: any, isOnboarding: boolean) => {
    try {
        const response = await deleteFileApi(input, data, isOnboarding);
        return response.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
export const getUpdateMetaDataForDebtSchedule = async (
    tag: boolean,
    onboardingId: string,
    path: string,
) => {
    try {
        const response = await updateMetaDataForDebtSchedule(tag, onboardingId, path);
        return response.data;
    } catch (err) {
        captureMessagesViaErrorLogger(err);
    }
};
