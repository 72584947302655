import React, { useEffect } from 'react';
import styles from '../../pages/GetStarted/Components/GetStartedForm.module.scss';
import { JOB_ROLES } from '../../../../constants/consts';
import './JobRoleDropdown.scss';

interface JobRoleDropDownProps {
    jobRole: string;
    setJobRole: (jobRole: string) => void;
    className?: string;
    otherJobRole: string;
    otherClassName?: string;
    setOtherJobRole: (jobRole: string) => void;
    disable?: boolean;
}
export const JobRoleDropDown = (props: JobRoleDropDownProps) => {
    const [isOtherDesignation, setIsOtherDesignation] = React.useState(false);
    useEffect(() => {
        if (props.jobRole === 'Others') {
            setIsOtherDesignation(true);
        } else {
            setIsOtherDesignation(false);
        }
    }, [props.jobRole]);
    return (
        <>
            <div
                style={
                    props.className === 'IndividualCoborrower'
                        ? { gap: '1rem', marginBottom: '0.5rem', width: '50%' }
                        : {}
                }
                className={props.className === 'IndividualCoborrower' ? 'dflex' : ''}
            >
                <select
                    name="job-role"
                    id="job-role-select"
                    disabled={props.disable}
                    className={`${styles.formInput} ${props.className}`}
                    style={
                        props.className === 'IndividualCoborrower' && isOtherDesignation
                            ? { width: '40%' }
                            : {}
                    }
                    value={props.jobRole}
                    required
                    onChange={(e) => {
                        if (e.target.value === 'Others') {
                            setIsOtherDesignation(true);
                        }
                        props.setJobRole(e.target.value);
                    }}
                >
                    <option value="" selected disabled hidden>
                        Your Job Role
                    </option>
                    {JOB_ROLES.map((jobRole) => (
                        <option value={jobRole.value}>{jobRole.label}</option>
                    ))}
                </select>
                {isOtherDesignation && (
                    <input
                        id="otherDesignation"
                        required
                        disabled={props.disable}
                        value={props.otherJobRole}
                        onChange={(e) => props.setOtherJobRole(e.target.value)}
                        type="text"
                        className={`loginInput form-control ${styles.formInput} ${props.otherClassName}`}
                    />
                )}
            </div>
        </>
    );
};
