import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import styles from './SearchBoxWithDropDown.module.scss';
import { isEmpty, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { triggerDrawer } from '../../modules/App/AppActions';
import { DRAWER_HEADINGS } from '../../DrawerConsts';
import { useNavigate } from 'react-router';
import {
    metDataMappingRedirection,
    ObjectItem,
    NOTIFICATION_SEARCHHISTORY,
    ALL_DRAWDOWNS,
    ALL_TERM_SHEETS_RESULTNAME,
    TDS_REFUND_RESULTNAME,
    TRANSACTION_DOCUMENTS,
} from './searchConsts';
import { getUserDetails } from '../../modules/App/AppReducer';
import { mixPanelTrack } from '../../util/utility';

const cookies = new Cookies();

const SearchBoxWithDropDown: React.FC = () => {
    const {
        searchBoxContainer,
        searchResultsContainer,
        searchResultContainer,
        focused,
        dropdownContainer,
    } = styles;
    const [isFocused, setIsFocused] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResultsData, setSearchResults] = useState<ObjectItem[]>([]);
    const [typingTimeOut, setTypingTimeOut] = useState<ReturnType<typeof setTimeout> | null>(null);
    const userDetails = useSelector((state) => getUserDetails(state));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const completeDefaultList = useMemo(() => {
        const defaultData = [
            ALL_DRAWDOWNS,
            ALL_TERM_SHEETS_RESULTNAME,
            TRANSACTION_DOCUMENTS,
            TDS_REFUND_RESULTNAME,
        ];
        const result: any[] = [];
        for (let i = 0; i < metDataMappingRedirection.length; i++) {
            for (let j = 0; j < defaultData.length; j++) {
                if (defaultData[j] === metDataMappingRedirection[i].resultName) {
                    let payload = {
                        ...metDataMappingRedirection[i],
                    };
                    //@ts-ignore
                    delete payload.keywords;
                    result.push(payload);
                }
            }
        }
        return result;
    }, []);

    useEffect(() => {
        if (!isNil(searchTerm)) {
            if (typingTimeOut) {
                clearTimeout(typingTimeOut);
                setTypingTimeOut(null);
            }
            setTypingTimeOut(
                setTimeout(() => {
                    if (isEmpty(searchResultsData) && searchTerm) {
                        //mixpanel track when no results found, After typing stopped for 3seconds
                        mixPanelTrack({
                            id: userDetails.investee_organization_id,
                            trackUserObj: {
                                email: userDetails.contact_email,
                                search_query: searchTerm,
                            },
                            trackCategoryName: 'Search - No results',
                        });
                    }
                }, 3000),
            );
        }
    }, [searchTerm]);

    useEffect(() => {
        const oldSearchData = cookies.get(NOTIFICATION_SEARCHHISTORY);
        if (!oldSearchData?.length) {
            let currentDate = new Date();
            // Add one year to the current date
            let expirationDate = new Date(currentDate.getTime());
            expirationDate.setFullYear(currentDate.getFullYear() + 1);
            cookies.set(NOTIFICATION_SEARCHHISTORY, completeDefaultList, {
                path: '/',
                expires: expirationDate,
            });
            setSearchResults(completeDefaultList);
        } else setSearchResults(oldSearchData);
    }, [completeDefaultList]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const searchObjectsByKeyword = (searchTerm: string) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        return metDataMappingRedirection.filter((obj) => {
            obj.keywords.push(obj.resultName);
            return obj.keywords.some((keyword) =>
                keyword.toLowerCase().includes(lowerCaseSearchTerm),
            );
        });
    };

    const handleClick = () => {
        //mixpanel track
        mixPanelTrack({
            id: userDetails.investee_organization_id,
            trackUserObj: {
                email: userDetails.contact_email,
            },
            trackCategoryName: 'Search bar clicks',
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        if (event.target.value.trim() === '') {
            const oldSearchData = cookies.get(NOTIFICATION_SEARCHHISTORY);
            setSearchResults(oldSearchData ?? completeDefaultList);
            return;
        }
        const results = searchObjectsByKeyword(event.target.value);
        setSearchResults(results);
    };
    const redirectOnSearchResult = (obj: ObjectItem) => {
        //@ts-ignore
        obj = metDataMappingRedirection.find((item) => item.resultName === obj.resultName);
        const resultNamesArray = searchResultsData.map((obj) => obj.resultName);
        const all_search_results = resultNamesArray?.join(', ');
        //mixpanel track
        mixPanelTrack({
            id: userDetails.investee_organization_id,
            trackUserObj: {
                email: userDetails.contact_email,
                search_query: searchTerm,
                all_search_results,
                clicked_search_result: obj?.resultName,
            },
            trackCategoryName: 'Search results clicked',
        });

        setIsFocused(false);
        const oldSearchData = cookies.get(NOTIFICATION_SEARCHHISTORY);
        let cookiePayload;
        const cookiesObj = { ...obj };
        //@ts-ignore
        delete cookiesObj['keywords'];
        if (oldSearchData?.length) {
            //@ts-ignore //
            const isAlreadyExist = oldSearchData?.findIndex((item: any) => {
                return item?.resultName === cookiesObj.resultName;
            });
            if (isAlreadyExist === -1) {
                cookiePayload = [cookiesObj, ...oldSearchData];
                cookiePayload.pop();
            } else {
                oldSearchData.splice(isAlreadyExist, 1);
                cookiePayload = [cookiesObj, ...oldSearchData];
            }
        } else {
            let rest = completeDefaultList.filter(
                (item: any) => item.resultName !== cookiesObj.resultName,
            );
            cookiePayload = [cookiesObj, ...rest];
            if (cookiePayload.length > completeDefaultList.length) cookiePayload.pop();
        }
        setSearchResults(cookiePayload);
        let currentDate = new Date();
        // Add one year to the current date
        let expirationDate = new Date(currentDate.getTime());
        expirationDate.setFullYear(currentDate.getFullYear() + 1);
        cookies.set(NOTIFICATION_SEARCHHISTORY, cookiePayload, {
            path: '/',
            expires: expirationDate,
        });
        if (obj.drawer) dispatch(triggerDrawer(obj.drawer));
        if (obj.externalNavigation) window.open(obj.externalNavigation, '_blank');
        else navigate(obj.redirection);
    };
    return (
        <div className={`${searchBoxContainer} ${isFocused && focused}`}>
            <span>
                <img
                    alt=""
                    src="../../../../../assets/search-black.svg"
                    style={{ height: '18px', width: '18px' }}
                />
            </span>
            <input
                type="text"
                value={searchTerm}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClick={handleClick}
                placeholder={isFocused ? 'Search for anything you need' : 'Search'}
            />

            {isFocused && (
                <div className={`${dropdownContainer} ${focused}`}>
                    <div className={searchResultsContainer}>
                        {isEmpty(searchResultsData) && searchTerm ? (
                            <div key="noRes" className={searchResultContainer}>
                                No Results Found
                            </div>
                        ) : (
                            searchResultsData?.map((result, index) => (
                                <div
                                    key={index}
                                    className={searchResultContainer}
                                    onMouseDown={() => redirectOnSearchResult(result)}
                                >
                                    <img
                                        alt=""
                                        src="../../../../../assets/search-black.svg"
                                        style={{ height: '18px', width: '18px', color: 'black' }}
                                    />
                                    <span style={{ marginLeft: '8px' }}>{result.resultName}</span>
                                </div>
                            ))
                        )}
                    </div>
                    <hr />
                    {/* {searchTerm && <hr />} */}
                    <div
                        style={{ cursor: 'pointer', color: '#2F8FFF', marginLeft: '7px' }}
                        onMouseDown={() => navigate('/company/customer-support')}
                    >
                        <img
                            className="r-mr-2"
                            src="/assets/need-help-black.svg"
                            style={{ height: '18px', width: '18px' }}
                            alt=""
                        />
                        {'   '}
                        Need Help
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchBoxWithDropDown;
