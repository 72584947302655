import React from 'react';

const MobileView = () => {
    return (
        <div className="mobile_view" style={{ margin: '0 auto', textAlign: 'center' }}>
            <img
                src="https://recur-image.s3.us-east-1.amazonaws.com/Mobile_view.svg"
                style={{ height: '220px', marginTop: '25px' }}
                alt="Mobile View"
            />
            <h4
                style={{
                    color: '#853CE8',
                    fontSize: '26px',
                    paddingTop: '30px',
                    fontWeight: '600',
                }}
            >
                Our mobile site is under construction
            </h4>
            <p
                style={{
                    color: '#B3B3B3',
                    fontSize: '14px',
                    fontWeight: '300',
                    paddingTop: '30px',
                }}
            >
                We are working very hard to give you the best
                <br /> experience with this one.{' '}
            </p>
            <p
                style={{
                    color: '#303030',
                    fontSize: '14px',
                    paddingTop: '20px',
                    fontWeight: '300',
                }}
            >
                In the meanwhile you can still login from your laptop, desktop or tablet to use your
                Recur account.
            </p>
            <div className="mobile_view_button" style={{ marginTop: '25px' }}>
                <a
                    href="https://www.recurclub.com/"
                    style={{
                        color: '#000000',
                        backgroundColor: '#69DBD7',
                        padding: '10px 36px 14px',
                        borderRadius: '20px',
                    }}
                >
                    Back to Home
                </a>
            </div>
        </div>
    );
};

export default MobileView;
