export const APPLICATION_CONTEXT = '/'; //@TODO TAKE FROM ENV VARS
export const INVESTEE_BASE_PATH = APPLICATION_CONTEXT; //APPLICATION_CONTEXT + 'company/' @TODO CHECK EVERY ROUTE AND THEN CHANGE THIS
export const INVESTOR_BASE_PATH = APPLICATION_CONTEXT + 'investor/';

//INVESTEE ROUTES
export const INVESTEE_ROUTES = {
    PUBLIC: {
        //INVESTEE_LOGIN: '/', @TODO CHECK THIS FROM BACKEND
        SIGNUP: 'signup',
        LOGIN: 'login',
        SIGNUP_GET_STARTED: 'signup/get-started',
        VERIFY_EMAIL: 'verifyemail',
        OTP_SCREEN: 'otp-screen',
        FORGOT_PASSWORD: 'forgot-password',
        RESET_PASSWORD: 'reset-password',
        RECUR_CHECKOUT: ':id/recur-checkout', //Do we need this to be public
        GST_INVOICES_FETCH: ':id/gst-invoices-fetch',
        TRADEBOOK_FILTERS: 'filters',
        VERIFY: 'verify',
        CIBIL_VERIFICATION: '/olympus/rest/cibil/captureConsent',
        FUNDING_CALCULATOR: '/funding-calculator',
    },
    PRIVATE: {
        BASE_PATH: 'company',
        ONBOARDING: 'onboarding',
        ADD_ON_LIMIT: 'add-on-limit',
        GET_STARTED: 'get-started',
        RECUR_PRODUCTS: 'recur-products',
        DASHBOARD: 'dashboard',
        COMPLIANCES: 'compliances',
        PRO: 'pro',
        REPAYMENTS: 'finances/transactions',
        TRANSACTIONS_PAYOUTS: 'finances/transactions/payouts',
        TRANSACTIONS_PAYMENTS: 'finances/transactions/payments',
        FINANCES_REPORTS: 'finances/reports',
        INSIDERS: 'insiders',
        COLLECTIONS: 'collections',
        DATA_VAULT: 'data-vault',
        TRADE_LIMITS: 'trade-limits',
        TERMS: 'terms',
        TRADE: 'trade',
        CUSTOMER_SUPPORT: 'customer-support',
        GLOBALNEEDINFO: 'globalneedinfo',
    },
};

//INVESTOR ROUTES
export const INVESTOR_ROUTES = {
    PUBLIC: {
        SIGNUP: 'signup',
        LOGIN: 'login',
        FORGOT_PASSWORD: 'forgot-password',
        RESET_PASSWORD: 'reset-password',
        REQUEST_SUBMITTED: 'request-submitted',
        VERIFY_EMAIL: 'verify-email',
        OTP_SCREEN: 'otp-screen',
        COUNTER_SIGN: 'counter-sign',
    },
    PRIVATE: {
        BASE_PATH: 'investor',
        DASHBOARD: 'dashboard',
        INVESTBOOK: 'investbook',
        DEALS: 'deals',
        DOCS: 'docs',
        PORTFOLIO: 'portfolio',
        FINANCES_TRANSACTIONS: 'finances/transactions',
        FINANCES_REPORTS: 'finances/reports',
        FINANCES_DRAW_DOWN: 'finances/transactions/drawdowns',
        FINANCES_PAYMENTS: 'finances/transactions/payments',
        // FINANCES_STATEMENTS: 'finances/statements',
        FINANCES_INVOICES: 'finances/invoices',
        PROFILE: 'profile',
        HELP: 'help',
        STRATERGY: 'strategy',
        CUSTOMER_REQUESTS: 'customer-requests',
        CREATE_STRATERGY: 'CreateStrategy',
        VIEW_EDIT_STRATERGY: 'ViewEditStrategy/:id',
        TERMS: 'terms',
        MARKET_ID: 'market/:id',
        MARKET: 'market',
    },
};
