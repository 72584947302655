import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../DemoAccount/DemoAccountLine.module.scss';

const { yellowLine, yellowLowerLine, Demo_tooltip, Demo_tooltiptext } = styles;

function DemoAccountLine() {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <div className={yellowLine}>
            <div className={yellowLowerLine}>
                <span className="r-mr-3">
                    {/*  You are in
                    <span className="ff-gb"> demo mode</span>. */}{' '}
                    Please complete
                    <NavLink to="/company/get-started" className="ff-gb">
                        {' '}
                        KYC documents{' '}
                    </NavLink>{' '}
                    to go live
                </span>
                <span
                    className={Demo_tooltip}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <img src="/assets/demo-acc-help.svg" alt="" />
                    {showTooltip && (
                        <div className={Demo_tooltiptext}>
                            Your account is under review and is presently not live on our investor
                            platform. Displayed trading limit and price are only indicative and will
                            be updated once your account is approved.
                            <br />
                            <br />
                            Please make sure you provide all the requested details and sign the
                            documentation to start trading. For more information contact us from
                            help center.
                        </div>
                    )}
                </span>
            </div>
        </div>
    );
}

export default DemoAccountLine;
