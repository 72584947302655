import React from 'react';
import { PieChart, Pie, Tooltip } from 'recharts';

import CustomTooltip from '../../../../../../../components/CustomToolTip';

export default function PayoutOverviewChart({ data }) {
    return (
        <div className="overview-pie">
            <PieChart width={730} height={250}>
                <Pie
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={210}
                    outerRadius={350}
                    labelList
                />
                <Tooltip content={<CustomTooltip isPie={data} />} />
            </PieChart>
        </div>
    );
}
