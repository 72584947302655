import { isEmpty } from 'lodash';
import { ADD_ON_LIMIT_STATUS, FETCH_STATUS } from '../../enums/appEnums';
import { mergeByProperty } from '../../util/calculateTermUtil';
import {
    FETCH_TEAM_MEMBERS,
    FETCH_TEAM_MEMBERS_SUCCESS,
    FETCHING_TEAM_MEMBERS,
    ADD_NEW_MEMBER,
    ADDING_NEW_MEMBER,
    FETCHING_CONTRACT_LIST,
    FETCH_CONTRACT_LIST,
    FETCHING_TRADEBOOK_CONTRACT_LIST,
    FETCH_TRADEBOOK_CONTRACT_LIST,
    FETCHING_TRADEBOOK_ORDERS,
    FETCH_TRADEBOOK_ORDERS,
    UPLOADING_CONTRACTS,
    UPLOAD_CONTRACTS,
    MARKING_CONTRACT_FOR_LISTING,
    MARKED_CONTRACT_FOR_LISTING,
    FETCH_INVESTEE_TRANSACTIONS,
    FETCH_REPAYMENT_SCHEDULE,
    FETCHING_REPAYMENT_SCHEDULE,
    FETCHING_INVESTEE_TRANSACTIONS,
    FETCHING_ARR_DATA,
    FETCH_ARR_DATA,
    FETCHING_TRADEBOOK_DATA,
    FETCH_TRADEBOOK_DATA,
    SENDING_RESET_PASS_MAIL,
    SET_TRADE_CONTRACTS,
    FETCHING_INVESTEE_ACCOUNT_STATEMENT,
    FETCH_INVESTEE_ACCOUNT_STATEMENT,
    SETTING_REPAYMENT_SCHEDULE,
    RESET_CONTRACT_LIST,
    FETCHING_ONBOARDING_DATA,
    FETCH_ONBOARDING_DATA,
    SET_REPAYMENT_SCHEDULE,
    FETCHING_FINBOX_REDIRECT_URL,
    FETCH_FINBOX_REDIRECT_URL,
    FETCHING_OVERVIEW_DATA,
    FETCH_OVERVIEW_DATA,
    FETCHING_INVESTEE_DASHBOARD_STATS,
    FETCH_INVESTEE_DASHBOARD_STATS,
    FETCHING_INVOICES,
    FETCH_INVOICES,
    FETCHING_NOTIFICATIONS,
    FETCH_NOTIFICATIONS,
    FETCH_UNREAD_NOTIFICATION_COUNT,
    FETCHING_UNREAD_NOTIFICATION_COUNT,
    FETCHING_KYC_DOCS,
    FETCH_KYC_DOCS,
    TOGGLE_AUTH_SIGN_MODAL,
    FETCHING_ORDER_REPAYMENT_SCHEDULE,
    FETCH_ORDER_REPAYMENT_SCHEDULE,
    SET_SELECTED_ORDER,
    FETCH_CUSTOMERS_TRADED,
    UPDATE_CONTRACT_LIST,
    IS_FETCHING_TOT_DATA,
    FETCH_TOT_DATA,
    FETCHING_INVESTEE_DEALS,
    FETCH_INVESTEE_DEALS,
    FETCHING_ADD_ON_LIMIT_STATUS,
    FETCH_ADD_ON_LIMIT_STATUS,
    FETCHING_ADD_ON_DATA,
    FETCH_ADD_ON_DATA,
    FETCH_LIMIT_OVERVIEW_DATA,
    FETCH_RECUR_SCALE_OFFERS,
    UPDATE_CAPITAL_AVAILABLE_DATA,
    UPDATE_COLLECTION_MODE,
    TRIGGER_ADD_ON_LIMIT_MODAL,
    UPDATE_FORECAST_DATA,
    UPDATING_FORECAST_DATA,
    REPAYMENT_DRAWER_TYPE,
    SET_INVESTEE_TRANSACTIONS_CANCEL_TOKEN,
    BANK_ACCOUNT_CLICKED_FROM_REVIEW_PAGE,
    FETCHING_MOBILE_UPLOAD_STEPS,
    FETCH_MOBILE_UPLOAD_STEPS,
} from './InvesteeActions';

const initialState = {
    repayment_schedule: [],
    is_fetching_members: false,
    is_member_data_fetched_success: false,
    members: [],
    dashboardStats: [],
    is_fetching_trade_contracts: FETCH_STATUS.IDLE,
    trade_contracts: [],
    priorityInvestorLimit: null,
    priorityInvestorDealStatus: null,
    is_fetching_tradebook_contracts: false,
    tradebook_contracts: [],
    isFetchingTradeOrders: false,
    tradebookOrders: {
        id: '',
        list: [],
    },
    tradebook_contracts_page_size: 200,
    tradebook_contracts_total_count: 0,
    is_fetching_notifications: false,
    is_fetching_unread_notifications_count: false,
    notifications: [],
    notifications_total_count: 0,
    unread_notifications_count: 0,
    platform_faqs: [],
    is_marking_contract_for_listing: false,
    trade_contracts_total_count: 0,
    approved_contract_count: 0,
    noMoreLoading: false,
    tradebook_contracts_listed_count: 0,
    trade_contracts_page_size: 200,
    payout_transactions: [],
    payment_transactions: [],
    payout_transactions_total_count: 0,
    payment_transactions_total_count: 0,
    is_fetching_account_statement: false,
    investee_account_statement: [],
    account_statement_total_records: 0,
    repayment_schedule_on_hover: [],
    isFetchingRepaymentSchedule: false,
    isFetchingOrderRepaymentSchedule: false,
    isFetchingCustomerTraded: false,
    customersTraded: {
        list: [],
        id: '',
    },
    repaymentOrderSchedule: {},
    userSelectedOrder: {},
    is_fetching_tradebook_data: false,
    onboardingData: {
        isFetchingDetails: false,
        isPanVerified: false,
        isCinVerified: false,
        companyDetails: {
            pan_number: '',
            cin_number: '',
            company_website: '',
            registered_company_name: '',
            current_company_address: '',
            is_business_verified_complete: false,
        },
        isUploadingAnyDoc: false,
        haveSomeCompanyDetails: false,
        additionalDocs: [],
        misDocs: [],
        misDone: false,
        misIntegrations: {},
        financialStatements: [],
        financialStatementsDone: false,
        invoicingDocs: [],
        invoicingDone: false,
        invoicingIntegrations: {},
        customerContracts: [],
        customerContractsDone: false,
        companyBankDetails: {
            bank_name: '',
            account_number: '',
            account_holder_name: '',
            ifsc: '',
            account_nick_name: '',
            is_bank_details_complete: false,
        },
        isBankDetailsComplete: false,
        haveSomeCompanyBankDetails: false,
        isEditingPrimaryBankDetails: false,
        bankAccounts: [],
        isResetingBankDetails: false,
        finboxBankDone: {
            hdfc: false,
            sbi: false,
            icici: false,
            otherBanks: false,
        },
        statementDocs: [],
        isFetchBankAccountDone: false,
        GST: '',
        isGSTVerified: false,
        isGSTDetailComplete: false,
        isApplicationSubmitted: false,
        isAppStarted: false,
    },
    finboxRedirectUrl: null,
    isFetchingFinboxRedirectUrl: false,
    finboxError: null,
    isFetchingFinancesOverviewData: false,
    payoutOverviewData: null,
    totalRemainingUpcomingPayment: null,
    totalCompletedRecentPayment: null,
    isFetchingInvesteeDashboardStats: false,
    primaryUserName: null,
    isFetchingKycDocs: false,
    kycDocs: {
        companyKycDocuments: {
            certificateofIncorporation: {},
            gstCertificate: {},
            companyPan: {},
            memorandumOfAssociation: {},
            articlesOfAssociation: {},
            utilityBill: {},
            officialAddressProof: {},
            isOfficialAddressProofComplete: false,
        },
        isKycDocsComplete: false,
        signatoryKycDocs: {
            signatoryPan: {},
            passportOrDlOrVoterId: {},
        },
        addedBeneficiary: {},
        isSignatoryKycComplete: false,
        additionalDocs: [],
        isAdditionalDocsComplete: false,
    },
    lastSanctionData: null,
    openAuthSignModal: false,
    isFetchingTotData: false,
    totData: {},
    isFetchingDeals: false,
    deals: [],
    isFetchingAddOnLimitStatus: false,
    addOnLimitStatus: ADD_ON_LIMIT_STATUS.ALLOWED_TO_REQUEST,
    arrData: {},
    addOnData: {},
    isFetchingAddOnData: false,
    openAddOnLimitModal: false,
    recurScaleOffers: [],
    investeeRepaymentDrawerType: 'Payments',
    investeeRepaymentDrawerFilter: [],
    is_fetching_investee_transactions: false,
    investeTransactionCancelToken: null,
    isAddBankAccountClicked: false,
    isFetchingMobileUploadSteps: false,
    mobileUploadSteps: {},
};

const investeeReducer = (state = initialState, action) => {
    let members = [];

    switch (action.type) {
        case FETCHING_ONBOARDING_DATA:
            const onboardingData = { ...state.onboardingData };
            onboardingData.isFetchingDetails = action.isFetchingDetails;
            return {
                ...state,
                onboardingData,
            };

        case FETCH_ONBOARDING_DATA:
            const onboardingDetails = { ...state.onboardingData };
            onboardingDetails.isApplicationSubmitted = action.isApplicationSubmitted;
            onboardingDetails.isAppStarted = action.isAppStarted;
            onboardingDetails.companyDetails = action.companyDetails;
            onboardingDetails.isPanVerified = action.isPanVerified;
            onboardingDetails.isCinVerified = action.isCinVerified;
            onboardingDetails.additionalDocs = action.additionalDocs;
            onboardingDetails.misDocs = action.misDocs;
            onboardingDetails.financialStatements = action.financialStatements;
            onboardingDetails.misDone = action.misDone;
            onboardingDetails.financialStatementsDone = action.financialStatementsDone;
            onboardingDetails.misIntegrations = action.misIntegrations;
            onboardingDetails.invoicingDocs = action.invoicingDocs;
            onboardingDetails.customerContracts = action.customerContracts;
            onboardingDetails.invoicingDone = action.invoicingDone;
            onboardingDetails.customerContractsDone = action.customerContractsDone;
            onboardingDetails.invoicingIntegrations = action.invoicingIntegrations;
            onboardingDetails.bankAccounts = action.bankAccounts;
            onboardingDetails.isBankDetailsComplete = action.isBankDetailsComplete;
            onboardingDetails.isFetchBankAccountDone = action.isFetchBankAccountDone;
            onboardingDetails.statementDocs = action.statementDocs;
            onboardingDetails.finboxBankDone = action.finboxBankDone;
            onboardingDetails.GST = action.GST;
            onboardingDetails.isGSTVerified = action.isGSTVerified;
            onboardingDetails.isGSTDetailComplete = action.isGSTDetailComplete;
            onboardingDetails.isFetchingDetails = action.isFetchingDetails;
            return {
                ...state,
                onboardingData: onboardingDetails,
            };

        case IS_FETCHING_TOT_DATA:
            return {
                ...state,
                isFetchingTotData: action.isFetchingTotData,
            };

        case FETCH_TOT_DATA:
            return {
                ...state,
                totData: action.totData,
                isFetchingTotData: action.isFetchingTotData,
            };

        case FETCHING_INVESTEE_DEALS:
            return {
                ...state,
                isFetchingDeals: action.isFetchingDeals,
            };

        case FETCH_INVESTEE_DEALS:
            return {
                ...state,
                deals: action.deals,
                isFetchingDeals: action.isFetchingDeals,
            };

        case TRIGGER_ADD_ON_LIMIT_MODAL:
            return {
                ...state,
                openAddOnLimitModal: action.openAddOnLimitModal,
            };

        case FETCHING_FINBOX_REDIRECT_URL:
            return {
                ...state,
                isFetchingFinboxRedirectUrl: action.isFetchingFinboxRedirectUrl,
                finboxError: action.finboxError ? action.finboxError : null,
            };

        case FETCH_FINBOX_REDIRECT_URL:
            return {
                ...state,
                finboxRedirectUrl: action.finboxRedirectUrl,
                isFetchingFinboxRedirectUrl: action.isFetchingFinboxRedirectUrl,
            };

        case FETCHING_KYC_DOCS:
            return {
                ...state,
                isFetchingKycDocs: action.isFetchingKycDocs,
            };

        case FETCH_KYC_DOCS:
            return {
                ...state,
                kycDocs: action.kycDocs,
                isFetchingKycDocs: action.isFetchingKycDocs,
            };

        case TOGGLE_AUTH_SIGN_MODAL:
            return {
                ...state,
                openAuthSignModal: action.openAuthSignModal,
            };

        case UPDATING_FORECAST_DATA:
            return {
                ...state,
                isLoadingForecastData: action.isLoadingForecastData,
            };

        case UPDATE_FORECAST_DATA:
            return {
                ...state,
                forecastData: action.forecastData,
                isLoadingForecastData: action.isLoadingForecastData,
            };

        case FETCHING_INVOICES:
            return {
                ...state,
                isFetchingInvoices: action.isFetchingInvoices,
            };

        case FETCH_INVOICES:
            return {
                ...state,
                invoices: action.invoices,
                invoicesTotalCount: action.invoicesTotalCount,
                isFetchingInvoices: action.isFetchingInvoices,
            };

        case FETCHING_OVERVIEW_DATA:
            return {
                ...state,
                isFetchingFinancesOverviewData: action.isFetchingFinancesOverviewData,
            };

        case FETCH_OVERVIEW_DATA:
            return {
                ...state,
                payoutOverviewData: action.payoutOverviewData,
                investeePayouts: action.investee_payouts,
                monthlyCompletedPayments: action.monthly_completed_payments,
                monthlyScheduledPayments: action.monthly_scheduled_payments,
                monthlyScheduledPaymentsGraph: action.monthly_scheduled_payments_graph,
                totalRemainingUpcomingPayment: action.totalRemainingUpcomingPayment,
                totalCompletedRecentPayment: action.totalCompletedRecentPayment,
                isFetchingFinancesOverviewData: action.isFetchingFinancesOverviewData,
            };

        case FETCHING_INVESTEE_DASHBOARD_STATS:
            return {
                ...state,
                isFetchingInvesteeDashboardStats: action.isFetchingInvesteeDashboardStats,
            };

        case UPDATE_CAPITAL_AVAILABLE_DATA:
            return {
                ...state,
                capitalAvailableData: action.capitalAvailableData,
            };
        case UPDATE_COLLECTION_MODE:
            return {
                ...state,
                collectionMode: action.collectionMode,
            };

        // case FETCHING_INVESTEE_DEALS:
        //     return {
        //         ...state,
        //         isFetchingInvesteeDeals: action.isFetchingInvesteeDeals,
        //     };

        // case FETCH_INVESTEE_DEALS:
        //     return {
        //         ...state,
        //         investeeDeals: action.investeeDeals,
        //         primaryUserName: action.primaryUserName,
        //         isFetchingInvesteeDeals: action.isFetchingInvesteeDeals,
        //     };

        case FETCH_LIMIT_OVERVIEW_DATA:
            return {
                ...state,
                limitOverviewData: action.limitOverviewData,
                primaryUserName: action.primaryUserName,
                lastSanctionData: action.lastSanctionData,
            };

        case FETCH_RECUR_SCALE_OFFERS:
            return {
                ...state,
                recurScaleOffers: action.recurScaleOffers,
                primaryUserName: action.primaryUserName,
            };

        case FETCHING_TEAM_MEMBERS:
            return {
                ...state,
                is_fetching_members: action.is_fetching_members,
            };

        case SETTING_REPAYMENT_SCHEDULE:
            return {
                ...state,
                repayment_schedule: [],
            };

        case FETCH_REPAYMENT_SCHEDULE:
            if (action.dispatchedOnHover)
                return {
                    ...state,
                    repayment_schedule_on_hover: action.repayment_schedule,
                    isFetchingRepaymentSchedule: action.is_fetching_repayment_schedule,
                };
            else
                return {
                    ...state,
                    repayment_schedule: action.repayment_schedule,
                    isFetchingRepaymentSchedule: action.is_fetching_repayment_schedule,
                };
        case FETCHING_REPAYMENT_SCHEDULE:
            return {
                ...state,
                isFetchingRepaymentSchedule: action.is_fetching_repayment_schedule,
            };

        case FETCHING_ADD_ON_LIMIT_STATUS:
            return {
                ...state,
                isFetchingAddOnLimitStatus: action.isFetchingAddOnLimitStatus,
            };

        case FETCH_ADD_ON_LIMIT_STATUS:
            return {
                ...state,
                isFetchingAddOnLimitStatus: action.isFetchingAddOnLimitStatus,
                addOnLimitStatus: action.addOnLimitStatus,
            };

        case FETCHING_ADD_ON_DATA:
            return {
                ...state,
                isFetchingAddOnData: action.isFetchingAddOnData,
            };

        case FETCH_ADD_ON_DATA:
            return {
                ...state,
                isFetchingAddOnData: action.isFetchingAddOnData,
                addOnData: action.addOnData,
            };

        case SET_SELECTED_ORDER:
            return {
                ...state,
                userSelectedOrder: action.data,
            };
        case FETCHING_ORDER_REPAYMENT_SCHEDULE:
            return {
                ...state,
                isFetchingOrderRepaymentSchedule: action.data,
            };
        case FETCH_ORDER_REPAYMENT_SCHEDULE:
            return {
                ...state,
                repaymentOrderSchedule: action.data,
            };
        case FETCH_TEAM_MEMBERS:
            return {
                ...state,
                is_fetching_members: action.is_fetching_members,
                is_member_data_fetched_success: action.is_member_data_fetched_success,
                members: action.members,
            };

        case FETCH_TEAM_MEMBERS_SUCCESS:
            return {
                ...state,
                is_member_data_fetched_success: action.is_member_data_fetched_success,
            };

        case FETCHING_CONTRACT_LIST:
            return {
                ...state,
                is_fetching_trade_contracts: action.is_fetching_trade_contracts,
            };

        case RESET_CONTRACT_LIST:
            if (action.isFiltering) {
                return {
                    ...state,
                    trade_contracts: [],
                };
            }
            return state;

        case FETCH_CONTRACT_LIST: {
            const trade_contracts = state.trade_contracts;
            return {
                ...state,
                is_fetching_trade_contracts: action.is_fetching_trade_contracts,
                trade_contracts:
                    trade_contracts === undefined || trade_contracts.length === 0
                        ? action.trade_contracts
                        : mergeByProperty(trade_contracts, action.trade_contracts, '_id'),
                trade_contracts_total_count: action.trade_contracts_total_count,
                approved_contract_count: action.approved_contract_count,
                ...(action.priorityInvestorLimit && {
                    priorityInvestorLimit: action.priorityInvestorLimit,
                }),
                investorOrgId: action.investorOrgId,
                dealObjOrderListing: action.dealObjOrderListing,
                noMoreLoading: !action.trade_contracts || action.trade_contracts.length === 0,
                ...(action.priorityInvestorDealStatus && {
                    priorityInvestorDealStatus: action.priorityInvestorDealStatus,
                }),
            };
        }

        case UPDATE_CONTRACT_LIST: {
            return {
                ...state,
                trade_contracts: action.trade_contracts ?? state.trade_contracts,
            };
        }

        case FETCHING_TRADEBOOK_CONTRACT_LIST:
            return {
                ...state,
                is_fetching_filtered_tradebook_contracts:
                    action.is_fetching_filtered_tradebook_contracts,
            };

        case FETCH_TRADEBOOK_CONTRACT_LIST: {
            let tradebook_contracts = action.isReseting ? [] : state.tradebook_contracts;
            return {
                ...state,
                is_fetching_filtered_tradebook_contracts:
                    action.is_fetching_filtered_tradebook_contracts,
                tradebook_contracts:
                    tradebook_contracts === undefined || tradebook_contracts.length === 0
                        ? action.tradebook_contracts
                        : mergeByProperty(tradebook_contracts, action.tradebook_contracts, '_id'),
                tradebook_contracts_total_count: action.tradebook_contracts_total_count,
                tradebook_contracts_listed_count: action.tradebook_contracts_listed_count,
            };
        }
        case FETCHING_TRADEBOOK_ORDERS: {
            return {
                ...state,
                isFetchingTradeOrders: action.data.isFetchingTradeOrders,
            };
        }
        case FETCH_TRADEBOOK_ORDERS: {
            return {
                ...state,
                tradebookOrders: action.data.tradebookOrders,
                isFetchingTradeOrders: action.data.isFetchingTradeOrders,
            };
        }
        case FETCH_CUSTOMERS_TRADED: {
            return {
                ...state,
                customersTraded: action.data,
            };
        }

        case FETCHING_NOTIFICATIONS:
            return {
                ...state,
                is_fetching_notifications: action.is_fetching_notifications,
            };

        case FETCH_NOTIFICATIONS: {
            let notifications = action.isReseting ? [] : state.notifications;
            return {
                ...state,
                is_fetching_notifications: action.is_fetching_notifications,
                notifications:
                    notifications === undefined || notifications.length === 0
                        ? action.notifications
                        : mergeByProperty(notifications, action.notifications, 'id'),
                notifications_total_count: action.notifications_total_count,
                unread_notifications_count: action.unread_notifications_count,
            };
        }

        case FETCH_UNREAD_NOTIFICATION_COUNT: {
            return {
                ...state,
                is_fetching_unread_notifications_count:
                    action.is_fetching_unread_notifications_count,
                unread_notifications_count: action.unread_notifications_count,
            };
        }
        case FETCHING_UNREAD_NOTIFICATION_COUNT: {
            return {
                ...state,
                is_fetching_unread_notifications_count:
                    action.is_fetching_unread_notifications_count,
                unread_notifications_count: action.unread_notifications_count,
            };
        }

        case FETCHING_INVESTEE_TRANSACTIONS:
            return {
                ...state,
                is_fetching_investee_transactions: action.is_fetching_investee_transactions,
            };

        case FETCH_INVESTEE_TRANSACTIONS:
            if (action.transactionType === 'Payouts') {
                const payout_transactions = action.isFiltering ? [] : state.payout_transactions;
                return {
                    ...state,
                    is_fetching_investee_transactions: action.is_fetching_investee_transactions,
                    payout_transactions: isEmpty(payout_transactions)
                        ? action.investee_transactions
                        : mergeByProperty(payout_transactions, action.investee_transactions, '_id'),
                    payout_transactions_total_count: action.investee_transactions_total_count,
                };
            } else {
                const payment_transactions = action.isFiltering ? [] : state.payment_transactions;
                return {
                    ...state,
                    is_fetching_investee_transactions: action.is_fetching_investee_transactions,
                    payment_transactions: isEmpty(payment_transactions)
                        ? action.investee_transactions
                        : mergeByProperty(
                              payment_transactions,
                              action.investee_transactions,
                              'paymentDate',
                          ),
                    payment_transactions_total_count: action.investee_transactions_total_count,
                };
            }

        case FETCHING_INVESTEE_ACCOUNT_STATEMENT:
            return {
                ...state,
                is_fetching_account_statement: action.is_fetching_account_statement,
            };

        case FETCH_INVESTEE_ACCOUNT_STATEMENT:
            const investee_account_statement = action.isReseting
                ? action.investee_account_statement
                : mergeByProperty(
                      state.investee_account_statement,
                      action.investee_account_statement,
                      'txn_id',
                  );
            return {
                ...state,
                is_fetching_account_statement: action.is_fetching_account_statement,
                investee_account_statement: investee_account_statement,
                account_statement_total_records: action.account_statement_total_records,
            };

        case SENDING_RESET_PASS_MAIL:
            return {
                ...state,
                is_sending_reset_password_mail: action.is_sending_reset_password_mail,
            };

        case ADDING_NEW_MEMBER:
            return {
                ...state,
                is_inviting_new_member: action.is_inviting_new_member,
            };

        case ADD_NEW_MEMBER:
            members = state.members;
            if (action.member) {
                members.push(action.member);
            }
            return {
                ...state,
                is_inviting_new_member: action.is_inviting_new_member,
                members: members,
            };

        case UPLOADING_CONTRACTS:
            return {
                ...state,
                is_uploading_contracts: action.is_uploading_contracts,
            };

        case UPLOAD_CONTRACTS: {
            let trade_contracts = state.trade_contracts;
            if (action.uploaded_contracts) {
                action.uploaded_contracts.forEach((item) => {
                    trade_contracts.push(item);
                });
            }
            return {
                ...state,
                is_uploading_contracts: action.is_uploading_contracts,
                trade_contracts: trade_contracts,
            };
        }

        case MARKING_CONTRACT_FOR_LISTING:
            return {
                ...state,
                is_marking_contract_for_listing: action.is_marking_contract_for_listing,
            };

        case MARKED_CONTRACT_FOR_LISTING:
            return {
                ...state,
                is_marking_contract_for_listing: action.is_marking_contract_for_listing,
                listed_contracts: action.contracts,
            };

        case FETCHING_ARR_DATA:
            return {
                ...state,
                is_fetching_arr_data: action.is_fetching_arr_data,
            };

        case FETCH_ARR_DATA:
            return {
                ...state,
                is_fetching_arr_data: action.is_fetching_arr_data,
                arrData: action.arrData,
                total_available_arr: action.total_available_arr,
                available_limit: action.available_limit,
                company_price: action.company_price,
                total_contracts_count: action.total_contracts_count,
                tradable_contracts_count: action.tradable_contracts_count,
                allowed_to_trade_contracts_count: action.allowed_to_trade_contracts_count,
            };

        case FETCHING_TRADEBOOK_DATA:
            return {
                ...state,
                is_fetching_tradebook_data: action.is_fetching_tradebook_data,
            };

        case FETCH_TRADEBOOK_DATA:
            return {
                ...state,
                listed_count: action.listed_count,
                active_count: action.active_count,
                expired_count: action.expired_count,
                is_fetching_tradebook_data: action.is_fetching_tradebook_data,
            };
        case SET_TRADE_CONTRACTS:
            return {
                ...state,
                trade_contracts: action.trade_contracts,
            };

        case SET_REPAYMENT_SCHEDULE:
            return {
                ...state,
                repayment_schedule: action.repayment_schedule,
            };
        case REPAYMENT_DRAWER_TYPE: {
            return {
                ...state,
                investeeRepaymentDrawerType: action.drawerType,
                investeeRepaymentDrawerFilter: action.filters,
            };
        }
        case SET_INVESTEE_TRANSACTIONS_CANCEL_TOKEN: {
            return {
                ...state,
                investeTransactionCancelToken: action.cancelToken,
            };
        }
        case BANK_ACCOUNT_CLICKED_FROM_REVIEW_PAGE: {
            return {
                ...state,
                isAddBankAccountClicked: action.payload,
            };
        }
        case FETCHING_MOBILE_UPLOAD_STEPS: {
            return {
                ...state,
                isFetchingMobileUploadSteps: action.isFetchingMobileUploadSteps,
            };
        }
        case FETCH_MOBILE_UPLOAD_STEPS: {
            return {
                ...state,
                isFetchingMobileUploadSteps: action.isFetchingMobileUploadSteps,
                mobileUploadSteps: action.mobileUploadSteps,
            };
        }
        default:
            return state;
    }
};

export default investeeReducer;

// selectors
export const getOnboardingData = (state) => state.investee.onboardingData;
export const getTeamMembers = (state) => state.investee.members;
export const getTeamMemberLoadingState = (state) => state.investee.is_fetching_members;
export const getTeamMemberFetchSuccess = (state) => state.investee.is_member_data_fetched_success;
export const getTeamMemberInvitingState = (state) => state.investee.is_inviting_new_member;
export const getContractUploadingState = (state) => state.investee.is_uploading_contracts;
export const getDashboardStats = (state) => state.investee.dashboardStats;
export const getTradeContractsFetchingState = (state) => state.investee.is_fetching_trade_contracts;
export const getTradeContracts = (state) => state.investee.trade_contracts;
export const getTradeContractsTotalCount = (state) => state.investee.trade_contracts_total_count;
export const getApprovedContractsCount = (state) => state.investee.approved_contract_count;
export const getPriorityInvestorLimit = (state) => state.investee.priorityInvestorLimit;
export const getPriorityInvestorDealStatus = (state) => state.investee.priorityInvestorDealStatus;
export const getInvestorOrgId = (state) => state.investee.investorOrgId;
export const getTradeContractsPageSize = (state) => state.investee.trade_contracts_page_size;
export const getInvesteeTransactionsFetchingState = (state) =>
    state.investee.is_fetching_investee_transactions;
export const getPayoutTransactions = (state) => state.investee.payout_transactions;
export const getPaymentTransactions = (state) => state.investee.payment_transactions;
export const getPayoutTransactionsTotalCount = (state) =>
    state.investee.payout_transactions_total_count;
export const getPaymentTransactionsTotalCount = (state) =>
    state.investee.payment_transactions_total_count;
export const getInvesteePayouts = (state) => state.investee.investeePayouts;
export const getInvesteeMonthlyCompletedPayments = (state) =>
    state.investee.monthlyCompletedPayments;
export const getInvesteeMonthlyScheduledPayments = (state) =>
    state.investee.monthlyScheduledPayments;
export const getInvesteeMonthlyScheduledPaymentsGraph = (state) =>
    state.investee.monthlyScheduledPaymentsGraph;
export const getListingContractState = (state) => state.investee.is_marking_contract_for_listing;
export const getListedContracts = (state) => state.investee.listed_contracts;
export const getTradeBookContracts = (state) => state.investee.tradebook_contracts;
export const getTradeBookOrders = (state) => state.investee.tradebookOrders;
export const getIsFetchingTradeOrders = (state) => state.investee.isFetchingTradeOrders;
export const getFetchingFilteredTradebookContracts = (state) =>
    state.investee.is_fetching_filtered_tradebook_contracts;
export const getTradeBookContractsTotalCount = (state) =>
    state.investee.tradebook_contracts_total_count;
export const getTradeBookContractsListedCount = (state) =>
    state.investee.tradebook_contracts_listed_count;
export const getFetchingArrState = (state) => state.investee.is_fetching_arr_data;
export const getTotalAvailableArr = (state) => state.investee.total_available_arr;
export const getAvailableLimit = (state) => state.investee.available_limit;
export const getTradableContractsCount = (state) => state.investee.tradable_contracts_count;
export const getAllowedTradableCount = (state) => state.investee.allowed_to_trade_contracts_count;
export const getTotalContractsCount = (state) => state.investee.total_contracts_count;
export const getCompanyPrice = (state) => state.investee.company_price;
export const getListedContractsCount = (state) => state.investee.listed_count;
export const getActiveContractsCount = (state) => state.investee.active_count;
export const getExpiredContractsCount = (state) => state.investee.expired_count;
export const getFetchingTradebookState = (state) => state.investee.is_fetching_tradebook_data;
export const getResetPasswordMailState = (state) => state.investee.is_sending_reset_password_mail;
export const getRepaymentSchedule = (state) => state.investee.repayment_schedule;
export const getIfInvesteeIsFetchingAccountStatement = (state) =>
    state.investee.is_fetching_account_statement;
export const getInvesteeAccountStatement = (state) => state.investee.investee_account_statement;
export const getInvesteeAccountStatementTotalRecords = (state) =>
    state.investee.account_statement_total_records;
export const getRepaymentScheduleOnHover = (state) => state.investee.repayment_schedule_on_hover;
export const getIfFetchingRepaymentSchedule = (state) => state.investee.isFetchingRepaymentSchedule;
export const getIfFetchingFinboxRedirectUrl = (state) => state.investee.isFetchingFinboxRedirectUrl;
export const getFinboxRedirectUrl = (state) => state.investee.finboxRedirectUrl;
export const getFinboxError = (state) => state.investee.finboxError;
export const getIfFetchingFinancesOverviewData = (state) =>
    state.investee.isFetchingFinancesOverviewData;
export const getPayoutOverviewData = (state) => state.investee.payoutOverviewData;
export const getTotalRemainingUpcomingPayment = (state) =>
    state.investee.totalRemainingUpcomingPayment;
export const getTotalCompletedRecentPayment = (state) => state.investee.totalCompletedRecentPayment;
export const getDashboardLoader = (state) => state.investee.isFetchingInvesteeDashboardStats;
export const getDealsLoader = (state) => state.investee.isFetchingInvesteeDeals;
export const getDeals = (state) => state.investee.investeeDeals;
export const getLimitOverviewData = (state) => state.investee.limitOverviewData;
export const getRecurScaleOffersData = (state) => state.investee.recurScaleOffers;
export const getPrimaryUserName = (state) => state.investee.primaryUserName;
export const getLastSanctionData = (state) => state.investee.lastSanctionData;
export const getIfFetchingInvoices = (state) => state.investee.isFetchingInvoices;
export const getInvoices = (state) => state.investee.invoices;
export const getInvoicesTotalCount = (state) => state.investee.invoicesTotalCount;
export const getNotifications = (state) => state.investee.notifications;
export const getNotificationsTotalCount = (state) => state.investee.notifications_total_count;
export const getUnreadNotificationCount = (state) => state.investee.unread_notifications_count;
export const getIsFetchingNotifications = (state) => state.investee.is_fetching_notifications;
export const getIfFetchingKycDocs = (state) => state.investee.isFetchingKycDocs;
export const getKycDocs = (state) => state.investee.kycDocs;
export const getIfAuthSignModalIsOpen = (state) => state.investee.openAuthSignModal;
export const getIfFetchingOrderRepaymentSchedule = (state) =>
    state.investee.isFetchingOrderRepaymentSchedule;
export const getRepaymentOrderSchedule = (state) => state.investee.repaymentOrderSchedule;
export const getUserSelectedOrder = (state) => state.investee.userSelectedOrder;
export const getTradedCustomers = (state) => state.investee.customersTraded;
export const getIfNoMoreLoading = (state) => state.investee.noMoreLoading;
export const getIfFetchingTotData = (state) => state.investee.isFetchingTotData;
export const getTotDataFromStore = (state) => state.investee.totData;
export const getIfFetchingInvesteeDeals = (state) => state.investee.isFetchingDeals;
export const getInvesteeDeals = (state) => state.investee.deals;
export const getAddOnLimitStatus = (state) => state.investee.addOnLimitStatus;
export const getArrData = (state) => state.investee.arrData;
export const getAddOnData = (state) => state.investee.addOnData;
export const getIfFetchingAddOnData = (state) => state.investee.isFetchingAddOnData;
export const getDealObjOrderListing = (state) => state.investee.dealObjOrderListing;
export const getCapitalAvailableData = (state) => state.investee.capitalAvailableData;
export const getCollectionMode = (state) => state.investee.collectionMode;
export const getIfAddOnLimitModalOpen = (state) => state.investee.openAddOnLimitModal;
export const getIfLoadingForecastData = (state) => state.investee.isLoadingForecastData;
export const getForecastData = (state) => state.investee.forecastData;
export const getInvesteeRepaymentDrawerType = (state) => state.investee.investeeRepaymentDrawerType;
export const getInvesteeRepaymentDrawerFilters = (state) =>
    state.investee.investeeRepaymentDrawerFilter;
export const getIsAddBankAccountClicked = (state) => state.investee.isAddBankAccountClicked;
export const getMobileUploadSteps = (state) => state.investee.mobileUploadSteps;
