import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getInvesteeOrganizationInfoStatus } from '../../../../App/AppReducer';
import { VERIFICATION, websiteUrlRegex } from '../components/VerifyBusiness/VerifyBusiness';
import { GstInvoicingChecks } from './checks';
import { MONTHLY_VIEW_DATA_TYPES, STEP_NAMES, UPLOAD_STAGE } from './constants';

export const getUploadJourneySteps = (stage: string, data: any, companyStatus: String) => {
    switch (stage) {
        case UPLOAD_STAGE.ONBOARDING:
            return getUploadStepsOnboarding(stage, data, companyStatus);
        case UPLOAD_STAGE.ADD_ON:
            return getUploadStepsAddOn();
        case UPLOAD_STAGE.DATA_VAULT:
        default:
            return getUploadStepsDataVault();
    }
};

const getUploadStepsOnboarding = (stage: string, data: any, companyStatus: String) => {
    let allUploadSteps = [
        {
            id: STEP_NAMES.COMPANY_DIRECTORS,
            substeps: [],
            description: 'Verify company & directors details',
        },
        {
            id: STEP_NAMES.BANK_ACCOUNTS,
            substeps: [],
            description: 'Connect all your bank accounts',
        },
        {
            id: STEP_NAMES.GST_FETCH,
            substeps: [],
            description: 'Connect & verify all your GST’s',
        },
        {
            id: STEP_NAMES.AUDITED_FINANCIALS,
            substeps: [],
            description: 'Upload latest audited financials',
        },
        {
            id: STEP_NAMES.PROVISIONAL_FINANCIALS,
            substeps: [],
            description: 'Upload provisional Financials',
        },
        {
            id: STEP_NAMES.EXISTING_DEBT,
            substeps: [],
            description: 'Upload latest Debt Schedule',
        },
        {
            id: STEP_NAMES.ADDITIONAL_DATA,
            substeps: [],
            description: 'Upload any additional data',
            iconPath: '',
        },
    ];
    return allUploadSteps;
};

const getUploadStepsAddOn = () => {
    let allUploadSteps = [
        {
            id: STEP_NAMES.BANK_ACCOUNTS,
            substeps: [],
            description: 'Connect all your bank accounts',
        },
        {
            id: STEP_NAMES.GST_FETCH,
            substeps: [],
            description: 'Connect & verify all your GST’s',
        },
        {
            id: STEP_NAMES.AUDITED_FINANCIALS,
            substeps: [],
            description: 'Upload latest audited financials',
        },
        {
            id: STEP_NAMES.PROVISIONAL_FINANCIALS,
            substeps: [],
            description: 'Upload provisional Financials',
        },
        {
            id: STEP_NAMES.EXISTING_DEBT,
            substeps: [],
            description: 'Upload latest Debt Schedule',
        },
        { id: STEP_NAMES.ADDITIONAL_DATA, substeps: [], description: 'Upload any additional data' },
        // { id: STEP_NAMES.REVIEW, substeps: [], iconPath: '/assets/snb5.svg' },
    ];
    return allUploadSteps;
};

const getUploadStepsDataVault = () => {
    let allUploadSteps = [
        {
            id: STEP_NAMES.BANK_ACCOUNTS,
            substeps: [],
            description: 'Connect all your bank accounts',
        },
        {
            id: STEP_NAMES.GST_FETCH,
            substeps: [],
            description: 'Connect & verify all your GST’s',
        },
        {
            id: STEP_NAMES.PROVISIONAL_FINANCIALS,
            substeps: [],
            description: 'Upload provisional Financials',
        },
        {
            id: STEP_NAMES.EXISTING_DEBT,
            substeps: [],
            description: 'Upload latest Debt Schedule',
        },
        {
            id: STEP_NAMES.ADDITIONAL_DATA,
            substeps: [],
            description: 'Upload any additional data',
        },
        // { id: STEP_NAMES.REVIEW, substeps: [], iconPath: '/assets/snb5.svg' },
    ];
    return allUploadSteps;
};

export const initializeMonthlyViewData = (monthlyViewData: any, setMonthlyViewData: Function) => {
    const { INGESTED, NOT_UPLOADED, UPLOADED } = MONTHLY_VIEW_DATA_TYPES;
    const months = getPrevious24Months();
    let data = monthlyViewData || {};

    let bankStatementDates: StringValueMap = {};
    let availableMisMonthlyDates: StringValueMap = {};
    let availableGSTMonthlyDates: StringValueMap = {};
    let invoicingCompleteCount = 0,
        gstCompleteCount = 0,
        pnlCompleteCount = 0,
        cashflowCompleteCount = 0,
        balanceSheetCompleteCount = 0,
        isDataMissing = false;

    months.forEach((month) => {
        let gstStatus = '';
        if (!isEmpty(data.availableBankTrxMonthlyDates)) {
            let dataAvailable = true;
            for (let account of Object.keys(data.availableBankTrxMonthlyDates)) {
                if (data.availableBankTrxMonthlyDates[account][month] !== INGESTED) {
                    dataAvailable = false;
                    isDataMissing = true;
                    break;
                }
            }
            bankStatementDates[month] = dataAvailable ? INGESTED : NOT_UPLOADED;
        }

        if (
            [
                data.availablePnlMonthlyDates?.[month],
                data.availableCashflowMonthlyDates?.[month],
                data.availableBalanceSheetMonthlyDates?.[month],
            ].every((val) => val === INGESTED)
        )
            availableMisMonthlyDates[month] = INGESTED;
        else {
            isDataMissing = isDataMissing ? isDataMissing : true;
            availableMisMonthlyDates[month] = NOT_UPLOADED;
        }

        if (!isEmpty(data.availableInvoiceGstMonthlyDates)) {
            Object.keys(data.availableInvoiceGstMonthlyDates || {}).forEach((gst) => {
                if (!gstStatus) {
                    if (data.availableInvoiceGstMonthlyDates?.[gst]?.[month] === NOT_UPLOADED)
                        gstStatus = NOT_UPLOADED;
                    else if (data.availableInvoiceGstMonthlyDates?.[gst]?.[month] === UPLOADED)
                        gstStatus = NOT_UPLOADED;
                }
            });
            if (!gstStatus) gstStatus = INGESTED;
        } else gstStatus = NOT_UPLOADED;
        availableGSTMonthlyDates[month] = gstStatus;

        if (data.availableInvoiceMonthlyDates?.[month] === INGESTED) invoicingCompleteCount++;
        if (data.availableInvoiceGstMonthlyDates?.[month] === INGESTED) gstCompleteCount++;
        if (data.availablePnlMonthlyDates?.[month] === INGESTED) pnlCompleteCount++;
        if (data.availableCashflowMonthlyDates?.[month] === INGESTED) cashflowCompleteCount++;
        if (data.availableBalanceSheetMonthlyDates?.[month] === INGESTED)
            balanceSheetCompleteCount++;
    });

    if (
        invoicingCompleteCount < 24 ||
        gstCompleteCount < 24 ||
        pnlCompleteCount < 24 ||
        cashflowCompleteCount < 24 ||
        balanceSheetCompleteCount < 24
    )
        isDataMissing = isDataMissing ? isDataMissing : true;

    data.availableMisMonthlyDates = availableMisMonthlyDates;
    data.availableGSTMonthlyDates = availableGSTMonthlyDates;
    data.bankStatementDates = bankStatementDates;
    data.invoicing_complete_months = invoicingCompleteCount;
    data.gst_complete_months = gstCompleteCount;
    data.pnl_complete_months = pnlCompleteCount;
    data.cashflow_complete_months = cashflowCompleteCount;
    data.balance_sheet_complete_months = balanceSheetCompleteCount;
    data.is_data_missing = isDataMissing;
    setMonthlyViewData(data);
};

export const getMonthsByYear = (numOfMonths: number) => {
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    let endDate = dayjs();
    let endDateMonth = endDate.month() + 1;
    let endDateYear = endDate.year();
    let startDate = dayjs().subtract(numOfMonths, 'months');
    let startDateYear = startDate.year();
    let datesObj: any = {};
    for (let i = startDateYear; i <= endDateYear; i++) {
        let monthsArr;
        if (i === endDateYear)
            monthsArr = months.filter((month) => parseInt(month) <= endDateMonth);
        else monthsArr = months;
        datesObj[i] = monthsArr;
    }
    return { months, datesObj, startDate, endDate };
};

export const getIntegratedSource = (category: string, resObj: any) => {
    if (category in resObj) {
        const integrationObj: any = resObj[category];
        for (let i in integrationObj) {
            if (i !== 'by_admin' && i !== 'by_user' && integrationObj[i]) return i;
        }
        return '';
    }
    return '';
};

export function getPrevious24Months() {
    let today = new Date();
    today.setMonth(today.getMonth() - 24);
    let dates = [];
    for (let i = 0; i <= 24; i++) {
        let month = today.getMonth();
        let year = today.getFullYear();
        dates.push(`${month + 1 < 10 ? '0' : ''}${month + 1}-${year}`);
        if (month === 0) {
            today.setMonth(1);
        }
        today.setMonth(month + 1);
    }
    return dates;
}
