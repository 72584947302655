import clsx from 'clsx';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDataSpecialist, getInvesteeOrganizationInfo } from '../../../../../App/AppReducer';
import { fetchAgentsData } from '../../../../../App/AppActions';
import { VerifyBusinessChecks } from '../../utils/checks';
import { Mixpanel } from '../../../../../../util/mixpanel';
import { isEnvProd, showNotification } from '../../../../../../util/utility';

import styles from './VerifyBusiness.module.scss';
import DirectorDetails from './DirectorDetails';
import { renderTootip } from '../../../../../../components/RenderToolTip';
import { getCibilVerificationStatus, verifyGstinHelper } from '../../../../common/apiHelpers';
import { Player } from '@lottiefiles/react-lottie-player';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import {
    SHOW_NOTIFICATION_STATUS,
    GENERIC_ERROR,
    DEFAULT_DATA_SPECIALIST_ID,
} from '../../../../../../enums/appEnums';

export const websiteUrlRegex = new RegExp(
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
);

const FIELDS = {
    GSTIN: 'gstin',
};

export const VERIFICATION = {
    NOT_VERIFIED: 'Not Verified',
    UNDER_VERIFICATION: 'Under Verification',
    VERIFIED: 'Verified',
    INVALID: 'Invalid',
};

interface VerifyBusinessProps {
    investeeOnboardedId: String;
    investeeOrgId: String;
    onboardingData: any;
    isReviewPage: boolean;
    goToNextStep: Function;
    fetchOnboardingData: Function;
}

const VerifyBusiness = ({
    investeeOnboardedId,
    investeeOrgId,
    onboardingData,
    goToNextStep,
    isReviewPage,
    fetchOnboardingData,
}: VerifyBusinessProps) => {
    const {
        DirectorDetailsContainerAnimation,
        FetchDetailAnimation,
        AnimationContainerShow,
        DirectorDetailsContainer,
    } = styles;

    useEffect(() => {
        initializeFields();
        //eslint-disable-next-line
    }, [onboardingData]);

    useEffect(() => {
        getDirDetails();
    }, []);

    const dispatch = useDispatch();
    const [companyDetails, setCompanyDetails] = useState({
        [FIELDS.GSTIN]: '',
    });
    const [verifying, setVerifying] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState({
        [FIELDS.GSTIN]: '',
    });

    const [showVerifyDetailLoader, setShowVerifyDetailLoader] = useState(false);
    const [params, setParams] = useSearchParams();
    const fetchDetailPressed = useRef(false);
    const [showFetchDetailCTA, setShowFetchDetailCTA] = useState(true);
    const [fetchDetailLoader, setFetchDetailLoader] = useState(false);
    const [directorDetails, setDirectorDetails] = useState<DirectorDetails[]>([
        {
            directorName: '',
            phoneNo: '',
            consentStatus: false,
            lastInviteSentAt: '',
            isNewDir: true,
        },
    ]);
    const [isDirectorDetailsFetched, setIsDirectorDetailsFetched] = useState(false);
    const allVerified = VerifyBusinessChecks.isGstinVerified(onboardingData);

    useEffect(() => {
        setShowFetchDetailCTA(!isDirectorDetailsFetched);
    }, [isDirectorDetailsFetched]);

    const enableVerifyButton =
        companyDetails[FIELDS.GSTIN] &&
        [verificationStatus[FIELDS.GSTIN]].every(
            (val) => val !== VERIFICATION.NOT_VERIFIED && val !== VERIFICATION.INVALID,
        );

    const dataSpecialist = useSelector((state: any) => getDataSpecialist(state));
    const orgId = useSelector((state) => getInvesteeOrganizationInfo(state));
    const data_specialist_id = orgId.data_specialist_id ?? DEFAULT_DATA_SPECIALIST_ID;

    useEffect(() => {
        if (isEmpty(dataSpecialist)) {
            dispatch(fetchAgentsData(null, data_specialist_id));
        }
        //eslint-disable-next-line
    }, [data_specialist_id]);

    useEffect(() => {
        // Displays loader for 2 sec on the "Verify Detail" button.
        let timer: any;
        if (showVerifyDetailLoader) {
            timer = setTimeout(() => {
                setShowVerifyDetailLoader(false);
                setShowFetchDetailCTA(true);
            }, 2000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [showVerifyDetailLoader]);

    const getDirDetails = async () => {
        const res = await getCibilVerificationStatus({ investeeOrgId: investeeOrgId });
        if (res.responseData.responseCode === 20) {
            if (!isEmpty(res.directorDetails)) {
                setDirectorDetails(res.directorDetails);
                return true;
            } else {
                setDirectorDetails([
                    {
                        directorName: '',
                        phoneNo: '',
                        consentStatus: false,
                        lastInviteSentAt: '',
                        isNewDir: true,
                    },
                ]);
                return false;
            }
        }
        return false;
    };

    const changeCompanyDetails = (key: string, value: string) => {
        setCompanyDetails((details) => ({
            ...details,
            [key]: value,
        }));
        if (verificationStatus[key] !== VERIFICATION.VERIFIED)
            setVerificationStatus((status) => ({
                ...status,
                [key]: '',
            }));
    };

    const initializeFields = () => {
        setCompanyDetails({
            [FIELDS.GSTIN]:
                onboardingData?.gstin_number !== 'NA' && onboardingData?.gstin_number
                    ? onboardingData?.gstin_number
                    : onboardingData?.gstin_number == undefined ||
                      onboardingData?.gstin_number == null
                    ? companyDetails[FIELDS.GSTIN]
                    : onboardingData?.pan_number,
        });
        setVerificationStatus({
            [FIELDS.GSTIN]:
                (onboardingData?.gstin_number && onboardingData?.gstin_status) ??
                verificationStatus[FIELDS.GSTIN],
        });
        setIsDirectorDetailsFetched(onboardingData?.director_details_fetched ?? false);
    };

    const getFieldStatus = (status: String | Boolean, text: String) => {
        switch (status) {
            case VERIFICATION.NOT_VERIFIED: {
                return (
                    <span className={clsx(styles.Badge, styles.NotVerified)}>
                        {VERIFICATION.NOT_VERIFIED}
                    </span>
                );
            }
            case VERIFICATION.UNDER_VERIFICATION: {
                return (
                    <span className={clsx(styles.Badge, styles.UnderVerification)}>
                        Verification in Progress
                    </span>
                );
            }
            case VERIFICATION.VERIFIED:
            case true: {
                return (
                    <span className={clsx(styles.Badge, styles.Verified)}>
                        {VERIFICATION.VERIFIED}
                    </span>
                );
            }
            case VERIFICATION.INVALID: {
                if (text.trim())
                    return <span className={clsx(styles.Badge, styles.Invalid)}>Invalid URL</span>;
                else return <></>;
            }
            case false: {
                if (text.trim())
                    return (
                        <span className={clsx(styles.Badge, styles.NotVerified)}>
                            {VERIFICATION.NOT_VERIFIED}
                        </span>
                    );
                else return <></>;
            }
            default:
                return <></>;
        }
    };
    const isGstVerified = () => {
        return [VERIFICATION.VERIFIED].includes(verificationStatus[FIELDS.GSTIN]) || verifying;
    };

    const stopPolling = () => {
        params.delete('startPolling');
        setParams(params);
    };

    const submitCompanyDetails = async () => {
        setVerifying(true);
        setShowVerifyDetailLoader(true);
        params.set('startPolling', 'true');
        setParams(params);

        const body = {
            gstin: companyDetails[FIELDS.GSTIN],
            investee_onboarded_id: investeeOnboardedId,
            investee_organization_id: investeeOrgId,
        };
        try {
            const response = await verifyGstinHelper(body);
            if (response.status === 'Success') {
                const { gstin } = response.data;
                // *********** REFETCH ONBOARDING DATA HERE
                fetchOnboardingData();
                setVerificationStatus({
                    [FIELDS.GSTIN]: gstin?.status ?? '',
                });

                if (gstin?.status !== VERIFICATION.VERIFIED) {
                    stopPolling();
                    showNotification(
                        SHOW_NOTIFICATION_STATUS.ERROR,
                        gstin?.message ?? GENERIC_ERROR,
                    );
                }

                // Mixpanel event
                if (isEnvProd) {
                    Mixpanel.identify('investeeOrgId');
                    Mixpanel.people.set({
                        'gstin verification_status': gstin.status,
                    });
                    Mixpanel.track('Verify Business', {
                        business_verified: gstin.status !== VERIFICATION.NOT_VERIFIED,
                    });
                }
            } else {
                stopPolling();
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
            }
        } catch (error) {
            stopPolling();
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, GENERIC_ERROR);
        }
        setVerifying(false);
    };
    const toolTipTextForHaveMultipleGstin = (
        <div>
            <div>Please use the GSTIN for the state where you have an office.</div>
            <br />
            <span>We’ll need a recent utility bill or a rent agreement as an address proof.</span>
        </div>
    );

    const pollingFunction = (): NodeJS.Timer => {
        let pollingCount = 0;
        const interval = setInterval(async () => {
            if (pollingCount < 10 && params.get('startPolling') === 'true') {
                pollingCount++;
                const res = await getDirDetails();
                if (res) {
                    setFetchDetailLoader(false);
                    stopPolling();
                    fetchDetailPressed.current && setShowFetchDetailCTA(false);
                    clearInterval(interval);
                }
            } else {
                setFetchDetailLoader(false);
                stopPolling();
                fetchDetailPressed.current && setShowFetchDetailCTA(false);
                clearInterval(interval);
            }
        }, 1000);
        return interval;
    };

    useEffect(() => {
        // Polling to check if the director details are updated
        if (params.get('startPolling') === 'true') {
            const interval = pollingFunction();
            return () => clearInterval(interval);
        }
    }, [params.get('startPolling')]);

    return (
        <>
            <div className={`StepContainer ${isReviewPage && 'ReviewStep'}`}>
                <div className="Title">Company & Directors</div>
                <div className="Card">
                    <div className={clsx(styles.Header, { [styles.Hide]: isReviewPage })}>
                        Company Details
                        {allVerified && !showVerifyDetailLoader && (
                            <img src="/assets/Done2.svg" alt="Complete" />
                        )}
                    </div>

                    <div className={styles.FormContainer}>
                        <div className={styles.Field}>
                            <input
                                id="gst"
                                name="gst"
                                type="text"
                                value={companyDetails[FIELDS.GSTIN]}
                                onChange={(e) => changeCompanyDetails(FIELDS.GSTIN, e.target.value)}
                                className={clsx(styles.FormInput, {
                                    [styles.InputError]:
                                        verificationStatus[FIELDS.GSTIN] ===
                                        VERIFICATION.NOT_VERIFIED,
                                })}
                                disabled={
                                    [
                                        VERIFICATION.VERIFIED,
                                        VERIFICATION.UNDER_VERIFICATION,
                                    ].includes(verificationStatus[FIELDS.GSTIN]) || verifying
                                }
                                required
                            />

                            <label
                                className={clsx(styles.FormLabel, {
                                    [styles.LabelTop]: companyDetails[FIELDS.GSTIN],
                                })}
                                htmlFor="gst"
                            >
                                GSTIN
                            </label>
                            {verificationStatus[FIELDS.GSTIN] && !showVerifyDetailLoader ? (
                                getFieldStatus(
                                    verificationStatus[FIELDS.GSTIN],
                                    companyDetails[FIELDS.GSTIN],
                                )
                            ) : (
                                <div className={styles.MultipleGstins}>
                                    <span data-tip="" data-for="have-multiple-gstin">
                                        Have multiple GSTIN's?
                                    </span>

                                    {renderTootip(
                                        toolTipTextForHaveMultipleGstin,
                                        'have-multiple-gstin',
                                        'bottom',
                                        'repayment-tooltip',
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {!isGstVerified() || showVerifyDetailLoader ? (
                        showVerifyDetailLoader ? (
                            <div className={clsx(styles.VerifyButton, styles.VerifyEnabled)}>
                                <Player
                                    src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/loaderVerifyGstin.json"
                                    loop
                                    style={{
                                        width: '70px',
                                        height: '70px',
                                        backgroundColor: 'transparent',
                                        position: 'relative',
                                        marginLeft: '-5rem',
                                    }}
                                    autoplay
                                ></Player>
                                Verifying Details
                            </div>
                        ) : (
                            <div
                                className={clsx(styles.VerifyButton, {
                                    [styles.VerifyDisabled]: !enableVerifyButton,
                                    [styles.VerifyEnabled]: enableVerifyButton,
                                    [styles.VerifyComplete]: allVerified,
                                })}
                                onClick={submitCompanyDetails}
                            >
                                {allVerified ? 'Verified' : 'Verify Details'}
                            </div>
                        )
                    ) : null}
                    {!isGstVerified() && (
                        <div className={styles.CallSchedule}>
                            Need help with verification?
                            <a
                                className={styles.Link}
                                target="_blank"
                                rel="noreferrer"
                                href={dataSpecialist?.calendy_link}
                            >
                                Schedule a call
                            </a>
                            with data specialist.
                        </div>
                    )}
                </div>
            </div>

            {isGstVerified() && !showVerifyDetailLoader && (
                <div
                    className={clsx(styles.DirectorDetailsContainer, {
                        [styles.DirectorDetailsContainerAnimation]:
                            showFetchDetailCTA && !isReviewPage,
                    })}
                >
                    <DirectorDetails
                        fetchDetailLoader={fetchDetailLoader}
                        setFetchDetailLoader={setFetchDetailLoader}
                        setShowFetchDetailCTA={setShowFetchDetailCTA}
                        fetchOnboardingData={fetchOnboardingData}
                        showFetchDetailCTA={showFetchDetailCTA}
                        directorDetailsFromPolling={directorDetails}
                        fetchDetailPressed={fetchDetailPressed}
                    />
                </div>
            )}
        </>
    );
};

export default VerifyBusiness;
