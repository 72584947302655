import React from 'react';

import blackPlus from '../../assets/black-plus.svg';
import styles from './DragDrop.module.scss';

function DragDropUpload({ isBackgroudWhite = false }: any) {
    return (
        <div
            className={
                isBackgroudWhite ? `${styles.BGColor} ${styles.NewButton}` : `${styles.NewButton}`
            }
        >
            <div className={styles.Left}>
                <div className={styles.Drag}>Drag and Drop</div>
                <div className={styles.Select}>Select a file or drop one here</div>
            </div>
            <div className={styles.Right}>
                <img src={blackPlus} alt="plus" height="20px" />
            </div>
        </div>
    );
}

export default DragDropUpload;
