import {
    InvestorTransactionsPaymentsLazy, // Investor Component
    InvesteeLimitOverviewPageLazy,
    InvesteeTradebookPageLazy,
    InvoicesLazy,
    ManageProfileBankAccountLazy,
    NewKycDocsLazy,
    NewTeamMembersLazy,
    NewUserProfileLazy,
    NotificationPageLazy,
    SignedDocumentsLazy,
    InvestorTdsDepositLazy,
    AutoInvestCreatePagePECLazy,
    PayOutTransactionsLazy,
    CustomerSupportDrawerLazy,
    TdsRefundDrawer,
    TdsDepositDrawer,
    CollectionDrawerLazy,
    BalanceConfirmationDrawerLazy,
    BalanceConfirmationViewOnlyDrawerLazy,
    CustomerRequestsDrawerWrapperLazy,
    NewTicketDrawerLazy,
    StatementOfAccountDrawerLazy,
    OtherTicketDrawerLazy,
    NoDuesCertificateDrawerLazy,
    TotalRepaymentPageLazy,
} from './Lazy';

export const DRAWER_HEADINGS = {
    /**Investor Component starts */
    REPAYMENTS_DETAILS: 'Repayments Details',
    TDS_DEPOSIT_DETAILS: 'TDS',
    CREATE_PEC: 'Pre Eligibility Criteria',
    /**Investor Component end */
    ALL_DRAWDOWNS: 'All Drawdowns',
    ALL_TERMS_SHEET: 'All Term Sheets',
    TRANSACTION_DOCUMENTS: 'Transaction Documents',
    // DIGITAL_ACCOUNT_STATEMENT: 'Digital Account Statement',
    INVOICES: 'Invoices',
    TDS_REFUND: 'TDS Refund',
    YOUR_ACCOUNT: 'Your Account',
    BANK_ACCOUNTS: 'Bank Account',
    TEAM: 'Team',
    HELP_CENTER: 'Help Center',
    NOTIFICATION: 'Notification',
    KYC_DOCUMENTS: 'KYC Documents',
    REPAYMENTS: 'REPAYMENTS',
    PAYOUTS: 'Payouts',
    COLLECTIONS: 'Collections',
    CUSTOMER_SUPPORT: 'Customer Support',
    TICKET_DETAILS: 'Ticket Details',
    BALANCE_CONFIRMATION: 'Balance Confirmation',
    NEW_TICKET: 'New Ticket',
    STATEMENT_OF_ACCOUNT: 'Statement of Account',
    OTHER_TICKET: 'New  Ticket',
    NO_DUES_CERTIFICATE: 'No Dues Certificate',
    TDS_DEPOSIT_CALCULATION: 'TDS Deposit Calculations',
    TOTAL_REPAYMENTS: 'Total Repayment',
};

export const DRAWER_COMPONENT_MAPPING = {
    /**Investor Component starts */
    [DRAWER_HEADINGS.REPAYMENTS_DETAILS]: {
        component: InvestorTransactionsPaymentsLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.TDS_DEPOSIT_DETAILS]: {
        component: InvestorTdsDepositLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.CREATE_PEC]: {
        component: AutoInvestCreatePagePECLazy,
        width: '58%',
        props: {
            mode: 'create',
        },
        headingStyles: {
            color: '#6021B3',
        },
    },
    /**Investor Component end */
    [DRAWER_HEADINGS.ALL_DRAWDOWNS]: {
        component: InvesteeTradebookPageLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.ALL_TERMS_SHEET]: {
        component: InvesteeLimitOverviewPageLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.TRANSACTION_DOCUMENTS]: {
        component: SignedDocumentsLazy,
        width: '85%',
    },
    // [DRAWER_HEADINGS.DIGITAL_ACCOUNT_STATEMENT]: {
    //     component: StatementsLazy,
    //     width: '85%',
    // },
    [DRAWER_HEADINGS.INVOICES]: {
        component: InvoicesLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.YOUR_ACCOUNT]: {
        component: NewUserProfileLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.BANK_ACCOUNTS]: {
        component: ManageProfileBankAccountLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.TEAM]: {
        component: NewTeamMembersLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.NOTIFICATION]: {
        component: NotificationPageLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.KYC_DOCUMENTS]: {
        component: NewKycDocsLazy,
        width: '40%',
    },
    [DRAWER_HEADINGS.REPAYMENTS]: {
        component: PayOutTransactionsLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.PAYOUTS]: {
        component: PayOutTransactionsLazy,
        width: '85%',
    },
    [DRAWER_HEADINGS.COLLECTIONS]: {
        component: CollectionDrawerLazy,
        width: '85%',
        headingStyles: {
            color: '#4F2595',
        },
    },
    [DRAWER_HEADINGS.CUSTOMER_SUPPORT]: {
        component: CustomerSupportDrawerLazy,
        width: '37%',
    },
    [DRAWER_HEADINGS.TDS_REFUND]: {
        component: TdsRefundDrawer,
        width: '37%',
    },
    [DRAWER_HEADINGS.TDS_DEPOSIT_CALCULATION]: {
        component: TdsDepositDrawer,
        width: '37%',
    },
    [DRAWER_HEADINGS.BALANCE_CONFIRMATION]: {
        component: BalanceConfirmationDrawerLazy,
        width: '37%',
    },
    [DRAWER_HEADINGS.TICKET_DETAILS]: {
        component: CustomerRequestsDrawerWrapperLazy,
        width: '37%',
    },
    [DRAWER_HEADINGS.NEW_TICKET]: {
        component: NewTicketDrawerLazy,
        width: '37%',
    },
    [DRAWER_HEADINGS.STATEMENT_OF_ACCOUNT]: {
        component: StatementOfAccountDrawerLazy,
        width: '37%',
    },
    [DRAWER_HEADINGS.NO_DUES_CERTIFICATE]: {
        component: NoDuesCertificateDrawerLazy,
        width: '37%',
    },
    [DRAWER_HEADINGS.OTHER_TICKET]: {
        component: OtherTicketDrawerLazy,
        width: '37%',
    },
    [DRAWER_HEADINGS.TOTAL_REPAYMENTS]: {
        component: TotalRepaymentPageLazy,
        width: '85%',
    },
};
