import {
    BANK_NAME,
    ACCOUNT_HOLDER_NAME,
    ACCOUNT_NUMBER,
    IFSC,
    IFSC_CODE,
} from '../AddBankAccounts';
import styles from '../AddBankAccounts.module.scss';

function CompletedBankForm({ bankDetails }: any) {
    return (
        <div style={{ marginTop: '35px' }}>
            <div className="row">
                <div className={`${styles.FormDiv} col-md-6`}>
                    <div className={styles.SelectDiv}>{bankDetails[BANK_NAME]}</div>
                    <label
                        className={styles.FormLabel}
                        htmlFor={BANK_NAME}
                        style={{ marginLeft: '6%' }}
                    >
                        Bank name
                    </label>
                </div>
                <div className="col-md-6">
                    <div className={styles.FormDiv}>
                        <div className={styles.SelectDiv}>{bankDetails[ACCOUNT_HOLDER_NAME]}</div>
                        <label className={styles.FormLabel} htmlFor={ACCOUNT_HOLDER_NAME}>
                            Account holder name
                        </label>
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginBottom: '-10px' }}>
                <div className="col-md-6">
                    <div className={styles.FormDiv}>
                        <div className={styles.SelectDiv}>{bankDetails[ACCOUNT_NUMBER]}</div>
                        <label className={styles.FormLabel} htmlFor={ACCOUNT_NUMBER}>
                            Account number
                        </label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={styles.FormDiv}>
                        <div className={styles.SelectDiv}>
                            {bankDetails[IFSC_CODE] || bankDetails[IFSC]}
                        </div>
                        <label className={styles.FormLabel} htmlFor={IFSC_CODE}>
                            IFSC code
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompletedBankForm;
