// ** Theme Config Imports
import themeConfig from '../themeConfig';

// ** Util Import
import { hexToRGBA } from '../utils/hex-to-rgba';

const Button = (theme) => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: 8,
                    lineHeight: 1.715,
                    padding: `${theme.spacing(1.75, 3)}`,
                    textTransform: 'none',
                    '&.MuiButton-textPrimary:hover': {
                        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
                    },
                    '&.MuiButton-textSecondary:hover': {
                        backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.08),
                    },
                    '&.MuiButton-textSuccess:hover': {
                        backgroundColor: hexToRGBA(theme.palette.success.main, 0.08),
                    },
                    '&.MuiButton-textError:hover': {
                        backgroundColor: hexToRGBA(theme.palette.error.main, 0.08),
                    },
                    '&.MuiButton-textWarning:hover': {
                        backgroundColor: hexToRGBA(theme.palette.warning.main, 0.08),
                    },
                    '&.MuiButton-textInfo:hover': {
                        backgroundColor: hexToRGBA(theme.palette.info.main, 0.08),
                    },
                },
                contained: {
                    boxShadow: theme.shadows[3],
                    padding: `${theme.spacing(1.75, 5.5)}`,
                    '&:hover': {
                        // Add this selector for hover state
                        backgroundColor: theme.palette.primary.dark, // Set the background color to red on hover
                    },
                },
                outlined: {
                    lineHeight: 1.572,
                    padding: `${theme.spacing(1.75, 5.25)}`,
                    '&.MuiButton-outlinedPrimary:hover': {
                        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
                    },
                    '&.MuiButton-outlinedSecondary:hover': {
                        backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.08),
                    },
                    '&.MuiButton-outlinedSuccess:hover': {
                        backgroundColor: hexToRGBA(theme.palette.success.main, 0.08),
                    },
                    '&.MuiButton-outlinedError:hover': {
                        backgroundColor: hexToRGBA(theme.palette.error.main, 0.08),
                    },
                    '&.MuiButton-outlinedWarning:hover': {
                        backgroundColor: hexToRGBA(theme.palette.warning.main, 0.08),
                    },
                    '&.MuiButton-outlinedInfo:hover': {
                        backgroundColor: hexToRGBA(theme.palette.info.main, 0.08),
                    },
                },
                sizeSmall: {
                    lineHeight: 1.693,
                    padding: `${theme.spacing(1, 2.25)}`,
                    '&.MuiButton-contained': {
                        padding: `${theme.spacing(1, 3.25)}`,
                    },
                    '&.MuiButton-outlined': {
                        lineHeight: 1.539,
                        padding: `${theme.spacing(1, 3)}`,
                    },
                },
                sizeLarge: {
                    lineHeight: 1.734,
                    padding: `${theme.spacing(2, 5.5)}`,
                    '&.MuiButton-contained': {
                        padding: `${theme.spacing(2, 6.5)}`,
                    },
                    '&.MuiButton-outlined': {
                        lineHeight: 1.6,
                        padding: `${theme.spacing(2, 6.25)}`,
                    },
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: themeConfig.disableRipple,
            },
        },
    };
};

export default Button;
