import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { basePathInvestee, isThereAuthComponents } from '../util/utility';
import { getWidgetData, getInvesteeSidebarConfigTab } from '../modules/App/AppReducer';
import { useSelector } from 'react-redux';

const NotFoundComponent = ({ isInvestor }: { isInvestor: boolean }) => {
    const navigate = useNavigate();
    const sidebarConfig = useSelector(getInvesteeSidebarConfigTab);
    const widget = useSelector(getWidgetData);
    useEffect(() => {
        if (!isThereAuthComponents()) navigate(isInvestor ? '/investor/login' : '/');
        // else navigate(isInvestor ? '/investor/dashboard' :  '/company/dashboard');
        else if (isInvestor) {
            navigate('/investor/dashboard');
        } else {
            if (sidebarConfig?.length && widget) {
                let basePath = basePathInvestee(sidebarConfig, widget);
                navigate(basePath, { replace: true });
            }
        }
        // eslint-disable-next-line
    }, [sidebarConfig, widget, isInvestor]);
    return null;
};

export default NotFoundComponent;
