import { WidgetProps } from '../../common/models/SidebarInvestee';

export const CATEGORIES = {
    DATA: 'data',
    KYC: 'kyc',
};

export const DATA_LABELS = {
    UPLOAD_DATA: 'Upload Data',
    ACCEPT_TERMS: 'Accept Terms',
    SIGN_SANCTION_DOCS: 'Sign Sanction Docs',
    SETUP_COLLECTIONS: 'Setup Collections',
    APPLICATION_APPROVED: 'Application Approved',
    NO_INVESTOR_BIDS: 'No Investor Bids',
};
export const KYC_LABELS = {
    UPLOAD_KYC: 'Upload KYC',
    VIEW_APPROVED_LIMIT: 'View Approved Limit',
    RE_APPLY_IN_SOMETIME: 'Re apply in 3 months',
    RE_APPLY: 'Re apply',
};

export const blockWidgetStep = (widget: WidgetProps, category: string) => {
    const { applicationStatus, isKycLocked, kycStatus, dataStatus } = widget ?? {
        applicationStatus: 'Open',
        isKycLocked: true,
        kycStatus: KYC_LABELS.UPLOAD_KYC,
        dataStatus: DATA_LABELS.UPLOAD_DATA,
    };
    const inProcess = applicationStatus === 'Submitted';
    const isApplicationApproved = applicationStatus === 'Approved';
    const isApplicationExpired = applicationStatus === 'Expired';
    if (category === CATEGORIES.DATA)
        return (
            inProcess ||
            (isApplicationApproved &&
                dataStatus !== DATA_LABELS.ACCEPT_TERMS &&
                dataStatus !== DATA_LABELS.SIGN_SANCTION_DOCS &&
                dataStatus !== DATA_LABELS.SETUP_COLLECTIONS)
        );
    if (category === CATEGORIES.KYC)
        return (
            isKycLocked ||
            kycStatus === KYC_LABELS.RE_APPLY_IN_SOMETIME ||
            kycStatus === KYC_LABELS.RE_APPLY ||
            kycStatus === KYC_LABELS.VIEW_APPROVED_LIMIT
        );
};
