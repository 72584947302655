/**
 * Main store function
 */
import rootReducer from '../reducers';
import {
    persistReducer,
    persistStore,
    /*  FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER, */
} from 'redux-persist';
import localStorage from 'redux-persist/lib/storage'; //use session storage
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { configureStore as toolKitConfigure } from '@reduxjs/toolkit';

export const persistConfig = {
    key: 'recur_app',
    whitelist: [''], // These reducers will persist on refresh.
    storage: localStorage,
    //version: 1, take this from cache buster
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const isNotProd = process.env.NODE_ENV !== 'production';

export const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

export const configureStore = (preloadedState = {}) => {
    const store = toolKitConfigure({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                // Ignore these action types
                /*  serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                }, */
                serializableCheck: false,
                immutableCheck: false, //@TODO change this after moving whole redux to toolkit
            }).concat(routerMiddleware),
        preloadedState,
    });
    const persistor = persistStore(store);

    // For hot reloading reducers
    if (isNotProd && module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextReducer = require('../reducers').default; // eslint-disable-line global-require
            store.replaceReducer(nextReducer);
        });
    }
    return { store, persistor };
};
