export const STEP_NAMES = {
    VERIFY_BUSINESS: 'Verify Business',
    COMPANY_DIRECTORS: 'Company & Directors',
    BANK_ACCOUNTS: 'Bank Accounts',
    GST_INVOICING: 'GST and Invoicing',
    GST_FETCH: 'GST Fetch',
    INVOICING: 'Invoicing',
    FINANCIALS: 'Financials',
    AUDITED_FINANCIALS: 'Audited Financials',
    PROVISIONAL_FINANCIALS: 'Provisional Financials',
    DEBT_SCHEDULE: 'Do You Have Any Existing Loans/Debts?',
    MIS: 'MIS',
    FINANCIAL_STATEMENTS: 'Financial Statements',
    ADDITIONAL_DATA: 'Additional Data',
    REVIEW: 'Review Progress',
    EXISTING_DEBT: 'Existing Debt',
};

export const REVIEW_PAGE_CARD_CATEGORIES = {
    VERIFY_BUSINESS: 'Verify Business',
    BANK_ACCOUNTS: 'Bank Accounts',
    GST_FETCH: 'GST Fetch',
    FINANCIALS: 'Financials',
    GST_AND_INVOICING: 'GST and Invoicing',
};

export const UPLOAD_STAGE = {
    ONBOARDING: 'Onboarding',
    DATA_VAULT: 'Data Vault',
    ADD_ON: 'Add On Limit',
};

export const MIS_TYPES = {
    PNL: 'PNL',
    CASHFLOW: 'CASHFLOW',
    BALANCE_SHEET: 'BALANCE_SHEET',
};

export const MONTHLY_VIEW_DATA_TYPES = {
    NOT_UPLOADED: 'Not Uploaded',
    UPLOADED: 'Uploaded',
    INGESTED: 'Ingested',
};

export const monthsEnum: { [x: string]: { name: string; fullName: string } } = {
    '01': {
        name: 'Jan',
        fullName: 'January',
    },
    '02': {
        name: 'Feb',
        fullName: 'February',
    },
    '03': {
        name: 'Mar',
        fullName: 'March',
    },
    '04': {
        name: 'Apr',
        fullName: 'April',
    },
    '05': {
        name: 'May',
        fullName: 'May',
    },
    '06': {
        name: 'Jun',
        fullName: 'June',
    },
    '07': {
        name: 'Jul',
        fullName: 'July',
    },
    '08': {
        name: 'Aug',
        fullName: 'August',
    },
    '09': {
        name: 'Sep',
        fullName: 'September',
    },
    '10': {
        name: 'Oct',
        fullName: 'October',
    },
    '11': {
        name: 'Nov',
        fullName: 'November',
    },
    '12': {
        name: 'Dec',
        fullName: 'December',
    },
};

export const APPLICATION_TYPE = {
    ONBOARDING: 'onboarding',
    ADD_ON_LIMIT: 'add_on_limit',
};

export const ADD_ON_LIMIT_CONST = 'ADD_ON_LIMIT';
export const RI_DEAL_CONST = 'RI Deal';

// gstin constants
export const GSTIN_TYPE = 'gstin';

export const RENDER_LINKS = [
    {
        title: 'How to make TDS Deposit?',
        link: 'https://help.recur.club/en/article/tds-deposit',
    },
    {
        title: 'How to export data from tally?',
        link: 'https://help.recur.club/en/article/exporting-data-from-tally',
    },
    {
        title: 'How to do Razorpay Onboarding?',
        link: 'https://help.recur.club/en/article/razorpay',
    },
    {
        title: 'Learn about KYC Documents',
        link: 'https://help.recur.club/en/article/kyc-documents',
    },
];
