import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router';
import PageLoader from '../../../components/loader/PageLoader';
import SidebarInvestee from '../../../components/Sidebar/SidebarInvestee';
import {
    INVESTEE_ORG_INACTIVE,
    USER_TYPE_INVESTEE,
    USER_TYPE_INVESTOR,
} from '../../../enums/appEnums';
import storage from '../../../util/storageService';
import {
    closeHubspotLiveChat,
    getNotAllowedRoutes,
    isThereAuthComponents,
    setupHubspotLiveChatV2,
} from '../../../util/utility';
import {
    fetchHubspotToken,
    fetchInvesteeSidebarConfigTab,
    fetchInvesteeWidgetData,
    fetchUserProfile,
    logOutUser,
} from '../../App/AppActions';
import {
    getApprovedState,
    getAuthenticatedState,
    getEmailVerifiedState,
    getHubspotToken,
    getIfUserIsLoggingOut,
    getInvesteeOrganizationCompanyApprovalStatus,
    getInvesteeOrganizationInfo,
    getInvesteeOrganizationInfoCheckOut,
    getInvesteeOrganizationInfoId,
    getInvesteeOrganizationInfoStatus,
    getInvesteeSidebarConfigTab,
    getPhoneNumber,
    getPhoneVerifiedState,
    getShowMobileNavHeader,
    getUserDetails,
    getWidgetData,
} from '../../App/AppReducer';
import DemoAccountLine from './DemoAccount/DemoAccountLine';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import NavbarHeader from './NavbarHeader/NavbarHeader';
import { fetchAddOnLimitStatus } from '../InvesteeActions';
import MobileNavbarHeader from './NavbarHeader/MobileNavbarHeader';
import { useWindowSize } from 'react-use';
import { ROUTES_MAP, SIDEBAR_LABELS } from '../../../components/Sidebar/consts/consts';

/**
 *
 * @Description
 * 1. It will check if user is logged in or not.
 * 2. if user is not logged in it will redirect user to login page.
 * 3. if user is logged in then it should fetchUserProfile and then redirect user to corrent screen.
 * 4.
 */
const InvesteePrivateRoutes = ({ pathToNavigate }: { pathToNavigate: string }) => {
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(getAuthenticatedState);
    const showMobileNavHeader = useSelector(getShowMobileNavHeader);
    const isUserApproved = useSelector(getApprovedState);
    const status = useSelector(getInvesteeOrganizationInfoStatus);
    const sidebarConfig = useSelector(getInvesteeSidebarConfigTab);
    const widget = useSelector(getWidgetData);
    const investeeOnboardingId = useSelector(getInvesteeOrganizationInfoId);
    const isEmailVerified = useSelector(getEmailVerifiedState);
    const phoneVerified =
        useSelector((state) => getPhoneVerifiedState(state)) ?? storage.get('phoneVerified');
    const isPhoneNumber = isEmpty(useSelector((state) => getPhoneNumber(state)));
    const recurCheckoutAllowed = useSelector(getInvesteeOrganizationInfoCheckOut);
    const isLoggingOut = useSelector(getIfUserIsLoggingOut);
    const [isFetchingUser, setIsFetchingUser] = useState(false);
    const userDetails = useSelector(getUserDetails);
    const investeeOrganizationId = useSelector((state) => getInvesteeOrganizationInfo(state)._id);
    const hubspotToken = useSelector(getHubspotToken);
    const isCompanyApproved = useSelector(getInvesteeOrganizationCompanyApprovalStatus);
    const [listOfDisabledRoutes, setLisOfDisabledRoutes] = useState<string[]>([]);

    useEffect(() => {
        if (!isNil(phoneVerified)) {
            storage.set('phoneVerified', phoneVerified);
        }
    });

    useEffect(() => {
        if (
            isEmailVerified &&
            isAuthenticated &&
            isUserApproved &&
            phoneVerified &&
            isPhoneNumber
        ) {
            setupHubspotLiveChatV2(
                userDetails,
                hubspotToken,
                (firstName: string, lastName: string) => {
                    dispatch(fetchHubspotToken(userDetails.contact_email, firstName, lastName));
                },
            );
            return () => {
                closeHubspotLiveChat();
            };
        }
        // eslint-disable-next-line
    }, [
        isEmailVerified,
        isAuthenticated,
        isUserApproved,
        phoneVerified,
        userDetails,
        isPhoneNumber,
        hubspotToken,
    ]);

    /**
     * @description main idea behind is to check if user is allowed on particular route or not
     * only specific to company
     */
    const handleRedirection = () => {
        const notAllowedRoutes = [
            ...getNotAllowedRoutes(sidebarConfig, widget),
            ...listOfDisabledRoutes,
        ];
        if (
            width <= 1025 &&
            !(
                location.pathname === ROUTES_MAP?.[SIDEBAR_LABELS.RECUR_PRODUCTS] ||
                location.pathname === ROUTES_MAP?.[SIDEBAR_LABELS.ONBOARDING] ||
                location.pathname === ROUTES_MAP?.[SIDEBAR_LABELS.LIMIT_DOCUMENTS]
            )
        ) {
            navigate(ROUTES_MAP?.[SIDEBAR_LABELS.RECUR_PRODUCTS]);
            return;
        }
        if (
            !isFetchingUser &&
            !isNil(isUserApproved) &&
            isEmailVerified &&
            phoneVerified &&
            notAllowedRoutes.includes(location.pathname)
        ) {
            navigate(pathToNavigate);
        }
    };

    useEffect(() => {
        if (investeeOnboardingId) setIsFetchingUser(false);
    }, [investeeOnboardingId]);

    /**
     * @Description This is main utility which will run auth token change logging out the user if not valid
     */

    const onAuthChange = () => {
        const isInvestor = storage.get(USER_TYPE_INVESTOR); //@TODO check this
        // if we have the token assume user is logged in info api automatically logout user via interceptor
        if (!isThereAuthComponents()) {
            navigate(isInvestor ? '/investor/login' : '/', {
                state: {
                    pathname: location?.pathname,
                    search: location?.search,
                },
            });
        }
    };

    useEffect(() => {
        let userId = storage.get('user_id');
        let isInvestorLoggedIn = storage.get(USER_TYPE_INVESTOR);
        const isInvestee = storage.get('investee') === 'INVESTEE';
        const orgId = storage.get('org_id');

        //loggout user in case of previous user was of investor;
        if (isInvestorLoggedIn) {
            dispatch(logOutUser(navigate, USER_TYPE_INVESTEE));
            return;
        }
        if (userId && !isFetchingUser) {
            if (isNil(status)) setIsFetchingUser(true);
            if (isInvestee) {
                dispatch(fetchInvesteeSidebarConfigTab(userId));
                setTimeout(() => dispatch(fetchInvesteeWidgetData(userId)), 800); // to fetch widget data coz kyc coverage might have updated
            }
            dispatch(fetchUserProfile(userId, false, false, phoneVerified));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const isInvestee = storage.get('investee')?.trim() === 'INVESTEE';
        if (isInvestee && investeeOrganizationId) {
            dispatch(fetchAddOnLimitStatus(investeeOrganizationId));
        }
    }, [investeeOrganizationId]);

    useEffect(() => {
        if (!isNil(isAuthenticated)) onAuthChange();
        // eslint-disable-next-line
    }, [isAuthenticated]);

    useEffect(() => {
        if (!isFetchingUser && sidebarConfig?.length && widget) handleRedirection();
        // eslint-disable-next-line
    }, [
        isUserApproved,
        location,
        sidebarConfig,
        widget,
        isFetchingUser,
        phoneVerified,
        isEmailVerified,
        width,
    ]);

    if (isFetchingUser) return <PageLoader />; //save undefined errors by waiting until user info is fetched;
    if (!isFetchingUser)
        return (
            <>
                {isAuthenticated && (
                    <SidebarInvestee
                        isAuthenticated={isAuthenticated} // remove these props from here causing a remount of private routes
                        userSegment={USER_TYPE_INVESTEE}
                        isUserApproved={isUserApproved}
                        isLoggingOut={isLoggingOut}
                        investeeOnboardingId={investeeOnboardingId}
                        selectedSegment={USER_TYPE_INVESTEE}
                        recurCheckoutAllowed={recurCheckoutAllowed}
                        navigate={navigate}
                        setLisOfDisabledRoutes={setLisOfDisabledRoutes}
                    />
                )}
                {showMobileNavHeader ? <MobileNavbarHeader /> : null}
                <div className="d-flex">
                    <div className="logged-in-wrapper">
                        {isCompanyApproved && status === INVESTEE_ORG_INACTIVE && (
                            <DemoAccountLine />
                        )}
                        <NavbarHeader headingText={sidebarConfig?.heading} />
                        <Outlet />
                    </div>
                </div>
                {isAuthenticated && <CustomDrawer />}
            </>
        );
};

export default InvesteePrivateRoutes;
