import clsx from 'clsx';
import React from 'react';
import redInfoIcon from '../../../../../../../assets/red-info.svg';
import styles from '../UploadCard.module.scss';
import { CATEGORIES } from '../utils/uploadDocUtils';
import Spinner from './Spinner';
import { getS3IconLink } from '../../../../../../../util/utility';
import { ASSET_LINKS, ICON_NAMES } from '../../../../../../../constants/assetLinks';

function SingleFile({
    file,
    downloadFile,
    isInvestor,
    deleteFile,
    disabled,
    isDownloading,
    isDeletingFile,
    category,
    isTally,
    truncateSmallerText,
    renderInAModal = false,
    showDocInModal,
    renderModal,
    customStyleForFileName = {},
    hideDeleteIcon = false,
}: any) {
    const {
        rejected_rows,
        accepted_rows,
        is_file_empty,
        missing_col,
        // file_url,
        total_rows,
        upload_error,
    } = file?.errors || {};
    const fileInvalid = !!(is_file_empty || accepted_rows === 0 || upload_error);

    const getFileErrors = () => {
        if (fileInvalid)
            return ` Imported but not processed automatically. ${
                file?.errors?.is_file_empty
                    ? '(File is Empty)'
                    : file?.errors?.missing_col?.length
                    ? ` (headers missing: ${file?.errors?.missing_col?.join(',')})`
                    : ''
            }`;
        else return ``;
    };

    return (
        <>
            <div
                className={clsx({
                    [styles.UploadedPDF]: true,
                    [styles.UploadedPDFError]: fileInvalid,
                })}
            >
                <div className="display-f">
                    <span title={`${file.doc_name}${getFileErrors()}`}>
                        {fileInvalid && <img src={redInfoIcon} alt="red" height="12px" />}
                        <div
                            className={clsx(styles.downloadable, styles.textTruncate, {
                                [styles.textTruncate2]: truncateSmallerText,
                                [styles.underline]: fileInvalid,
                                [styles.shortname]: fileInvalid && missing_col?.length,
                            })}
                            style={customStyleForFileName}
                            onClick={() =>
                                renderInAModal
                                    ? showDocInModal(file.doc_link, file.doc_name)
                                    : downloadFile(file.doc_link, file.doc_name)
                            }
                        >
                            {file.doc_name}
                        </div>
                        {getFileErrors()}
                        {renderInAModal && renderModal()}
                    </span>
                    {isDownloading && isDownloading === file.doc_link && <Spinner />}
                </div>

                <span className="cursor-pointer display-f">
                    {isDeletingFile && isDeletingFile === file.doc_link ? (
                        <div id="loading-spinner" style={{ marginLeft: '5px' }}>
                            <div className="spin-icon" style={{ width: '12px', height: '12px' }} />
                        </div>
                    ) : (
                        <>
                            {fileInvalid ? (
                                <span className={styles.Link}>
                                    {category === CATEGORIES.INVOICING ? (
                                        <a
                                            href="https://help.recur.club/en/article/how-to-upload-data"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Learn More
                                        </a>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                            ) : (
                                <>
                                    {category === CATEGORIES.MIS ||
                                    category === CATEGORIES.DEBT_SCHEDULE ||
                                    category === CATEGORIES.FINANCIAL ? (
                                        <div className={styles.Imported}>
                                            {file?.totalRows && file?.acceptedRows ? (
                                                <span>
                                                    {file?.acceptedRows}/{file?.totalRows} rows
                                                    imported
                                                </span>
                                            ) : (
                                                <span>Imported Successfully</span>
                                            )}
                                        </div>
                                    ) : (
                                        <div className={styles.Imported}>
                                            {total_rows > 0 && (
                                                <span>{`${accepted_rows}/${total_rows} Imported`}</span>
                                            )}

                                            {!hideDeleteIcon ? (
                                                isInvestor ? (
                                                    !disabled && (
                                                        <img
                                                            src={getS3IconLink(
                                                                ICON_NAMES.PURPLE_CROSS,
                                                            )}
                                                            onClick={() =>
                                                                deleteFile(
                                                                    file.doc_link,
                                                                    file._id,
                                                                    file.doc_name,
                                                                )
                                                            }
                                                            alt="clear-doc"
                                                        />
                                                    )
                                                ) : (
                                                    <img
                                                        onClick={() =>
                                                            deleteFile(
                                                                file.doc_link,
                                                                file._id,
                                                                file.doc_name,
                                                            )
                                                        }
                                                        src="/assets/clear-blue.svg"
                                                        alt="clear-doc"
                                                    />
                                                )
                                            ) : null}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </span>
            </div>
            {!fileInvalid && rejected_rows > 0 && (
                <div className={styles.ErrorBlock}>
                    <img src={redInfoIcon} alt="red" height="12px" />
                    {category === CATEGORIES.INVOICING ? (
                        <>{rejected_rows} rows were not processed due to some error. </>
                    ) : (
                        <>There are some issues in the file.</>
                    )}

                    <span
                        className={styles.Link}
                        onClick={() =>
                            downloadFile(
                                file?.errors?.file_url,
                                `Errors.${file?.errors?.file_url?.split('.')?.[1] ?? 'xlsx'}`,
                                true,
                            )
                        }
                    >
                        {category === CATEGORIES.INVOICING
                            ? ' View rows with error'
                            : ' View data with issues'}
                    </span>
                </div>
            )}
        </>
    );
}

export default SingleFile;
