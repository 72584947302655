import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { getMonthsByYear } from '../../utils/uploadJourneyUtils';
import { MONTHLY_VIEW_DATA_TYPES, monthsEnum } from '../../utils/constants';

import notAvailable from '../../../../../../assets/UploadDataJourney/not-available.svg';
import greenTick from '../../../../../../assets/UploadDataJourney/available.svg';
import greyDash from '../../../../../../assets/UploadDataJourney/grey-dash.svg';
import expandIcon from '../../../../../../assets/UploadDataJourney/expand.svg';
import collapseIcon from '../../../../../../assets/UploadDataJourney/collapse.svg';
import red from '../../../../../../assets/UploadDataJourney/red-i-full.svg';
import green from '../../../../../../assets/UploadDataJourney/green-tick.svg';
import styles from './SmallMonthlyView.module.scss';
import dayjs from 'dayjs';

interface SmallMonthlyViewProps {
    parentHovered?: boolean;
    category?: string;
    isReview?: boolean | string;
    collapsibleMonthlyViewData: any;
    expanded?: boolean;
    hover?: boolean;
    totalmonths?: number;
    smallMonthlyViewLowPad?: boolean;
    handleClick?: any;
}

function SmallMonthlyView({
    parentHovered,
    collapsibleMonthlyViewData = [],
    category = '',
    isReview = false,
    expanded = false,
    hover = false,
    totalmonths = 24,
    smallMonthlyViewLowPad = false,
    handleClick,
}: SmallMonthlyViewProps) {
    const { months, datesObj, startDate } = getMonthsByYear(totalmonths);
    const [hovered, setHovered] = useState(hover);
    const [expand, setExpand] = useState(expanded);
    const dataLength = collapsibleMonthlyViewData?.length;
    const mouseEnter = () => {
        setHovered(true);
    };

    const mouseLeave = () => {
        setHovered(false);
    };

    useEffect(() => {
        setExpand(expanded);
    }, [expanded]);

    const getAvailabilityStatusIcon = (month: string, data: any) => {
        const current = dayjs(`01-${month}`, 'DD-MM-YYYY');
        const start = dayjs(startDate, 'DD-MM-YYYY');

        if (start.isAfter(current)) return greyDash;
        if (data?.monthlyViewDates?.[month]) {
            if (data?.monthlyViewDates?.[month] === MONTHLY_VIEW_DATA_TYPES.INGESTED)
                return greenTick;
            else return notAvailable;
        }
        return notAvailable;
    };

    return (
        <div
            className={clsx({
                [styles.SmallMonthlyView]: true,
                [styles.SmallMonthlyViewLowPad]: smallMonthlyViewLowPad,
                [styles.Hover]: hovered || parentHovered,
                [styles.Expand]: expand,
            })}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onClick={() => {
                setExpand(!expand);
                handleClick();
            }}
            style={{
                maxHeight: category === 'mis' && expand ? '620px' : '',
                ...(isReview && { padding: '17px 10px' }),
            }}
        >
            {collapsibleMonthlyViewData.map((data: any, index: number) => (
                <div key={index} style={{ padding: '0px 9px 0px 0px ' }}>
                    <div className={styles.Top} style={{ margin: index !== 0 ? '10px 0' : '' }}>
                        <div className={styles.TopLeft}>
                            {expand ? (
                                <div className={styles.SingleSummary}>
                                    <img
                                        src={
                                            data?.availableMonthsCount === totalmonths ? green : red
                                        }
                                        alt="chc"
                                        height="11px"
                                    />
                                    <span className={styles.Bold}></span> {data?.label}:&nbsp;
                                    <span className={styles.Bold}>
                                        {data?.availableMonthsCount}/{`${totalmonths} months`}
                                    </span>
                                </div>
                            ) : (
                                <div className={styles.Row}>
                                    <div
                                        className={styles.SingleSummaryContainer}
                                        style={{ marginLeft: isReview ? '20px' : '' }}
                                    >
                                        {collapsibleMonthlyViewData.map((singleData: any) => (
                                            <div
                                                className={styles.SingleSummary}
                                                key={singleData.label}
                                                style={{ marginRight: isReview ? '30px' : '' }}
                                            >
                                                <img
                                                    src={
                                                        singleData?.availableMonthsCount ===
                                                        totalmonths
                                                            ? green
                                                            : red
                                                    }
                                                    alt="chc"
                                                    height="11px"
                                                />
                                                <span className={styles.Bold}></span>{' '}
                                                {singleData?.label}:&nbsp;
                                                <span className={styles.Bold}>
                                                    {singleData?.availableMonthsCount}/
                                                    {`${totalmonths} months`}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        {expand ? (
                            <div className={styles.TopRight}>
                                <img src={greenTick} alt="green" height="5px" />
                                <div>Uploaded</div>
                                <img src={notAvailable} alt="not" height="5px" />
                                <div>Not Uploaded</div>
                            </div>
                        ) : null}
                    </div>

                    {index === 0 ? (
                        <div className={styles.ViewStatus}>
                            <div className={styles.Button}>
                                View Detailed Status
                                <img src={expandIcon} alt="exp" height="20px" />
                            </div>
                        </div>
                    ) : null}

                    <div className={clsx(styles.TableContainer, { [styles.FadeIn]: expand })}>
                        <table
                            className={clsx(styles.Table, { [styles.TableReviewScreen]: isReview })}
                        >
                            <thead>
                                <tr>
                                    <th className={styles.FirstColumn}> </th>
                                    {months.map((month: any, i: number) => (
                                        <th className={styles.MonthName} key={`${month}${i}`}>
                                            {monthsEnum[month]?.name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {Object.keys(datesObj).map((year, i) => (
                                    <tr key={i}>
                                        <td className={styles.Year}>{year}</td>
                                        {datesObj[year].map((month: any, ind: any) => (
                                            <td key={ind} className={styles.Item}>
                                                <div className={styles.Img}>
                                                    <img
                                                        src={getAvailabilityStatusIcon(
                                                            `${month}-${year}`,
                                                            data,
                                                        )}
                                                        alt="avl"
                                                        height="8px"
                                                    />
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {index === dataLength - 1 ? (
                        <div className={clsx(styles.HideStatus, { [styles.FadeIn]: expand })}>
                            <div className={styles.Button}>
                                Hide Upload Status
                                <img src={collapseIcon} alt="col" height="20px" />
                            </div>
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
}

export default SmallMonthlyView;
