import React from 'react';
import styles from './NavbarHeader.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { companyInviteModal, logOutUser, triggerDrawer } from '../../../App/AppActions';
import { USER_TYPE_INVESTEE } from '../../../../enums/appEnums';
import { getEmail, getInvesteeSidebarConfigTab } from '../../../App/AppReducer';
import { mixPanelTrack } from '../../../../util/utility';
import { Sidebar } from '../../common/models/SidebarInvestee';
import NewInviteTeamMemberModal from '../../pages/ManageProfile/components/NewTeamMember/NewInviteTeamMemberModal';
import { DRAWER_HEADINGS } from '../../../../DrawerConsts';
function UserDropdownTooltipContent({
    setShowUserDropdown,
    investeeOrgName,
    isRecurUser,
    roleObjectMap,
    invitableRoles,
}: {
    setShowUserDropdown: (key: boolean) => void;
    investeeOrgName: string;
    isRecurUser: () => boolean;
    roleObjectMap: any;
    invitableRoles: any;
}) {
    const { UserDropdownListitem } = styles;
    const navigate = useNavigate();
    const sidebarConfig = useSelector(getInvesteeSidebarConfigTab);
    const emailId = useSelector(getEmail);
    const dispatch = useDispatch();
    const handleClick = (link: string) => {
        if (link === '') {
            const authInstance = window?.gapi?.auth2?.getAuthInstance();
            if (!authInstance) {
                dispatch(logOutUser(navigate, USER_TYPE_INVESTEE));
                return;
            }
            authInstance
                .signOut()
                .then(() => {
                    dispatch(logOutUser(navigate, USER_TYPE_INVESTEE));
                })
                .catch(() => {
                    dispatch(logOutUser(navigate, USER_TYPE_INVESTEE));
                });
        } else {
            // @ts-ignore
            mixPanelTrack({
                trackUserObj: {
                    clicked_by: emailId,
                    clicked_on: link,
                },
                trackCategoryName: 'User Dropdown',
            });
            setShowUserDropdown(false);
            if (link === DRAWER_HEADINGS.HELP_CENTER) navigate('/company/customer-support');
            else dispatch(triggerDrawer(link));
        }
    };
    return (
        <div>
            {isRecurUser() && (
                <div
                    style={{ background: '#b7b7b7', cursor: 'no-drop' }}
                    className={UserDropdownListitem}
                >
                    {investeeOrgName}
                </div>
            )}
            {sidebarConfig
                ?.find((item: Sidebar) => item.label === 'Header')
                .child.map((item: any, index: number) => {
                    if (item.label === 'Notifications') return;
                    return (
                        <>
                            {item.display && (
                                <div
                                    onClick={() => {
                                        if (item.label === 'Invite Teammate') {
                                            dispatch(companyInviteModal(true));
                                        } else handleClick(item.label);
                                    }}
                                    className={UserDropdownListitem}
                                    key={index}
                                >
                                    {item.label === 'Help Center' ? 'Customer Support' : item.label}
                                </div>
                            )}
                        </>
                    );
                })}
            <div onClick={() => handleClick('')} className={UserDropdownListitem}>
                Logout
            </div>
            <NewInviteTeamMemberModal
                roleObjectMap={roleObjectMap}
                invitableRoles={invitableRoles}
                isTransparent={false}
            />
        </div>
    );
}

export default UserDropdownTooltipContent;
