export const BANK_NAME = 'bank_name';
export const ACCOUNT_HOLDER_NAME = 'account_holder_name';
export const ACCOUNT_NUMBER = 'account_number';
export const IFSC = 'ifsc';

export const FILLING = 'filling';
export const COMPLETED = 'completed';
export const CONNECTED = 'connected';
export const UPLOADING = 'uploading';
export const EDITING = 'editing';
export const ADDED = 'added';
export const EXIT_FINBOX = 'exit-finbox';
export const ZEUS_USER_EMAIL = 'zeus.company@recur.club';
export const STATEMENT_BUTTONS = {
    M1: '1m',
    M3: '3m',
    M6: '6m',
    Y1: '1y',
};

export const INVESTOR = 'Investor';

export const SAMPLE_FILES = {
    MIS: 'https://sample-file-templates.s3.ap-south-1.amazonaws.com/sample_mis.xlsx',
};

export const DEAL_EVALUATION_TAGS = {
    GOOD: 'good',
    LOW: 'low',
    YES: 'yes',
    POOR: 'poor',
    NO: 'no',
    HIGH: 'high',
};
