import React from 'react';
import styles from './styles/RecurTabs.module.scss';

export default function RecurTabs({
    tabs,
    currentTab,
    setCurrentTab,
    isInvestor,
    style,
    liStyle = {},
    aStyle = {},
}) {
    const {
        TabContainer,
        InvesteeTabLinksActive,
        InvesteeTabLinks,
        InvestorTabLinksActive,
        InvestorTabLinks,
    } = styles;
    const handleClick = (label) => {
        setCurrentTab(label);
    };

    const tabsToMap = tabs?.map((item, index) => {
        let obj = {};
        obj.id = index + 1;
        obj.label = item;
        return obj;
    });

    const getClass = (label) => {
        if (isInvestor) {
            if (currentTab === label) return InvestorTabLinksActive;
            return InvestorTabLinks;
        }
        if (currentTab === label) {
            return InvesteeTabLinksActive;
        }
        return InvesteeTabLinks;
    };

    return (
        <div className={TabContainer} style={style ?? {}}>
            <ul role="tablist">
                {tabsToMap?.map((item, index) => (
                    <li key={index} style={liStyle ?? {}}>
                        <a
                            data-toggle="tab"
                            href={'menu' + item.id}
                            onClick={() => handleClick(item.label)}
                            className={getClass(item.label)}
                            style={aStyle ?? {}}
                        >
                            <span>{item.label}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}
