import _ from 'lodash';
import banks from '../../constants/banks.json';
import accountHolders from '../../constants/accountHolders.json';
import { setUserInErrorLogger, unsetUserInErrorLogger } from '../../util/errorLogger';
import {
    ATTEMPT_TO_LOGIN,
    OPEN_COMPANY_HELP_MODAL,
    OPEN_COMPANY_ADD_BANK_MODAL,
    OPEN_COMPANY_INVITE_MODAL,
    OPEN_INVESTOR_HELP_MODAL,
    OPEN_INVESTOR_INVITE_MODAL,
    ATTEMPT_TO_LOGOUT,
    CHANGE_INVESTEE_DATA,
    CHANGE_USER_DATA,
    FETCH_PLATFORM_FAQS,
    FETCH_USER_PROFILE,
    FETCHING_USER_INVITABLE_ROLES,
    FETCH_USER_INVITABLE_ROLES,
    FETCHING_PLATFORM_FAQS,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    UPDATE_INVESTEE_INFO,
    UPDATED_INVESTEE_ORGANIZATION_INFO,
    UPDATING_INVESTEE_INFO,
    UPDATING_INVESTEE_ORGANIZATION_INFO,
    FETCH_INVESTOR_PROFILE,
    UPDATING_INVESTOR_INFO,
    UPDATE_INVESTOR_INFO,
    FETCHING_USER_PROFILE,
    FETCH_BANK_LIST,
    FETCHING_BANK_LIST,
    FETCH_USER_ESCROW_ACCOUNT_DETAILS,
    FETCHING_USER_ESCROW_ACCOUNT_DETAILS,
    CREATING_USER_BANK_ACCOUNT,
    USER_ADDING_BANK,
    USER_ADDING_BANK_FAILED,
    FETCH_INVESTOR_ESCROW_ACCOUNT_INFO,
    FETCHING_INVESTEE_ESCROW_ACCOUNT_INFO,
    FETCH_INVESTEE_ESCROW_ACCOUNT_INFO,
    FETCHING_INVESTOR_ESCROW_ACCOUNT_INFO,
    OPEN_COMPANY_DEPOSIT_MODAL,
    OPEN_COMPANY_WITHDRAW_MODAL,
    SENDING_DEPOSIT_URL,
    SENDING_DEPOSIT_URL_RECUR_CHECKOUT,
    FETCHING_KARZA_INVOICES,
    WITHDRAWING_TO_BANK,
    WITHDRAW_TO_BANK,
    SHOW_MENU_ON_RIGHT_CLICK,
    SET_WITHDRAW_MODAL_STATE,
    OPEN_COMPANY_INTEGRATION_MODAL,
    OPEN_COMPANY_UPDATE_DATA_MODAL,
    FETCH_AGENTS,
    OPEN_COMPANY_TALLY_MODAL,
    OPEN_PLEASE_CONFIRM_MODAL,
    OPEN_ACCOUNT_STATUS_LOCKED_MODAL,
    DATA_VAULT_PENDING_STATUS,
    UPDATE_INVESTEE_ORGANIZATION_INFO,
    FETCH_OPEN_WITHDRAWALS,
    FETCHING_INVESTOR_DRAWDOWN_REQUESTS,
    FETCH_INVESTOR_DRAWDOWN_REQUESTS,
    FETCHING_HUBSPOT_TOKEN,
    FETCH_HUBSPOT_TOKEN,
    UPDATE_IS_APP_LOADING,
    UPDATE_PROGRESS_BAR,
    FETCH_DEALS_FOR_APPROVAL,
    FETCH_COUNTER_SIGN_USER,
    SET_INSIGHTS_UNLOCKED,
    UPDATE_INVESTEE_SIDEBAR_CONFIG_TAB,
    SET_IS_TALLY_CONNECTOR,
    FETCHING_COLLECTION_METHODS,
    FETCH_COLLECTION_METHODS,
    SET_COMPANY_NAME,
    OPEN_DRAWER,
    UPDATE_LATEST_ADD_ON_PATH,
    UPDATE_INVESTEE_WIDGET_DATA,
    UPDATE_APPLICATION_STRENGTH,
    TOGGLE_ONBOARDING_REAPPLICATION_MODAL,
    SHOW_MOBILE_NAVHEADER,
    FETCH_UPCOMING_PAYMENT_DETAILS_SUCCESS,
    FETCH_UPCOMING_PAYMENT_DETAILS_FAILURE,
    FETCH_CAPITAL_AMOUNT_SUCCESS,
    FETCH_CAPITAL_AMOUNT_FAILURE,
} from './AppActions';
import { PRODUCTION } from '../../enums/appEnums';
import { INVESTOR_TYPE } from '../../constants/consts';

const initialState = {
    companyHelpModalOpen: false,
    companyAddBankModalOpen: false,
    companyInviteModalOpen: false,
    companyUpdateDataModalOpen: '',
    companyIntegrationModalOpen: '',
    investorHelpModalOpen: false,
    investorInviteModalOpen: false,
    isTokenFound: true,
    isVerifyingToken: false,
    isAuthenticated: false,
    isEmailVerified: null,
    isUserApproved: null,
    isLoggingIn: false,
    investee: {},
    investeeOrganization: {},
    user: {},
    roles: [],
    permissions: null,
    isInvestee: false,
    is_updating_investee: false,
    isInvestor: false,
    isAdmin: false,
    investor: {},
    is_updating_investee_org: false,
    investorOrganization: {},
    is_fetching_faqs: false,
    platform_faqs: [],
    banks: banks,
    accountHolders: accountHolders,
    isFetchingBankList: false,
    bankList: [],
    is_fetching_user_escrow_account_details: false,
    escrowAccountDetails: [],
    is_user_adding_bank: false,
    user_adding_account_failed: false,
    investeeWithdrawableBalance: 0,
    isFetchingInvestorEscrowAccountInfo: false,
    investorEscrowAccountInfo: {
        user_id: 'NA',
        name: 'NA',
        balance: 'NA',
        escrow_account: 'NA',
        bank_name: 'NA',
        ifsc_code: 'NA',
    },
    investeeEscrowAccountInfo: {
        user_id: 'NA',
        name: 'NA',
        balance: 'NA',
        escrow_account: 'NA',
        bank_name: 'NA',
        ifsc_code: 'NA',
    },
    isFetchingInvesteeEscrowAccountInfo: false,
    is_sending_deposit_url: false,
    is_sending_deposit_url_recur_checkout: false,
    is_fetching_karza_invoices: false,
    isWithdrawingToBankState: false,
    transactionDetails: {},
    idOnRightClick: false,
    withdraw_modal_state: 'Proceed',
    // isFetchingCapitalPartners: false,
    // capitalPartners: [],
    salesPartner: [],
    dataSpecialist: [],
    isFetchingAgentsData: false,
    companyTallyModalOpen: false,
    salesPartners: [],
    pleaseConfirmModal: false,
    orgStatusLockedModal: false,
    dataVaultPendingStatus: false,
    withdraws: [],
    isLoggingOut: false,
    is_invalid_gst_credential: false,
    isFetchingHubspotToken: false,
    hubspotToken: '',
    roleObjMapRedux: {},
    invitableRolesRedux: [],
    isFetchingRoles: false,
    isAppLoading: false,
    tally_connector: false,
    dealsForApproval: [],
    counterSignUserByAdmin: {},
    isFetchingRcmDetails: false,
    rcmDetails: {},
    drawerHeading: '',
    drawerData: {},
    latestAddonPath: '',
    applicationStrengthObj: {},
    showProgressBar: false,
    showOnboardingReapplicationModal: false,
    showMobileNavHeader: true,
    upCommingPaymentDetails: null,
    capitalAmount: null,
};

const appReducer = (state = initialState, action) => {
    let platform_faqs = [];
    switch (action.type) {
        case UPDATE_LATEST_ADD_ON_PATH:
            return {
                ...state,
                latestAddonPath: action.latestAddonPath,
            };
        case OPEN_DRAWER:
            if (!action.drawerHeading)
                return {
                    ...state,
                    drawerHeading: '',
                    drawerData: '',
                };
            return {
                ...state,
                drawerHeading: action.drawerHeading,
                drawerData: action.drawerData,
            };
        case SHOW_MENU_ON_RIGHT_CLICK:
            return {
                ...state,
                idOnRightClick: action.idOnRightClick,
            };

        case OPEN_COMPANY_WITHDRAW_MODAL:
            return {
                ...state,
                companyWithdrawModalOpen: action.companyWithdrawModalOpen,
            };

        case SET_WITHDRAW_MODAL_STATE:
            return {
                ...state,
                withdraw_modal_state: action.modal_state,
            };

        case WITHDRAW_TO_BANK:
            return {
                ...state,
                transactionDetails: action.transactionDetails,
            };

        case OPEN_COMPANY_DEPOSIT_MODAL:
            return {
                ...state,
                companyDepositModalOpen: action.companyDepositModalOpen,
            };

        case UPDATE_IS_APP_LOADING:
            return {
                ...state,
                isAppLoading: action.isAppLoading,
            };
        case UPDATE_PROGRESS_BAR:
            return {
                ...state,
                showProgressBar: action.showProgressBar,
            };
        case WITHDRAWING_TO_BANK:
            return {
                ...state,
                isWithdrawingToBankState: action.isWithdrawingToBankState,
            };

        case SENDING_DEPOSIT_URL:
            return {
                ...state,
                is_sending_deposit_url: action.is_sending_deposit_url,
            };
        case SENDING_DEPOSIT_URL_RECUR_CHECKOUT:
            return {
                ...state,
                is_sending_deposit_url_recur_checkout: action.is_sending_deposit_url_recur_checkout,
            };

        case FETCHING_COLLECTION_METHODS:
            return {
                ...state,
                isFetchingRcmDetails: action.isFetchingRcmDetails,
            };

        case FETCH_COLLECTION_METHODS:
            return {
                ...state,
                rcmDetails: action.rcmDetails,
                isFetchingRcmDetails: action.isFetchingRcmDetails,
            };

        case FETCHING_HUBSPOT_TOKEN:
            return {
                ...state,
                isFetchingHubspotToken: action.isFetchingHubspotToken,
            };

        case FETCH_HUBSPOT_TOKEN:
            return {
                ...state,
                isFetchingHubspotToken: action.isFetchingHubspotToken,
                hubspotToken: action.hubspotToken,
            };

        case FETCHING_KARZA_INVOICES:
            return {
                ...state,
                is_fetching_karza_invoices: action.is_fetching_karza_invoices,
                is_invalid_gst_credential: action.is_invalid_gst_credential,
            };

        case FETCHING_INVESTEE_ESCROW_ACCOUNT_INFO:
            return {
                ...state,
                isFetchingInvesteeEscrowAccountInfo: action.isFetchingInvesteeEscrowAccountInfo,
            };

        case FETCH_INVESTEE_ESCROW_ACCOUNT_INFO:
            return {
                ...state,
                isFetchingInvesteeEscrowAccountInfo: action.isFetchingInvesteeEscrowAccountInfo,
                investeeEscrowAccountInfo: action.investeeEscrowAccountInfo,
                investeeWithdrawableBalance: action.investeeWithdrawableBalance,
            };

        case FETCHING_INVESTOR_ESCROW_ACCOUNT_INFO:
            return {
                ...state,
                isFetchingInvestorEscrowAccountInfo: action.isFetchingInvestorEscrowAccountInfo,
            };

        case FETCH_INVESTOR_ESCROW_ACCOUNT_INFO:
            return {
                ...state,
                isFetchingInvestorEscrowAccountInfo: action.isFetchingInvestorEscrowAccountInfo,
                investorEscrowAccountInfo: action.investorEscrowAccountInfo,
            };

        case OPEN_COMPANY_HELP_MODAL:
            return {
                ...state,
                companyHelpModalOpen: action.companyHelpModalOpen,
            };

        case OPEN_COMPANY_ADD_BANK_MODAL:
            return {
                ...state,
                companyAddBankModalOpen: action.companyAddBankModalOpen,
            };

        case OPEN_COMPANY_INVITE_MODAL:
            return {
                ...state,
                companyInviteModalOpen: action.companyInviteModalOpen,
            };

        case OPEN_COMPANY_UPDATE_DATA_MODAL:
            return {
                ...state,
                companyUpdateDataModalOpen: action.companyUpdateDataModalOpen,
            };

        case OPEN_COMPANY_INTEGRATION_MODAL:
            return {
                ...state,
                companyIntegrationModalOpen: action.companyIntegrationModalOpen,
            };

        case OPEN_COMPANY_TALLY_MODAL:
            return {
                ...state,
                companyTallyModalOpen: action.companyTallyModalOpen,
            };

        case OPEN_ACCOUNT_STATUS_LOCKED_MODAL:
            return {
                ...state,
                orgStatusLockedModal: action.orgStatusLockedModal,
            };

        case OPEN_PLEASE_CONFIRM_MODAL:
            return {
                ...state,
                pleaseConfirmModal: action.pleaseConfirmModal,
            };

        case OPEN_INVESTOR_HELP_MODAL:
            return {
                ...state,
                investorHelpModalOpen: action.investorHelpModalOpen,
            };

        case OPEN_INVESTOR_INVITE_MODAL:
            return {
                ...state,
                investorInviteModalOpen: action.investorInviteModalOpen,
            };

        case ATTEMPT_TO_LOGIN:
            return {
                ...state,
                isLoggingIn: action.isLoggingIn,
            };

        case UPDATE_INVESTEE_SIDEBAR_CONFIG_TAB:
            return {
                ...state,
                investeeSidebarConfigTab: action.investeeSidebarConfigTab,
            };

        case UPDATE_INVESTEE_WIDGET_DATA:
            return {
                ...state,
                widgetData: action.widgetData,
            };

        case UPDATE_APPLICATION_STRENGTH:
            return {
                ...state,
                applicationStrengthObj: action.applicationStrengthObj,
            };

        case LOGIN_SUCCESS:
            if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
                setUserInErrorLogger(action.user.contact_email);
            }
            return {
                ...state,
                isLoggingIn: action.isLoggingIn,
                isEmailVerified: action.isEmailVerified,
                phoneVerified: action.phoneVerified,
                isAuthenticated: action.isAuthenticated,
                isUserApproved: action.isUserApproved,
                user: action.user,
            };

        case LOGIN_FAILED:
            return {
                ...state,
                isLoggingIn: action.isLoggingIn,
                isAuthenticated: action.isAuthenticated,
                isEmailVerified: action.isEmailVerified,
                user: action.user,
            };

        case FETCH_USER_PROFILE:
            return {
                ...state,
                investee: action.investee,
                investor: action.investor,
                isInvestee: !_.isEmpty(action.investee),
                isInvestor: !_.isEmpty(action.investor),
                admin: action.admin,
                roles: action.roles,
                permissions: action.permissions,
                investeeOrganization: action.investeeOrganization,
                investeeProfileMetrics: action.investeeProfileMetrics,
                investeeContractStats: action.investeeContractStats,
                investorOrganization: action.investorOrganization,
                is_fetching_user: action.is_fetching_user,
            };

        case FETCHING_USER_INVITABLE_ROLES:
            return {
                ...state,
                isFetchingRoles: action.isFetchingRoles,
            };
        case FETCH_USER_INVITABLE_ROLES:
            return {
                ...state,
                isFetchingRoles: action.isFetchingRoles,
                invitableRolesRedux: action.invitableRolesRedux,
                roleObjMapRedux: action.roleObjMapRedux,
            };

        case FETCH_INVESTOR_PROFILE:
            return {
                ...state,
                investor: action.investor,
            };

        case FETCHING_USER_PROFILE:
            return {
                ...state,
                is_fetching_user: action.is_fetching_user,
            };

        case CHANGE_USER_DATA:
            return {
                ...state,
                user: action.user,
            };

        case CHANGE_INVESTEE_DATA:
            let investee = state.investee;
            investee[action.keyName] = action.keyValue;
            return {
                ...state,
                investee: investee,
            };

        case UPDATING_INVESTEE_INFO:
            return {
                ...state,
                is_updating_investee: action.is_updating_investee,
            };

        case UPDATE_INVESTEE_INFO:
            return {
                ...state,
                is_updating_investee: action.is_updating_investee,
                investee: action.investee,
                user: action.user,
            };

        case UPDATING_INVESTOR_INFO:
            return {
                ...state,
                is_updating_investor: action.is_updating_investor,
            };

        case UPDATE_INVESTOR_INFO:
            return {
                ...state,
                is_updating_investor: action.is_updating_investor,
                investor: action.investor,
            };

        case UPDATING_INVESTEE_ORGANIZATION_INFO:
            return {
                ...state,
                is_updating_investee_org: action.is_updating_investee_org,
            };

        case UPDATED_INVESTEE_ORGANIZATION_INFO:
            return {
                ...state,
                is_updating_investee_org: action.is_updating_investee_org,
                investeeOrganization: action.investeeOrganization,
            };

        case ATTEMPT_TO_LOGOUT:
            if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
                unsetUserInErrorLogger();
            }
            return {
                ...state,
                isVerifyingToken: action.isVerifyingToken,
                isAuthenticated: action.isAuthenticated,
                user: action.user,
                token: action.token,
                isLoggingOut: action.isLoggingOut,
            };

        case FETCHING_PLATFORM_FAQS:
            return {
                ...state,
                is_fetching_faqs: action.is_fetching_faqs,
            };

        case FETCH_PLATFORM_FAQS:
            platform_faqs = [];
            let faq = {};
            if (action.platform_faqs && action.platform_faqs.length > 0) {
                action.platform_faqs.forEach((item) => {
                    if (faq[item.topic] && faq[item.topic].faqs) {
                        faq[item.topic].faqs.push({
                            question_text: item.question,
                            answer_text: item.answer,
                        });
                    } else {
                        faq[item.topic] = {
                            topic: item.topic,
                        };
                        faq[item.topic].faqs = [];
                        faq[item.topic].faqs.push({
                            question_text: item.question,
                            answer_text: item.answer,
                        });
                    }
                });
                for (let key in faq) {
                    platform_faqs.push(faq[key]);
                }
            }
            return {
                ...state,
                is_fetching_faqs: action.is_fetching_faqs,
                platform_faqs: platform_faqs,
            };

        case FETCH_BANK_LIST:
            return {
                ...state,
                isFetchingBankList: action.isFetchingBankList,
                bankList: action.bankList,
            };

        case FETCHING_BANK_LIST:
            return {
                ...state,
                isFetchingBankList: action.isFetchingBankList,
            };

        case FETCH_USER_ESCROW_ACCOUNT_DETAILS:
            return {
                ...state,
                is_fetching_user_escrow_account_details:
                    action.is_fetching_user_escrow_account_details,
                escrowAccountDetails: action.escrowAccountDetails,
            };

        case FETCHING_USER_ESCROW_ACCOUNT_DETAILS:
            return {
                ...state,
                is_fetching_user_escrow_account_details:
                    action.is_fetching_user_escrow_account_details,
            };

        // case FETCHING_CAPITAL_PARTNER:
        //     return {
        //       ...state,
        //       isFetchingCapitalPartners: action.isFetchingCapitalPartners
        //     };

        // case FETCH_CAPITAL_PARTNER:
        //     return {
        //       ...state,
        //       capitalPartners: action.capitalPartners,
        //       isFetchingCapitalPartners: action.isFetchingCapitalPartners
        //     };

        case FETCH_AGENTS:
            return {
                ...state,
                salesPartner: action.salesPartner,
                dataSpecialist: action.dataSpecialist,
                isFetchingAgentsData: action.isFetchingAgentsData,
            };

        case CREATING_USER_BANK_ACCOUNT:
            return {
                ...state,
                is_user_adding_bank: action.is_user_adding_bank,
            };

        case USER_ADDING_BANK:
            return {
                ...state,
                is_user_adding_bank: action.is_user_adding_bank,
            };

        case USER_ADDING_BANK_FAILED:
            return {
                ...state,
                is_user_adding_bank: action.is_user_adding_bank,
                user_adding_account_failed: action.user_adding_account_failed,
            };

        // case FETCH_SALES_PARTNER:
        //     return {
        //         ...state,
        //         salesPartners: action.salesPartners,
        //     };

        case DATA_VAULT_PENDING_STATUS:
            return {
                ...state,
                dataVaultPendingStatus: action.dataVaultPendingStatus,
            };

        case UPDATE_INVESTEE_ORGANIZATION_INFO:
            return {
                ...state,
                investeeOrganization: action.investeeOrganization,
            };

        case FETCH_OPEN_WITHDRAWALS:
            return {
                ...state,
                withdraws: action.withdraws,
            };

        case FETCHING_INVESTOR_DRAWDOWN_REQUESTS:
            return {
                ...state,
                isFetchingDrawdownRequests: action.isFetchingDrawdownRequests,
            };

        case FETCH_INVESTOR_DRAWDOWN_REQUESTS:
            return {
                ...state,
                investorDrawdowns: action.investorDrawdowns,
            };

        case FETCH_DEALS_FOR_APPROVAL:
            return {
                ...state,
                dealsForApproval: action.dealsForApproval,
            };

        case FETCH_COUNTER_SIGN_USER:
            return {
                ...state,
                counterSignUserByAdmin: action.counterSignUserByAdmin,
            };
        case SET_INSIGHTS_UNLOCKED:
            return {
                ...state,
                investeeOrganization: {
                    ...state.investeeOrganization,
                    is_insights_unlocked: action.isInsightsUnlocked,
                },
            };
        case SET_IS_TALLY_CONNECTOR:
            return {
                ...state,
                investeeOrganization: {
                    ...state.investeeOrganization,
                    tally_connector: action.isTallyConnector,
                },
            };
        case SET_COMPANY_NAME:
            return {
                ...state,
                companyName: action.companyName,
            };
        case TOGGLE_ONBOARDING_REAPPLICATION_MODAL:
            return {
                ...state,
                showOnboardingReapplicationModal: action.showOnboardingReapplicationModal,
            };
        case SHOW_MOBILE_NAVHEADER:
            return {
                ...state,
                showMobileNavHeader: action.showMobileNavHeader,
            };
        case FETCH_UPCOMING_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                upCommingPaymentDetails: action.payload,
            };
        case FETCH_UPCOMING_PAYMENT_DETAILS_FAILURE:
            return {
                ...state,
                upCommingPaymentDetails: null,
            };
        case FETCH_CAPITAL_AMOUNT_SUCCESS:
            return {
                ...state,
                capitalAmount: action.payload,
            };
        case FETCH_CAPITAL_AMOUNT_FAILURE:
            return {
                ...state,
                capitalAmount: null,
            };
        default:
            return state;
    }
};

export default appReducer;

export const getIfUserIsLoggingOut = (state) => state.app.isLoggingOut;
export const getCompanyHelpModalState = (state) => state.app.companyHelpModalOpen;
export const getCompanyAddBankModalState = (state) => state.app.companyAddBankModalOpen;
export const getCompanyInviteModalState = (state) => state.app.companyInviteModalOpen;
export const getCompanyUpdateDataModalState = (state) => state.app.companyUpdateDataModalOpen;
export const getCompanyIntegrationModalState = (state) => state.app.companyIntegrationModalOpen;
export const getCompanyTallyModalOpenState = (state) => state.app.companyTallyModalOpen;
export const getPleaseConfirmModalState = (state) => state.app.pleaseConfirmModal;
export const getOrgStatusLockedModalState = (state) => state.app.orgStatusLockedModal;
export const getInvestorHelpModalState = (state) => state.app.investorHelpModalOpen;
export const getInvestorInviteModalState = (state) => state.app.investorInviteModalOpen;
export const getTokenFoundState = (state) => state.app.isTokenFound;
export const getTokenVerifyingState = (state) => state.app.isVerifyingToken;
export const getUserProfileFetchingState = (state) => state.app.is_fetching_user;
export const getAuthenticatedState = (state) => state.app.isAuthenticated;
export const getApprovedState = (state) => state.app.isUserApproved;
export const getEmailVerifiedState = (state) => state.app.isEmailVerified;
export const getLoggingState = (state) => state.app.isLoggingIn;
export const getPhoneVerifiedState = (state) => state.app.phoneVerified;
export const getUserDetails = (state) => state.app.user;
export const getUserPermissions = (state) => state.app.permissions;
export const getUserId = (state) => (state.app.user ? state.app.user?._id : '');
export const getUserInvesteeId = (state) => (state.app.user ? state.app.user?.investee_id : '');
export const getUserInvestorId = (state) => (state.app.user ? state.app.user?.investor_id : '');
export const getUserInvesteeOrganizationId = (state) =>
    state.app.user ? state.app.user?.investee_organization_id : '';
export const getUserInvestorOrganizationId = (state) =>
    state.app.user ? state.app.user?.investor_organization_id : '';
export const getUserApprovalStatus = (state) => state.app.user?.is_user_approved;
export const getIfUserIsInvestee = (state) => state.app.isInvestee;
export const getIfUserIsInvestor = (state) => (state.app.user ? state.app.user?.is_investor : '');
export const getIfUserIsAdmin = (state) => state.app.isAdmin;
export const getInvesteeUpdatingState = (state) => state.app.is_updating_investee;
export const getInvestorUpdatingState = (state) => state.app.is_updating_investor;
export const getInvesteeOrganizationUpdatingState = (state) => state.app.is_updating_investee_org;
export const getInvesteeInfo = (state) => state.app.investee;
export const getInvestorInfo = (state) => state.app.investor;
export const getInvesteeOrganizationInfo = (state) => state.app.investeeOrganization;
export const getLastTradingDate = (state) =>
    state.app.investeeOrganization && state.app.investeeOrganization.last_trading_date
        ? state.app.investeeOrganization.last_trading_date
        : null;
export const getInvesteeOrganizationInfoCheckOut = (state) =>
    state.app.investeeOrganization?.recur_checkout_allowed;
export const getInvesteeOrganizationInfoId = (state) =>
    state.app.investeeOrganization?.investee_onboarded_id;
export const getInvesteeTotId = (state) => state.app.investeeOrganization?.current_sent_tot_id;
export const getInvesteeOrganizationInfoStatus = (state) => state.app.investeeOrganization?.status;
export const getInvesteeOrganizationCompanyApprovalStatus = (state) =>
    state.app.investeeOrganization?.is_company_approved;
export const getInvesteeOrganizationCompanyActiveStatus = (state) =>
    state.app.investeeOrganization?.is_company_active;
export const getInvesteeOrganizationTotalActiveContractCount = (state) =>
    state.app.investeeOrganization?.total_active_contract_count;
export const getInvesteeOrganizationNextPaymentDate = (state) =>
    state.app.investeeOrganization?.next_payment_date;
export const getInvesteeOrganizationIsInsightsEnabled = (state) =>
    state.app.investeeOrganization?.is_insights_unlocked;
export const getInvesteeOrganizationIsTallyConnector = (state) =>
    state.app.investeeOrganization?.tally_connector;
export const getInvesteeProfileMetricsInfo = (state) => state.app.investeeProfileMetrics;
export const getInvesteeContractStatsInfo = (state) => state.app.investeeContractStats;
export const getInvestorOrganizationInfo = (state) => state.app.investorOrganization;
export const getPlatformLoadingState = (state) => state.app.is_fetching_faqs;
export const getPlatformFAQs = (state) => state.app.platform_faqs;
export const getUserInvesteePaymentDelay = (state) =>
    state.app.investeeOrganization && state.app.investeeOrganization.global_delay
        ? state.app.investeeOrganization.global_delay
        : 0;
export const getUserName = (state) => {
    if (state.app.user.is_investee && state.app.investee) {
        return state.app.investee.contact_name
            ? state.app.investee.contact_name
            : state.app.user.contact_name;
    } else if (state.app.user.is_investee && state.app.investor) {
        return state.app.investor.contact_name
            ? state.app.investor.contact_name
            : state.app.user.contact_name;
    } else {
        return state.app.user.contact_name;
    }
};
export const getIfCurrentUserIsPrimaryUser = (state) => state.app.user?.is_primary_user ?? false;

export const getEmail = (state) => {
    if (state.app.user.is_investee && state.app.user.contact_email) {
        return state.app.user.contact_email;
    } else if (state.app.user.is_investee && state.app.investee) {
        return state.app.investee.contact_name;
    }
};
export const getInvestorEmail = (state) =>
    state.app.user.is_investor && state.app.user.contact_email ? state.app.user.contact_email : '';
export const getInvesteeEscrowAccountInfo = (state) => state.app.investeeEscrowAccountInfo;
export const getInvesteeWithdrawableBalance = (state) => state.app.investeeWithdrawableBalance;
export const getPhoneNumber = (state) => state.app.user?.phone_number;
export const getCountryCode = (state) => state.app.user?.country_code;
export const getInvesteeEscrowAccountInfoLoadingState = (state) =>
    state.app.isFetchingInvesteeEscrowAccountInfo;
export const getBanks = (state) => state.app.banks;
export const getAccountHolders = (state) => state.app.accountHolders;
export const getBankList = (state) => state.app.bankList;
export const getEscrowAccountDetails = (state) => state.app.escrowAccountDetails;
export const getIfUserIsAddingBank = (state) => state.app.is_user_adding_bank;
export const getUserAddingBankFailed = (state) => state.app.user_adding_account_failed;
export const getInvestorEscrowAccountInfo = (state) => state.app.investorEscrowAccountInfo;
export const getInvestorEscrowAccountInfoLoadingState = (state) =>
    state.app.isFetchingInvestorEscrowAccountInfo;
export const getUpcomingPayments = (state) => state.app.upcomingPayments;
export const getCompanyWithdrawModalState = (state) => state.app.companyWithdrawModalOpen;
export const getCompanyDepositModalState = (state) => state.app.companyDepositModalOpen;
export const getDepositUrlState = (state) => state.app.is_sending_deposit_url;
export const getDepositUrlRecurCheckoutState = (state) =>
    state.app.is_sending_deposit_url_recur_checkout;
export const getIsFetchingKarzaFetching = (state) => state.app.is_fetching_karza_invoices;
export const getWithdrawState = (state) => state.app.isWithdrawingToBankState;
export const getTransactionDetails = (state) => state.app.transactionDetails;
export const getWithrawModalState = (state) => state.app.withdraw_modal_state;
export const getIdOnRightClick = (state) => state.app.idOnRightClick;
export const getUserPermissionsFromRoles = (state) => state.app.permissions;
// export const getIsFetchingCapitalPartners = state => state.app.isFetchingCapitalPartners;
// export const getCapitalPartners = state => state.app.capitalPartners;
// export const getSalesPartners = state => state.app.salesPartners;
export const getIsFetchingAgentsData = (state) => state.app.isFetchingAgentsData;
export const getSalesPartner = (state) => state.app.salesPartner;
export const getDataSpecialist = (state) => state.app.dataSpecialist;

export const getCheckIfUserHasRelevantPermissions = (state, shouldHavePermissions) => {
    if (shouldHavePermissions && shouldHavePermissions.length > 0) {
        let userPermissions = getUserPermissionsFromRoles(state);
        if (userPermissions) {
            let leftPermissions = shouldHavePermissions.filter(
                (item) => userPermissions.indexOf(item) !== -1,
            );
            return leftPermissions && leftPermissions.length > 0;
        } else {
            return false;
        }
    } else {
        return true;
    }
};

export const getInvestorType = (state) => state.app.investorOrganization?.investor_type;
export const getIsNBFCforDebt = (state) =>
    state.app.investorOrganization?.investor_type === INVESTOR_TYPE.NBFC_FOR_DEBT;
export const getDataVaultPendingStatus = (state) => state.app.dataVaultPendingStatus;
// export const getOpenWithdrawals = (state) => state.app.withdraws;
export const getIsInvalidGstCredentials = (state) => state.app.is_invalid_gst_credential;
export const getInvestorDrawdowns = (state) => state.app.investorDrawdowns;
export const getIfFetchingHubspotToken = (state) => state.app.isFetchingHubspotToken;
export const getHubspotToken = (state) => state.app.hubspotToken;
export const getInvitableRoles = (state) => state.app.invitableRolesRedux;
export const getRoleObjMap = (state) => state.app.roleObjMapRedux;
export const getIsFetchingRoles = (state) => state.app.isFetchingRoles;
export const getIsAppLoading = (state) => state.app.isAppLoading;
export const getProgressBar = (state) => state.app.showProgressBar;
export const getDealsForApproval = (state) => state.app.dealsForApproval;
export const getCounterSignUserByAdmin = (state) => state.app.counterSignUserByAdmin;
export const getInvesteeSidebarConfigTab = (state) => state.app.investeeSidebarConfigTab;
export const getWidgetData = (state) => state.app.widgetData;
export const getApplicationStrength = (state) => state.app.applicationStrengthObj;
export const getLatestAddOnPath = (state) => state.app.latestAddonPath;
export const getRcmDetails = (state) => state.app.rcmDetails;
export const getIfFetchingRcmDetails = (state) => state.app.isFetchingRcmDetails;
export const getCompanyName = (state) => state.app.companyName;
export const getDrawerHeading = (state) => state.app.drawerHeading;
export const getDrawerData = (state) => state.app.drawerData;
export const getShowOnboardingReapplicationModal = (state) =>
    state.app.showOnboardingReapplicationModal;
export const getShowMobileNavHeader = (state) => state.app.showMobileNavHeader;
