import { useEffect, useState } from 'react';
import styles from './DirectorDetails.module.scss';
import clsx from 'clsx';
import {
    getCibilVerificationStatus,
    sendCibilVerificationRequestMessage,
} from '../../../../common/apiHelpers';
import { useSelector } from 'react-redux';
import { getEmail, getInvesteeOrganizationInfo } from '../../../../../App/AppReducer';
import {
    getS3IconLink,
    mixPanelTrack,
    numberOnly,
    showNotification,
} from '../../../../../../util/utility';
import { ASSET_LINKS, ICON_NAMES } from '../../../../../../constants/assetLinks';
import { SHOW_NOTIFICATION_STATUS } from '../../../../../../enums/appEnums';
import { get, isEmpty, set } from 'lodash';
import { DIRECTOR_DETAILS } from './../../../../../../constants/consts';
import { Player } from '@lottiefiles/react-lottie-player';
import { useSearchParams } from 'react-router-dom';
import DirectorInputField from './DirectorInputField';

interface DirectorDetails {
    directorName: string;
    phoneNo: string;
    consentStatus?: boolean;
    lastInviteSentAt?: string;
    isNewDir?: boolean;
}

function DirectorDetails({
    isNeedInfo,
    directorDetailsFromPolling,
    showFetchDetailCTA,
    fetchDetailLoader,
    setFetchDetailLoader,
    setShowFetchDetailCTA,
    fetchDetailPressed,
    fetchOnboardingData,
}: {
    isNeedInfo?: boolean;
    directorDetailsFromPolling?: any;
    fetchDetailLoader?: boolean;
    setFetchDetailLoader?: any;
    setShowFetchDetailCTA?: any;
    fetchOnboardingData?: any;
    showFetchDetailCTA?: any;
    fetchDetailPressed?: any;
}) {
    const {
        DirDetHeading,
        DirDetSubHeading,
        FormContainer,
        FormInput,
        Field,
        FormLabel,
        AddDirText,
        DisabledBannerText,
        AddDirCta,
        NewDirRemove,
        BannerText,
        VerifyButton,
        VerifyEnabled,
    } = styles;
    const investeeOrgId = useSelector(getInvesteeOrganizationInfo)?._id;
    const [params, setParams] = useSearchParams();
    const [directorDetails, setDirectorDetails] = useState<DirectorDetails[]>([
        {
            directorName: '',
            phoneNo: '',
            consentStatus: false,
            lastInviteSentAt: '',
            isNewDir: true,
        },
    ]);
    const [disableSendLink, setDisableSendLink] = useState<boolean>(true);
    const emailId = useSelector(getEmail);

    useEffect(() => {
        if (directorDetailsFromPolling && directorDetailsFromPolling.length > 0) {
            setDirectorDetails(directorDetailsFromPolling);
        } else {
            setDirectorDetails([
                {
                    directorName: '',
                    phoneNo: '',
                    consentStatus: false,
                    lastInviteSentAt: '',
                    isNewDir: true,
                },
            ]);
        }
    }, [directorDetailsFromPolling]);

    const addDirector = () => {
        setDirectorDetails([
            ...directorDetails,
            {
                directorName: '',
                phoneNo: '',
                consentStatus: false,
                lastInviteSentAt: '',
                isNewDir: true,
            },
        ]);
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let { name, value } = e.target;
        if (name === 'phoneNo') {
            value = numberOnly(value);
            if (value.length > 10) {
                return;
            }
        } else if (name === 'directorName') {
            value = value.toUpperCase();
        }
        const list: any = [...directorDetails];
        list[index][name] = value;
        setDirectorDetails(list);
    };
    const getDirDetails = async () => {
        const res = await getCibilVerificationStatus({ investeeOrgId: investeeOrgId });
        if (res.responseData.responseCode === 20) {
            if (!isEmpty(res.directorDetails)) {
                setDirectorDetails(res.directorDetails);
                return true;
            } else {
                setDirectorDetails([
                    {
                        directorName: '',
                        phoneNo: '',
                        consentStatus: false,
                        lastInviteSentAt: '',
                        isNewDir: true,
                    },
                ]);
                return false;
            }
        }
        return false;
    };

    // sets the "director_details_fetched" field as true, inside of investeeOnboarding collection
    // Tracks if the user has clicked "Fetch Detail" button, ensuring a one-time click.
    const updateFetchDetailStatus = async (status: boolean) => {
        const res = await getCibilVerificationStatus({
            fetchDetail: status,
            investeeOrgId: investeeOrgId,
        });
        if (res.responseData.responseCode === 20) {
            if (!isEmpty(res.directorDetails)) {
                setDirectorDetails(res.directorDetails);
                fetchOnboardingData();
                return true;
            } else {
                setDirectorDetails([
                    {
                        directorName: '',
                        phoneNo: '',
                        consentStatus: false,
                        lastInviteSentAt: '',
                        isNewDir: true,
                    },
                ]);
                return false;
            }
        }
        return false;
    };
    useEffect(() => {
        if (params.get('fetchDirectors') === 'true') {
            fetchDetailOnClick();
            params.delete('fetchDirectors');
            setParams(params);
        }
    }, [params.get('fetchDirectors')]);

    const fetchDetailOnClick = () => {
        fetchDetailPressed.current = true;
        updateFetchDetailStatus(fetchDetailPressed.current);
        if (params.get('startPolling') === 'true') {
            setFetchDetailLoader(true);
        } else {
            setShowFetchDetailCTA(false);
        }
    };

    const mixpanelTrackForNewDirector = () => {
        mixPanelTrack({
            id: investeeOrgId,
            trackUserObj: {
                directorName:
                    directorDetails.filter((director) => director.isNewDir)[0].directorName ?? '',
                directorMobileNo:
                    directorDetails.filter((director) => director.isNewDir)[0].phoneNo ?? '',
            },
            trackCategoryName: 'Cibil New Director',
        });
    };
    const removeDirector = (index: number) => {
        const list = [...directorDetails];
        list.splice(index, 1);
        setDirectorDetails(list);
    };
    const sendLink = async (name: string, phoneNo: string, setDisableSendLink: Function) => {
        setDisableSendLink(false);
        const payload = {
            directorName: name,
            investeeOrganizationId: investeeOrgId,
            phoneNumber: phoneNo,
        };
        mixPanelTrack({
            id: investeeOrgId,
            trackUserObj: {
                email: emailId,
                directorName: name,
                directorMobileNo: phoneNo,
            },
            trackCategoryName: 'Cibil Send Link',
        });
        if (name.length === 0) {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Please enter a valid name');
            setDisableSendLink(true);
            return;
        }
        if (phoneNo.length !== 10) {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Please enter a valid mobile number');
            setDisableSendLink(true);
            return;
        }
        const res = await sendCibilVerificationRequestMessage(payload);
        if (res) {
            if (res.responseData.responseCode === 20) {
                setDisableSendLink(true);
                showNotification(
                    SHOW_NOTIFICATION_STATUS.SUCCESS,
                    'Consent link sent successfully',
                );
                getDirDetails();
            } else {
                setDisableSendLink(true);
                showNotification(SHOW_NOTIFICATION_STATUS.ERROR, res.responseData.responseMessage);
            }
        } else {
            setDisableSendLink(true);
        }
    };
    return (
        <div>
            <div className={DirDetHeading}>{DIRECTOR_DETAILS.HEADING}</div>
            <div className={DirDetSubHeading}>
                <img
                    src={getS3IconLink(ICON_NAMES.LIGHT_BULB_BLACK)}
                    alt={ICON_NAMES.LIGHT_BULB_BLACK}
                />
                {DIRECTOR_DETAILS.SUBHEADING}
            </div>
            {showFetchDetailCTA ? (
                <div className={`${VerifyButton} ${VerifyEnabled}`} onClick={fetchDetailOnClick}>
                    {fetchDetailLoader && (
                        <Player
                            src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/loaderVerifyGstin.json"
                            loop
                            style={{
                                width: '70px',
                                height: '70px',
                                backgroundColor: 'transparent',
                                position: 'relative',
                                marginLeft: '-5rem',
                            }}
                            autoplay
                        ></Player>
                    )}
                    Fetch Director List
                </div>
            ) : (
                <>
                    {directorDetails?.map((director, index) => {
                        if (!director.isNewDir) {
                            return (
                                <DirectorInputField
                                    director={director}
                                    index={index}
                                    handleChange={handleChange}
                                    sendLink={sendLink}
                                />
                            );
                        } else {
                            return (
                                <div
                                    className="dflex"
                                    style={{ gap: '1rem', marginBottom: '1.12rem' }}
                                >
                                    <div style={{ width: '100%' }} className={FormContainer}>
                                        <div style={{ margin: '0 0 1rem 0' }} className={Field}>
                                            <input
                                                id="directorName"
                                                name="directorName"
                                                type="text"
                                                value={director.directorName}
                                                onChange={(e) => {
                                                    handleChange(e, index);
                                                }}
                                                className={clsx(FormInput)}
                                                disabled={false}
                                                required
                                            />
                                            <label
                                                className={clsx(FormLabel)}
                                                htmlFor="directorName"
                                            >
                                                Director Name
                                            </label>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%' }} className={FormContainer}>
                                        <div style={{ margin: '0 0 1rem 0' }} className={Field}>
                                            <input
                                                id="phoneNo"
                                                name="phoneNo"
                                                type="text"
                                                value={director.phoneNo}
                                                onChange={(e) => {
                                                    handleChange(e, index);
                                                }}
                                                className={clsx(FormInput)}
                                                disabled={false}
                                                required
                                            />
                                            <label
                                                className={clsx(FormLabel)}
                                                htmlFor="directorName"
                                            >
                                                Director Mobile No.
                                            </label>
                                            {director.phoneNo && (
                                                <div
                                                    onClick={() => {
                                                        mixpanelTrackForNewDirector();
                                                        sendLink(
                                                            director.directorName,
                                                            director.phoneNo,
                                                            setDisableSendLink,
                                                        );
                                                    }}
                                                    className={
                                                        disableSendLink &&
                                                        director.phoneNo.length === 10
                                                            ? BannerText
                                                            : DisabledBannerText
                                                    }
                                                >
                                                    Send Link
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <img
                                        src={ASSET_LINKS.CROSS_ICON_GREY}
                                        alt="Remove"
                                        className={NewDirRemove}
                                        onClick={() => removeDirector(index)}
                                    />
                                </div>
                            );
                        }
                    })}
                    <div className={AddDirText}>
                        Add more directors for credit bureau check.{' '}
                        <span onClick={() => addDirector()} className={AddDirCta}>
                            Add Director
                        </span>
                    </div>
                </>
            )}
        </div>
    );
}

export default DirectorDetails;
