import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import isNil from 'lodash/isNil';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { API_STATUS, FETCH_STATUS, SHOW_NOTIFICATION_STATUS } from '../../enums/appEnums';
import callApi, { callApiWithAxios } from '../../util/apiCaller';
import { calculateTerm } from '../../util/calculateTermUtil';
import { isEnvProd, mixPanelTrack, showNotification } from '../../util/utility';
import { companyInviteModal, fetchBankList, setIsAppLoading } from '../App/AppActions';
import { PAYOUTS } from './pages/FinancesPage/TransactionsOverview';
import { captureMessagesViaErrorLogger } from '../../util/errorLogger';
import {
    fetchForecastData,
    fetchLimitOverview,
    fetchInvesteeCapitalAvailableData,
    fetchInvesteeCollectionMode,
    getAddOnLimitStatusHelper,
    getAllDealsLinkedToATot,
    getMonthlyOnboardingStatus,
    getTotData,
    getRecurScaleOffers,
    fetchMobileUploadeStepsHelper,
} from './common/apiHelpers';

export const FETCHING_TEAM_MEMBERS = 'FETCHING_TEAM_MEMBERS';
export const FETCH_TEAM_MEMBERS = 'FETCH_TEAM_MEMBERS';
export const FETCH_TEAM_MEMBERS_SUCCESS = 'FETCH_TEAM_MEMBERS_SUCCESS';

export const ADDING_NEW_MEMBER = 'ADDING_NEW_MEMBER';
export const ADD_NEW_MEMBER = 'ADD_NEW_MEMBER';

export const UPLOADING_CONTRACTS = 'UPLOADING_CONTRACTS';
export const UPLOAD_CONTRACTS = 'UPLOAD_CONTRACTS';

export const FETCHING_CONTRACT_LIST = 'FETCHING_CONTRACT_LIST';
export const FETCH_CONTRACT_LIST = 'FETCH_CONTRACT_LIST';
export const RESET_CONTRACT_LIST = 'RESET_CONTRACT_LIST';
export const UPDATE_CONTRACT_LIST = 'UPDATE_CONTRACT LIST';

export const FETCHING_TRADEBOOK_CONTRACT_LIST = 'FETCHING_TRADEBOOK_CONTRACT_LIST';
export const FETCH_TRADEBOOK_CONTRACT_LIST = 'FETCH_TRADEBOOK_CONTRACT_LIST';
export const FETCHING_TRADEBOOK_ORDERS = 'FETCHING_TRADEBOOK_ORDERS';
export const FETCH_TRADEBOOK_ORDERS = 'FETCH_TRADEBOOK_ORDERS';

export const FETCHING_NOTIFICATIONS = 'FETCHING_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_UNREAD_NOTIFICATION_COUNT = 'FETCH_UNREAD_NOTIFICATION_COUNT';
export const FETCHING_UNREAD_NOTIFICATION_COUNT = 'FETCHINT_UNREAD_NOTIFICATION_COUNT';

export const FETCHING_INVESTEE_TRANSACTIONS = 'FETCHING_INVESTEE_TRANSACTIONS';
export const FETCH_INVESTEE_TRANSACTIONS = 'FETCH_INVESTEE_TRANSACTIONS';

export const MARKING_CONTRACT_FOR_LISTING = 'MARKING_CONTRACT_FOR_LISTING';
export const MARKED_CONTRACT_FOR_LISTING = 'MARKED_CONTRACT_FOR_LISTING';

export const MARKING_CONTRACT_FOR_DELISTING = 'MARKING_CONTRACT_FOR_DELISTING';
export const MARKED_CONTRACT_FOR_DELISTING = 'MARKED_CONTRACT_FOR_DELISTING';

export const INVESTEE_SUPPORTING_MEMBER = 'INVESTEE_SUPPORTING_MEMBER';
export const INVESTEE_SUPPORT_MEMBER = 'INVESTEE_SUPPORT_MEMBER';

export const FETCHING_ARR_DATA = 'FETCHING_ARR_DATA';
export const FETCH_ARR_DATA = 'FETCH_ARR_DATA';

export const FETCHING_TRADEBOOK_DATA = 'FETCHING_TRADEBOOK_DATA';
export const FETCH_TRADEBOOK_DATA = 'FETCH_TRADEBOOK_DATA';

export const FETCH_REPAYMENT_SCHEDULE = 'FETCH_REPAYMENT_SCHEDULE';
export const FETCH_ORDER_REPAYMENT_SCHEDULE = 'FETCH_ORDER_REPAYMENT_SCHEDULE';

export const FETCHING_REPAYMENT_SCHEDULE = 'FETCHING_REPAYMENT_SCHEDULE';
export const FETCHING_ORDER_REPAYMENT_SCHEDULE = 'FETCHING_ORDER_REPAYMENT_SCHEDULE';
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';

export const SENDING_RESET_PASS_MAIL = 'SENDING_RESET_PASS_MAIL';
export const SET_TRADE_CONTRACTS = 'SET_TRADE_CONTRACTS';

export const FETCHING_INVESTEE_ACCOUNT_STATEMENT = 'FETCHING_INVESTEE_ACCOUNT_STATEMENT';
export const FETCH_INVESTEE_ACCOUNT_STATEMENT = 'FETCH_INVESTEE_ACCOUNT_STATEMENT';
export const SETTING_REPAYMENT_SCHEDULE = 'SETTING_REPAYMENT_SCHEDULE';
export const SET_REPAYMENT_SCHEDULE = 'SETTING_REPAYMENT_SCHEDULE';

export const FETCHING_ONBOARDING_DATA = 'FETCHING_ONBOARDING_DATA';
export const FETCH_ONBOARDING_DATA = 'FETCH_ONBOARDING_DATA';

export const FETCHING_FINBOX_REDIRECT_URL = 'FETCHING_FINBOX_REDIRECT_URL';
export const FETCH_FINBOX_REDIRECT_URL = 'FETCH_FINBOX_REDIRECT_URL';

export const FETCHING_OVERVIEW_DATA = 'FETCHING_OVERVIEW_DATA';
export const FETCH_OVERVIEW_DATA = 'FETCH_OVERVIEW_DATA';

export const FETCHING_INVESTEE_DASHBOARD_STATS = 'FETCHING_INVESTEE_DASHBOARD_STATS';
export const FETCH_INVESTEE_DASHBOARD_STATS = 'FETCH_INVESTEE_DASHBOARD_STATS';

export const FETCHING_INVOICES = 'FETCHING_INVOICES';
export const FETCH_INVOICES = 'FETCH_INVOICES';

export const INTERNAL_SERVER_ERROR = 'Internal Server Error';
export const FETCHING_KYC_DOCS = 'FETCHING_KYC_DOCS';
export const FETCH_KYC_DOCS = 'FETCH_KYC_DOCS';
export const FETCH_FILTERED_DEALS = 'FETCH_FILTERED_DEALS';

export const FETCH_LIMIT_OVERVIEW_DATA = 'FETCH_LIMIT_OVERVIEW_DATA';
export const FETCH_RECUR_SCALE_OFFERS = 'FETCH_RECUR_SCALE_OFFERS';

export const TOGGLE_AUTH_SIGN_MODAL = 'TOGGLE_AUTH_SIGN_MODAL';
export const FETCH_CUSTOMERS_TRADED = 'FETCH_CUSTOMERS_TRADED';

export const IS_FETCHING_TOT_DATA = 'IS_FETCHING_TOT_DATA';
export const FETCH_TOT_DATA = 'FETCH_TOT_DATA';

export const FETCHING_INVESTEE_DEALS = 'FETCHING_INVESTEE_DEALS';
export const FETCH_INVESTEE_DEALS = 'FETCH_INVESTEE_DEALS';

export const FETCHING_ADD_ON_LIMIT_STATUS = 'FETCHING_ADD_ON_LIMIT_STATUS';
export const FETCH_ADD_ON_LIMIT_STATUS = 'FETCH_ADD_ON_LIMIT_STATUS';

export const FETCHING_ADD_ON_DATA = 'FETCHING_ADD_ON_DATA';
export const FETCH_ADD_ON_DATA = 'FETCH_ADD_ON_DATA';

export const UPDATE_CAPITAL_AVAILABLE_DATA = 'UPDATE_CAPITAL_AVAILABLE_DATA';
export const UPDATE_COLLECTION_MODE = 'UPDATE_COLLECTION_MODE';

export const TRIGGER_ADD_ON_LIMIT_MODAL = 'TRIGGER_ADD_ON_LIMIT_MODAL';

export const UPDATING_FORECAST_DATA = 'UPDATING_FORECAST_DATA';
export const UPDATE_FORECAST_DATA = 'UPDATE_FORECAST_DATA';
export const REPAYMENT_DRAWER_TYPE = 'REPAYMENT_DRAWER_TYPE';
export const SET_INVESTEE_TRANSACTIONS_CANCEL_TOKEN = 'SET_INVESTEE_TRANSACTIONS_CANCEL_TOKEN';
export const BANK_ACCOUNT_CLICKED_FROM_REVIEW_PAGE = 'BANK_ACCOUNT_CLICKED_FROM_REVIEW_PAGE';

export const FETCHING_MOBILE_UPLOAD_STEPS = 'FETCHING_MOBILE_UPLOAD_STEPS';
export const FETCH_MOBILE_UPLOAD_STEPS = 'FETCH_MOBILE_UPLOAD_STEPS';

const { SUCCESS, ERROR } = API_STATUS;

dayjs.extend(UTC);
dayjs.extend(customParseFormat);

export const setCustomerTraded = (data) => {
    return (dispatch) => {
        dispatch({
            type: FETCH_CUSTOMERS_TRADED,
            data,
        });
    };
};

export const setSelectedOrder = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_ORDER,
            data,
        });
    };
};

export const setOrders = (data) => {
    return (dispatch) => {
        dispatch({
            type: FETCH_ORDER_REPAYMENT_SCHEDULE,
            data,
        });
    };
};

export const setIsFetchingOrders = (data) => {
    return (dispatch) => {
        dispatch({
            type: FETCHING_ORDER_REPAYMENT_SCHEDULE,
            data,
        });
    };
};

export const toggleAuthSignModal = (value = false) => {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_AUTH_SIGN_MODAL,
            openAuthSignModal: value,
        });
    };
};

export const triggerAddOnLimitModal = (value = false) => {
    return (dispatch) => {
        dispatch({
            type: TRIGGER_ADD_ON_LIMIT_MODAL,
            openAddOnLimitModal: value,
        });
    };
};

export const updateKycDetailsToLatest = (obj, dispatch) => {
    const updateKycDocsInReducer = (dispatch) => {
        dispatch({
            type: FETCH_KYC_DOCS,
            kycDocs: kycObj,
            isFetchingKycDocs: false,
        });
    };
    const {
        //* Signatory Details
        full_name,
        contact_number,
        job_role,
        email,
        is_authorized_signatory_complete,
        //* Company Authorisation
        company_authorization,
        is_company_authorization_complete,
        //* KYC Documents
        certificate_of_incorporation,
        company_pan,
        memorandum_of_association,
        articles_of_association,
        shareholding_pattern,
        gstin,
        gst_certificate,
        utility_bill,
        rent_agreement,
        official_address_proof,
        is_official_address_proof_complete,
        signatory_official_document,
        added_beneficiary,
        additional_documents,
        is_kyc_documents_complete,
        is_additional_documents_complete,
        //* User Has Accepted the terms and Signed
        user_signed_documents,
        is_esigned,
    } = obj;

    //* Auth Signatory / Signatory Details
    const authSignatory = {};
    authSignatory.fullName = full_name;
    authSignatory.contactNumber = contact_number;
    authSignatory.jobRole = job_role;
    authSignatory.email = email;
    authSignatory.isAuthSignatoryComplete = is_authorized_signatory_complete;
    //* KYC Documents
    const kycObj = {
        authSignatory,
        companyKycDocuments: {},
        signatoryKycDocs: {},
    };
    //* Company KYC Documents
    kycObj.companyKycDocuments.certificateOfInc = certificate_of_incorporation;
    kycObj.companyKycDocuments.gstin = gstin;
    kycObj.companyKycDocuments.gstCertificate = gst_certificate;
    kycObj.companyKycDocuments.companyPan = company_pan;
    kycObj.companyKycDocuments.MOAorAOA = articles_of_association;
    kycObj.companyKycDocuments.officialAddressProof = official_address_proof;
    kycObj.companyKycDocuments.isOfficialAddressProofComplete = is_official_address_proof_complete;
    kycObj.companyKycDocuments.memorandumOfAssociation = memorandum_of_association;
    kycObj.companyKycDocuments.articlesOfAssociation = articles_of_association;
    kycObj.companyKycDocuments.shareholdingPattern = shareholding_pattern;
    kycObj.companyKycDocuments.utilityBill = utility_bill;
    kycObj.companyKycDocuments.rentAgreement = rent_agreement;
    kycObj.isKycDocsComplete = is_kyc_documents_complete;

    //* Company Authorisation
    kycObj.companyAuth = {
        company_authorization,
        is_company_authorization_complete,
    };

    //* Company Signatory KYC Documents
    kycObj.signatoryKycDocs.signatoryPan = signatory_official_document?.sign_pan;
    kycObj.signatoryKycDocs.passportOrDlOrVoterId =
        signatory_official_document?.sign_dl_voter_passport;
    kycObj.isSignatoryKycComplete = signatory_official_document?.status === 'Submitted';
    kycObj.addedBeneficiary = added_beneficiary;

    //* User Has Accepted the terms and Signed
    kycObj.userSignedDocuments = user_signed_documents;
    kycObj.isEsigned = is_esigned;

    //* Company KYC Additional Documents
    if (!isEmpty(additional_documents)) {
        kycObj.additionalDocs = additional_documents;
        kycObj.isAdditionalDocsComplete = is_additional_documents_complete;
    }
    if (dispatch) {
        updateKycDocsInReducer(dispatch);
        return;
    }
    return (dispatch) => {
        updateKycDocsInReducer(dispatch);
    };
};
export function fetchKycDocs(investeeOnboardingId) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_KYC_DOCS,
            isFetchingKycDocs: true,
        });
        callApi(
            `investee_kyc_application/investee_onboarding/${investeeOnboardingId}/investee/documents`,
            'get',
        ).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                if (res.data) {
                    updateKycDetailsToLatest(res.data, dispatch);
                }
            } else {
                const message = res.data?.message ? res.data?.message : 'Some error occurred';
                showNotification('Error', message);
                dispatch({
                    type: FETCHING_KYC_DOCS,
                    isFetchingKycDocs: false,
                });
            }
        });
    };
}

export function fetchAddOnLimitStatus(investeeOrgId) {
    return async (dispatch) => {
        dispatch({
            type: FETCHING_ADD_ON_LIMIT_STATUS,
            isFetchingAddOnLimitStatus: true,
        });
        const { limit_add_on_status } = await getAddOnLimitStatusHelper(investeeOrgId);
        if (limit_add_on_status) {
            dispatch({
                type: FETCH_ADD_ON_LIMIT_STATUS,
                addOnLimitStatus: limit_add_on_status,
                isFetchingAddOnLimitStatus: false,
            });
        } else {
            dispatch({
                type: FETCHING_ADD_ON_LIMIT_STATUS,
                isFetchingAddOnLimitStatus: false,
            });
        }
    };
}

export function fetchAllDealsRelatedToATot(investee_organization_id, tot_id, doFinally) {
    return async (dispatch) => {
        dispatch({
            type: FETCHING_INVESTEE_DEALS,
            isFetchingDeals: true,
        });
        const deals = await getAllDealsLinkedToATot({
            investee_organization_id,
            tot_id,
        });
        if (!isEmpty(deals)) {
            dispatch({
                type: FETCH_INVESTEE_DEALS,
                deals,
                isFetchingDeals: false,
            });
        } else
            dispatch({
                type: FETCHING_INVESTEE_DEALS,
                isFetchingDeals: false,
            });
        if (doFinally) doFinally();
    };
}

export function fetchTotDataFromActions(investeeOrganizationId, totId) {
    const STANDARD = 'Standard';
    const MAX = 'Max';
    const MAB = 'MAB';
    const updateTotData = (data, category) => {
        if (data?.stageTots) {
            if (category in data.stageTots) {
                if ('mab' in data.stageTots[category] && data.stageTots[category].mab) {
                    if (
                        !('secondary_rcm' in data.stageTots[category]) ||
                        !data.stageTots[category].secondary_rcm
                    ) {
                        data.stageTots[category].secondary_rcm = {};
                    }
                    data.stageTots[category].secondary_rcm[MAB] = {};
                    data.stageTots[category].secondary_rcm[MAB].amount =
                        data.stageTots[category].mab;
                }
            }
        }
        return data;
    };
    return async (dispatch) => {
        dispatch({
            type: IS_FETCHING_TOT_DATA,
            isFetchingTotData: true,
        });
        let totData = await getTotData(investeeOrganizationId, totId);
        if (typeof totData === 'string') {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, totData);
            dispatch({
                type: IS_FETCHING_TOT_DATA,
                isFetchingTotData: false,
            });
        } else {
            totData = updateTotData(totData, STANDARD);
            totData = updateTotData(totData, MAX);
            dispatch({
                type: FETCH_TOT_DATA,
                totData,
                isFetchingTotData: false,
            });
        }
    };
}

export function fetchInvoices(investeeOrgId) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVOICES,
            isFetchingInvoices: true,
        });
        return callApi(`investee_organization/${investeeOrgId}/invoice-list`, 'post', {
            pageNumber: 1,
            pageSize: 500,
        }).then((res) => {
            if (res.status === SUCCESS) {
                dispatch({
                    type: FETCH_INVOICES,
                    invoices: res.data.list,
                    invoicesTotalCount: res.data.count,
                    isFetchingInvoices: false,
                });
            } else {
                showNotification('Error', 'Some Error Occured');
                dispatch({
                    type: FETCHING_INVOICES,
                    isFetchingInvoices: false,
                });
            }
        });
    };
}

// export function fetchInvesteeDeals(investeeOrgId) {
//     return (dispatch) => {
//         dispatch({
//             type: FETCHING_INVESTEE_DEALS,
//             isFetchingInvesteeDeals: true,
//         });
//         callApi(`deals/investee-organization/get-all-deals`, 'post', {
//             investee_organization_id: investeeOrgId,
//         })
//             .then((res) => {
//                 if (res.status === SUCCESS) {
//                     const investeeDeals = {};
//                     investeeDeals.deals = res.deals;
//                     investeeDeals.doesDataExist = true;
//                     dispatch({
//                         type: FETCH_INVESTEE_DEALS,
//                         investeeDeals,
//                         primaryUserName: res.primary_username,
//                         isFetchingInvesteeDeals: false,
//                     });
//                 } else {
//                     const message = res.data?.message ?? 'Some error occurred!';
//                     showNotification(ERROR, message);
//                     dispatch({
//                         type: FETCHING_INVESTEE_DEALS,
//                         isFetchingInvesteeDeals: false,
//                     });
//                 }
//             })
//             .catch(() => {
//                 showNotification(ERROR, 'Some error occurred!');
//                 dispatch({
//                     type: FETCHING_INVESTEE_DEALS,
//                     isFetchingInvesteeDeals: false,
//                 });
//             });
//     };
// }
export function fetchLimitOverviewDeals(investeeOrgId) {
    return async (dispatch) => {
        const result = await fetchLimitOverview({ investee_organization_id: investeeOrgId });
        if (result) {
            dispatch({
                type: FETCH_LIMIT_OVERVIEW_DATA,
                limitOverviewData: result?.sanctions ?? [],
                primaryUserName: result?.primaryUserData?.contact_name,
                lastSanctionData: result?.lastSanction,
            });
        }
    };
}

export function fetchRecurLimitScaleOffers() {
    return async (dispatch) => {
        try {
            const result = await getRecurScaleOffers();
            if (!isEmpty(result)) {
                dispatch({
                    type: FETCH_RECUR_SCALE_OFFERS,
                    recurScaleOffers: result?.terms ?? [],
                    primaryUserName: result?.primaryUserName,
                });
            }
        } catch (err) {}
    };
}

export const fetchCapitalAvailableData = (investeeOrgId) => {
    return async (dispatch) => {
        dispatch(setIsAppLoading(true));
        const capitalAvailableData = await fetchInvesteeCapitalAvailableData(investeeOrgId);
        dispatch(setIsAppLoading(false));
        dispatch({
            type: UPDATE_CAPITAL_AVAILABLE_DATA,
            capitalAvailableData,
        });
    };
};

export const fetchCollectionMode = (investeeOrgId) => {
    return async (dispatch) => {
        dispatch(setIsAppLoading(true));
        const collectionMode = await fetchInvesteeCollectionMode(investeeOrgId);
        dispatch(setIsAppLoading(false));
        dispatch({
            type: UPDATE_COLLECTION_MODE,
            collectionMode,
        });
    };
};

export function fetchFinancesOverviewData(investee_organization_id, input) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_OVERVIEW_DATA,
            isFetchingFinancesOverviewData: true,
        });
        const body = {
            investee_organization_id,
        };
        body.payments = input?.payments ? input?.payments : 'Upcoming';
        body.payouts = input?.payouts ? input?.payouts : 'All Time';
        return callApi(`investeeorganization/finance-transaction-overview`, 'post', body)
            .then((res) => {
                if (res.status === SUCCESS) {
                    dispatch({
                        type: FETCH_OVERVIEW_DATA,
                        payoutOverviewData: res.data.payout_received_data,
                        monthly_scheduled_payments: res.data.monthly_scheduled_payments,
                        monthly_scheduled_payments_graph: res.data.monthly_scheduled_payments_graph,
                        monthly_completed_payments: res.data.monthly_completed_payments,
                        investee_payouts: res.data.payouts,
                        totalRemainingUpcomingPayment: res.data.total_upcoming_payment_amount,
                        totalCompletedRecentPayment: res.data.total_completed_payment_amount,
                        isFetchingFinancesOverviewData: false,
                    });
                } else {
                    dispatch({
                        type: FETCHING_OVERVIEW_DATA,
                        isFetchingFinancesOverviewData: false,
                    });
                    const message = res.data?.message ? res.data?.message : 'Some error occurred!';
                    showNotification(ERROR, message);
                }
            })
            .catch(() => {
                dispatch({
                    type: FETCHING_OVERVIEW_DATA,
                    isFetchingFinancesOverviewData: false,
                });
                showNotification(ERROR, 'Some error occurred!');
            });
    };
}

export function fetchFinboxRedirectUrl(from_date, to_date, link_id, bank_name) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_FINBOX_REDIRECT_URL,
            isFetchingFinboxRedirectUrl: true,
        });
        const obj = {
            from_date,
            to_date,
            link_id,
        };
        if (bank_name) obj.bank_name = bank_name;
        return callApi('finbox/get-session-url', 'post', obj)
            .then((res) => {
                if (res.status === SUCCESS) {
                    dispatch({
                        type: FETCH_FINBOX_REDIRECT_URL,
                        finboxRedirectUrl: res.data.redirect_url,
                        isFetchingFinboxRedirectUrl: false,
                    });
                } else {
                    const message = res.data?.message ? res.data?.message : 'Some error occurred!';
                    dispatch({
                        type: FETCHING_FINBOX_REDIRECT_URL,
                        isFetchingFinboxRedirectUrl: false,
                        finboxError: message,
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: FETCHING_FINBOX_REDIRECT_URL,
                    isFetchingFinboxRedirectUrl: false,
                    finboxError: 'Some error occurred!',
                });
            });
    };
}

export function fetchOnboardingDetails(investeeOnboardingId) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_ONBOARDING_DATA,
            isFetchingDetails: true,
        });
        return callApi(`investee-onboarding/${investeeOnboardingId}`, 'get').then((res) => {
            if (res.status === SUCCESS) {
                const {
                    is_application_submitted,
                    is_application_started,
                    pan_number,
                    cin_number,
                    company_website,
                    registered_company_name,
                    current_company_address,
                    is_business_verified_complete,
                    onboarding_stage_verify_business_docs_link,
                    onboarding_stage_data_financials_mis,
                    is_data_finances_mis_complete,
                    onboarding_stage_data_financials_statements,
                    is_data_finances_statements_complete,
                    integration_mis,
                    onboarding_stage_data_invoicing,
                    is_data_invoicing_complete,
                    onboarding_stage_data_customer_contracts,
                    is_data_customer_contracts_complete,
                    integration_invoicing,
                    bank_account,
                    is_bank_details_complete,
                    is_fetch_bank_account_complete,
                    bills_invoices_bank_transactions,
                    finbox_banks_done,
                    gstin_number,
                    is_gst_detail_complete,
                } = res.data?.investeeOnboardingData;
                let companyDetailObj = {};
                companyDetailObj.pan_number = pan_number ? pan_number : '';
                companyDetailObj.cin_number = cin_number ? cin_number : '';
                companyDetailObj.company_website = company_website ? company_website : '';
                companyDetailObj.registered_company_name = registered_company_name
                    ? registered_company_name
                    : '';
                companyDetailObj.current_company_address = current_company_address
                    ? current_company_address
                    : '';
                companyDetailObj.is_business_verified_complete = is_business_verified_complete
                    ? is_business_verified_complete
                    : false;
                const bankAccount =
                    bank_account.length !== 0
                        ? [...bank_account]
                        : [
                              {
                                  bank_name: '',
                                  account_number: '',
                                  account_holder_name: '',
                                  ifsc: '',
                                  account_nick_name: '',
                                  is_bank_details_complete: false,
                              },
                          ];
                dispatch({
                    type: FETCH_ONBOARDING_DATA,
                    isApplicationSubmitted: is_application_submitted,
                    isAppStarted: is_application_started,
                    companyDetails: companyDetailObj,
                    isPanVerified: pan_number ? true : false,
                    isCinVerified: cin_number ? true : false,
                    additionalDocs: onboarding_stage_verify_business_docs_link,
                    misDocs: onboarding_stage_data_financials_mis,
                    financialStatements: onboarding_stage_data_financials_statements,
                    misDone: is_data_finances_mis_complete,
                    financialStatementsDone: is_data_finances_statements_complete,
                    misIntegrations: integration_mis,
                    invoicingDocs: onboarding_stage_data_invoicing,
                    customerContracts: onboarding_stage_data_customer_contracts,
                    invoicingDone: is_data_invoicing_complete,
                    customerContractsDone: is_data_customer_contracts_complete,
                    invoicingIntegrations: integration_invoicing,
                    bankAccounts: bankAccount,
                    isBankDetailsComplete: is_bank_details_complete,
                    isFetchBankAccountDone: is_fetch_bank_account_complete,
                    statementDocs: bills_invoices_bank_transactions,
                    finboxBankDone: finbox_banks_done
                        ? finbox_banks_done
                        : {
                              hdfc: false,
                              sbi: false,
                              icici: false,
                              otherBanks: false,
                          },
                    GST: gstin_number ? gstin_number : '',
                    isGSTVerified: gstin_number ? true : false,
                    isGSTDetailComplete: is_gst_detail_complete,
                    isFetchingDetails: false,
                });
            } else {
                const message = res.data?.message ? res.data?.message : 'Some error occurred!';
                showNotification(ERROR, message);
                dispatch({
                    type: FETCHING_ONBOARDING_DATA,
                    isFetchingDetails: false,
                });
            }
        });
    };
}

export function fetchTeamMembers(investee_organization_id, isUpdating = false) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_TEAM_MEMBERS,
            is_fetching_members: true,
            is_member_data_fetched_success: false,
        });
        return callApi(
            `investeeOrganization/${investee_organization_id}/profile/get-team-v2`,
            'get',
        ).then((res) => {
            if (res.status === SUCCESS) {
                dispatch({
                    type: FETCH_TEAM_MEMBERS,
                    members: res.data.investees,
                    is_fetching_members: false,
                });
                if (isUpdating) {
                    dispatch({
                        type: FETCH_TEAM_MEMBERS_SUCCESS,
                        is_member_data_fetched_success: true,
                    });
                }
            } else {
                dispatch({
                    type: FETCHING_TEAM_MEMBERS,
                    is_fetching_members: false,
                    is_member_data_fetched_success: false,
                });
            }
        });
    };
}

export function settingRepaymentSchedule() {
    return (dispatch) => {
        setTimeout(() => {
            dispatch({
                type: SETTING_REPAYMENT_SCHEDULE,
            });
        }, 500);
    };
}

export function fetchForecastDashboardData(investeeOrgId) {
    return async (dispatch) => {
        dispatch({
            type: UPDATING_FORECAST_DATA,
            isLoadingForecastData: true,
        });
        const data = await fetchForecastData(investeeOrgId);
        if (data) {
            const forecastData = {
                capitalForecastForDashboard: data.capitalForecastForDashboard,
                capitalForecastStatus: data.capitalForecastStatus,
            };
            dispatch({
                type: UPDATE_FORECAST_DATA,
                forecastData,
                isLoadingForecastData: false,
            });
        } else {
            dispatch({
                type: UPDATING_FORECAST_DATA,
                isLoadingForecastData: false,
            });
        }
    };
}

export function fetchRepaymentSchedule(inputobj) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_REPAYMENT_SCHEDULE,
            is_fetching_repayment_schedule: true,
        });
        if (inputobj.contract_id_array?.length !== 0) {
            return callApi(`contract_transactions/repayment-schedule`, 'post', {
                contract_ids: inputobj.contract_id_array,
                ...(!isEmpty(inputobj.trade_id) && { trade_id: inputobj.trade_id }),
                ...(!isNil(inputobj.custom_tenure) && {
                    custom_tenure_fixed: inputobj.custom_tenure,
                }),
                ...(!isNil(inputobj.is_for_listed) && { is_for_listed: inputobj.is_for_listed }),
                ...(!isNil(inputobj.partial_contract_id) && {
                    partial_contract_id: inputobj.partial_contract_id,
                }),
                ...(!isNil(inputobj.partial_contract_perc) && {
                    partial_contract_perc: inputobj.partial_contract_perc,
                }),
                ...(!isNil(inputobj.show_received_payment) && {
                    show_received_payment: inputobj.show_received_payment,
                }),
            }).then((res) => {
                if (res.status === SUCCESS) {
                    dispatch({
                        type: FETCH_REPAYMENT_SCHEDULE,
                        repayment_schedule: res.data,
                        is_fetching_repayment_schedule: false,
                        dispatchedOnHover: inputobj.dispatchedOnHover,
                        is_moratorium_on: inputobj.is_moratorium_on,
                        is_for_listed: inputobj.is_for_listed,
                    });
                } else {
                    showNotification('Error', 'Some error occurred');
                    dispatch({
                        type: FETCHING_REPAYMENT_SCHEDULE,
                        is_fetching_repayment_schedule: false,
                    });
                }
            });
        } else {
            dispatch({
                type: FETCH_REPAYMENT_SCHEDULE,
                repayment_schedule: [],
                is_fetching_repayment_schedule: false,
            });
        }
    };
}

export function addNewMember(investee_id, investee_organization_id, teammate, onSuccess) {
    return (dispatch) => {
        dispatch({
            type: ADDING_NEW_MEMBER,
            is_inviting_new_member: true,
        });
        return callApi(`investee/${investee_id}/invite-teammate-v2`, 'post', {
            teammate: teammate,
            investee_organization_id: investee_organization_id,
        }).then((res) => {
            if (res.status === SUCCESS) {
                const device = window.localStorage.getItem('device');
                showNotification(SUCCESS, 'New Member Added!');
                !res.data.userExists && dispatch(companyInviteModal(false));
                dispatch({
                    type: ADD_NEW_MEMBER,
                    member: res.data.savedInvestee,
                    is_inviting_new_member: false,
                });
                dispatch(fetchTeamMembers(investee_organization_id));
                if (isEnvProd) {
                    mixPanelTrack({
                        id: investee_organization_id,
                        trackUserObj: {
                            source: device,
                        },
                        trackCategoryName: 'Invite Team member',
                    });
                }

                onSuccess && onSuccess();
            } else {
                showNotification('Error', res?.data?.message);
                dispatch({
                    type: ADDING_NEW_MEMBER,
                    is_inviting_new_member: false,
                });
            }
        });
    };
}

export function sendResetPasswordMail(email) {
    return (dispatch) => {
        dispatch({
            type: SENDING_RESET_PASS_MAIL,
            is_sending_reset_password_mail: true,
        });
        return callApi(`auth/forgot-password`, 'post', {
            email: email,
        }).then((res) => {
            if (res.status === SUCCESS) {
                showNotification(SUCCESS, 'Reset Password link sent on email successfully');
                dispatch({
                    type: SENDING_RESET_PASS_MAIL,
                    is_sending_reset_password_mail: false,
                });
            } else {
                dispatch({
                    type: SENDING_RESET_PASS_MAIL,
                    is_sending_reset_password_mail: false,
                });
            }
        });
    };
}

export function uploadContracts(investee_organization_id, contracts) {
    return (dispatch) => {
        dispatch({
            type: UPLOADING_CONTRACTS,
            is_uploading_contracts: true,
        });
        return callApi(
            `investeeorganization/${investee_organization_id}/contracts/bulk-upload`,
            'post',
            {
                contracts: contracts,
            },
        ).then((res) => {
            if (res.status === SUCCESS) {
                dispatch({
                    type: UPLOAD_CONTRACTS,
                    uploaded_contracts: res.data.contracts,
                    is_uploading_contracts: false,
                });
                showNotification(SUCCESS, 'Contracts Added Successfully');
            } else {
                dispatch({
                    type: UPLOADING_CONTRACTS,
                    is_uploading_contracts: false,
                });
            }
        });
    };
}

// Action -> work that you are going
// path -> module_name/:objectid/action
export function investeeSupportMember(investee_organization_id) {
    return (dispatch) => {
        dispatch({
            type: INVESTEE_SUPPORTING_MEMBER,
        });
        return callApi(
            `investeeOrganization/${investee_organization_id}/profile/get-team`,
            'get',
        ).then((res) => {
            if (res.status === SUCCESS) {
                dispatch({
                    type: INVESTEE_SUPPORT_MEMBER,
                    members: res.data.investees,
                    is_fetching_members: false,
                });
            } else {
                dispatch({
                    type: INVESTEE_SUPPORTING_MEMBER,
                });
            }
        });
    };
}

export function updateTradeContracts(trade_contracts) {
    return (dispatch) => {
        if (trade_contracts && Array.isArray(trade_contracts))
            dispatch({
                type: UPDATE_CONTRACT_LIST,
                trade_contracts,
            });
    };
}

export function getFilteredTradeContracts(input, params, isFiltering = false, boolMoratorium) {
    return async (dispatch) => {
        if (!(input?.custom_tenure && input?.filters?.investee_organization_id)) return;
        dispatch({
            type: FETCHING_CONTRACT_LIST,
            is_fetching_trade_contracts: FETCH_STATUS.LOADING,
        });
        const res = await callApi('contract/get-filtered-contract', 'post', input);
        let trade_contracts = [];
        if (res.status === SUCCESS) {
            if (res.data && res.data.contracts) {
                trade_contracts = res.data.contracts.map((item) => {
                    const termObj = calculateTerm(
                        item,
                        item.contract_payment_delay
                            ? item.contract_payment_delay
                            : params.paymentDelay,
                        params.fees,
                        boolMoratorium,
                    );
                    item.payOut = item.contract_xnpv;
                    item.term = termObj.term;
                    item.term_numeric = termObj.term_numeric;
                    item.repayment_schedule = termObj.transactionsArr;
                    return item;
                });
            }
            dispatch({
                type: RESET_CONTRACT_LIST,
                isFiltering,
            });
            const dealObjOrderListing = {
                investorDealId: res.data.investorDealId,
                investorOrgId: res.data.investorOrgId,
            };
            dispatch({
                type: FETCH_CONTRACT_LIST,
                trade_contracts,
                is_fetching_trade_contracts: FETCH_STATUS.SUCCESS,
                trade_contracts_total_count: res.data.totalCount,
                approved_contract_count: res.data.approved_contract_count,
                ...(res.data.priorityInvestorLimit && {
                    priorityInvestorLimit: res.data.priorityInvestorLimit,
                }),
                investorOrgId: res.data.investorOrgId,
                dealObjOrderListing,
                ...(res.data.investorDealStatus && {
                    priorityInvestorDealStatus: res.data.investorDealStatus,
                }),
            });
        } else {
            dispatch({
                type: FETCHING_CONTRACT_LIST,
                is_fetching_trade_contracts: FETCH_STATUS.FAILURE,
            });
        }
    };
}

export const setTradeContracts = (trade_contracts) => {
    return (dispatch) => {
        dispatch({
            type: SET_TRADE_CONTRACTS,
            trade_contracts: trade_contracts,
        });
    };
};

export const setRepaymentSchedule = () => {
    return (dispatch) => {
        dispatch({
            type: SET_REPAYMENT_SCHEDULE,
            repayment_schedule: [],
        });
    };
};
// export function fetchFilteredTradebookContract(input,paymentDelay,company_irr,filters) {
//     return (dispatch) => {
//         dispatch({
//             type: FETCHING_TRADEBOOK_CONTRACT_LIST,
//             is_fetching_tradebook_contracts: true
//         });
//         return callApi(`contract/get-filtered-contract`, "post",
//             {
//                 "filters": filters,
//                 "pageNum":input.pageNum,
//                 "pageSize":input.pageSize,
//                 "sortBy": input.sortBy,
//             }
//         ).then((res) => {
//             let  tradebook_contracts =[];
//             if (res.status === SUCCESS) {
//                 if(res.data && res.data.contracts){
//                     tradebook_contracts = res.data.contracts.map(item =>{
//                     let termObj = calculateTerm(item, item.contract_payment_delay ? item.contract_payment_delay : paymentDelay)
//                     let xnpv = calculateXNPV(company_irr,termObj.transactionsArr,item.recurring_payment_value);
//                     item.payOut = xnpv;
//                     item.term = termObj.term;
//                     return item;
//                     })
//                 }
//                 dispatch({
//                     type: FETCH_TRADEBOOK_CONTRACT_LIST,
//                     tradebook_contracts: res.data.contracts,
//                     is_fetching_tradebook_contracts: false
//                 });
//             } else {
//                 dispatch({
//                     type: FETCHING_TRADEBOOK_CONTRACT_LIST,
//                     is_fetching_tradebook_contracts: false
//                 });
//             }
//         });
//     };
// }

export function fetchFilteredTradebookContract(input, isReseting = false) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_TRADEBOOK_CONTRACT_LIST,
            is_fetching_filtered_tradebook_contracts: true,
        });
        return callApi(`contract/get-filtered-trades`, 'post', input).then((res) => {
            if (res.status === SUCCESS) {
                if (res.data && res.data.listedTradedContracts) {
                    // tradebook_contracts = res.data.listedTradedContracts.map(item => {
                    //     let termObj = calculateTerm(item, item.contract_payment_delay ? item.contract_payment_delay : paymentDelay)
                    //     // let xnpv = calculateXNPV(company_irr,termObj.transactionsArr,item.recurring_payment_value);
                    //     item.payOut = item.contract_xnpv;
                    //     item.term = termObj.term;
                    //     item.repayment_schedule = termObj.transactionsArr
                    //     return item;
                    // })
                    dispatch({
                        type: FETCH_TRADEBOOK_CONTRACT_LIST,
                        tradebook_contracts: res.data.listedTradedContracts,
                        is_fetching_filtered_tradebook_contracts: false,
                        tradebook_contracts_total_count: res.data.totalCount,
                        tradebook_contracts_listed_count: res.data.listedCount,
                        isReseting,
                    });
                }
            } else {
                dispatch({
                    type: FETCHING_TRADEBOOK_CONTRACT_LIST,
                    is_fetching_filtered_tradebook_contracts: false,
                });
            }
        });
    };
}

export const fetchTradebookOrders = ({ investeeOrganizationId }) => {
    return (dispatch) => {
        try {
            dispatch({
                type: FETCHING_TRADEBOOK_ORDERS,
                data: {
                    isFetchingTradeOrders: true,
                },
            });
            return callApi(`contract/get-filtered-trades/v2`, 'post', {
                investee_organization_id: investeeOrganizationId,
            }).then((res) => {
                if (res.status === SUCCESS) {
                    if (res && !isEmpty(res.data)) {
                        dispatch({
                            type: FETCH_TRADEBOOK_ORDERS,
                            data: {
                                tradebookOrders: {
                                    list: res.data,
                                    id: investeeOrganizationId,
                                },
                                isFetchingTradeOrders: false,
                            },
                        });
                    } else showNotification('Orders', 'No Orders found');
                } else showNotification('Error', 'Some error occurred');
            });
        } catch (err) {
            captureMessagesViaErrorLogger(err.toString());
            showNotification('Error', 'Some error occurred');
        } finally {
            dispatch({
                type: FETCHING_TRADEBOOK_ORDERS,
                data: {
                    isFetchingTradeOrders: false,
                },
            });
        }
    };
};
export function fetchFilteredNotifications(input, isReseting = false) {
    return async (dispatch) => {
        dispatch({
            type: FETCHING_NOTIFICATIONS,
            is_fetching_notifications: true,
        });
        const res = await callApi(`notification/get-all-notifications`, 'post', input, {
            'request-id': 'bsdfgbgnbsg',
        });
        if (res?.status === 200) {
            if (res?.data?.notificationsList) {
                dispatch({
                    type: FETCH_NOTIFICATIONS,
                    notifications: res.data.notificationsList,
                    is_fetching_notifications: false,
                    notifications_total_count: res.data.totalCount,
                    unread_notifications_count: res.data.unreadNotificationsCount,
                    isReseting,
                });
            }
            return API_STATUS.SUCCESS;
        } else {
            dispatch({
                type: FETCHING_NOTIFICATIONS,
                is_fetching_notifications: false,
            });
            return INTERNAL_SERVER_ERROR;
        }
    };
}

export function fetchUnreadNotificationCount(email) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_UNREAD_NOTIFICATION_COUNT,
            is_fetching_unread_notifications_count: true,
        });
        return callApi(
            `notification/get-unread-notification-count`,
            'post',
            { email },
            { 'request-id': 'bsdfgbgnbsg' },
        ).then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: FETCH_UNREAD_NOTIFICATION_COUNT,
                    unread_notifications_count: res.data.unreadNotificationsCount,
                    is_fetching_unread_notifications_count: false,
                });
            } else {
                dispatch({
                    type: FETCHING_UNREAD_NOTIFICATION_COUNT,
                    is_fetching_unread_notifications_count: false,
                });
            }
        });
    };
}

export function fetchInvesteeTransactions(transactionType, input, isFiltering) {
    return (dispatch, getState) => {
        // let offset = -d.getTimezoneOffset();
        // input.offset = offset;
        const currentState = getState().investee;
        if (currentState.investeTransactionCancelToken) {
            currentState.investeTransactionCancelToken.cancel(
                'API call aborted due to new request',
            );
        }
        const cancelToken = axios.CancelToken.source();
        dispatch({
            type: SET_INVESTEE_TRANSACTIONS_CANCEL_TOKEN,
            cancelToken,
        });
        dispatch({
            type: FETCHING_INVESTEE_TRANSACTIONS,
            is_fetching_investee_transactions: true,
        });
        return callApiWithAxios({
            endpoint: `investeeorganization/finance/transaction-list/${transactionType?.toLowerCase()}`,
            method: 'post',
            body: input,
            cancelToken: cancelToken.token,
        })
            .then((res) => {
                if (res.status === SUCCESS) {
                    dispatch({
                        type: FETCH_INVESTEE_TRANSACTIONS,
                        investee_transactions:
                            transactionType === PAYOUTS
                                ? res.data.payoutTransactions
                                : res.data.aggregateOrderTrns,
                        investee_transactions_total_count:
                            transactionType === PAYOUTS
                                ? res.data.payoutTransactionsCount
                                : res.data.aggregateOrderTrnsCount,
                        is_fetching_investee_transactions: false,
                        transactionType,
                        isFiltering,
                    });
                } else {
                    dispatch({
                        type: FETCHING_INVESTEE_TRANSACTIONS,
                        is_fetching_investee_transactions: false,
                    });
                }
                dispatch({
                    type: SET_INVESTEE_TRANSACTIONS_CANCEL_TOKEN,
                    cancelToken: null,
                });
            })
            .catch((error) => {
                // This is for when api get cancelled
                console.log(error);
            });
    };
}

export function fetchInvesteeAccountStatement(
    investeeOrganizationId,
    body,
    isFirstRender,
    isReseting,
) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_INVESTEE_ACCOUNT_STATEMENT,
            is_fetching_account_statement: true,
        });
        callApi(
            `repayments/investee-organization/${investeeOrganizationId}/account-statements`,
            'post',
            body,
        ).then((res) => {
            if (res.status === SUCCESS && res.data) {
                dispatch({
                    type: FETCH_INVESTEE_ACCOUNT_STATEMENT,
                    is_fetching_account_statement: false,
                    investee_account_statement: res.data.banklist.data,
                    account_statement_total_records: res.data.banklist.total_records,
                    isReseting: isReseting,
                });
            } else {
                if (!isFirstRender && res.data?.message === 'user does not exist')
                    showNotification(ERROR, "You don't have Escrow Pay Account!");
                else !isFirstRender && showNotification(ERROR, 'Some error occurred');
                dispatch({
                    type: FETCHING_INVESTEE_ACCOUNT_STATEMENT,
                    is_fetching_account_statement: false,
                });
            }
        });
    };
}

export function updateAddOnData(data) {
    return (dispatch) => {
        dispatch({
            type: FETCH_ADD_ON_DATA,
            addOnData: data,
        });
    };
}

export function fetchAddOnData(investeeOrgId, addOnPath) {
    return async (dispatch) => {
        dispatch({
            type: FETCHING_ADD_ON_DATA,
            isFetchingAddOnData: true,
        });
        const data = await getMonthlyOnboardingStatus(investeeOrgId, addOnPath);
        dispatch({
            type: FETCH_ADD_ON_DATA,
            addOnData: data,
            isFetchingAddOnData: false,
        });
    };
}

export function fetchArrData(investee_organization_id) {
    return (dispatch) => {
        if (!investee_organization_id) return;
        dispatch({
            type: FETCHING_ARR_DATA,
            is_fetching_arr_data: true,
        });
        return callApi(`investeeorganization/${investee_organization_id}/get-arr-data`, 'get').then(
            (res) => {
                if (res.status === SUCCESS) {
                    dispatch({
                        type: FETCH_ARR_DATA,
                        arrData: res.data,
                        total_available_arr: res.data.total_available_arr,
                        available_limit: res.data.available_limit,
                        total_contracts_count: res.data.total_contracts_count,
                        tradable_contracts_count: res.data.tradable_contracts_count,
                        allowed_to_trade_contracts_count: res.data.allowed_to_trade_contracts_count,
                        company_price: res.data.company_price,
                        is_fetching_arr_data: false,
                    });
                } else {
                    dispatch({
                        type: FETCHING_ARR_DATA,
                        is_fetching_arr_data: false,
                    });
                }
            },
        );
    };
}

export function fetchTradeBookData(investee_organization_id) {
    return (dispatch) => {
        dispatch({
            type: FETCHING_TRADEBOOK_DATA,
            is_fetching_tradebook_data: true,
        });
        return callApi(
            `investeeorganization/${investee_organization_id}/get-tradebook-data`,
            'get',
        ).then((res) => {
            if (res.status === SUCCESS) {
                dispatch({
                    type: FETCH_TRADEBOOK_DATA,
                    listed_count: res.data.listed_count,
                    active_count: res.data.active_count,
                    expired_count: res.data.expired_count,
                    is_fetching_tradebook_data: false,
                });
            } else {
                dispatch({
                    type: FETCHING_TRADEBOOK_DATA,
                    is_fetching_tradebook_data: false,
                });
            }
        });
    };
}

export function markContractForListing(
    contract_ids,
    paymentDelay,
    to_confirm_for_listing,
    custom_tenure,
    investee_organization_id,
    listed_perc,
    partial_contract_id,
    payoutAmount,
) {
    return async (dispatch) => {
        dispatch({
            type: MARKING_CONTRACT_FOR_LISTING,
            is_marking_contract_for_listing: true,
        });
        const res = await callApi(`trade/mark-contract-listed`, 'post', {
            contract_ids,
            investee_organization_id,
            custom_tenure,
            listed_perc,
            partial_contract_id,
            requestedCapital: payoutAmount,
        });
        let listed_contracts = [];
        if (res.status === SUCCESS) {
            if (res.data && res.data.contracts) {
                listed_contracts = to_confirm_for_listing.map((item) => {
                    const termObj = calculateTerm(
                        item,
                        item?.contract_payment_delay ? item.contract_payment_delay : paymentDelay,
                    );
                    item.payOut = item.contract_xnpv;
                    item.term = termObj.term;
                    return item;
                });
            }
            dispatch({
                type: MARKED_CONTRACT_FOR_LISTING,
                contracts: listed_contracts,
                is_marking_contract_for_listing: false,
            });
            return SUCCESS;
        } else {
            dispatch({
                type: MARKING_CONTRACT_FOR_LISTING,
                is_marking_contract_for_listing: false,
            });
            return res?.data?.message;
        }
    };
}

export function delistContracts(contract_ids) {
    return (dispatch) => {
        dispatch({
            type: MARKING_CONTRACT_FOR_DELISTING,
            is_marking_contract_for_delisting: true,
        });
        return callApi(`trade/delist-contracts`, 'post', {
            contract_ids: contract_ids,
        }).then((res) => {
            var listed_contracts = [];
            if (res.status === SUCCESS) {
                showNotification(SUCCESS, 'Contract Delisted');
                dispatch({
                    type: MARKED_CONTRACT_FOR_DELISTING,
                    contracts: listed_contracts,
                    is_marking_contract_for_delisting: false,
                });
            } else {
                dispatch({
                    type: MARKING_CONTRACT_FOR_DELISTING,
                    is_marking_contract_for_delisting: false,
                });
            }
        });
    };
}

export function toggleMemberDataFetchSuccess() {
    return (dispatch) => {
        dispatch({
            type: FETCH_TEAM_MEMBERS_SUCCESS,
            is_member_data_fetched_success: false,
        });
    };
}

export function repaymentDrawerType(drawerType, filters) {
    return (dispatch) => {
        dispatch({
            type: REPAYMENT_DRAWER_TYPE,
            drawerType,
            filters: filters ?? [],
        });
    };
}

export function updateBankAccountCalled(isBankAccountClicked) {
    return (dispatch) => {
        dispatch({
            type: BANK_ACCOUNT_CLICKED_FROM_REVIEW_PAGE,
            payload: isBankAccountClicked,
        });
    };
}

export const verifyBankAccount = ({ orgId, ifscCode, accNumber, setLoading, index }) => {
    return (dispatch) => {
        setLoading(index ?? true);
        if (!ifscCode?.trim()) {
            showNotification('Error', 'IFSC code is Required');
        } else if (!ifscCode?.match(/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/)) {
            showNotification('Error', 'IFSC code format is not valid');
        }
        callApi(`/validateBankAccountDetails`, 'post', {
            orgId: orgId,
            accountNumber: accNumber,
            ifsc: ifscCode,
        }).then((res) => {
            setLoading(false);
            dispatch(fetchBankList(orgId, 'investee'));
            if (res?.status === 'Success' && res.data?.pennyDropVerificationStatus === 'success') {
                showNotification('Success', 'Bank Verified');
            } else if (res?.data?.pennyDropVerificationStatus == 'failure') {
                showNotification(
                    'Error',
                    'Verification failed! Please check Account details and try again!',
                );
            } else if (res?.data?.pennyDropVerificationStatus == 'pending') {
                showNotification('Error', 'Something went wrong! Please try again later');
            } else if (res?.data?.pennyDropVerificationStatus == 'not_exist_org_bank') {
                showNotification('Error', 'Bank Account does not exist in organization');
            } else {
                showNotification(
                    'Error',
                    'Not able to verify Bank Account ! please try after some',
                );
            }
        });
    };
};

export const fetchMobileUploadeSteps = (investeeOrgId) => {
    return async (dispatch) => {
        dispatch({
            type: FETCHING_MOBILE_UPLOAD_STEPS,
            isFetchingMobileUploadSteps: true,
        });
        const data = await fetchMobileUploadeStepsHelper({ investeeOrgId });
        dispatch({
            type: FETCH_MOBILE_UPLOAD_STEPS,
            mobileUploadSteps: data,
            isFetchingMobileUploadSteps: false,
        });
    };
};

// todo get filtered list api for tradebook page
// todo get filtered list api for trade
// todo add investee support request
// todo add investee stats
// todo add investee payments and payouts
