import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { UPLOAD_STAGE } from '../../utils/constants';
import { GstInvoicingChecks } from '../../utils/checks';
import styles from './Invoicing.module.scss';

import UploadTally from '../UploadTally';
import UploadCsv from '../UploadCsv';
import SmallMonthlyView from '../SmallMonthlyView/SmallMonthlyView';
import {
    handleIntegration,
    allIntegrations,
    INTEGRATION_LOGOS,
    INTEGRATION_TYPES,
} from '../../utils/integrations';
import { IS_SMALL_MONTHLY_VIEW_VISIBLE } from '../../../../../../enums/appEnums';

interface InvoicingProps {
    data: any;
    stage: string;
    files: FileData[];
    investeeOnboardedId: string;
    integration?: string;
    isReviewPage: boolean;
    path?: string | null;
    monthlyViewData?: any;
    isTallyConnector: boolean;
    updatingTallyConnector: boolean;
    fetchData?: Function;
    toggleTallyConnector: Function;
    fetchMonthlyViewData: Function;
    updateDataAttachments?: Function;
    onMetadataSuccess: Function;
    goToNextStep: Function;
    goToPreviousStep: Function;
}

export const INVOICING = 'invoicing';
export const UPLOAD_KEY = 'onboarding_stage_data_invoicing';
export const Upload_Manually_SVG =
    'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/upload-file.svg';

function Invoicing({
    data,
    files,
    stage,
    fetchMonthlyViewData,
    investeeOnboardedId,
    integration,
    isReviewPage,
    path,
    monthlyViewData,
    isTallyConnector,
    toggleTallyConnector,
    updatingTallyConnector,
    onMetadataSuccess,
    updateDataAttachments,
    goToNextStep,
    goToPreviousStep,
}: InvoicingProps) {
    const [hovered, setHovered] = useState(false);
    const [selected, setSelected] = useState<string | null>(null);
    const [csvFiles, setCsvFiles] = useState<FileData[]>([]);
    const [tallyFiles, setTallyFiles] = useState<FileData[]>([]);
    const [transition, setTransition] = useState('');

    const collapsibleMonthlyViewData = [
        {
            label: 'Invoicing',
            availableMonthsCount: monthlyViewData?.invoicing_complete_months,
            monthlyViewDates: monthlyViewData?.availableInvoiceMonthlyDates,
        },
    ];

    useEffect(() => {
        if (files && files?.length) {
            let csv_files: FileData[] = [],
                tally_files: FileData[] = [];
            let newFiles = JSON.parse(JSON.stringify(files));
            newFiles.forEach((file: FileData) => {
                if (file?.doc_name) {
                    const names = file?.doc_name.split('_');
                    const namesLength = names?.length;
                    file.doc_name = file?.doc_name.replace('_invoices', '');
                    if (names && namesLength >= 2) {
                        if (names[namesLength - 1].includes('tally')) {
                            file.doc_name = file?.doc_name.replace('_tally', '');
                            tally_files.push(file);
                        } else {
                            csv_files.push(file);
                        }
                        setCsvFiles(csv_files);
                        setTallyFiles(tally_files);
                    }
                }
            });
        } else {
            setTallyFiles([]);
            setCsvFiles([]);
        }
        //eslint-disable-next-line
    }, [files]);

    const integrationClick = (type: string) => {
        if (type === INTEGRATION_TYPES.TALLY) return selectOption(type);
        if (!!integration) return;
        handleIntegration(2, type, path === UPLOAD_STAGE.ONBOARDING);
    };

    const selectOption = (option: string) => {
        setTransition(option ? 'next' : 'previous');
        setTimeout(() => {
            setSelected((prev) => {
                if (prev === option) return null;
                else {
                    return option;
                }
            });
        }, 300);
    };

    const handleUpdateDocsWithErrors = (fileWithErrors: any, deletedDocId: string = '') => {
        updateDataAttachments && updateDataAttachments(INVOICING, fileWithErrors, deletedDocId);
        // setTimeout(() => {
        //   fetchMonthlyViewData();
        // }, 4000);
    };

    const LandingCard = () => (
        <div className={clsx({ [styles.Content]: true })}>
            {!isReviewPage ? (
                <div className={styles.Title}>
                    Invoicing Data
                    {GstInvoicingChecks.isInvoicingComplete(data, monthlyViewData, stage) && (
                        <img src="/assets/Done2.svg" alt="" style={{ width: '20px' }} />
                    )}
                </div>
            ) : null}
            <div className={styles.Subtext}>
                Connect your Company's billing manager. We request view only access.
            </div>

            <div className={styles.Options}>
                {allIntegrations.map((type, i) => (
                    <div
                        key={i}
                        className={clsx({
                            [styles.Option]: true,
                            [styles.Integrated]:
                                integration === type ||
                                (type === INTEGRATION_TYPES.TALLY && tallyFiles?.length),
                            [styles.Selected]: selected === type,
                            [styles.Disabled]:
                                type !== INTEGRATION_TYPES.TALLY &&
                                integration &&
                                integration !== type,
                        })}
                        onClick={() => integrationClick(type)}
                    >
                        <img src={INTEGRATION_LOGOS[type]} height="25px" alt="integ" />
                    </div>
                ))}
                <div
                    className={clsx({
                        [styles.Option]: true,
                        [styles.Integrated]: csvFiles?.length,
                        [styles.Selected]: selected === INTEGRATION_TYPES.CSV,
                    })}
                    onClick={() => {
                        selectOption(INTEGRATION_TYPES.CSV);
                    }}
                >
                    <img src={Upload_Manually_SVG} height="25px" alt="zoho" />
                    <div className={styles.uploadManually}>
                        <div className={styles.upload}>upload</div>
                        <div className={styles.upload}>manually</div>
                    </div>
                </div>
            </div>
        </div>
    );

    const getContentCard = () => {
        switch (selected) {
            case INTEGRATION_TYPES.CSV:
                return (
                    <UploadCsv
                        category={INVOICING}
                        keyName={UPLOAD_KEY}
                        files={csvFiles}
                        path={path ?? dayjs().format('YYYY-MM')}
                        stage={stage}
                        selectOption={selectOption}
                        onUploadDocSuccess={handleUpdateDocsWithErrors}
                        investeeOnboardedId={investeeOnboardedId}
                    />
                );
            case INTEGRATION_TYPES.TALLY:
                return (
                    <UploadTally
                        category={INVOICING}
                        keyName={UPLOAD_KEY}
                        onUploadDocSuccess={onMetadataSuccess}
                        files={tallyFiles}
                        path={path ?? dayjs().format('YYYY-MM')}
                        selectOption={selectOption}
                        investeeOnboardedId={investeeOnboardedId}
                        isTallyConnector={isTallyConnector}
                        updatingTallyConnector={updatingTallyConnector}
                        toggleTallyConnector={toggleTallyConnector}
                        isReviewPage={isReviewPage}
                    />
                );
            default:
                return <LandingCard />;
        }
    };
    return (
        <div className={'StepContainer'}>
            {!isReviewPage ? <div className={'Title'}>GST Data (2/2)</div> : null}
            <div
                className={clsx('Card', { ReviewCard: isReviewPage })}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <div
                    className={clsx({
                        [styles.Next]: transition === 'next',
                        [styles.Previous]: transition === 'previous',
                    })}
                >
                    {getContentCard()}
                </div>
            </div>
            {IS_SMALL_MONTHLY_VIEW_VISIBLE ? (
                <SmallMonthlyView
                    collapsibleMonthlyViewData={monthlyViewData ? collapsibleMonthlyViewData : []}
                    parentHovered={hovered && !isReviewPage}
                    isReview={isReviewPage}
                    category={INVOICING}
                />
            ) : (
                <div className={styles.commentSmallMonthlyView}></div>
            )}
        </div>
    );
}

export default Invoicing;
