import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { applyInterceptor } from './Interceptor';
import storage from '../util/storageService';
import {
    fetchInvesteeSidebarConfigTab,
    fetchInvesteeWidgetData,
    fetchUserProfile,
} from '../modules/App/AppActions';
import { isEnvProd } from '../util/utility';

declare global {
    interface Window {
        hj(method: string, ...args: any[]): void;
    }
}

function NavigateFunctionComponent() {
    let navigate = useNavigate();
    const [ran, setRan] = useState(false);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [summonZeusParams, setSummonZeusParams] = useState({});

    useEffect(() => {
        const orgId = searchParams.get('orgId');
        const cerberusToken = searchParams.get('cerberusToken');
        const userId = searchParams.get('userId');
        const type = searchParams.get('type');
        setSummonZeusParams({
            orgId,
            cerberusToken,
            userId,
        });
        if (orgId && cerberusToken && userId && type) {
            storage.set('user_id', userId);
            storage.set('org_id', orgId);
            storage.set('cerberus_token', cerberusToken);
            const resetParms = {
                user_id: '',
                org_id: '',
                cerberus_token: '',
                type: '',
            };
            setSearchParams(resetParms);
            if (isEnvProd) {
                if (
                    userId !== null &&
                    orgId !== null &&
                    window.hj &&
                    typeof window.hj === 'function'
                ) {
                    window.hj('identify', userId, {
                        Organization_id: orgId,
                    });
                }
            }
            if (type === 'INVESTEE') {
                storage.set('investee', 'INVESTEE');
                dispatch(fetchInvesteeSidebarConfigTab(userId));
                dispatch(fetchInvesteeWidgetData(userId, true));
                dispatch(fetchUserProfile(userId));
            } else storage.set('investor', 'INVESTOR');
        }
    }, [searchParams]);
    /* only run setup once */
    if (!ran) {
        applyInterceptor(axios, navigate, summonZeusParams);
        setRan(true);
    }
    return <></>;
}

export default NavigateFunctionComponent;
