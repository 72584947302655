import { getS3IconLink } from '../../util/utility';
import { ICON_NAMES } from '../../constants/assetLinks';
import styles from './BankStatementUploadWarning.module.scss';

interface BankStatementUploadWarningProps {
    textToDisplay: string;
}

export default function BankStatementUploadWarning({
    textToDisplay,
}: BankStatementUploadWarningProps) {
    return (
        <div className={styles.BankStatementInfo}>
            <img src={getS3IconLink(ICON_NAMES.INFO_YELLOW)} alt={ICON_NAMES.LIGHT_BULB_BLACK} />
            <p>
                <span>Note:</span> Please ensure that the uploaded bank statements correspond to the
                mentioned details: <span>{textToDisplay}</span>
            </p>
        </div>
    );
}
