import styles from '../ODFD/ODFD.module.scss';
import UploadCard from '../../../../utils/UploadCard/UploadCard';
import DragDropUpload from '../../../../../../../../../src/components/DragDropUpload';
import { getInvesteeOrganizationInfo } from '../../../../../../../App/AppReducer';
import { useSelector } from 'react-redux';
import { updateODFDData } from '../../../../../../../Investee/common/apiHelpers';
import { useEffect } from 'react';
export const isOD = 'isOD';
export const isFD = 'isFD';
export const isFDLien = 'isFDLien';
export const odAmount = 'odAmount';
export const fdAmount = 'fdAmount';
export const fdLienAmount = 'fdLienAmount';
export const ACCOUNT_NUMBER = 'account_number';
export const ONBOARDING = 'Onboarding';

function ODFD({
    bankDetails,
    setBankDetails,
    bankAccounts,
    data,
    getFilesForOD,
    category,
    getFilesForFD,
    onUploadODFDDocSuccess,
    mixpanelForOdFDChecked,
    onReviewPage,
    isNeedInfo,
    accountNumber,
    path,
    setHovered,
    index,
    hovered,
    setAccountNumberState,
    isFinbitBank,
    checkIfFileUploaded,
}: {
    bankDetails: any;
    setBankDetails?: any;
    bankAccounts?: any;
    data?: any;
    getFilesForOD?: any;
    getFilesForFD?: any;
    category?: any;
    onUploadODFDDocSuccess: any;
    mixpanelForOdFDChecked?: any;
    onReviewPage?: any;
    isNeedInfo?: any;
    accountNumber?: any;
    path: any;
    setHovered?: any;
    index?: any;
    hovered?: any;
    setAccountNumberState?: any;
    isFinbitBank?: any;
    checkIfFileUploaded?: any;
}) {
    const {
        OdfdContainer,
        OdfdDisabled,
        Odfd,
        OdOrFdContainer,
        DocumentText,
        Blue,
        FormDiv,
        FormInput,
        FormLabel,
    } = styles;

    useEffect(() => {
        if (accountNumber) {
            setBankDetails && setBankDetails(bankAccounts[accountNumber]);
            setAccountNumberState && setAccountNumberState(accountNumber);
        }
    }, []);

    const investeeOnboardingId = useSelector(
        (state) => getInvesteeOrganizationInfo(state).investee_onboarded_id,
    );

    const callApiToUpdateODFD = async (odfd_data: any, path: string) => {
        const response = await updateODFDData(odfd_data, path);
        return response;
    };

    /**
     * for the case when the user has not competed the bank details section
     * @returns
     */
    const isODFDDisabled = () => {
        let disabled = false;
        if (isNeedInfo || path !== 'Onboarding') {
            return disabled;
        }
        if (
            bankDetails &&
            !bankDetails?.bills_invoices_bank_transactions?.length &&
            !bankDetails?.finbox_entity_ids
        ) {
            disabled = true;
        }
        return disabled;
    };

    /**
     * immediately updates the value of the checkbox both locally and at the DB level
     * @param e
     * @param checkedFor
     */
    const handleCheckboxChange = async (e: any, checkedFor: string) => {
        let odfdData: any = {};
        odfdData.accountNumber = bankDetails?.account_number ?? accountNumber;
        odfdData.investeeOnboardedId = investeeOnboardingId;

        const bank_obj = { ...bankDetails };

        const { checked } = e.target;
        switch (checkedFor) {
            case isOD:
                mixpanelForOdFDChecked && mixpanelForOdFDChecked('OD');
                odfdData.isOD = checked;
                bank_obj.is_od = checked;
                break;
            case isFD:
                mixpanelForOdFDChecked && mixpanelForOdFDChecked('FD');
                odfdData.isFD = checked;
                bank_obj.is_fd = checked;
                break;
            case isFDLien:
                mixpanelForOdFDChecked && mixpanelForOdFDChecked('FDLien');
                odfdData.isFDLien = checked;
                bank_obj.is_fd_lien = checked;
                break;
        }
        if (setBankDetails) {
            setBankDetails({ ...bank_obj });
        } else {
            bankDetails = bank_obj;
        }
        if (setHovered) {
            hovered === false ? setHovered(index) : setHovered(false);
        }
        data.bank_account[bankDetails[ACCOUNT_NUMBER]] = {
            ...data.bank_account[bankDetails[ACCOUNT_NUMBER]],
            ...bank_obj,
        };
        const response = await callApiToUpdateODFD(odfdData, path);
    };

    /**
     *
     * @param amount changes data locally for amounts only
     * @param checkedFor
     */
    const handleAmountonChange = (amount: string, checkedFor: string) => {
        const bank_obj = { ...bankDetails };
        switch (checkedFor) {
            case odAmount:
                bank_obj.od_amount = amount;
                break;
            case fdAmount:
                bank_obj.fd_amount = amount;
                break;
            case fdLienAmount:
                bank_obj.fd_amount_lien = amount;
                break;
        }
        if (setBankDetails) {
            setBankDetails({ ...bank_obj });
        } else {
            bankDetails = bank_obj;
        }

        if (setHovered) {
            hovered === false ? setHovered(index) : setHovered(false);
        }
        data.bank_account[bankDetails[ACCOUNT_NUMBER]] = {
            ...data.bank_account[bankDetails[ACCOUNT_NUMBER]],
            ...bank_obj,
        };
    };

    /**
     * changes data at the DB level, only for amounts
     * @param amount
     * @param checkedFor
     */
    const handleAmountonBlur = (amount: string, checkedFor: string) => {
        let odfdData: any = {};
        odfdData.accountNumber = bankDetails?.account_number;
        odfdData.investeeOnboardedId = investeeOnboardingId;

        const bank_obj = { ...bankDetails };
        switch (checkedFor) {
            case odAmount:
                odfdData.odAmount = amount.length > 0 ? amount : '0';
                break;
            case fdAmount:
                odfdData.fdAmount = amount.length > 0 ? amount : '0';
                break;
            case fdLienAmount:
                odfdData.fdAmountLien = amount.length > 0 ? amount : '0';
                break;
        }
        if (setBankDetails) {
            setBankDetails({ ...bank_obj });
        } else {
            bankDetails = bank_obj;
        }
        if (data) {
            data.bank_account[bankDetails[ACCOUNT_NUMBER]] = {
                ...data.bank_account[bankDetails[ACCOUNT_NUMBER]],
                ...bank_obj,
            };
        }
        if (setHovered) {
            hovered === false ? setHovered(index) : setHovered(false);
        }

        const response = callApiToUpdateODFD(odfdData, path);
    };

    return (
        <div className={OdfdContainer}>
            <div className={isODFDDisabled() ? `${OdfdDisabled}` : `${Odfd}`}>
                <input
                    id="hasOD"
                    type="checkbox"
                    checked={bankDetails?.is_od} // vary it according to the api data and input
                    onChange={(e) => handleCheckboxChange(e, isOD)}
                    disabled={isODFDDisabled()}
                />
                <label htmlFor="hasOD" className="ml-2 mb-0">
                    Has Overdraft Facility (OD)
                </label>
            </div>
            {bankDetails?.is_od && (
                <div className={OdOrFdContainer}>
                    {/* <div className={FormDiv}>
                        <input
                            className={`${FormInput} bg-white`}
                            id="ODLimit"
                            type="number"
                            value={bankDetails?.od_amount}
                            onChange={(e) => handleAmountonChange(e.target.value, odAmount)}
                            onBlur={(e) => handleAmountonBlur(e.target.value, odAmount)}
                            required
                            disabled={isODFDDisabled()}
                        />
                        <label className={FormLabel} htmlFor="ODLimit">
                            Overdraft Limit (INR)
                        </label>
                    </div> */}
                    <span className={DocumentText}>Upload Sanction Letter.</span>
                    <UploadCard
                        mainOnboarding
                        category="OD"
                        keyName="od_supporting_document"
                        onSuccess={onUploadODFDDocSuccess}
                        files={getFilesForOD()}
                        needInfoCategory={category}
                        accountNumber={bankDetails[ACCOUNT_NUMBER]}
                        bankDetails={bankDetails}
                        bankData={bankDetails}
                        path={path}
                        isOnboarding={true}
                        onReviewPage={onReviewPage}
                        isMnemosyneFlow="true"
                        renderComponent={<DragDropUpload isBackgroudWhite="true" />}
                        truncateSmallerText={true}
                        disabled={isODFDDisabled()}
                    />
                </div>
            )}
            <div className={isODFDDisabled() ? `${OdfdDisabled}` : `${Odfd}`}>
                <input
                    id="hasFD"
                    type="checkbox"
                    checked={bankDetails?.is_fd} // vary it according to the api data and input
                    disabled={isODFDDisabled()}
                    onChange={(e) => handleCheckboxChange(e, isFD)}
                />
                <label htmlFor="hasFD" className="ml-2 mb-0">
                    Has Fixed Deposit (FD)
                </label>
            </div>
            {bankDetails?.is_fd && (
                <div className={OdOrFdContainer}>
                    <div className={FormDiv}>
                        <input
                            className={`${FormInput} bg-white mb-4`}
                            id="FDLimit"
                            type="number"
                            value={bankDetails?.fd_amount}
                            onChange={(e) => handleAmountonChange(e.target.value, fdAmount)}
                            onBlur={(e) => handleAmountonBlur(e.target.value, fdAmount)}
                            required
                            disabled={isODFDDisabled()}
                        />
                        <br />
                        <label className={FormLabel} htmlFor="FDLimit">
                            Fixed Deposit Amount (INR)
                        </label>
                    </div>
                    <div className={`${Odfd}  mr-0 mb-4`} style={{ height: '1rem' }}>
                        <input
                            id="hasFDLien"
                            type="checkbox"
                            checked={bankDetails?.is_fd_lien} // vary it according to the api data and input
                            onChange={(e) => handleCheckboxChange(e, isFDLien)}
                            disabled={isODFDDisabled()}
                        />

                        <label htmlFor="hasFDLien" className="ml-2 mb-0" style={{ width: '60%' }}>
                            Any FD amount used as a security/lien?
                        </label>
                        {bankDetails?.is_fd_lien && (
                            <div className={`${FormDiv} ml-4`}>
                                <input
                                    className={`${FormInput} mb-0 bg-white`}
                                    id="lienAmount"
                                    type="text"
                                    value={bankDetails?.fd_amount_lien}
                                    onChange={(e) =>
                                        handleAmountonChange(e.target.value, fdLienAmount)
                                    }
                                    onBlur={(e) => handleAmountonBlur(e.target.value, fdLienAmount)}
                                    required
                                    disabled={isODFDDisabled()}
                                />
                                <br />
                                <label className={FormLabel} htmlFor="lienAmount">
                                    Lien Amount (INR)
                                </label>
                            </div>
                        )}
                    </div>
                    <div>
                        <span className={DocumentText}>
                            Upload Fixed Deposit receipt.
                            <a
                                href="https://help.recur.club/en/article/how-to-upload-data"
                                target="_blank"
                                rel="noreferrer"
                                className={`${Blue} ml-2`}
                            >
                                Learn More
                            </a>
                        </span>
                        <UploadCard
                            mainOnboarding
                            category="FD"
                            keyName="fd_supporting_document"
                            onSuccess={onUploadODFDDocSuccess}
                            files={getFilesForFD()}
                            accountNumber={bankDetails[ACCOUNT_NUMBER]}
                            bankDetails={bankDetails}
                            bankData={bankDetails}
                            path={path}
                            isOnboarding={true}
                            needInfoCategory={category}
                            onReviewPage={onReviewPage}
                            investee_onboarded_id={investeeOnboardingId}
                            isMnemosyneFlow="true"
                            renderComponent={<DragDropUpload isBackgroudWhite="true" />}
                            truncateSmallerText={true}
                            disabled={isODFDDisabled()}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ODFD;
