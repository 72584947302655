import { PRODUCTION } from '../enums/appEnums';
import axios from 'axios';
import { captureMessagesViaErrorLogger } from './errorLogger';
import { showNotification } from './utility';
import storage from './storageService';
export const UW_API = process.env.REACT_APP_UNDERWRITING_URL;
export const API_URL = process.env.REACT_APP_API_URL;
//export const API_URL = 'http://localhost:8070';
export const CERBERUS_URL = process.env.REACT_APP_CERBERUS_API_URL;
// export const CERBERUS_URL = 'https://apidev4.recur.club';
export const WEBUTILS_URL = process.env.REACT_APP_WEBUTILS_API_URL;
export const OLYMPUS_URL = `${process.env.REACT_APP_OLYMPUS_API_URL}/rest/`;
// export const OLYMPUS_URL = `http://localhost:8083/olympus/rest/`;

export default function callApi(
    endpoint,
    method = 'get',
    body,
    argHeaders = {},
    isCerberus = false,
    isWebUtils = false,
    isLambda = false,
) {
    const authInstance = window?.gapi?.auth2?.getAuthInstance();
    let userId = window.localStorage.getItem('recur_user_id');
    let orgId = window.localStorage.getItem('recur_org_id');
    let cerberusToken = window.localStorage.getItem('recur_cerberus_token') ?? '';
    let headers = { ...argHeaders };
    headers['content-type'] = 'application/json';
    if (cerberusToken && cerberusToken !== '') {
        //@todo: remove the token checks after the backend is done
        if (userId && userId !== '') {
            headers['x-user-id'] = userId;
        }
        if (orgId && orgId !== '') {
            headers['x-organization-id'] = orgId;
        }
        headers['x-cerberus-token'] = cerberusToken;
    }

    const overallEndpoint = isLambda
        ? endpoint
        : isCerberus
        ? `${CERBERUS_URL}/cerberus/rest${endpoint}`
        : isWebUtils
        ? `${WEBUTILS_URL}/utilities/rest${endpoint}`
        : `${API_URL}/api/${endpoint}`;
    const requestObj = {};
    requestObj.headers = headers;
    requestObj.method = method;
    if (method !== 'get' && body) {
        requestObj.body = JSON.stringify(body);
    }
    return fetch(overallEndpoint, requestObj)
        .then((response) => response.json().then((json) => ({ json, response })))
        .then(({ json, response }) => {
            let responseStatus = parseInt(response.status);
            if (cerberusToken && responseStatus === 401) {
                showNotification('Error', 'Session Timed Out');
                if (!authInstance) {
                    storage.flush();
                    window.location.reload(false);
                } else {
                    authInstance.signOut().then(() => {
                        storage.flush();
                        window.location.reload(false);
                    });
                }
            }
            if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
                if (responseStatus >= 400) {
                    if (responseStatus !== 404 || responseStatus !== 405) {
                        if (responseStatus < 500) {
                            if (json.message) {
                                captureMessagesViaErrorLogger(json.message);
                            } else if (
                                json &&
                                json.non_field_errors &&
                                json.non_field_errors.length > 0
                            ) {
                                captureMessagesViaErrorLogger(json.non_field_errors[0]);
                            } else {
                                captureMessagesViaErrorLogger(JSON.stringify(json));
                            }
                        } else {
                            if (json.status === 'Error') {
                                if (json.message) {
                                    captureMessagesViaErrorLogger(json.message);
                                }
                                if (json.data && json.data.message) {
                                    captureMessagesViaErrorLogger(json.data.message);
                                }
                            }
                        }
                    }
                }
            }
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            (response) => response,
            (error) => error,
        );
}

// export  function callApiCookies() {

//         var config = {
//           method: 'get',
//           url: 'https://d1sug8xp8srvmc.cloudfront.net/TestingPvtLtd-abc-T21228000010.pdf',
//           withCredentials:true,
//           responseType: 'arraybuffer',
//           headers: {
//               'Accept': 'application/pdf',
//               "origin":"https://appdev.recur.club",
//               "Access-Control-Allow-Origin": "https://appdev.recur.club",
//               "Access-Control-Allow-Methods": "GET,PUT,POST",

//           "Cookie": `CloudFront-Key-Pair-Id=${storage.getCookie('CloudFront-Key-Pair-Id')}; CloudFront-Policy=${storage.getCookie('CloudFront-Policy')}; CloudFront-Signature=${storage.getCookie('CloudFront-Signature')}`
//           }
//         };

//         axios(config)
//         .then(function (response) {
//            console.log(response)

//         })
//         .catch(function (error) {
//           console.log(error);
//         //   alert(error)
//         });

// }

export async function callApiWithAxios({
    endpoint,
    method = 'get',
    body,
    argHeaders = {},
    isCerberus = false,
    isWebUtils = false,
    isLambda = false,
    cancelToken = null,
}) {
    const authInstance = window?.gapi?.auth2?.getAuthInstance();
    let userId = window.localStorage.getItem('recur_user_id');
    let orgId = window.localStorage.getItem('recur_org_id');
    let cerberusToken = window.localStorage.getItem('recur_cerberus_token') ?? '';
    let headers = { ...argHeaders };
    headers['content-type'] = 'application/json';
    if (cerberusToken && cerberusToken !== '') {
        //@todo: remove the token checks after the backend is done
        if (userId && userId !== '') {
            headers['x-user-id'] = userId;
        }
        if (orgId && orgId !== '') {
            headers['x-organization-id'] = orgId;
        }
        headers['x-cerberus-token'] = cerberusToken;
    }

    const overallEndpoint = isLambda
        ? endpoint
        : isCerberus
        ? `${CERBERUS_URL}/cerberus/rest${endpoint}`
        : isWebUtils
        ? `${WEBUTILS_URL}/utilities/rest${endpoint}`
        : `${API_URL}/api/${endpoint}`;
    const requestConfig = {
        headers: headers,
        method: method,
        url: overallEndpoint,
        data: body,
        cancelToken: cancelToken, // Pass the cancel token to Axios request config
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        let responseStatus = error.response?.status || 0;
        if (cerberusToken && responseStatus === 401) {
            showNotification('Error', 'Session Timed Out');
            if (!authInstance) {
                storage.flush();
                window.location.reload(false);
            } else {
                authInstance.signOut().then(() => {
                    storage.flush();
                    window.location.reload(false);
                });
            }
        }
        if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
            if (responseStatus >= 400) {
                if (responseStatus !== 404 || responseStatus !== 405) {
                    if (responseStatus < 500) {
                        const json = error.response?.data;
                        if (json.message) {
                            captureMessagesViaErrorLogger(json.message);
                        } else if (
                            json &&
                            json.non_field_errors &&
                            json.non_field_errors.length > 0
                        ) {
                            captureMessagesViaErrorLogger(json.non_field_errors[0]);
                        } else {
                            captureMessagesViaErrorLogger(JSON.stringify(json));
                        }
                    } else {
                        if (error.response?.data?.status === 'Error') {
                            const errorMessage = error.response?.data?.message || '';
                            captureMessagesViaErrorLogger(errorMessage);
                        }
                    }
                }
            }
        }
        return await Promise.reject(error);
    }
}
