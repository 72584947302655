export const USER_TYPE_INVESTEE = 'investee';
export const USER_TYPE_INVESTOR = 'investor';
export const BASE_PATH_INVESTEE_APPROVED_INACTIVE = 'company/get-started';
export const BASE_PATH_INVESTEE_APPROVED_ACTIVE = 'company/dashboard';
export const BASE_PATH_INVESTEE_NOT_APPROVED = 'company/onboarding';
export const TIME_ENDS = '00:00:00';
export const BASE_PATH_INVESTOR = 'investor/dashboard';
export const MIN_TERM_ALLOWED = 0;
export const MAX_TERM_ALLOWED = 24;
export const INVESTOR_FEE_NBFC_ADVANCE_PAYOUT = 'INVESTOR_FEE_NBFC_ADVANCE_PAYOUT';
export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ROUTE = {
    GOOGLE: 'google',
    OTP: 'otp',
    NORMAL: 'normal',
};

export const ALLOWED_INVITABLE_ROLES_FOR_DS = {
    VIEW_ONLY: 'View Only',
    VIEW_DEAL_DOCUMENTS: 'View Deal Documents',
    ACCEPT_DEAL: 'Accept Deal',
    TEAM_MANAGER: 'Team Manager',
    DEAL_SPECIFIC_ACCESS: 'Deal Specific Access',
};
export const PAYMENT_STATUS = {
    SCHEDULED: 'Scheduled',
    COMPLETE: 'Complete',
    DELAYED: 'Delayed',
    PAID_DELAYED: 'PaidDelayed',
    PARTIALLY_PAID: 'Partially Paid',
    PARTIALLY_RECEIVED: 'Partially Received',
    OVERDUE: 'Overdue',
    PAID: 'Paid',
};

export const DEFAULT_FILTERS = {
    dateObj: {
        keyName: 'repaymentDate',
        date: ['', ''],
        status: false,
    },
    companyNameObj: {
        keyName: 'companies',
        value: '',
        status: false,
    },
    sliderObjPayout: {
        minVal: 0,
        maxVal: 300000000,
        currentMax: 300000000,
        currentMin: 0,
        keyName: 'repayment',
        status: false,
    },
    selectorObjStatus: {
        keyName: 'status',
        value: [
            {
                label: 'Scheduled',
                selected: true,
            },
            {
                label: 'Complete',
                selected: true,
            },
            {
                label: 'Overdue',
                selected: true,
            },
            {
                label: 'Partially Paid',
                selected: true,
            },
        ],
        status: false,
    },
    sortObj: {
        keyName: '',
        sortBy: 0,
    },
};
export const RECUR_RATING_VALUE = {
    C: 20,
    CC: 30,
    CCC: 40,
    B: 50,
    BB: 60,
    BBB: 70,
    A: 80,
    AA: 90,
    AAA: 100,
};
export const RECUR_RATING_OPTIONS = [
    'Unrated',
    'C',
    'CC',
    'CCC',
    'B',
    'BB',
    'BBB',
    'A',
    'AA',
    'AAA',
];
export const RECUR_RATINGS = {
    10: 'Unrated',
    20: 'C',
    30: 'CC',
    40: 'CCC',
    50: 'B',
    60: 'BB',
    70: 'BBB',
    80: 'A',
    90: 'AA',
    100: 'AAA',
};

export const FUNDING_STAGE = {
    10: 'Bootstrapped',
    20: 'Angel',
    30: 'Seed',
    40: 'Series A',
    50: 'Series B',
    60: 'Series C',
    70: 'Series D',
    80: 'Series E',
    100: 'Listed',
};

export const WAITLIST_INVESTOR_TYPES = ['HNI', 'Others'];

export const INVESTEE_ORG_ACTIVE = 'Active';
export const INVESTEE_ORG_LOCKED = 'Locked';
export const INVESTEE_ORG_INACTIVE = 'Inactive';
export const INVESTEE_ORG_UNCLAIMED = 'Unclaimed';
export const MARKET_VIEW_ALL = 'All';
export const MARKET_VIEW_LISTED = 'Only Listed';

// Recur Checout Enums
export const PAY_NOW = 'Pay now';
export const PAY_VIA_BANK = 'Pay via bank';

// Integer consts
export const LAKH = 1_00_000;
export const CRORE = 1_00_00_000;

// API Status
export const API_STATUS = {
    SUCCESS: 'Success',
    ERROR: 'Error',
    MNEMOSYNE_SUCCESS: 'OK',
};

export const MET = 'Met';
export const NOT_MET = 'Not Met';
export const EXPIRED = 'Expired';
export const RCM = 'RCM';

export const ENUM_RCM_STATUS = {
    [NOT_MET]: 'not_met',
    [MET]: 'met',
};

export const FETCH_STATUS = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
};

//AddOn Options
export const VALID_ADD_ON_VALUES = {
    immediately: 'Immediately',
    oneMonth: '1 month',
    threeMonth: '3 months',
    sixMonths: '6 months',
};

// account holder type
export const ACCOUNT_HOLDER_TYPE = 'Self';
export const ADD_ON_LIMIT_CONST = 'ADD_ON_LIMIT';

// User Roles
export const ADMIN = 'Admin';
export const SUMMON_READ_ZEUS = 'Summon Read Zeus';

export const INVESTOR_LOGIN_ROUTE = '/investor/login';
export const CONTACT_US_ROUTE = '/company/help';
// Generic Error
export const GENERIC_ERROR = 'Some error occurred!';

// Default Data Specialist ID incase data specialist is not assigned
export const DEFAULT_DATA_SPECIALIST_ID = '248343497';

// ShowNotification Statuses
export const SHOW_NOTIFICATION_STATUS = {
    SUCCESS: 'Success',
    ERROR: 'Error',
    WARNING: 'Warning',
};
export const PRODUCTION = 'production';

export const INVESTOR_TO_COMPANY = 'INVESTOR_TO_COMPANY';
export const COMPANY_TO_INVESTOR = 'COMPANY_TO_INVESTOR';

export const DECENTRO_RESPONSE = 'decentro_response';
export const CONTACT_NAME = 'contact_name';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const DESIGNATION = 'designation';
export const CONTACT_EMAIL = 'contact_email';
export const DRAWDOWN_LETTER = 'drawdown_letter';
export const DD_CALC_SHEET = 'dd_calc_sheet';
export const DISBURSEMENT_SHEET = 'disbursement_sheet';
export const USER_SEGMENT_TYPE = {
    [USER_TYPE_INVESTEE]: 'INVESTEE',
    [USER_TYPE_INVESTOR]: 'INVESTOR',
};
export const CERBERUS_STATUS_CODES = {
    SUCCESS: 20,
    FAILURE: 40,
};
export const DAYJS_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
export const GRAPH_ENUMS = {
    ALL_TIME: 'all_time',
    FYTD: 'FYTD',
    CYTD: 'CYTD',
    MONTHS: 'Month',
    CAPITAL_INVESTED: 'CapInvested',
    NUM_COMPANUES: 'NumCompanies',
};

export const ACTIVE_DEALS_PAGE_NAME = 'Portfolio';

export const FLOW_ID = {
    KYC: 1,
    UW_QUERIES: 2,
    TRANSACTION_DOCUMENTS: 4,
    ONBOARDING: 26,
    ADD_ON_LIMIT: 27,
};

export const ADDITIONAL_DEAL_DOC_CATEGORY_ID = 13;

export const DOC_TYPE_NAMES = {
    COI: 'COI',
    COMPANY_PAN: 'Company PAN',
    MOA: 'MOA',
    AOA: 'AOA',
    COMPANY_KYC_SHP: 'Company KYC SHP',
    GST: 'GST Certificate',
    UTILITY_BILL: 'Utility Bill',
    RENT_AGREEMENT: 'Rent Agreement',
    INDIVIDUAL_AUTHSIGN_PAN: 'Individual PAN AuthSig',
    INDIVIDUAL_AUTHSIGN_IDENTITY: 'Individual Identity AuthSig',
    INDIVIDUAL_INDIAN_PAN: 'Individual PAN Indian',
    INDIVIDUAL_INDIAN_IDENTITY: 'Individual Passport Indian',
    INDIVIDUAL_FOREIGN_PAN: 'Individual PAN Foreign',
    INDIVIDUAL_FOREIGN_IDENTITY: 'Individual Passport Foreign',
    INSTITUTIONAL_INDIAN_COI: 'Institutional COI Indian',
    INSTITUTIONAL_INDIAN_PAN: 'Institutional PAN Indian',
    INSTITUTIONAL_INDIAN_GST: 'Institutional GST Indian',
    INSTITUTIONAL_FOREIGN_REG_CERT: 'Institutional Reg Cert Foreign',
    FORM16A: 'Form 16A',
    BR: 'BR',
    LOD: 'LOD',
    SHP: 'SHP',
    ADDITIONAL_DOC: 'Additional Doc',
    SIGN_PAN_COBORROWER: 'sign_pan',
    OFFICIAL_ADDRESS_PROOF_COBORROWER: 'official_address_proof',
    ADDITIONAL_DOCUMENTS_COBORROWER: 'additional_documents',
    INDIVIDUAL_IDENTITY_AUTHSIGN_BACK: 'Individual Identity AuthSign Back',
};

export const ADD_ON_LIMIT_STATUS = {
    EXISTING_UPLOAD_DATA: 'EXISTING_UPLOAD_DATA',
    ALLOWED_TO_REQUEST: 'ALLOWED_TO_REQUEST',
    VIEW_STATUS: 'VIEW_STATUS',
    UTILIZATION_LTE_ZERO: 'UTILIZATION_LTE_ZERO',

    APPROVED: 'APPROVED',
    UNDER_REVIEW: 'UNDER REVIEW',
    NEED_DATA_INFO: 'NEED DATA INFO',
    UPLOAD_DATA: 'UPLOAD DATA',
    UPLOAD_KYC: 'UPLOAD KYC',
    BIDDING_ONGOING: 'BIDDING ONGOING',
    ACCEPT_TERMS: 'ACCEPT TERMS',
    SIGN_SANCTION: 'SIGN SANCTION',
    SETUP_COLLECTIONS: 'SETUP COLLECTIONS',
    NO_BIDS: 'NO BIDS',
    EXPIRED: 'EXPIRED',
};

export const ONBOARDING_STATUS = {
    OPEN: 'Open',
    SUBMITTED: 'Submitted',
    EXPIRED: 'Expired',
    WAITLISTED: 'Waitlisted',
    NEED_INFORMATION: 'Need Information',
    REJECTED: 'Not Approved',
    APPROVED: 'Approved',
    SPARK_APPROVED: 'Spark Approved',
    NEED_INFORMATION_OPTIONAL: 'Need Information Optional',
};

export const NEED_INFORMATION_STATUS_ARRAY = [
    ONBOARDING_STATUS.NEED_INFORMATION,
    ONBOARDING_STATUS.NEED_INFORMATION_OPTIONAL,
];
export const INVESTOR_DEAL_STATUS = {
    OPEN: 'open',
    SENT_FOR_SIGNING: 'sent_for_signing',
    SIGNING: 'signing',
    SIGNED: 'signed',
    FAILED: 'failed',
};
export const BASE_TOT_STATUS = {
    SENT: 'Sent',
    UPDATED_NOT_SENT: 'Updated Not Sent',
    ACCEPTED: 'Accepted',
};

export const COLLECTION_METHODS = {
    NACH_WEEKLY: 'nachWeekly',
    NACH_FORTNIGHTLY: 'nachFortnightly',
    RAZORPAY_SPLIT: 'razorpaySplit',
    CASHFREE_SPLIT: 'cashfreeSplit',
    DIRECT_COLLECTION: 'directCollection',
    COBORROWER: 'Coborrower',
    MAB: 'MAB',
    AMAZON: 'Amazon',
    SWIGGY: 'Swiggy',
    ZOMATO: 'Zomato',
    SHIPROCKET: 'Shiprocket',
    FLIPKART: 'Flipkart',
    EASEBUZZ: 'Easebuzz',
    NYKAA: 'Nykaa',
    SNAPDEAL: 'Snapdeal',
    PAYTM: 'Paytm',
};

export const {
    NACH_WEEKLY,
    NACH_FORTNIGHTLY,
    RAZORPAY_SPLIT,
    CASHFREE_SPLIT,
    DIRECT_COLLECTION,
    COBORROWER,
    AMAZON,
    SWIGGY,
    ZOMATO,
    SHIPROCKET,
    FLIPKART,
    EASEBUZZ,
    NYKAA,
    SNAPDEAL,
    PAYTM,
} = COLLECTION_METHODS;

export const VALID_PAYMENT_MODES = [
    NACH_WEEKLY,
    NACH_FORTNIGHTLY,
    RAZORPAY_SPLIT,
    CASHFREE_SPLIT,
    DIRECT_COLLECTION,
    AMAZON,
    SWIGGY,
    ZOMATO,
    SHIPROCKET,
    FLIPKART,
    EASEBUZZ,
    NYKAA,
    SNAPDEAL,
    PAYTM,
];

export const AUTO_WITHDRAW_COVER_MEHTODS = [
    COLLECTION_METHODS.AMAZON,
    COLLECTION_METHODS.SWIGGY,
    COLLECTION_METHODS.ZOMATO,
    COLLECTION_METHODS.SHIPROCKET,
    COLLECTION_METHODS.FLIPKART,
    COLLECTION_METHODS.EASEBUZZ,
    COLLECTION_METHODS.CASHFREE_SPLIT,
    COLLECTION_METHODS.NYKAA,
    COLLECTION_METHODS.SNAPDEAL,
    COLLECTION_METHODS.PAYTM,
];
export const DEAL_TYPES = {
    NEW_DEALS: 'New Deals',
    EVALUATED_DEALS: 'Evaluated Deals',
    PENDING_DRAWDOWN: 'Pending Drawdown',
    PENDING_VERIFICATION_DEALS: 'Pending Verification',
    COMPLETED_VERIFICATION_DEALS: 'Completed Verification',
};
export const TAB_TYPES = {
    DEAL_HIGHLIGHTS: 'Deal Highlights',
    DOCUMENTS: 'Documents',
    INVESTMENT_DETAILS: 'Investment Details',
    COMPANY_OVERVIEW: 'Company Overview',
};

export const EXPORT_CSV = {
    PENDING_VERIFICATION: 'pending_verification',
    COMPLETED_VERIFICATION: 'completed_verification',
    NEW_DEALS: 'new_deals',
    EVALUATED_DEALS: 'evaluated_deals',
};

export const ZEUS = {
    INVESTOR: 'zeus investor',
};

export const INVESTOR_DEAL_INACTIVE = 'Inactive';

export const DOC_NAMES = {
    company_kyc: 'Company KYC Documents',
    company_authorisation: 'Company Authorisation',
    beneficiary: 'Beneficial Owner(s) KYC',
    mfa_letter: 'MFA & Sanction Letter',
    additional_documents: 'Additional Documents (if any)',
    coborrowers: 'Co-borrower(s) KYC',
    drawdown: 'Drawdown',
};
export const ZERO_AMOUNT = '₹ 0';
export const ZERO_AMOUNT_WITH_TWO_DECIMAL = '₹ 0.00';

export const ROUND_OFF = {
    UP: 'UP',
    DOWN: 'DOWN',
};
export const INVESTOR_TABS = {
    ACTIVE_DEALS: 'Active Deals',
    COMPLETED_DEALS: 'Completed Deals',
};
export const WIDGETS = {
    WIDGET: 'Widget',
    APPLICATION_WIDGET: 'Application Widget',
    ADD_ON_LIMIT_WIDGET: 'Add On Limit Widget',
};
export const ADD_ON_LIMIT = 'ADD ON LIMIT';
export const LIMIT_DOCUMENTS = 'LIMIT DOCUMENTS';

export const DOC_VERIFICATION = 'DOC_VERIFICATION';
export const signed = 'signed';

export const INVITE_NEW_USER = 'inviteNewUser';

export const investor_mfa_status = {
    open: 'open',
    investorSentForSigning: 'investor_sent_for_signing',
    investorSigning: 'investor_signing',
    investorSigned: 'investor_signed',
    failed: 'failed',
};
export const TXT = 'txt';
export const DOCX = 'docx';
export const DONE = 'done';
export const NO_DATA_AVAILABLE = 'No Data Available';
export const STATUS_OPTIONS_ENUM = {
    APPROVED: 'APPROVED',
    NEED_UPDATE: 'NEED_UPDATE',
    NEW_REQUEST: 'NEW_REQUEST',
};

export const INVESTOR_STATUS_NAME_ENUM = {
    NEW_REQUEST: 'NEW REQUEST',
    NEED_UPDATE: 'NEED UPDATE',
    APPROVED: 'APPROVED',
};

export const INVESTOR_OVERVIEW_CARDS = {
    TOTAL_APPROVED: 'Total Approved',
    TOTAL_SANCTIONED: 'Total Sanctioned',
    TOTAL_DISBURSED: 'Total Disbursed',
    MONTHLY_DISBURSEMENT: 'Monthly Disbursement',
    REPAYMENT_HISTORY: 'Repayment History',
    DAYS_PAST_DUES: 'Days Past Dues',
};
export const PORTFOLIO_OVERVIEW_CARDS = {
    CAPITAL__INVESTED: 'Capital Invested',
    TENURE_: 'Tenure',
    RECUR__GRADE: 'Recur Grade (Score)',
    SIZE_ARR: 'Size (ARR)',
    VINTAGE_YEARS: 'Vintage (Years)',
    INDUSTRY: 'Industry',
};

export const MIX_PANEL_EVENTS = {
    DEALS_REDIRECTION_INVESTOR_DASHBOARD: 'Deals redirection Investor Dashboard',
    CARD_CLICK_INVESTOR_DASHBOARD: 'Card Click Investor Dashboard',
    GRAPH_HOVER_INVESTOR_DASHBOARD: 'Graph Hover Investor Dashboard',

    PORTFOLIO_OVERVIEW_BY_CAPITAL_INVESTED: 'Portfolio Overview by Capital Invested',
    PORTFOLIO_OVERVIEW_BY_NUMBER_OF_COMPANIES: 'Portfolio Overview by Number of Companies',
    CARD_CLICK_PORTFOLIO_OVERVIEW: 'Card Click Portfolio Overview',
    GRAPH_HOVER_PORTFOLIO_OVERVIEW: 'Graph Hover Portfolio Overview',

    INVESTMENT_REPORT_DOWNLOAD: 'Investment Report Download',
    APPROVED_DEAL: 'Approved Deal',
    PASSED_DEAL: 'Passed Deal',
    QUERIES_PER_DEAL: 'Queries per Deal',

    VIEW_AICA_REPORT: 'RecurInvestor_ViewAICA',
};
export const NO_BID_ADDON_DESCRIPTION = 'No investor bids. Unlocks after next repayment.';
export const NOT_FOUND = 'Not Found';
export const DEAL_MANAGER = 'Deal Manager';
export const DEAL_UNDERWRITER = 'Deal Manager';
export const INVESTOR_RELATIONS = 'Investor Relations';

export const RECUR_PRODUCTS = {
    RECUR_SWIFT: 'RECUR_SWIFT',
    RECUR_SCALE: 'RECUR_SCALE',
};

export const productActionEnum = {
    UPLOAD_KYC: 'UPLOAD_KYC',
    REVIEW_TERMS: 'REVIEW_TERMS',
    REVIEW_OFFER: 'REVIEW_OFFER',
    VIEW_OFFER: 'VIEW_OFFER',
    LEARN_MORE: 'LEARN_MORE',
    VIEW_TERMS: 'VIEW_TERMS',
};

export const productActionBtnName = {
    [productActionEnum.UPLOAD_KYC]: 'Upload KYC',
    [productActionEnum.REVIEW_TERMS]: 'Review Terms',
    [productActionEnum.VIEW_TERMS]: 'View Terms',
    [productActionEnum.LEARN_MORE]: 'Learn More',
};

export const BASE_TOT = 'baseTot';
export const STAGE_TOTS = 'stageTots';
export const TOT_STATUS = 'tot_status';
export const ACCEPTED_IDENTIFIER = 'acceptedStageTotIdentifier';
export const CAPITAL_LIMIT = 'capitalLimit';
export const DEFAULT_FILTERS_INVESTEE_PAYMENTS = {
    selectorObjStatus: {
        keyName: 'status',
        value: [
            {
                selected: true,
                label: PAYMENT_STATUS.PAID,
            },
            {
                selected: true,
                label: PAYMENT_STATUS.DELAYED,
            },
            {
                selected: true,
                label: PAYMENT_STATUS.SCHEDULED,
            },
            {
                selected: true,
                label: PAYMENT_STATUS.PAID_DELAYED,
            },
        ],
        status: false,
    },
};

export const queryTypes = {
    UW_QUERIES: 'uw_queries',
    KYC_QUERIES: 'kyc_queries',
};
export const AUDIT_HANDLER_URI = {
    INDICATIVE_TERMS: 'deal/save-indicative-terms',
    TRANSACTION_DOCUMENTS: 'investor_deals/investor_deal_update',
};
export const IPA_DOC = 'IPA';
export const UPLOAD_DOC = 'Upload Document';
export const UPLOADING_DOC = 'Uploading....';
export const kycFolderNames = {
    DEBT_ADDN_DOCS: 'debt_addn_docs',
};
export const monthMap = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
};

export const overdue = 'Overdue';
export const thisMonth = 'this month';

// Error Messages for Validations while Updating PEC Screen
export const AUTO_INVEST_VALIDATE_ENTRY = {
    NAME: 'Please enter a name',
    LOAN_TYPE: 'Please select loan types',
    COMPANY_SECTORS: 'Please select company sectors',
    STATES: 'Please select states',
    SECURITIES: 'Please select type of securities',
    PEC: 'Please select PEC types',
};

export const DEFAULT_MORAT_VAL_MONTHS = 1;
export const TERMS_CONDITIONS_FOUNDERLINK =
    'https://recur-club.webflow.io/terms-and-conditions-founderlink';
export const PRIVACY_POLICY_FOUNDERLINK =
    'https://recur-club.webflow.io/privacy-policy-founderlink';

export const PERMISSIONS = {
    EDIT_TEAM_MEMBER: 'Team Member:edit',
    EDIT_PRIMARY_USER: 'Primary User:edit',
    USER_DETAILS_EDIT: 'User Details:edit',
};
//This is Enum only for Messages Sender Type
export const SENDER_TYPE_INVESTOR = 'Investor';
export const RECUR_INVESTOR = 'Recur Investor';

export const GST_INACTIVE = 'INACTIVE';
export const GST_ACTIVE = 'ACTIVE';
export const IS_SMALL_MONTHLY_VIEW_VISIBLE = false;

// This Enum is only for Repayment Banner
export const REPAYMENT_BANNER_TEXT = {
    FIRST_PART: 'Your account is overdue! Delay charges of ',
    SECOND_PART: ' per annum is being accrued daily on total overdue amount.',
};

// For Mobile View
export const APPLICATION_TYPE = {
    ONBOARDING: 'Onboarding',
    ADD_ON: 'Add On',
};

export const APPLICATION_STATUS = {
    EXPIRED: 'Expired',
    WAITLISTED: 'Waitlisted',
    APPROVED: 'Approved',
    NEED_INFORMATION: 'Need Information',
};

// Movie View Capital Card Messages types
export const CAPITAL_AVAILABLITY_MESSAGES = {
    SIGN_SANCTION: 'Sign Sanction',
    TRADE_YOUR_CONTRACTS: 'Trade your contracts to draw this capital now',
    RAISE_ADDON: 'Raise addon',
    CAPITAL_IN_PROGRESS: 'Capital in progress',
    CAPITAL_DRAW_NOW: 'Draw now',
};
