import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
//@TODO write a logic for history.listen figuring out this via custom code
//import { HistoryRouter as Router } from "redux-first-history/rr6";
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import './css/Profile.css'; //@TODO remove after verifying

import CacheBuster from './CacheBuster';
import { displayMobileViewFor, isEnvProd, isThereAuthComponents } from './util/utility';
import MobileView from './components/MobileView';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import {
    getInvesteeOrganizationCompanyActiveStatus,
    getInvesteeOrganizationCompanyApprovalStatus,
    getInvesteeOrganizationNextPaymentDate,
    getInvesteeOrganizationTotalActiveContractCount,
    getIsAppLoading,
    getLastTradingDate,
    getProgressBar,
    getUserId,
} from './modules/App/AppReducer';
import PageLoader from './components/loader/PageLoader';
import RootRoutes from './RootRoutes';
import DebugRouter from './DebugRouter';
import NavigateFunctionComponent from './common/NavigateFunctionComponent';
import ProductFruits from 'react-product-fruits';
import HubspotChat from './components/HubspotChat/HubspotChat';
import { ProgressBar } from './components/ProgressBar/ProgressBar';
import { SettingsConsumer, SettingsProvider } from './context/settingsContext';
import ThemeComponent from './theme/ThemeComponent';
import storage from './util/storageService';
import Landscape from './components/Landscape/Landscape';
import { useOrientation, useWindowSize } from 'react-use';

function App({ history }) {
    const { width } = useWindowSize();
    const isAppLoading = useSelector(getIsAppLoading);
    const showProgressBar = useSelector(getProgressBar);
    const userId = useSelector(getUserId);
    const isCompanyApproved = useSelector(getInvesteeOrganizationCompanyApprovalStatus);
    const isCompanyActive = useSelector(getInvesteeOrganizationCompanyActiveStatus);
    const totalActiveContractCount = useSelector(getInvesteeOrganizationTotalActiveContractCount);
    const nextPaymentDate = useSelector(getInvesteeOrganizationNextPaymentDate);
    const lastTradingDate = useSelector(getLastTradingDate);
    const orientation = useOrientation();
    const [showLandscapeScreen, setShowLandscapeScreen] = useState(false);
    const location = window.location;

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'none';
    };
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        if (width <= 1025 && orientation.type.includes('landscape')) {
            setShowLandscapeScreen(true);
            document.body.style.backgroundColor = '#ffffff';
            document.body.style.position = 'relative';
        } else {
            setShowLandscapeScreen(false);
        }

        return () => {
            document.body.style.backgroundColor = '';
            document.body.style.position = '';
        };
    }, [width, orientation]);

    useEffect(() => {
        if (width <= 1025) {
            window.localStorage.setItem('device', 'mobile');
        } else {
            window.localStorage.setItem('device', 'desktop');
        }
    }, [width]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.localStorage.setItem(
                    'myIp',
                    get(JSON.parse(res.headers), 'x-forwarded-for'),
                );
            })
            .catch((err) => console.error('Problem fetching my IP', err));
    }, []);

    useEffect(() => {
        // Check if the screen orientation API is supported
        if (window.screen.orientation && window.screen.orientation.lock) {
            // Lock the screen orientation to portrait
            window.screen.orientation
                .lock('portrait')
                .then(() => console.log('Screen orientation locked to portrait'))
                .catch((err) => console.error('Unable to lock screen orientation:', err));
        } else {
            console.warn('Screen orientation API not supported on this device/browser.');
        }

        // Cleanup: Unlock the orientation when the component is unmounted
        return () => {
            if (window.screen.orientation && window.screen.orientation.unlock) {
                window.screen.orientation.unlock();
            }
        };
    }, []);

    //@TODO check better place to put this if there is no token logout the user
    // like a auth reducer
    if (!isThereAuthComponents()) {
        storage.flush();
    } /* else {
        const defaultPath = getDefaultBasePath();
        history.push(defaultPath); //@TODO check for this case doesn't fit in our scenarios
        window.localStorage.clear();
    } */

    const userInfo = {
        username: isEmpty(userId) ? 'logged_out_user' : userId,
        totalActiveContractCount,
        nextPaymentDate,
        props: {
            isCompanyDisbursed: lastTradingDate !== null,
            isCompanyActive,
            isCompanyApproved,
        },
    };
    return (
        <ErrorBoundary>
            <SettingsProvider>
                <SettingsConsumer>
                    {({ settings }) => {
                        return (
                            <ThemeComponent settings={settings}>
                                {isEnvProd && (
                                    <ProductFruits
                                        projectCode={process.env.REACT_APP_FRUIT_CODE_WORKSPACE}
                                        language="en"
                                        {...userInfo}
                                    />
                                )}
                                <HubspotChat />
                                <CacheBuster>
                                    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                                        if (loading) return null;
                                        if (!loading && !isLatestVersion) {
                                            // You can decide how and when you want to force reload
                                            refreshCacheAndReload();
                                        }
                                        if (showLandscapeScreen) {
                                            return (
                                                <div
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                    onDragEnter={handleDragEnter}
                                                    onDragLeave={handleDragLeave}
                                                >
                                                    <Landscape />
                                                </div>
                                            );
                                        }
                                        if (
                                            width <= 1025 &&
                                            displayMobileViewFor(location?.pathname)
                                        ) {
                                            return (
                                                <div
                                                    className="App"
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                    onDragEnter={handleDragEnter}
                                                    onDragLeave={handleDragLeave}
                                                >
                                                    <MobileView />
                                                </div>
                                            );
                                        }
                                        return (
                                            <React.Fragment>
                                                {isAppLoading && <PageLoader />}
                                                <ProgressBar state={showProgressBar} />
                                                <div
                                                    className="App"
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                    onDragEnter={handleDragEnter}
                                                    onDragLeave={handleDragLeave}
                                                >
                                                    <BrowserRouter
                                                        navigator={history}
                                                        location={history.location}
                                                        history={history}
                                                    >
                                                        <NavigateFunctionComponent />
                                                        <DebugRouter>
                                                            <RootRoutes history={history} />
                                                        </DebugRouter>
                                                    </BrowserRouter>
                                                    <ToastContainer />
                                                </div>
                                            </React.Fragment>
                                        );
                                    }}
                                </CacheBuster>
                            </ThemeComponent>
                        );
                    }}
                </SettingsConsumer>
            </SettingsProvider>
        </ErrorBoundary>
    );
}

export default App;
