import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
    fetchDataVaultPendingStatus,
    fetchAllowedInviteRolesRedux,
} from '../../modules/App/AppActions';
import { checkAnyChange, getAutoInvestCount } from '../../modules/Investor/InvestorReducers';
import { fetchAutoInvestForInvestor } from '../../modules/Investor/InvestorActions';
import {
    getApprovedState,
    getCheckIfUserHasRelevantPermissions,
    getDataVaultPendingStatus,
    getEmail,
    getInvesteeOrganizationInfo,
    getInvesteeOrganizationIsInsightsEnabled,
    getInvestorType,
    getUserDetails,
    getUserId,
    getUserInvestorOrganizationId,
    getUserPermissions,
} from '../../modules/App/AppReducer';
import UsernameBtn from './components/UsernameBtn.jsx';
import FinancesButton from './components/FinancesButton';
import { fetchUnreadNotificationCount } from '../../modules/Investee/InvesteeActions';
import withRouter from '../../common/withRouter';
import clsx from 'clsx';
import WithdrawFunds from './components/WithdrawFunds';
import {
    ACTIVE_DEALS_PAGE_NAME,
    BASE_PATH_INVESTOR,
    USER_TYPE_INVESTEE,
    USER_TYPE_INVESTOR,
} from '../../enums/appEnums';
import storage from '../../util/storageService';
import { INVESTOR_TYPE } from '../../constants/consts';
import './styles/InvestorSidebar.scss';
import { getS3FLIconLink } from '../../util/utility';

const investorSidebar = (investorType, userName) => {
    if (investorType === INVESTOR_TYPE.NBFC) {
        return [
            {
                url: '/investor/dashboard',
                name: 'Dashboard',
            },
            {
                url: '#',
                name: '',
                label: '',
                line: true,
            },
            {
                url: '/investor/deals',
                name: 'New Deals',
            },
            {
                url: '/investor/docs',
                name: 'Documentation',
            },
            {
                url: '/investor/portfolio',
                name: ACTIVE_DEALS_PAGE_NAME,
            },
            {
                url: '#',
                name: '',
                label: '',
                line: true,
            },
            {
                name: 'Repayments',
                url: '/investor/finances/transactions',
            },
            // {
            //     name: 'Reports',
            //     url: '/investor/finances/reports',
            // },
            {
                name: 'Customer Requests',
                url: '/investor/customer-requests',
            },
            {
                url: '/investor/cart',
                name: 'Cart',
            },
            {
                name: 'WithdrawFunds',
            },
            {
                name: userName,
                subRoutes: [
                    {
                        url: '/investor/profile',
                        name: 'Manage Profile',
                        blocked: false,
                    },
                    {
                        url: '/investor/help',
                        name: 'Help Center',
                        blocked: false,
                    },
                    {
                        name: 'Logout',
                    },
                ],
            },
        ];
    } else if (investorType === INVESTOR_TYPE.NBFC_FOR_DEBT) {
        return [
            {
                url: '/investor/dashboard',
                name: 'Dashboard',
            },
            {
                url: '#',
                name: '',
                label: '',
                line: true,
            },
            {
                url: '/investor/deals',
                name: 'New Deals',
            },
            {
                url: '/investor/docs',
                name: 'Documentation',
            },
            {
                url: '/investor/portfolio',
                name: ACTIVE_DEALS_PAGE_NAME,
            },
            {
                url: '#',
                name: '',
                label: '',
                line: true,
            },
            {
                url: '/investor/cart',
                name: 'Cart',
            },
            {
                name: userName,
                subRoutes: [
                    {
                        url: '/investor/profile',
                        name: 'Manage Profile',
                        blocked: false,
                    },
                    {
                        url: '/investor/help',
                        name: 'Help Center',
                        blocked: false,
                    },
                    {
                        name: 'Logout',
                    },
                ],
            },
        ];
    }
    return [
        {
            url: '/investor/dashboard',
            name: 'Dashboard',
        },
        {
            url: '/investor/market',
            name: 'Market',
        },
        {
            url: '/investor/investbook',
            name: 'Investbook',
        },
        {
            url: '',
            name: '',
        },
        {
            url: '#',
            name: '',
        },
        {
            url: '/investor/strategy',
            name: 'Pre Eligibility Criteria',
        },
        {
            url: '/investor/cart',
            name: 'Cart',
        },
        {
            url: '#',
            name: '',
        },
        {
            name: 'WithdrawFunds',
        },
        {
            name: userName,
            subRoutes: [
                {
                    url: '/investor/profile',
                    name: 'Manage Profile',
                    blocked: false,
                },
                {
                    url: '/investor/help',
                    name: 'Help Center',
                    blocked: false,
                },
                {
                    name: 'Logout',
                },
            ],
        },
    ];
};

class SidebarV2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 1,
            pageNum: 1,
            hasSelectedAnyOptionFromUserbtn: false,
            investor_links: investorSidebar(props.investorType, props.userName),
        };
    }

    componentDidMount() {
        const {
            dispatch,
            investeeOrgId,
            investorOrganizationId,
            email,
            userPermissions,
            isUserApproved,
        } = this.props;
        if (investorOrganizationId) {
            dispatch(fetchAutoInvestForInvestor(investorOrganizationId));
        }
        if (investeeOrgId && isUserApproved) dispatch(fetchDataVaultPendingStatus(investeeOrgId));
        if (email) dispatch(fetchUnreadNotificationCount(email));
        if (userPermissions) dispatch(fetchAllowedInviteRolesRedux(userPermissions));
    }

    componentDidUpdate(prevProps) {
        const { pathname: url } = this.props.location;
        const { hasSelectedAnyOptionFromUserbtn } = this.state;
        if (
            !hasSelectedAnyOptionFromUserbtn &&
            ['/company/profile', '/company/help'].includes(url)
        ) {
            this.setState({ hasSelectedAnyOptionFromUserbtn: true });
        }
    }

    goToInsights = () => {
        const { investeeOrgId, userId, insightsStatus } = this.props;
        const cerberusToken = storage.get('cerberus_token');
        if (insightsStatus === 'Approved') {
            window.open(
                `${process.env.REACT_APP_INSIGHTS_URL}?investeeOrgId=${investeeOrgId}&cerberusToken=${cerberusToken}&userId=${userId}`,
            );
        }
    };

    getInput = () => {
        let input = {};
        input.pageSize = this.state.pageSize;
        input.pageNum = this.state.pageNum;
        return input;
    };

    renderNavLinks = (item, i, hasSelectedAnyOptionFromUserbtn) => {
        return (
            <NavLink
                hidden={
                    item.name === 'Cart' || item.name === 'Investbook' || item.name === 'Finances'
                }
                key={i}
                className={({ isActive }) => {
                    return clsx({
                        'sidebar-line': item.line,
                        'sidebar-link investor-sidebar-link': true,
                        activeStyle: isActive,
                    });
                }}
                to={`${item.url}`}
                data-autoinvest={this.props.autoinvestCount ? this.props.autoinvestCount : 0}
                onClick={(e) => {
                    item.blocked && e.preventDefault();
                    hasSelectedAnyOptionFromUserbtn !==
                        this.state.hasSelectedAnyOptionFromUserbtn &&
                        this.setState({ hasSelectedAnyOptionFromUserbtn });
                }}
            >
                {item.name}
            </NavLink>
        );
    };

    renderSideBar = (item, i, category) => {
        if (item.name === 'Finances') {
            return (
                <FinancesButton
                    key={`username-btn-${i}`}
                    onSelection={() => {
                        if (this.state.hasSelectedAnyOptionFromUserbtn) {
                            this.setState({ hasSelectedAnyOptionFromUserbtn: false });
                        }
                    }}
                    blocked={item.blocked}
                    category={category}
                />
            );
        }
        if (item.name === 'WithdrawFunds') {
            return <WithdrawFunds key={`withdraw-funds-${i}`} />;
        }
        if (!item.url) {
            return (
                <UsernameBtn
                    {...item}
                    key={`username-btn-${i}`}
                    category={category}
                    renderNavLinks={this.renderNavLinks}
                    selectedSegment={this.props.selectedSegment}
                    hasSelectedAnyOption={this.state.hasSelectedAnyOptionFromUserbtn}
                />
            );
        }
        return this.renderNavLinks(item, i);
    };

    render() {
        const { selectedSegment, isUserApproved, navigate } = this.props;
        if (selectedSegment === USER_TYPE_INVESTOR) {
            const { investor_links } = this.state;
            return (
                <>
                    <div className={'investor-sidebar'}>
                        <div className="d-flex flex-column justify-content-between logout-ht">
                            <div className="d-flex flex-column">
                                <img
                                    className="sidebar-recur-logo"
                                    onClick={() => navigate(`/${BASE_PATH_INVESTOR}`)}
                                    src={getS3FLIconLink('recur-new-logo-3', 'svg')}
                                    alt=""
                                />
                                {investor_links &&
                                    investor_links.map((item, idx) =>
                                        this.renderSideBar(item, idx, USER_TYPE_INVESTOR),
                                    )}
                            </div>
                            <button onClick={() => this.logOut()} className="d-flex page-head-btn">
                                <span className="page-head-btn-logout">Log Out</span>
                            </button>
                        </div>
                    </div>
                </>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, props) {
    return {
        anyChange: checkAnyChange(state),
        investorOrganizationId: getUserInvestorOrganizationId(state),
        investorType: getInvestorType(state) ?? '',
        autoinvestCount: getAutoInvestCount(state),
        investeeOnboardingId: getInvesteeOrganizationInfo(state)?.investee_onboarded_id,
        investeeOrgId: getInvesteeOrganizationInfo(state)?._id,
        isUserApproved: getApprovedState(state),
        status: getInvesteeOrganizationInfo(state)?.status,
        is_company_approved: getInvesteeOrganizationInfo(state)?.is_company_approved,
        dataVaultPendingStatus: getDataVaultPendingStatus(state),
        userName: getUserDetails(state).contact_name,
        recurCheckoutAllowed: getInvesteeOrganizationInfo(state)?.recur_checkout_allowed,
        userHasEditAccess: getCheckIfUserHasRelevantPermissions(state, [
            'Data Collaborator:invite',
        ]),
        email: getEmail(state),
        userPermissions: getUserPermissions(state),
        insightsStatus: getInvesteeOrganizationIsInsightsEnabled(state),
        userId: getUserId(state),
    };
}

export default withRouter(connect(mapStateToProps)(SidebarV2));
