import React from 'react';
import './ProgressBar.scss';
import PageLoader from '../loader/PageLoader';

export function ProgressBar({ state }) {
    return (
        <div
            style={{
                visibility: state ? 'visible' : 'hidden',
                transition: '1s',
            }}
        >
            <div className="progress-line"></div>
            <div className="overlay">
                <PageLoader />
            </div>
        </div>
    );
}
