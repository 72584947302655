import { isEmpty } from 'lodash';
import { GST_ACTIVE, INVESTEE_ORG_UNCLAIMED } from '../../../../../enums/appEnums';
import { VERIFICATION, websiteUrlRegex } from '../components/VerifyBusiness/VerifyBusiness';
import { UPLOAD_STAGE } from './constants';

export const VerifyBusinessChecks = {
    isGstinVerified: (data: any) =>
        [VERIFICATION.VERIFIED, VERIFICATION.UNDER_VERIFICATION].includes(data?.gstin_status),
    isDirectorDetailsFetched: (data: any) => data?.director_details_fetched,
    isStepComplete: (data: any) => {
        return (
            VerifyBusinessChecks.isGstinVerified(data) &&
            VerifyBusinessChecks.isDirectorDetailsFetched(data)
        );
    },
};

export const BankAccountsChecks = {
    isStepComplete: (data: any, isOnboarding: boolean, isAddOn: boolean) => {
        if (isOnboarding) return !isEmpty(data?.bank_account) && data?.all_bank_account_added;
        else {
            const bank_accounts = data?.bank_accounts ?? [];
            const finboxIds = data?.finbox_entity_ids ?? {};
            const files = data?.bills_invoices_bank_transactions ?? [];
            let all_accounts_fetched = true;

            for (let i = 0; i < bank_accounts?.length; i++) {
                const accountNo = bank_accounts[i]?.account_number;
                if (!checkIfStatementIsFetched({ finboxIds, files, accountNo })) {
                    all_accounts_fetched = false;
                    break;
                }
            }
            if (isAddOn) {
                return all_accounts_fetched && data?.all_bank_account_added;
            }
            return all_accounts_fetched;
        }
    },
};

export const GstInvoicingChecks = {
    isGstFetchComplete: (data: any) => {
        const GSTs = data?.gstin ?? data?.gstins;
        if (isEmpty(GSTs)) return false;
        else {
            let gstsCount = Object.keys(GSTs)?.length;
            let fetchedGstsCount = 0;
            for (let gst in GSTs)
                if (
                    (GSTs[gst].user_id && GSTs[gst].password) ||
                    GSTs[gst]?.active_status !== GST_ACTIVE
                )
                    fetchedGstsCount++;
            if (gstsCount > 5 && fetchedGstsCount >= 1) return true;
            else if (gstsCount <= 5 && fetchedGstsCount === gstsCount) return true;
            else return false;
        }
    },
    isInvoicingComplete: (data: any, monthlyViewData: any, stage: string) => {
        if (stage === UPLOAD_STAGE.ONBOARDING) {
            return !!(data?.invoicing?.file_arr?.length || data?.invoicingIntegration);
        } else if (stage === UPLOAD_STAGE.DATA_VAULT) {
            return !!(
                monthlyViewData.invoicing_complete_months === 24 || data?.invoicingIntegration
            );
        } else {
            return !!(data?.onboarding_stage_data_invoicing?.length || data?.invoicingIntegration);
        }
    },
    hideInvoicing: (data: any, stage: string, companyStatus: String) => {
        if (companyStatus === INVESTEE_ORG_UNCLAIMED) {
            return false;
        }
        if (stage === UPLOAD_STAGE.ONBOARDING || stage === UPLOAD_STAGE.ADD_ON) {
            const GSTINS = data?.gstin ?? data?.gstins ?? {};
            const gstinsCount = Object.keys(GSTINS).length;
            return gstinsCount <= 5;
        }
        return true;
    },
};

export const FinancialChecks = {
    mis: {
        isMisComplete: (data: any, monthlyViewData: any, stage: string) => {
            if (stage === UPLOAD_STAGE.ONBOARDING) {
                return !!(data?.mis?.file_arr?.length || data?.misIntegration);
            } else if (stage === UPLOAD_STAGE.DATA_VAULT) {
                const {
                    pnl_complete_months,
                    cashflow_complete_months,
                    balance_sheet_complete_months,
                } = monthlyViewData;
                return !!(
                    (pnl_complete_months === 24 &&
                        cashflow_complete_months === 24 &&
                        balance_sheet_complete_months === 24) ||
                    data?.misIntegration
                );
            } else {
                return !!(
                    data?.onboarding_stage_data_financials_mis?.length || data?.misIntegration
                );
            }
        },
    },
    provisionalFinancials: {
        // activeLoansComplete: (data: any) => {
        //     if (data?.debtSchedule?.noDebtSchedules) return true;
        //     else if ((data?.debtSchedule?.file_arr?.length ?? 0) === 0) return false;
        //     return true;
        // },
        isprovisionalFinancials: (data: any, monthlyViewData: any, stage: string) => {
            if (stage === UPLOAD_STAGE.ONBOARDING) {
                return !!(data?.mis?.file_arr?.length || data?.misIntegration);
            } else if (stage === UPLOAD_STAGE.DATA_VAULT) {
                const {
                    pnl_complete_months,
                    cashflow_complete_months,
                    balance_sheet_complete_months,
                } = monthlyViewData;
                return !!(
                    (pnl_complete_months === 24 &&
                        cashflow_complete_months === 24 &&
                        balance_sheet_complete_months === 24) ||
                    data?.misIntegration
                );
            } else {
                return !!(
                    data?.onboarding_stage_data_financials_mis?.length || data?.misIntegration
                );
            }
        },
        isStepComplete: (data: any, monthlyViewData: any, stage: string) => {
            return FinancialChecks.provisionalFinancials.isprovisionalFinancials(
                data,
                monthlyViewData,
                stage,
            );
        },
    },
    auditedFinancials: (data: any, isAddOnLimit?: boolean) => {
        if (!isAddOnLimit) {
            return (
                data?.financial_statement?.audited_financial_statement?.file_arr.length ||
                data?.is_audited_financial_statement_complete
            );
        } else {
            return true;
        }
    },
    existingDebt: (data: any) => {
        if (data?.debtSchedule?.noDebtSchedules) return true;
        else if ((data?.debtSchedule?.file_arr?.length ?? 0) === 0) return false;
        return true;
    },
    statements: {
        activeLoansComplete: (data: any) => {
            return !!(data?.debtSchedule?.noDebtSchedules || data?.debtSchedule?.file_arr?.length);
        },
        financialStatementsComplete: (data: any) => {
            const auditedStatementsStatus =
                data?.financial_statement?.audited_financial_statement?.status;
            const provisionalStatementStatus =
                data?.financial_statement?.provisional_financial_statement?.status;
            return !!(
                ['Submitted', 'Approved'].includes(auditedStatementsStatus) &&
                ['Submitted', 'Approved'].includes(provisionalStatementStatus)
            );
        },
        isStepComplete: (data: any) => {
            return !!(
                FinancialChecks.statements.activeLoansComplete(data) &&
                FinancialChecks.statements.financialStatementsComplete(data)
            );
        },
    },
};

export const AdditionalDataChecks = {
    isStepComplete: (data: any) => {
        return !!data?.additional_data?.length;
    },
};

const checkIfStatementIsFetched = ({
    finboxIds,
    files,
    accountNo,
}: {
    finboxIds: any;
    files: any;
    accountNo: string;
}) => {
    if (!isEmpty(finboxIds) && accountNo in finboxIds && !isEmpty(finboxIds[accountNo]))
        return true;
    if (!isEmpty(files) && accountNo in files && !isEmpty(files[accountNo])) return true;
    return '';
};

// (financial_statement?.audited_financial_statement?.status === 'Submitted' ||
// financial_statement?.audited_financial_statement?.status === 'Approved') &&
// (financial_statement?.provisional_financial_statement?.status === 'Submitted' ||
//     financial_statement?.provisional_financial_statement?.status === 'Approved'),

// const cinVerified = [VERIFICATION.VERIFIED, VERIFICATION.UNDER_VERIFICATION].includes(
//   verificationStatus[FIELDS.CIN],
// );
// const gstinVerified = verificationStatus[FIELDS.GSTIN] === VERIFICATION.VERIFIED;
// const websiteVerified = verificationStatus[FIELDS.WEBSITE] === VERIFICATION.VERIFIED;
// const allVerified = !!(cinVerified && gstinVerified && websiteVerified);

// onboardingData?.cin_status
// onboardingData?.gstin_status

// for (let gst in GSTs) if (!GSTs[gst].user_id && !GSTs[gst].password) return false;
