import React from 'react';
import UploadGif from '../../../../../../../assets/Uploading.gif';

function UploadingCloud({ width, left }: any) {
    return (
        <span>
            <img
                src={UploadGif}
                alt="uploading gif"
                style={{ width, position: 'relative', left }}
            />
        </span>
    );
}

export default UploadingCloud;
