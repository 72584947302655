import styles from './CustomDrawer.module.scss';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getDrawerData, getDrawerHeading } from '../../modules/App/AppReducer';
import { triggerDrawer } from '../../modules/App/AppActions';
import { getEmail, getInvesteeOrganizationInfo } from '../../modules/App/AppReducer';
import { Suspense, useEffect, useState } from 'react';
import { DRAWER_COMPONENT_MAPPING, DRAWER_HEADINGS } from '../../DrawerConsts';
import { useSearchParams } from 'react-router-dom';
import { mixPanelTrack, isZeusUser } from '../../util/utility';
import { ALL_TERM_SHEETS_RESULTNAME } from '../SearchBoxWithDropDown/searchConsts';

function CustomDrawer() {
    const { HeadingText, CloseBtn, HeadingTextNormal, TdsBanner } = styles;
    const dispatch = useDispatch();
    const drawerData = useSelector(getDrawerData);
    const drawerHeading = useSelector(getDrawerHeading);
    const [params, setParams] = useSearchParams();
    const orgId = useSelector(getInvesteeOrganizationInfo)?._id;
    const emailID = useSelector(getEmail);
    const zIndex = drawerHeading === ALL_TERM_SHEETS_RESULTNAME ? '20' : '1200';

    const onClose = (isCrossClicked: boolean) => {
        if (
            (params.get('openTickets') || params.get('drawerType') === drawerHeading) &&
            !isCrossClicked
        )
            return;
        const customerSupportTicketHeadings: string[] = [
            DRAWER_HEADINGS.BALANCE_CONFIRMATION,
            DRAWER_HEADINGS.STATEMENT_OF_ACCOUNT,
        ];
        if (
            customerSupportTicketHeadings.includes(drawerHeading) ||
            (drawerHeading === DRAWER_HEADINGS.TDS_REFUND && !params.get('ticketStatus'))
        ) {
            mixPanelTrack({
                id: orgId,
                trackUserObj: {
                    email: emailID,
                    zeusUser: isZeusUser(emailID) ? 'Yes' : 'No',
                    ticketType: drawerHeading,
                },
                trackCategoryName: 'Drop off in journey',
            });
        }
        if (isCrossClicked) {
            setParams('');
        }
        dispatch(triggerDrawer(''));
    };

    const Component: any = DRAWER_COMPONENT_MAPPING[drawerHeading]?.component;
    const componentProps = DRAWER_COMPONENT_MAPPING[drawerHeading]?.props ?? {};
    const [width, setWidth] = useState<any>('');
    useEffect(() => {
        if (!DRAWER_COMPONENT_MAPPING[drawerHeading]?.width) return;
        setWidth(DRAWER_COMPONENT_MAPPING[drawerHeading]?.width);
    }, [drawerHeading]);

    return (
        <>
            <Drawer
                style={{ zIndex }}
                anchor="right"
                open={!!drawerHeading && !!Component}
                onClose={() => {
                    onClose(false);
                }}
                transitionDuration={500}
                PaperProps={{
                    style: {
                        width: width ?? '85%',
                    },
                }}
                disableEnforceFocus
            >
                <Suspense fallback={null}>
                    {drawerHeading && Component ? (
                        <>
                            <div className="dflex">
                                <div
                                    className={width !== '85%' ? HeadingTextNormal : HeadingText}
                                    style={
                                        DRAWER_COMPONENT_MAPPING[drawerHeading].headingStyles ?? {}
                                    }
                                >
                                    {drawerHeading}
                                    {drawerData?.status && (
                                        <span
                                            style={{
                                                color: drawerData?.color,
                                                backgroundColor: drawerData?.banner,
                                            }}
                                            className={TdsBanner}
                                        >
                                            {drawerData?.status}
                                        </span>
                                    )}
                                </div>
                                <button onClick={() => onClose(true)} className={CloseBtn}>
                                    <img
                                        src="/assets/on-close.svg"
                                        alt="close"
                                        width={20}
                                        height={20}
                                    />
                                </button>
                            </div>
                            <div className="page-head-bottom-border" />
                            <Component {...componentProps} />
                        </>
                    ) : (
                        <></>
                    )}
                </Suspense>
            </Drawer>
        </>
    );
}

export default CustomDrawer;
