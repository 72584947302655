import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { history } from './common/history';
import { persistor, store } from './common/persist';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import initializeLogger from './util/errorLogger';
import { PersistGate } from 'redux-persist/integration/react';
import { PRODUCTION } from './enums/appEnums';

if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION && process.env.REACT_APP_SENTRY_URL) {
    initializeLogger();
}
ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <Provider store={store}>
                <PersistGate persistor={persistor} loading={null}>
                    <App history={history} />
                </PersistGate>
            </Provider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
// {statementDocs.length !== 0 ? statementDocs.map(doc => (
//   <Latest>
//       <LatestDoc>
//           <span>{doc.doc_name}</span>
//           {/* <span><img src="/assets/on-close.svg" alt="" /></span> */}
//       </LatestDoc>
//   </Latest>
// ))
//   :
//   (noBankConnected &&
//       <Red><GreyBorder>No files uploaded</GreyBorder></Red>
//   )
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
