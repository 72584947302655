import { closeHubspotLiveChat } from '../../util/utility';
import styles from './HubspotChat.module.scss';

export default function HubspotChat() {
    const { mainContainer, hubspotChat, closeButton } = styles;

    return (
        <div id="hubspot-chat-holder" className={mainContainer}>
            <button
                id="hubspot-close-button"
                className={closeButton}
                onClick={closeHubspotLiveChat}
            >
                <div>
                    <img src="/assets/clear-dark-blue.svg" alt="" width="8px" />
                </div>
            </button>
            <div id="hubspot-chat" className={hubspotChat}></div>
        </div>
    );
}
