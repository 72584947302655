import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    companyWithdrawModal,
    fetchBankList,
    withdrawModalStateChange,
    withdrawToBank,
} from '../modules/App/AppActions';
import {
    getBankList,
    getCompanyWithdrawModalState,
    getInvesteeOrganizationInfo,
    getTransactionDetails,
    getUserDetails,
    getUserInvesteeOrganizationId,
    getUserInvestorOrganizationId,
    getWithdrawState,
    getWithrawModalState,
} from '../modules/App/AppReducer';
import { priceFormatNoRounding, showNotification } from '../util/utility';
import { Link } from 'react-router-dom';

function WithdrawModal(props) {
    const [withdrawInput, setWithdrawInput] = useState({ amount: '', bank: '' });
    const [bankDetails, setBankDetails] = useState({});
    const dispatch = useDispatch();
    const withdrawState = useSelector((state) => getWithdrawState(state));
    const withdrawModalState = useSelector((state) => getWithrawModalState(state));
    const modalOpen = useSelector((state) => getCompanyWithdrawModalState(state));
    const bankList = useSelector((state) => getBankList(state));
    const transactionDetails = useSelector((state) => getTransactionDetails(state));
    const deposit = useSelector((state) => getInvesteeOrganizationInfo(state)?.deposit_perc);
    const org_id = useSelector((state) => {
        if (props.type === 'investee') {
            return getUserInvesteeOrganizationId(state);
        }
        return getUserInvestorOrganizationId(state);
    });
    const submitted_by = useSelector((state) => {
        let userDetails = getUserDetails(state);
        return { name: userDetails.contact_name, id: userDetails._id };
    });
    let url = '';
    if (props.type === 'investee') {
        url = '/company/profile/bankaccount';
    } else {
        url = '/investor/profile';
    }

    useEffect(() => {
        dispatch(fetchBankList(org_id, 'investor'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (withdrawModalState === 'Review') {
            setWithdrawInput({ amount: '', bank: '' });
            setBankDetails({});
        }
    }, [withdrawModalState]);

    const toggleModal = () => {
        dispatch(companyWithdrawModal(false));
        setWithdrawInput({ amount: '', bank: '' });
        dispatch(withdrawModalStateChange('Proceed'));
        // setWithdrawModalState("Proceed");
        setBankDetails({});
    };

    const callConfirm = () => {
        let validation = handleValidation();
        if (validation.isValid) {
            setBankDetails(
                bankList.filter((bank) => bank.escrow_pay_bank_id === withdrawInput.bank)[0],
            );
            dispatch(withdrawModalStateChange('Confirm'));
            // setWithdrawModalState("Confirm")
            // dispatch(withdrawToBank(org_id,withdrawInput.bank,withdrawInput.amount,props.type));
            // setWithdrawInput({ amount: '', bank: '' });
        } else {
            showNotification('error', validation.message);
        }
    };

    const callWithdraw = () => {
        dispatch(
            withdrawToBank(
                org_id,
                withdrawInput.bank,
                withdrawInput.amount,
                props.type,
                submitted_by,
            ),
        );
        // dispatch(withdrawModalStateChange("Review"));
        // setWithdrawModalState("Review");
    };

    const withdrawableBalance =
        props.type === 'investee' ? props.withdrawableBalance : props.escrowAccountInfo?.balance;

    const handleValidation = () => {
        let isValid = true;
        let message = '';
        if (isNaN(withdrawInput.amount)) {
            isValid = false;
            message = 'Amount should only contain numbers';
        } else if (Number(withdrawInput.amount) > withdrawableBalance) {
            isValid = false;
            message =
                props.type === 'investee'
                    ? 'Amount cannot be greater than Withdrawable Funds'
                    : 'Amount cannot be greater than Avaialable Funds';
        } else if (withdrawInput.amount === '') {
            isValid = false;
            message = 'Amount cannot be empty';
        } else if (!(withdrawInput.amount > 0)) {
            isValid = false;
            message = 'Amount should be greater than 0';
        } else if (!withdrawInput.bank) {
            isValid = false;
            message = 'Please select a valid bank Account';
        }
        return { isValid, message };
    };

    return (
        <div>
            <Modal
                show={modalOpen}
                onHide={() => {
                    toggleModal();
                }}
            >
                <Modal.Header>
                    <Modal.Title style={{ textAlign: 'center' }}>
                        {withdrawModalState === 'Proceed'
                            ? 'Withdraw Funds'
                            : withdrawModalState === 'Confirm'
                            ? 'Confirm Withdrawal Request'
                            : null}
                    </Modal.Title>
                    <button type="button" className="close" onClick={() => toggleModal()}>
                        <span>&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {withdrawModalState === 'Proceed' && (
                        <div>
                            <p
                                className="page-title-sub-para"
                                style={{ marginTop: '-25px', marginBottom: '22px' }}
                            >
                                Please select the account to withdraw funds:
                            </p>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <span className="subtitle-normal">Withdrawable Funds:</span>
                                    <span
                                        className="subtitle-normal-number"
                                        style={{ color: '#49A71B' }}
                                    >
                                        {' '}
                                        <i className="fa fa-rupee-sign"></i>{' '}
                                        {withdrawableBalance === 'NA'
                                            ? 'NA'
                                            : props.type === 'investor'
                                            ? priceFormatNoRounding(withdrawableBalance, 2)
                                            : priceFormatNoRounding(
                                                  Math.floor(withdrawableBalance),
                                                  2,
                                              )}
                                    </span>
                                </div>
                                {props.type === 'investee' && (
                                    <div className="fs12 learn-more steps tooltips-ba">
                                        Learn more about withdrawal
                                        <span
                                            className="tooltiptexts-ba after1"
                                            style={{
                                                inset: 'auto',
                                                marginLeft: '193px',
                                                marginTop: '-119px',
                                                width: '237px',
                                                fontSize: '10px',
                                                fontWeight: '300',
                                            }}
                                        >
                                            You have to maintain a minimum escrow balance equivalent
                                            to <span className="fw5">{deposit + '%'}</span> of your
                                            traded contract value. This amount is automatically
                                            adjusted in your last repayment.
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-input-container">
                                <label className="input-div-label">Enter amount to withdraw:</label>
                                <span className="w-50 d-flex">
                                    <span className="enter-rupee">
                                        <i className="fa fa-rupee-sign"></i>
                                    </span>
                                    <input
                                        className="modal-input w-75"
                                        style={{ color: '#000000' }}
                                        value={withdrawInput.amount}
                                        onChange={(e) => {
                                            //doing this to ensure we get only 2 decical places in input
                                            const parts = e.target.value.split('.');
                                            if (parts[1] && parts[1].length > 2) {
                                                parts[1] = parts[1].slice(0, 2);
                                            }
                                            const newValue = parts.join('.');

                                            setWithdrawInput({
                                                ...withdrawInput,
                                                amount: newValue,
                                            });
                                        }}
                                        type="number"
                                        placeholder="Withdrawal Amount"
                                    />
                                </span>
                            </div>
                            <div className="modal-input-container">
                                <label className="input-div-label">Withdraw funds to</label>
                                <select
                                    className="modal-input w-50"
                                    style={{ color: '#000000' }}
                                    value={withdrawInput.bank}
                                    onChange={(e) =>
                                        setWithdrawInput({ ...withdrawInput, bank: e.target.value })
                                    }
                                    placeholder="Select Bank Account"
                                >
                                    <option value="">Select Bank Account</option>
                                    {bankList.map((item, index) => {
                                        let i = item.account_number.length;
                                        let accountNo = '';
                                        while (i-- > 4) {
                                            accountNo += '*';
                                        }
                                        accountNo += item.account_number.substr(
                                            item.account_number.length - 4,
                                        );
                                        return (
                                            <option key={index} value={item.escrow_pay_bank_id}>
                                                {item.nick_name}--{item.bank_name}--{accountNo}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <p className="subtitle-small">
                                <Link
                                    to={`${url}?account=true`}
                                    style={{ color: 'inherit' }}
                                    target="_blank"
                                >
                                    + add another account
                                </Link>
                            </p>
                            <button
                                style={{ width: '100%' }}
                                className={
                                    props.type === 'investee'
                                        ? 'modal-submit-btn-investee'
                                        : 'modal-submit-btn-investor'
                                }
                                onClick={() => callConfirm()}
                            >
                                Proceed
                                <img
                                    alt=""
                                    src="/assets/arrow-white.svg"
                                    style={{ width: '16px', paddingLeft: '5px' }}
                                />
                            </button>
                        </div>
                    )}
                    {withdrawModalState === 'Confirm' && (
                        <div>
                            <p
                                className="page-title-sub-para"
                                style={{ marginTop: '-25px', marginBottom: '22px' }}
                            >
                                Please confirm your request
                            </p>
                            <div className="withdraw-modal-flex-container">
                                <div
                                    className="withdraw-modal-flex-item subtitle-normal"
                                    style={{ width: '170px' }}
                                >
                                    Withdrawal amount:
                                </div>
                                <div
                                    className="withdraw-modal-flex-item subtitle-normal"
                                    style={{ fontWeight: '400' }}
                                >
                                    <i className="fa fa-rupee-sign"></i> {withdrawInput.amount}
                                </div>
                            </div>
                            <div className="withdraw-modal-flex-container">
                                <div
                                    className="withdraw-modal-flex-item subtitle-normal"
                                    style={{ width: '170px' }}
                                >
                                    Account holder name:
                                </div>
                                <div
                                    className="withdraw-modal-flex-item subtitle-normal"
                                    style={{ fontWeight: '400' }}
                                >
                                    {bankDetails.account_holder_name}
                                </div>
                            </div>
                            <div className="withdraw-modal-flex-container">
                                <div
                                    className="withdraw-modal-flex-item subtitle-normal"
                                    style={{ width: '170px' }}
                                >
                                    Account number:
                                </div>
                                <div
                                    className="withdraw-modal-flex-item subtitle-normal"
                                    style={{ fontWeight: '400' }}
                                >
                                    {bankDetails.account_number}
                                </div>
                            </div>
                            <div className="withdraw-modal-flex-container">
                                <div
                                    className="withdraw-modal-flex-item subtitle-normal"
                                    style={{ width: '170px' }}
                                >
                                    IFSC:
                                </div>
                                <div
                                    className="withdraw-modal-flex-item subtitle-normal"
                                    style={{ fontWeight: '400' }}
                                >
                                    {bankDetails.ifsc_code}
                                </div>
                            </div>
                            <div
                                className="withdraw-modal-flex-container"
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '35px',
                                }}
                            >
                                <p
                                    style={{ fontSize: '14px', margin: '0px' }}
                                    onClick={() => dispatch(withdrawModalStateChange('Proceed'))}
                                    className="withdraw-modal-back-button"
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 -5 5 20"
                                        className="trade-back-btn"
                                    >
                                        <path
                                            id="sg35cmay0a"
                                            d="M6.5 0.167L5.392 1.275 9.825 5.708 0.167 5.708 0.167 7.292 9.825 7.292 5.392 11.725 6.5 12.833 12.833 6.5z"
                                        />
                                    </svg>
                                    Back
                                </p>
                                <button
                                    className={
                                        props.type === 'investee'
                                            ? 'modal-submit-btn-investee'
                                            : 'modal-submit-btn-investor'
                                    }
                                    style={{ float: 'right', width: '65%' }}
                                    onClick={() => callWithdraw()}
                                >
                                    {withdrawState ? 'Confirming' : 'Confirm'}
                                    <img
                                        className="ml-2"
                                        style={{ width: '15px' }}
                                        alt=""
                                        src="/assets/white-forward-arrow.svg"
                                    />
                                </button>
                            </div>
                        </div>
                    )}
                    {withdrawModalState === 'Review' &&
                        Object.keys(transactionDetails).length !== 0 && (
                            <div style={{ textAlign: 'center' }}>
                                <div>
                                    <img alt="" src="/assets/order-placed.svg" />
                                </div>
                                <div className="order-place investor-green1">Request Placed</div>
                                <div className="mb-3 define mt-2">
                                    Funds will be credited to your account<br></br> within 1
                                    business day
                                </div>
                                <div className="trade-id">
                                    Withdawal ID: {transactionDetails.transaction_id}
                                </div>
                                <div
                                    style={{
                                        opacity: '0.26',
                                        border: '0.5px solid #979797',
                                        width: '90%',
                                        margin: '23px auto',
                                    }}
                                ></div>
                                <Link
                                    style={{ color: 'inherit' }}
                                    to={
                                        props.type === 'investee'
                                            ? '/company/finances/statements'
                                            : '/investor/finances/statements'
                                    }
                                >
                                    <p style={{ fontSize: '14px' }} onClick={() => toggleModal()}>
                                        Done
                                        <span className="Manage-in-Tradebook ml-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 13 13"
                                            >
                                                <defs>
                                                    <path
                                                        id="d9c0fym3ta"
                                                        d="M6.5 0.167L5.392 1.275 9.825 5.708 0.167 5.708 0.167 7.292 9.825 7.292 5.392 11.725 6.5 12.833 12.833 6.5z"
                                                    />
                                                </defs>
                                                <g fill="none" fillRule="evenodd">
                                                    <g>
                                                        <g>
                                                            <g transform="translate(-1085 -685) translate(833 180) translate(251.833 505)">
                                                                <use
                                                                    fill="#000"
                                                                    xlinkHref="#d9c0fym3ta"
                                                                />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                    </p>
                                </Link>
                            </div>
                        )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default WithdrawModal;
