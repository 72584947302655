import { useEffect, useState } from 'react';

export default function useShowOnHoverAndClick(blocked = false) {
    const [isClicked, setIsClicked] = useState(false);
    const [isOpenedFromHover, setIsOpenedFromHover] = useState(false);

    const onHover = () => {
        if (!blocked && !isClicked) {
            setIsOpenedFromHover(true);
        }
    };

    // This function was required to handle third click
    const onMouseLeave = () => {
        if (!blocked && !isClicked) {
            setIsOpenedFromHover(false);
        }
    };

    const handleClick = () => {
        if (isOpenedFromHover) {
            setIsClicked(true);
            setIsOpenedFromHover(false);
        } else setIsClicked(!isClicked);
    };

    useEffect(() => {
        if (isClicked) {
            document.addEventListener('click', handleClick);
        }
        if (!isClicked) {
            document.removeEventListener('click', handleClick);
        }
        return () => {
            document.removeEventListener('click', handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClicked]);

    const setShowFalse = () => {
        setIsOpenedFromHover(false);
        setIsClicked(false);
    };

    const hookObj = {
        onMouseEnter: onHover,
        onMouseLeave: onMouseLeave,
        onClick: handleClick,
    };

    return [isClicked || isOpenedFromHover, hookObj, setShowFalse];
}
