import { useState } from 'react';
import styles from './DirectorDetails.module.scss';
import clsx from 'clsx';
import moment from 'moment';

interface DirectorDetails {
    directorName: string;
    phoneNo: string;
    consentStatus?: boolean;
    lastInviteSentAt?: string;
    isNewDir?: boolean;
}

interface DirectorInputFieldProps {
    director: DirectorDetails;
    index: number;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    sendLink: (name: string, phoneNo: string, setDisableSendLink: Function) => void;
}

function DirectorInputField({ director, index, handleChange, sendLink }: DirectorInputFieldProps) {
    const {
        FormContainer,
        FormInput,
        Field,
        FormLabel,
        DisabledBannerText,
        LabelTop,
        BannerTextVerified,
        LastDateText,
        BannerText,
    } = styles;

    const [disableSendLink, setDisableSendLink] = useState<boolean>(true);

    return (
        <div key={index} className={FormContainer}>
            <div className={Field}>
                <input
                    id="phoneNo"
                    name="phoneNo"
                    type="text"
                    value={director.phoneNo}
                    onChange={(e) => {
                        handleChange(e, index);
                    }}
                    className={clsx(FormInput)}
                    disabled={director.consentStatus}
                    required
                />
                <label
                    className={clsx(FormLabel, {
                        [LabelTop]: director.consentStatus,
                    })}
                    htmlFor="name"
                >
                    Mobile No. of {director.directorName}
                </label>
                {director.phoneNo &&
                    (director.consentStatus ? (
                        <div className={BannerTextVerified}>Verified</div>
                    ) : (
                        <div
                            onClick={() =>
                                sendLink(
                                    director.directorName,
                                    director.phoneNo,
                                    setDisableSendLink,
                                )
                            }
                            className={
                                disableSendLink && director.phoneNo.length === 10
                                    ? BannerText
                                    : DisabledBannerText
                            }
                        >
                            <span className={LastDateText}>
                                {director.lastInviteSentAt &&
                                    `Sent ${moment(director.lastInviteSentAt).format(
                                        'hh:mm A DD MMM YYYY ',
                                    )}`}{' '}
                            </span>
                            {director.lastInviteSentAt ? 'Resend Link' : 'Send Link'}
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default DirectorInputField;
