import { Box, Typography } from '@mui/material';
import styles from './Landscape.module.scss';
const Landscape = () => {
    return (
        <Box className={styles.landscape}>
            <Box m={'auto'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <img
                    src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/landscape.svg"
                    className={styles.landscapeImg}
                />
                <Typography
                    color="#979797"
                    fontWeight={500}
                    fontSize={'0.625rem'}
                    textAlign={'center'}
                >
                    This page is not supported on Landscape mode, please switch to Portrait mode to
                    access the page
                </Typography>
            </Box>
        </Box>
    );
};

export default Landscape;
