import React from 'react';
import useShowOnHoverAndClick from '../../../customHooks/useShowOnHoverAndClick';
import { USER_TYPE_INVESTEE } from '../../../enums/appEnums';
import styles from '../styles/FinancesButton.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DRAWER_HEADINGS } from '../../../DrawerConsts';
import { triggerDrawer } from '../../../modules/App/AppActions';

export default function FinancesButton({ onSelection, blocked = false, category, routes }) {
    const { OuterContainer, InnerContainer, InvesteeSubLinks, InvestorSubLinks } = styles;
    const [showOptions, btnProps, setShowFalse] = useShowOnHoverAndClick(blocked);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const options =
        category === USER_TYPE_INVESTEE
            ? routes ?? [
                  {
                      name: 'Transactions',
                      url: '/company/finances/transactions',
                  },
                  {
                      name: 'Reports',
                      url: '/company/finances/reports',
                  },
                  {
                      name: 'Invoices',
                      url: '/company/finances/invoices',
                  },
              ]
            : [
                  {
                      name: 'Repayments',
                      url: '/investor/finances/transactions',
                  },
                  //   {
                  //       name: 'Statements',
                  //       url: '/investor/finances/statements',
                  //   },
                  {
                      name: 'Invoices',
                      url: '/investor/finances/invoices',
                  },
                  {
                      name: 'TDS',
                  },
              ];

    const redirect = (e) => {
        setShowFalse();
        for (let i of options) {
            if (i.name === 'TDS') {
                dispatch(triggerDrawer(DRAWER_HEADINGS.TDS_DEPOSIT_DETAILS));
                break;
            }
            if (i.name === e.target.id && !i.block) {
                navigate(i.url);
                onSelection();
                break;
            }
        }
    };

    const isFinancesActive = () => {
        const url = location.pathname;
        const regex = /company\/finances/g;
        if (regex.test(url)) return true;
        return false;
    };

    const getClassName = () => {
        if (blocked) return 'disable-modules';
        if (isFinancesActive()) {
            if (category === USER_TYPE_INVESTEE) return 'sidebar-modules activeStyle-investee';
            else return 'sidebar-modules activeStyle';
        }
        return 'sidebar-modules';
    };

    return (
        <div
            className={getClassName()}
            style={{ cursor: 'pointer', position: 'relative' }}
            {...btnProps}
        >
            <span>Finances</span>
            {showOptions && (
                <>
                    <div className={OuterContainer}>
                        {!blocked && (
                            <div className={InnerContainer}>
                                {options.map((option) => (
                                    <div
                                        key={option.name}
                                        id={option.name}
                                        onClick={redirect}
                                        className={
                                            category === USER_TYPE_INVESTEE
                                                ? InvesteeSubLinks
                                                : InvestorSubLinks
                                        }
                                    >
                                        {option.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
