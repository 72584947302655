import useMediaQuery from '@mui/material/useMediaQuery';
const Typography = (theme) => {
    const breakpoints = theme.breakpoints.values;
    const isSmScreen = useMediaQuery(`(max-width: ${breakpoints.xs}px)`);
    return {
        h1: {
            fontWeight: 500,
            letterSpacing: '0',
            color: theme.palette.text.primary,
        },
        h2: {
            fontWeight: 500,
            letterSpacing: '0',
            color: theme.palette.text.primary,
        },
        h3: {
            fontWeight: 500,
            letterSpacing: 0,
            color: theme.palette.text.primary,
        },
        h4: {
            fontWeight: 500,
            letterSpacing: '0',
            color: theme.palette.text.primary,
        },
        h5: {
            fontWeight: 500,
            letterSpacing: 0,
            color: theme.palette.text.primary,
        },
        h6: {
            letterSpacing: '0',
            color: theme.palette.text.primary,
        },
        subtitle1: {
            letterSpacing: '0',
            color: theme.palette.text.primary,
        },
        subtitle2: {
            letterSpacing: '0',
            color: theme.palette.text.secondary,
        },
        body1: {
            letterSpacing: '0',
            color: theme.palette.text.primary,
            fontSize: isSmScreen ? '0.75rem' : '1rem',
        },
        body2: {
            letterSpacing: '0',
            color: theme.palette.text.secondary,
            fontSize: isSmScreen ? '0.5rem' : '0.75rem',
        },
        button: {
            letterSpacing: '0',
            color: theme.palette.text.primary,
        },
        caption: {
            lineHeight: 1.25,
            letterSpacing: '0',
            color: theme.palette.text.secondary,
        },
        overline: {
            letterSpacing: '0',
            color: theme.palette.text.secondary,
        },
    };
};

export default Typography;
