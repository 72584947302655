import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import {
    getApprovedState,
    getAuthenticatedState,
    getEmailVerifiedState,
    getIfUserIsLoggingOut,
    getInvesteeOrganizationInfoStatus,
    getUserInvestorOrganizationId,
} from '../../App/AppReducer';
import storage from '../../../util/storageService';
import { fetchUserProfile, logOutUser } from '../../App/AppActions';
import { Navigate, NavigateFunction } from 'react-router-dom';
import useDidMountEffect from '../../../customHooks/useDidMountEffect';
import SidebarV2 from '../../../components/Sidebar/SidebarV2';
import { USER_TYPE_INVESTEE, USER_TYPE_INVESTOR } from '../../../enums/appEnums';
import PageLoader from '../../../components/loader/PageLoader';
import withRouter from '../../../common/withRouter';
import { isThereAuthComponents } from '../../../util/utility';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import { fetchInvestorOpsDetailsForDeals } from '../InvestorActions';

function InvestorPrivateRoute(props: any) {
    const {
        children,
        location,
        navigate,
    }: { children: JSX.Element; location: Location; navigate: NavigateFunction } = props;
    const dispatch = useDispatch();
    const status = useSelector(getInvesteeOrganizationInfoStatus);
    const isAuthenticated = useSelector(getAuthenticatedState);
    const hasToken = isThereAuthComponents();
    const isUserApproved = useSelector(getApprovedState);
    const isEmailVerified = useSelector(getEmailVerifiedState);
    const isLoggingOut = useSelector(getIfUserIsLoggingOut);
    const investor_organization_id = useSelector(getUserInvestorOrganizationId);

    const [isFetchingUser, setIsFetchingUser] = useState(false);

    useEffect(() => {
        let userId = storage.get('user_id');
        let isInvesteeLoggedIn = storage.get(USER_TYPE_INVESTEE);

        //loggout user in case of previous user was of investee;
        if (isInvesteeLoggedIn) {
            dispatch(logOutUser(navigate, USER_TYPE_INVESTOR));
            return;
        }

        if (isNil(status) && userId && !isFetchingUser && !investor_organization_id) {
            setIsFetchingUser(true);
            dispatch(fetchUserProfile(userId, false, false, true)); // phone Verification not included for Investor
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (investor_organization_id)
            dispatch(fetchInvestorOpsDetailsForDeals(investor_organization_id));
    }, [investor_organization_id]);

    const renderUi = () => {
        let defaultPath = '/investor/login';
        if (isAuthenticated && !isUserApproved) {
            if (isEmailVerified) defaultPath = `/investor/request-submitted`;
            else defaultPath = `/investor/verify-email`;
        }
        return <Navigate to={defaultPath} state={{ from: location }} replace />;
    };

    useDidMountEffect(() => {
        if (investor_organization_id) setIsFetchingUser(false);
    }, [investor_organization_id]);

    if (!isAuthenticated) {
        if (hasToken || isFetchingUser) return <PageLoader />;
        return renderUi();
    }

    if (isAuthenticated && !isUserApproved) return renderUi();

    return (
        <>
            {isAuthenticated && (
                <SidebarV2
                    isAuthenticated={isAuthenticated}
                    userSegment={USER_TYPE_INVESTOR}
                    isUserApproved={isUserApproved}
                    isEmailVerified={isEmailVerified}
                    isLoggingOut={isLoggingOut}
                    selectedSegment={USER_TYPE_INVESTOR}
                    navigate={navigate}
                />
            )}
            <div className="d-flex">
                <div className="logged-in-wrapper" style={{ overflow: 'hidden' }}>
                    {children}
                </div>
            </div>
            {isAuthenticated && <CustomDrawer />}
        </>
    );
}

export default withRouter(InvestorPrivateRoute);
