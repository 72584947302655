import React from 'react';
import { useEffect } from 'react';
import callApi from '../../../../util/apiCaller';
import { showNotification } from '../../../../util/utility';
import styles from '../CountryCodesDropdown/CountryCodesDropdown.module.scss';

function CountryCodesDropdown(props) {
    const { countryCodeBox, modalCountryCodebox } = styles;
    const [countryCodes, setCountryCodes] = React.useState([]);

    useEffect(() => {
        fetchCountryCodes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (i) => {
        props.setCountryCode(countryCodes[i]);
    };

    const fetchCountryCodes = () => {
        callApi('/geo/getCountryCallingCodes', 'get', null, {}, false, true).then((res) => {
            if (res.responseData.responseCode === 20) {
                setCountryCodes(res.geoCallingMapperList);
                props.setCountryCode(res.geoCallingMapperList[0]);
            } else {
                showNotification(
                    'Error',
                    res.responseData.responseMessage ?? "Couldn't Fetch Country Codes",
                );
            }
        });
    };

    return (
        <select
            disabled={props.disableBtn}
            style={props.style}
            className={props.isModal ? modalCountryCodebox : countryCodeBox}
            defaultValue={props.countryCode && props.countryCode}
            onChange={(e) => handleChange(e.target.value)}
        >
            {countryCodes &&
                countryCodes.map((item, index) => {
                    return (
                        <option key={index} value={index}>
                            +{item.callingCode} {!props.needNumber && `(${item.countryCode})`}
                        </option>
                    );
                })}
        </select>
    );
}

export default CountryCodesDropdown;
