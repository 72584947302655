import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getFinboxError, getFinboxRedirectUrl } from '../../modules/Investee/InvesteeReducers';
import { showNotification } from '../../util/utility';

export default function FinboxModal({ showFinbox, onSuccess, onExit, onError }) {
    const redirectUrl = useSelector((state) => getFinboxRedirectUrl(state));
    const finboxError = useSelector((state) => getFinboxError(state));

    useEffect(() => {
        window.addEventListener('message', eventTrigrred);
        return () => {
            window.removeEventListener('message', eventTrigrred);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eventTrigrred = (e) => {
        if (e.data.status === 'exit') {
            onExit(e.data.payload);
        } else if (e.data.status === 'success') {
            onSuccess(e.data.payload);
        } else if (e.data.status === 'error') {
            onError(e.data.payload);
        }
    };

    if (redirectUrl) {
        return (
            <div>
                <Modal show={showFinbox} onHide={onExit} centered>
                    <Modal.Body>
                        <iframe title="Finbox" src={redirectUrl} width="450px" height="700px" />
                    </Modal.Body>
                </Modal>
            </div>
        );
    } else {
        showNotification('Error', finboxError);
        return null;
    }
}
