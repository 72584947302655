import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, Outlet } from 'react-router-dom';
import withRouter from '../../../common/withRouter';
import useDidMountEffect from '../../../customHooks/useDidMountEffect';
import {
    getApprovedState,
    getAuthenticatedState,
    getEmailVerifiedState,
} from '../../App/AppReducer';
import { triggerDrawer } from '../../App/AppActions';
import { DRAWER_HEADINGS } from '../../../DrawerConsts';

const InvestorBaseComponent = ({
    navigate,
    location,
}: {
    navigate: NavigateFunction;
    location: any;
}) => {
    const isAuthenticated = useSelector(getAuthenticatedState);
    const isUserApproved = useSelector(getApprovedState);
    const isEmailVerified = useSelector(getEmailVerifiedState);
    const dispatch = useDispatch();

    /**
     * handle all after login cases here
     * scenario 1: if user tried to access any private route such as /investor/dashboard
     * redirect user to same url after login (retain user link after login)
     *
     * scenario 2: user tries to refresh page while logged in should land on same page
     *
     * scenario 3: simple case of user logging should land on dashboard by default;
     */
    useDidMountEffect(() => {
        const isNotLoginPage = location?.pathname.includes('/investor/login');
        if (isAuthenticated && isUserApproved && isEmailVerified) {
            if (isEmpty(location?.state) && isNotLoginPage) {
                navigate('/investor/dashboard', { replace: true }); //reset previous path
            } else {
                const pathToNavigate =
                    location?.state?.from?.pathname + location?.state?.from?.search;
                navigate(
                    pathToNavigate ? pathToNavigate : `${location?.pathname}${location?.search}`,
                );
            }
            //@TODO do this in investee base component also. handle other drawer also
            const drawerParam = new URLSearchParams(location?.search)?.get('drawer');

            if (drawerParam === DRAWER_HEADINGS.TDS_DEPOSIT_DETAILS.toLocaleLowerCase())
                navigate('/investor/customer-requests');
        }
    }, [isAuthenticated]);

    return <Outlet />;
};

export default withRouter(InvestorBaseComponent);
