import clsx from 'clsx';
import '../../UploadDataJourney.scss';

interface BackNextButtonProps {
    onBackClick?: Function;
    onNextClick?: Function;
    disableBack?: boolean;
    disableNext?: boolean;
}

function BackNextButtons({
    onBackClick,
    onNextClick,
    disableBack,
    disableNext,
}: BackNextButtonProps) {
    return (
        <div className={'BackNextButtons'}>
            {onBackClick ? (
                <div
                    className={clsx('Back', {
                        BackDisabled: disableBack,
                    })}
                    onClick={() => onBackClick && onBackClick()}
                >
                    &uarr;&nbsp;Back
                </div>
            ) : (
                <div></div>
            )}

            <div>
                <div className={'ProgressSaved'}>Progress Autosaved</div>
                {onNextClick ? (
                    <div
                        className={clsx('Next', { NextDisabled: disableNext })}
                        onClick={() => onNextClick && onNextClick()}
                    >
                        &darr;&nbsp;Next
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default BackNextButtons;
