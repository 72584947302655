import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import _, { isNil } from 'lodash';

export const MONTHLY_TIME_PERIOD = 'monthly';
export const QUARTERLY_TIME_PERIOD = 'quarterly';
export const SEMI_ANNUAL_TIME_PERIOD = 'semi-annually';
export const ANNUAL_TIME_PERIOD = 'annually';

dayjs.extend(UTC);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

function getContractTransactionDetails(input, boolMoratorium) {
    const obj = {};

    const payment_delay_with_additional = input.payment_delay + input.additionalDelay; // for revising dates if gapvalue check doesn't meet(two repayments clash)
    const collection_date = dayjs(input.invoice_date).add(payment_delay_with_additional, 'days');

    const month = collection_date.format('MM');
    const year = collection_date.format('YYYY');
    const toCheckDate = dayjs(`${year}-${parseInt(month)}-${input.repaymentDay}`, 'YYYY-MM-DD');

    let repayment_date;
    if (collection_date.isSameOrAfter(toCheckDate)) {
        // isAfter // isBefore
        if (parseInt(month) === 12) {
            repayment_date = dayjs.utc(
                `${parseInt(year) + 1}-01-${input.repaymentDay}`,
                'YYYY-MM-DD',
            );
        } else {
            repayment_date = dayjs.utc(
                `${year}-${parseInt(month) + 1}-${input.repaymentDay}`,
                'YYYY-MM-DD',
            );
        }
    } else {
        repayment_date = dayjs.utc(`${year}-${month}-${input.repaymentDay}`, 'YYYY-MM-DD');
    }
    let datetocheck;
    if (boolMoratorium) {
        let today = dayjs().utcOffset(330);
        const dayToday = parseInt(today.format('D'));
        if (dayToday >= 28) {
            today = today.add(1, 'M');
        }
        const monthToday = today.format('MM');
        const yearToday = today.format('YYYY');
        datetocheck = dayjs.utc(`28-${monthToday}-${yearToday}`, 'DD-MM-YYYY');
    } else {
        datetocheck = dayjs().utcOffset(330);
    }

    if (repayment_date.isSameOrBefore(datetocheck)) {
        obj.is_expired = true;
    }
    obj.scheduled_invoice_date = input.invoice_date;
    obj.scheduled_recur_payment_date = repayment_date;
    obj.scheduled_recur_payment_amount = input.contract.recurring_payment_value;
    return obj;
}

function getAllPossibleContractTransactions(
    contract,
    additionalDelay,
    paymentDelay,
    boolMoratorium,
) {
    const unit = contract.contract_term_unit.toLowerCase();
    const freqGapObj = getRepaymentFrequencyAndGapValue(unit);
    const frequency = freqGapObj.frequency;
    const contractStartDate = contract.contract_start_date;
    let contractEndDate = contract.contract_end_date;
    let startEndDiff = 0;
    if (!contractEndDate) {
        const now = dayjs().utcOffset(330).startOf('day').endOf('month');
        contractEndDate = now.add(13, 'months');
    }
    startEndDiff = monthsDiffBetweenDates(contractStartDate, contractEndDate);
    const sdate = dayjs.utc(contract.contract_start_date);
    const daySdate = sdate.format('DD');
    const monSdate = sdate.format('MM');
    const yearSdate = sdate.format('YYYY');
    let invoice_date = dayjs.utc(`${daySdate}-${monSdate}-${yearSdate}`, 'DD-MM-YYYY');

    const dayOfInitialDate = parseInt(sdate.format('DD'));
    const lastDayOfMonth = sdate.endOf('month').format('DD');
    const addTillEnd = dayOfInitialDate === parseInt(lastDayOfMonth) ? true : false;

    const contract_tranactions = [];
    for (let i = 0; i <= startEndDiff - frequency; i += frequency) {
        const input = {
            contract: contract,
            payment_delay: paymentDelay,
            invoice_date: invoice_date.clone(),
            additionalDelay: additionalDelay,
            repaymentDay: 28,
        };

        const trxDetails = getContractTransactionDetails(input, boolMoratorium);
        contract_tranactions.push(trxDetails);

        invoice_date = addMonth(invoice_date, frequency, dayOfInitialDate, addTillEnd);
        // invoice_date = invoice_date.add(frequency,'M');
    }
    return contract_tranactions;
}

function checkIfRevisionRequired(transactionObjArray, gapValue, gapValueGT) {
    for (let i = 0; i < transactionObjArray.length - 1; i++) {
        const current = transactionObjArray[i].scheduled_recur_payment_date;
        const next = transactionObjArray[i + 1].scheduled_recur_payment_date;
        const diffinDays = dayjs(next).diff(dayjs(current), 'days');
        if (diffinDays < gapValue || diffinDays > gapValueGT) {
            return true;
        }
    }
    return false;
}

export const calculateTerm = (contract, paymentDelay, fees_perc, boolMoratorium) => {
    let transactionObjArr = getAllPossibleContractTransactions(contract, 0, paymentDelay);
    const unexpiredTrxArray = [];
    const expiredTrxArray = [];
    if (transactionObjArr) {
        const gapfreqObj = getRepaymentFrequencyAndGapValue(contract.contract_term_unit);
        const maxVal = gapfreqObj.maxVal;
        const displayUnit = gapfreqObj.displayUnit;
        const isRevisionReq = checkIfRevisionRequired(
            transactionObjArr,
            gapfreqObj.gapValue,
            gapfreqObj.gapValueGT,
        );
        if (isRevisionReq) {
            transactionObjArr = getAllPossibleContractTransactions(
                contract,
                10,
                paymentDelay,
                boolMoratorium,
            );
        }
        let unexpiredTrxCount = 0;
        transactionObjArr.forEach((item) => {
            if (item.is_expired) {
                expiredTrxArray.push(item);
            } else {
                unexpiredTrxCount++;
                if (unexpiredTrxArray.length < maxVal) {
                    unexpiredTrxArray.push(item);
                }
            }
        });
        const fees = calculateFee(fees_perc, unexpiredTrxArray[unexpiredTrxArray.length - 1]);
        const termCount = unexpiredTrxCount > maxVal ? maxVal : unexpiredTrxCount;
        return {
            term: `${termCount} ${termCount > 1 ? displayUnit + 's' : displayUnit}`,
            transactionsArr: unexpiredTrxArray,
            term_numeric: Number(termCount),
            contract_fee_perc: fees,
        };
    } else {
        return '';
    }
};

function calculateFee(fee_perc, lastRepaymentDate) {
    if (fee_perc && lastRepaymentDate) {
        let days_difference = dayjs(lastRepaymentDate.scheduled_recur_payment_date)?.diff(
            dayjs().utcOffset(330),
            'days',
        );
        if (days_difference > 365) {
            days_difference = 365;
        }
        let fee = (fee_perc * days_difference) / 365;
        return Number(fee.toFixed(2));
    } else {
        return 0;
    }
}

export function mergeByProperty(arr1, arr2, prop) {
    _.each(arr2, function (arr2obj) {
        const arr1obj = _.find(arr1, function (arr1obj) {
            return arr1obj[prop] === arr2obj[prop];
        });

        arr1obj ? _.extend(arr1obj, arr2obj) : arr1.push(arr2obj);
    });
    return arr1;
}

export const termUnit = (term_unit, totalNoOfRepayments) => {
    const lowerCaseUnit = term_unit.toLowerCase();
    switch (lowerCaseUnit) {
        case MONTHLY_TIME_PERIOD:
            return !isNil(totalNoOfRepayments) && totalNoOfRepayments > 1 ? 'months' : 'month';
        case QUARTERLY_TIME_PERIOD:
            return !isNil(totalNoOfRepayments) && totalNoOfRepayments > 1 ? 'quarters' : 'quarter';
        case SEMI_ANNUAL_TIME_PERIOD:
            return 'half year';
        default:
            return 'year';
    }
};

export const displayTermUnit = (unit) => {
    if (unit) {
        const lowerCaseUnit = unit.toLowerCase();
        switch (lowerCaseUnit) {
            case MONTHLY_TIME_PERIOD:
                return 'mo';
            case QUARTERLY_TIME_PERIOD:
                return 'qtr';
            case SEMI_ANNUAL_TIME_PERIOD:
                return '6 m';
            default:
                return 'yr';
        }
    } else {
        return '';
    }
};

export const getRepaymentFrequencyAndGapValue = (unit) => {
    const lowerCaseUnit = unit.toLowerCase();
    switch (lowerCaseUnit) {
        case MONTHLY_TIME_PERIOD:
            return {
                frequency: 1,
                gapValue: 10,
                gapValueGT: 40,
                displayUnit: 'month',
                maxVal: 12,
            };
        case QUARTERLY_TIME_PERIOD:
            return {
                frequency: 3,
                gapValue: 75,
                gapValueGT: 105,
                displayUnit: 'quarter',
                maxVal: 4,
            };
        case SEMI_ANNUAL_TIME_PERIOD:
            return {
                frequency: 6,
                gapValue: 170,
                gapValueGT: 205,
                displayUnit: 'half year',
                maxVal: 2,
            };
        default:
            return {
                frequency: 12,
                gapValue: 340,
                gapValueGT: 380,
                displayUnit: 'year',
                maxVal: 1,
            };
    }
};

export const addMonth = (date, months, dayOfInitialDate, addTillEnd) => {
    const futureMonth = date.clone().add(months, 'M');
    const noDaysInMonth = getNoOfDays(futureMonth);
    const month = dayjs(futureMonth).format('MM');
    const year = dayjs(futureMonth).format('YYYY');

    if (addTillEnd) {
        return dayjs(futureMonth).endOf('month');
    } else if (parseInt(dayOfInitialDate) < noDaysInMonth) {
        return dayjs.utc(`${dayOfInitialDate}-${parseInt(month)}-${year}`, 'DD-MM-YYYY');
    } else {
        return dayjs.utc(`${parseInt(noDaysInMonth)}-${parseInt(month)}-${year}`, 'DD-MM-YYYY');
    }
};

const getNoOfDays = (date) => {
    return dayjs(date, 'YYYY-MM-DD').daysInMonth();
};

export const monthsDiffBetweenDates = (start, end) => {
    const start_date = dayjs.utc(start).startOf('day');
    const end_date = dayjs.utc(end).startOf('day');

    let diff = 0;
    while (
        start_date
            .clone()
            .add(diff + 1, 'months')
            .add(-1, 'days')
            .isSameOrBefore(end_date)
    ) {
        diff += 1;
    }
    return diff;
};
