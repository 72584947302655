const breakpoints = () => ({
    values: {
        xs: 320,
        sm: 481,
        md: 769,
        lg: 1025,
        xl: 1201,
    },
});

export default breakpoints;
