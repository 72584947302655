import React from 'react';
import './DisabledUploadCard.scss';

function DisabledUploadCard() {
    return (
        <div className="DisabledUploadCard">
            <div className="fs-12">
                <img
                    className="mr-1"
                    src="/assets/cloud_upload_grey.svg"
                    alt=""
                    style={{ width: '21px' }}
                />
                Bank Statement PDF (last 12 months)
            </div>
            <div className="fs-10">Click or drag here to upload files</div>
        </div>
    );
}

export default DisabledUploadCard;
