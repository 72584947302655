import DragDropUpload from '../../../../../../components/DragDropUpload';
import { UPLOAD_STAGE } from '../../utils/constants';
import { INVOICING } from '../Invoicing/Invoicing';
import { MIS } from '../MISUpload';

import UploadCard from '../../utils/UploadCard/UploadCard';
import styles from './UploadCsv.module.scss';
interface UploadCsvProps {
    selectOption: Function;
    onUploadDocSuccess: Function;
    category: string;
    keyName: string;
    stage: string;
    files: FileData[];
    investeeOnboardedId: string;
    path: string;
}

function UploadCsv({
    category,
    keyName,
    files,
    path,
    stage,
    selectOption,
    onUploadDocSuccess,
    investeeOnboardedId,
}: UploadCsvProps) {
    const deleteKeyName = () => {
        if (path === UPLOAD_STAGE.ONBOARDING) return category;
        else {
            if (category === INVOICING) return 'onboarding_stage_data_invoicing';
            else if (category === MIS) return 'onboarding_stage_data_financials_mis';
        }
    };

    const getHeading = () => {
        if (category === INVOICING) return 'Invoicing';
        else if (category === MIS) return 'MIS';
    };

    return (
        <div className={styles.Csv}>
            <div
                className={styles.Top}
                onClick={() => {
                    selectOption('');
                }}
            >
                <img src="/assets/arrow-left-blue.svg" alt="arrow" height="12px" />
                &nbsp;&nbsp;Integrate with {getHeading()} software
            </div>
            <div className={styles.Title}>{getHeading()} Data (Upload CSV)</div>
            <div className={styles.UploadText}>
                Upload {category === INVOICING ? 'invoicing data' : 'monthly MIS'} as you maintain
                it (last 24 months).{' '}
                <span className={styles.Blue}>
                    <a
                        href="https://help.recur.club/en/article/how-to-upload-data"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Learn More.
                    </a>
                </span>
            </div>

            <UploadCard
                mainOnboarding={false}
                category={category}
                keyName={keyName}
                deleteKeyName={deleteKeyName()}
                onSuccess={onUploadDocSuccess}
                mainText="Invoicing Data (last 24 months)"
                files={files}
                path={path}
                stage={stage}
                investee_onboarded_id={investeeOnboardedId}
                renderComponent={<DragDropUpload />}
            />
        </div>
    );
}

export default UploadCsv;
