const Avatar = (theme) => {
    return {
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.action.selected,
                },
                rounded: {
                    borderRadius: 8,
                },
            },
        },
        MuiAvatarGroup: {
            styleOverrides: {
                root: {
                    justifyContent: 'flex-end',
                    '.MuiCard-root & .MuiAvatar-root': {
                        borderColor: theme.palette.background.paper,
                    },
                },
            },
        },
    };
};

export default Avatar;
