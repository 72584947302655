import { UPLOAD_STAGE } from '../../utils/constants';
import { INVOICING } from '../Invoicing/Invoicing';
import { MIS } from '../MISUpload';

import DragDropUpload from '../../../../../../components/DragDropUpload';
import UploadCard from '../../utils/UploadCard/UploadCard';
import styles from './UploadTally.module.scss';
interface UploadTallyProps {
    category: string;
    keyName: string;
    onUploadDocSuccess: Function;
    files: FileData[];
    path: string;
    selectOption: Function;
    investeeOnboardedId: string;
    isTallyConnector: boolean;
    updatingTallyConnector: boolean;
    toggleTallyConnector: Function;
    isReviewPage: boolean;
}

function UploadTally({
    category,
    keyName,
    onUploadDocSuccess,
    files,
    path,
    selectOption,
    investeeOnboardedId,
    isTallyConnector,
    updatingTallyConnector,
    toggleTallyConnector,
    isReviewPage = false,
}: UploadTallyProps) {
    const deleteKeyName = () => {
        if (path === UPLOAD_STAGE.ONBOARDING) return category;
        else {
            if (category === INVOICING) return 'onboarding_stage_data_invoicing';
            else if (category === MIS) return 'onboarding_stage_data_financials_mis';
        }
    };

    const getHeading = () => {
        if (category === INVOICING) return 'Invoicing';
        else if (category === MIS) {
            const heading = path === UPLOAD_STAGE.ONBOARDING ? 'Provisional Financials ' : 'MIS';
            return heading;
        }
    };
    return (
        <div className={styles.Tally}>
            <div
                className={styles.Top}
                onClick={() => {
                    selectOption('');
                }}
            >
                <img src="/assets/arrow-left-blue.svg" alt="arrow" height="12px" />
                &nbsp;&nbsp;Integrate with {getHeading()} software
            </div>
            <div className={styles.Title}>{getHeading()} (Upload Tally Export)</div>

            <div className={styles.UploadText}>
                Upload monthly {getHeading()} from your Tally export.&nbsp;
                <span className={styles.Blue}>
                    <a
                        href="https://help.recur.club/en/article/how-to-upload-data"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Learn how to export from Tally.
                    </a>
                </span>
            </div>

            <UploadCard
                category={category}
                deleteKeyName={deleteKeyName()}
                keyName={keyName}
                onMetadataSuccess={onUploadDocSuccess}
                onSuccess={onUploadDocSuccess}
                mainText="Tally Dump or Export (last 24 months)"
                isTally
                files={files}
                investee_onboarded_id={investeeOnboardedId}
                path={path}
                renderComponent={<DragDropUpload />}
                useMnemosyneUploadFlow={true}
            />

            {!isReviewPage && (
                <div className={styles.BottomText}>
                    <input
                        type="checkbox"
                        className="mr-1 cursor-pointer"
                        id="terms"
                        value={1}
                        style={{ marginBottom: '2px' }}
                        checked={isTallyConnector}
                        disabled={updatingTallyConnector}
                        onChange={(e) => toggleTallyConnector(e.target.checked)}
                    />
                    <span className="fs-12">
                        <span className="blue1">Set-up tally connector</span> for easy onboarding
                        and unlock higher limits
                    </span>
                </div>
            )}
        </div>
    );
}

export default UploadTally;
