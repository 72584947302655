import dayjs from 'dayjs';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { UPLOAD_STAGE } from '../../utils/constants';
import { FinancialChecks } from '../../utils/checks';
import csvLogo from '../../../../../../assets/UploadDataJourney/csv-icon.svg';

import UploadCsv from '../UploadCsv';
import UploadTally from '../UploadTally';
import SmallMonthlyView from '../SmallMonthlyView/SmallMonthlyView';
import BackNextButtons from '../BackNextButtons/BackNextButtons';
import {
    allIntegrations,
    handleIntegration,
    INTEGRATION_LOGOS,
    INTEGRATION_TYPES,
} from '../../utils/integrations';
import styles from './MISUpload.module.scss';
interface MISUploadProps {
    data: any;
    monthlyViewData?: any;
    files: any[];
    investeeOnboardedId: string;
    integration: string;
    isReviewPage?: boolean;
    path?: string | null;
    isTallyConnector: boolean;
    updatingTallyConnector: boolean;
    stage: string;
    fetchData: Function;
    toggleTallyConnector: Function;
    fetchMonthlyViewData: Function;
    updateDataAttachments?: Function;
    goToNextStep: Function;
    goToPreviousStep: Function;
    onMetadataSuccess: Function;
}

export const MIS = 'mis';
export const UPLOAD_KEY = 'onboarding_stage_data_financials_mis';

function MISUpload({
    data,
    monthlyViewData,
    stage,
    fetchMonthlyViewData,
    files,
    investeeOnboardedId,
    integration,
    isReviewPage = false,
    path,
    isTallyConnector,
    updatingTallyConnector,
    toggleTallyConnector,
    updateDataAttachments,
    goToNextStep,
    goToPreviousStep,
    onMetadataSuccess,
}: MISUploadProps) {
    const [hovered, setHovered] = useState(false);
    const [selected, setSelected] = useState<string | null>(null);
    const [csvFiles, setCsvFiles] = useState<FileData[]>([]);
    const [tallyFiles, setTallyFiles] = useState<FileData[]>([]);
    const [transition, setTransition] = useState('');

    const collapsibleMonthlyViewData = [
        {
            label: 'PNL',
            availableMonthsCount: monthlyViewData?.pnl_complete_months,
            monthlyViewDates: monthlyViewData?.availablePnlMonthlyDates,
        },
        {
            label: 'Cashflow',
            availableMonthsCount: monthlyViewData?.cashflow_complete_months,
            monthlyViewDates: monthlyViewData?.availableCashflowMonthlyDates,
        },
        {
            label: 'Balance Sheet',
            availableMonthsCount: monthlyViewData?.balance_sheet_complete_months,
            monthlyViewDates: monthlyViewData?.availableBalanceSheetMonthlyDates,
        },
    ];

    useEffect(() => {
        if (files && files?.length) {
            let csv_files: FileData[] = [],
                tally_files: FileData[] = [];
            let newFiles = JSON.parse(JSON.stringify(files));
            newFiles.forEach((file: FileData) => {
                if (file?.doc_name) {
                    const names = file?.doc_name.split('_');
                    const namesLength = names?.length;
                    file.doc_name = file?.doc_name.replace('_mis', '');
                    if (names && namesLength >= 2) {
                        if (names[namesLength - 1].includes('tally')) {
                            file.doc_name = file?.doc_name.replace('_tally', '');
                            tally_files.push(file);
                        } else {
                            csv_files.push(file);
                        }
                        setCsvFiles(csv_files);
                        setTallyFiles(tally_files);
                    }
                }
            });
        } else {
            setTallyFiles([]);
            setCsvFiles([]);
        }
        //eslint-disable-next-line
    }, [files]);

    const integrationClick = (type: string) => {
        if (type === INTEGRATION_TYPES.TALLY) return selectOption(type);
        if (!!integration) return;
        else handleIntegration(1, type, path === UPLOAD_STAGE.ONBOARDING);
    };

    const selectOption = (option: string) => {
        setTransition(option ? 'next' : 'previous');
        setTimeout(() => {
            setSelected((prev) => {
                if (prev === option) return null;
                else {
                    return option;
                }
            });
        }, 300);
    };

    const handleUpdateDocs = (fileWithErrors: any, deletedDocId: string = '') => {
        updateDataAttachments && updateDataAttachments(MIS, fileWithErrors, deletedDocId);
        // setTimeout(() => {
        //   MonthlyViewData();
        // }, 4000);
    };

    const LandingCard = () => (
        <div className={clsx({ [styles.Content]: true })}>
            {!isReviewPage ? (
                <div className={styles.Heading}>
                    Management Information System (MIS)
                    {FinancialChecks.mis.isMisComplete(data, monthlyViewData, stage) && (
                        <img src="/assets/Done2.svg" alt="" style={{ width: '20px' }} />
                    )}
                </div>
            ) : null}
            <div className={styles.Subheading}>
                Connect your Company's financial manager. We request view only access.
            </div>

            <div className={styles.Options}>
                {allIntegrations.map((type, i) => (
                    <div
                        key={i}
                        className={clsx({
                            [styles.Option]: true,
                            [styles.Integrated]:
                                integration === type ||
                                (type === INTEGRATION_TYPES.TALLY && tallyFiles?.length),
                            [styles.Selected]: selected === type,
                            [styles.Disabled]:
                                type !== INTEGRATION_TYPES.TALLY &&
                                integration &&
                                integration !== type,
                        })}
                        onClick={() => integrationClick(type)}
                    >
                        <img src={INTEGRATION_LOGOS[type]} height="25px" alt="integ" />
                    </div>
                ))}
                <div
                    className={clsx({
                        [styles.Option]: true,
                        [styles.Integrated]: csvFiles?.length,
                        [styles.Selected]: selected === INTEGRATION_TYPES.CSV,
                    })}
                    onClick={() => selectOption(INTEGRATION_TYPES.CSV)}
                >
                    <img
                        src={'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/upload-file.svg'}
                        height="25px"
                        alt="zoho"
                    />
                    <div className={styles.uploadManually}>
                        <div className={styles.upload}>upload</div>
                        <div className={styles.upload}>manually</div>
                    </div>
                </div>
            </div>
        </div>
    );

    const getContentCard = () => {
        switch (selected) {
            case INTEGRATION_TYPES.CSV:
                return (
                    <UploadCsv
                        category={MIS}
                        keyName={UPLOAD_KEY}
                        files={csvFiles}
                        path={path ?? dayjs().format('YYYY-MM')}
                        stage={stage}
                        selectOption={selectOption}
                        onUploadDocSuccess={handleUpdateDocs}
                        investeeOnboardedId={investeeOnboardedId}
                    />
                );
            case INTEGRATION_TYPES.TALLY:
                return (
                    <UploadTally
                        category={MIS}
                        keyName={UPLOAD_KEY}
                        onUploadDocSuccess={onMetadataSuccess}
                        files={tallyFiles}
                        path={path ?? dayjs().format('YYYY-MM')}
                        selectOption={selectOption}
                        investeeOnboardedId={investeeOnboardedId}
                        isTallyConnector={isTallyConnector}
                        updatingTallyConnector={updatingTallyConnector}
                        toggleTallyConnector={toggleTallyConnector}
                        isReviewPage={isReviewPage}
                    />
                );
            default:
                return <LandingCard />;
        }
    };

    return (
        <div className={'StepContainer'}>
            {!isReviewPage ? <div className={'Title'}>Financials</div> : null}
            <div
                className={clsx('Card', { ReviewCard: isReviewPage })}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <div
                    className={clsx({
                        [styles.Next]: transition === 'next',
                        [styles.Previous]: transition === 'previous',
                    })}
                >
                    {getContentCard()}
                </div>
            </div>
            <SmallMonthlyView
                parentHovered={hovered}
                collapsibleMonthlyViewData={collapsibleMonthlyViewData ?? []}
                category={MIS}
                isReview={isReviewPage}
            />

            {!isReviewPage ? (
                <BackNextButtons onNextClick={goToNextStep} onBackClick={goToPreviousStep} />
            ) : null}
        </div>
    );
}

export default MISUpload;
