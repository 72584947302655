import { Component } from 'react';
import { connect } from 'react-redux';
import {
    getAuthenticatedState,
    getCheckIfUserHasRelevantPermissions,
    getEmailVerifiedState,
    getInvesteeOrganizationInfo,
    getPhoneNumber,
    getPhoneVerifiedState,
    getUserProfileFetchingState,
} from '../../../App/AppReducer';
import storage from '../../../../util/storageService';
import withRouter from '../../../../common/withRouter';
import { LoginPageLazy } from '../../../../Lazy';
import PageLoader from '../../../../components/loader/PageLoader';
import { INVESTEE_BASE_PATH } from '../../../../RoutesConst';
import { isEmpty, isEqual } from 'lodash';

class BaseInvesteeComponent extends Component {
    getPathToNavigate = () => {
        const { location, pathToNavigate } = this.props;
        const state = location?.state;

        //to retain user link previous route takes prefrence;
        if (state?.pathname) return `${state?.pathname}${state?.search}`;
        if (pathToNavigate) return pathToNavigate;
        return INVESTEE_BASE_PATH;
    };
    checkAndRedirectUser = () => {
        const investee = storage.get('investee');
        const investor = storage.get('investor');
        const {
            fromState,
            location,
            navigate,
            is_fetching_user,
            isEmailVerified,
            phoneVerified,
            phoneNo,
        } = this.props;
        if (investee !== null && investee !== undefined) {
            if (fromState) {
                //@TODO delete this
                navigate(`${fromState}`);
            } else {
                if (
                    !/recur-checkout/g.test(location.pathname) &&
                    !/gst-invoices-fetch/g.test(location.pathname)
                ) {
                    if (!is_fetching_user && isEmailVerified && phoneVerified && !isEmpty(phoneNo))
                        navigate(this.getPathToNavigate(), { replace: true });
                }
            }
            //@TODO check for investor flow this will not work
        } else if (investor !== null && investor !== undefined) {
            if (fromState) {
                navigate(`${fromState}`);
            } else {
                navigate('/investor');
            }
        } else {
            let myIp = window?.localStorage.getItem('myIp');
            storage.flush();
            window?.localStorage.setItem('myIp', myIp);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            (!prevProps.isAuthenticated && this.props.isAuthenticated) ||
            !isEqual(prevProps.sidebarConfig, this.props.sidebarConfig) ||
            !isEqual(prevProps.widget, this.props.widget)
        ) {
            this.checkAndRedirectUser();
        }
    }

    render() {
        const { is_fetching_user, isEmailVerified, phoneVerified, phoneNo } = this.props;
        if (is_fetching_user) return <PageLoader />;
        if (
            !is_fetching_user &&
            (isEmailVerified === false || phoneVerified === false || isEmpty(phoneNo))
        )
            return <LoginPageLazy />;
        return null;
    }
}

function mapStateToProps(state, props) {
    return {
        fromState:
            props.location && props.location.state && props.location.state._refurl
                ? props.location.state._refurl
                : '',
        urlQuery: props.location.search,
        isAuthenticated: getAuthenticatedState(state),
        phoneVerified: getPhoneVerifiedState(state),
        phoneNo: getPhoneNumber(state),
        isEmailVerified: getEmailVerifiedState(state),
        is_fetching_user: getUserProfileFetchingState(state),
        status: getInvesteeOrganizationInfo(state)?.status,
        // Acces for Get started will be given to Owner, Manager and Data Collaborator through "Data Collaborator:invite"
        userHasEditAccess: getCheckIfUserHasRelevantPermissions(state, [
            'Data Collaborator:invite',
        ]),
    };
}

export default withRouter(connect(mapStateToProps)(BaseInvesteeComponent));
