import React, { Component } from 'react';
import { captureMessagesViaErrorLogger } from '../../util/errorLogger';
import { Modal } from 'react-bootstrap';
import './ErrorBoundary.scss';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        const errorObj = {
            error: error,
            errorInfo: errorInfo,
        };
        if (this.state.error === null && error?.message) {
            this.setState({ error: error?.message });
            // showNotification(SHOW_NOTIFICATION_STATUS.ERROR, error?.message);
            captureMessagesViaErrorLogger(errorObj);
        }
    }

    onHide = () => {
        this.setState({ error: '' });
        window.location.reload();
    };

    render() {
        const { error } = this.state;
        if (error) {
            return (
                <Modal show={!!error} onHide={this.onHide} className="Error-Modal" centered>
                    <Modal.Header>
                        <button type="button" className="close" onClick={this.onHide}>
                            <span>
                                <img src="/assets/clear.png" alt="" />
                            </span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <img src="/assets/error-dog.svg" alt="" className="LeftImg" />
                        <div className="RightText">
                            <div className="FirstDiv">We’re so sorry!</div>
                            <div className="SecondDiv">
                                An unfortunate error has occured, It doesn't appear to have affected
                                your data, but our technical staff have been automatically notified
                                and will be looking into this with the utmost urgency.
                            </div>
                            <button onClick={this.onHide}>
                                <img src="/assets/reload.svg" alt="" />
                                <span>Try again</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        }
        return this.props.children;
    }
}
