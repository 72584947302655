import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    // YAxis,
    // CartesianGrid,
    Tooltip,
    // Legend,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import CustomTooltip from '../../../../../../components/CustomToolTip';
import { priceFormat } from '../../../../../../util/utility';
import {
    getInvesteePayouts,
    getPayoutOverviewData,
    getTotalRemainingUpcomingPayment,
    getInvesteeMonthlyScheduledPaymentsGraph,
    getInvesteeMonthlyCompletedPayments,
    getInvesteeMonthlyScheduledPayments,
} from '../../../../InvesteeReducers';
import PayoutOverviewChart from './components/PayoutOverviewChart.jsx';
import styles from '../../styles/OverviewPage.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from '../../../../../../stories/Button';
import { triggerDrawer } from '../../../../../App/AppActions';
import { DRAWER_HEADINGS } from '../../../../../../DrawerConsts';
import { repaymentDrawerType } from '../../../../InvesteeActions';
import { PAYMENTS, PAYOUTS } from '../../TransactionsOverview';
import { PAYMENT_STATUS } from '../../../../../../enums/appEnums';
import ReactTooltip from 'react-tooltip';

const { DelayTooltip } = styles;
dayjs.extend(UTC);
dayjs.extend(customParseFormat);

export const SCHEDULED_PAYMENTS = 'Scheduled Repayments';
export const PAID_PAYMENTS = 'Paid Repayments';
export const RECENT_PAYOUTS = 'Recent Payouts';

export default function OverviewPage({
    graphData,
    upcomingPayments,
    recentPayments,
    // fetchOverviewData,
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        Font_Family_Bold,
        PayType,
        PayValue,
        LeftValue,
        Line,
        ShowAll,
        NoActiveTrade,
        overviewcard,
    } = styles;
    // const currentFinancialYear = getCurrentFinancialYear();
    // const PAYMENT_OPTIONS = ['Upcoming', 'Last 12 months', `FY ${currentFinancialYear}-${currentFinancialYear+1}`, `FY ${currentFinancialYear-1}-${currentFinancialYear}`];
    // const PAYOUT_OPTIONS = ['All Time', 'Last 12 months', `FY ${currentFinancialYear}-${currentFinancialYear+1}`, `FY ${currentFinancialYear-1}-${currentFinancialYear}`];
    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    // const [paymentOption, setPaymentOption] = useState(PAYMENT_OPTIONS[0]);
    const [showPayoutOptions, setShowPayoutOptions] = useState(false);
    // const [payoutOption, setPayoutOption] = useState(PAYOUT_OPTIONS[0]);
    const monthlyScheduledPaymentsGraph = useSelector((state) =>
        getInvesteeMonthlyScheduledPaymentsGraph(state),
    );
    const totalRemainingUpcomingPayment = useSelector((state) =>
        getTotalRemainingUpcomingPayment(state),
    );
    const monthlyCompletedPayments = useSelector((state) =>
        getInvesteeMonthlyCompletedPayments(state),
    );
    const monthlyScheduledPayments = useSelector((state) =>
        getInvesteeMonthlyScheduledPayments(state),
    );
    const investeePayouts = useSelector((state) => getInvesteePayouts(state));
    const payoutOverviewData = useSelector((state) => getPayoutOverviewData(state));
    if (payoutOverviewData) {
        var { recur_fees, total_payments_for_contracts, total_payouts_received } =
            payoutOverviewData;
    }

    const tradeDiscountPercent = (1 - total_payouts_received / total_payments_for_contracts) * 100;
    const tradeDiscount = total_payments_for_contracts - total_payouts_received;
    const recurFeesWithoutGST = recur_fees / 1.18;
    const recurFeesPercent = (recurFeesWithoutGST / total_payouts_received) * 100;
    const gstPercent = recurFeesPercent * 0.18;
    const gstCredits = recur_fees - recurFeesWithoutGST;
    const totalReceived =
        total_payments_for_contracts - tradeDiscount - recurFeesWithoutGST - gstCredits;

    const pieChartData = [
        {
            name: 'Trade Discount',
            value: tradeDiscount,
            percent: tradeDiscountPercent,
            fill: 'url(#TradeDiscount)',
        },
        {
            name: 'Financial Advisory Fees',
            value: recurFeesWithoutGST,
            percent: recurFeesPercent,
            fill: 'url(#RecurFees)',
        },
        {
            name: 'GST Credits',
            value: gstCredits,
            percent: gstPercent,
            fill: 'url(#GSTCredits)',
        },
        {
            name: 'Total Received',
            value: totalReceived,
            percent: null,
            fill: 'url(#TotalReceived)',
        },
    ];

    useEffect(() => {
        document.addEventListener('click', closePaymentOptionsIfOpen);
        return () => {
            document.removeEventListener('click', closePaymentOptionsIfOpen);
        };
    }, [showPaymentOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.addEventListener('click', closePayoutOptionsIfOpen);
        return () => {
            document.removeEventListener('click', closePayoutOptionsIfOpen);
        };
    }, [showPayoutOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    // const togglePaymentOption = e => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setShowPaymentOptions(!showPaymentOptions);
    // }

    // const togglePayoutOption = e => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setShowPayoutOptions(!showPayoutOptions);
    // }

    const closePaymentOptionsIfOpen = () => {
        if (showPaymentOptions) setShowPaymentOptions(false);
    };

    const closePayoutOptionsIfOpen = () => {
        if (showPayoutOptions) setShowPayoutOptions(false);
    };

    // const getOptionToSend = (option, category) => {
    //     const arr = category === 'payments' ? [...PAYMENT_OPTIONS] : [...PAYOUT_OPTIONS];
    //     if (option === arr[2]) return 'Current Financial Year';
    //     if (option === arr[3]) return 'Last Financial Year';
    //     return option;
    // }

    // const handleBarChartOptionClick = (option) => {
    //     setPaymentOption(option);
    //     setShowPaymentOptions(false);
    //     if (showPayoutOptions) setShowPayoutOptions(false);
    //     fetchOverviewData({
    //         payments: getOptionToSend(option, 'payments'),
    //         payouts: getOptionToSend(payoutOption, 'payouts')
    //     });
    // }

    // const handlePieChartOptionClick = (option) => {
    //     setPayoutOption(option);
    //     setShowPayoutOptions(false);
    //     if (showPaymentOptions) setShowPaymentOptions(false);
    //     fetchOverviewData({
    //         payments: getOptionToSend(paymentOption, 'payments'),
    //         payouts: getOptionToSend(option, 'payouts')
    //     });
    // }

    const redirectToTransaction = (category, filters) => {
        dispatch(
            triggerDrawer(
                category == PAYOUTS ? DRAWER_HEADINGS.PAYOUTS : DRAWER_HEADINGS.REPAYMENTS,
            ),
        );
        dispatch(repaymentDrawerType(category, filters));
    };

    if (isEmpty(monthlyScheduledPaymentsGraph)) {
        return (
            <div className={NoActiveTrade}>
                <span>
                    No transactions available. <NavLink to="/company/trade">Draw Capital</NavLink>
                </span>
            </div>
        );
    }

    return (
        <>
            <div className="row" style={{ cursor: 'default', margin: '25px' }}>
                <div
                    className="col-md-8 mb-4 d-flex w-100 flex-column"
                    style={{
                        paddingLeft: '0px',
                        paddingRight: '25px',
                    }}
                >
                    <div className="light-round-border fin-padding h-100">
                        <div className={`${Font_Family_Bold} mb-5`}>Repayments</div>
                        {!isEmpty(graphData) ? (
                            <span>
                                {/* <div className={OverviewDropDown} onClick={togglePaymentOption}>
                                    <span>{paymentOption}</span>
                                    <img src="/assets/DD-arrow.svg"
                                        alt=""
                                    />
                                </div>
                                {showPaymentOptions && (
                                    <div className={OverviewDropDownOpen} onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>
                                        {PAYMENT_OPTIONS.map((option, index) => (
                                            <div key={index} onClick={() => handleBarChartOptionClick(option)}>{option}</div>
                                        ))}
                                    </div>
                                )}
                                <br/> */}
                                <div className="fin-graph overview-new-graph-cust mb-5">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={730}
                                            height={250}
                                            data={graphData}
                                            margin={{ top: 15, right: 0, left: 0, bottom: 0 }}
                                        >
                                            <defs>
                                                <linearGradient
                                                    id="gradientGraph"
                                                    x1="0"
                                                    y1="0"
                                                    x2="0"
                                                    y2="1"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor="#DEECFF"
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor="#E2FAFF"
                                                        stopOpacity={1}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient
                                                    id="gradientHover"
                                                    x1="0"
                                                    y1="0"
                                                    x2="0"
                                                    y2="1"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor="#91BFFF"
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor="#D8F8FF"
                                                        stopOpacity={1}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient
                                                    id="TotalReceived"
                                                    x1="1"
                                                    y1="1"
                                                    x2="0"
                                                    y2="0"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor="#F9FFF6"
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor="#62FF62"
                                                        stopOpacity={1}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient
                                                    id="TradeDiscount"
                                                    x1="1"
                                                    y1="1"
                                                    x2="0"
                                                    y2="0"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor="#E2FAFF"
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor="#67B3FF"
                                                        stopOpacity={1}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient
                                                    id="RecurFees"
                                                    x1="1"
                                                    y1="1"
                                                    x2="0"
                                                    y2="0"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor="#FDFBFF"
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor="#CBB7E2"
                                                        stopOpacity={1}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            {/**
                                             * ? UnComment the code when delay charge key come from the API
                                             *  */}

                                            <defs>
                                                <linearGradient
                                                    id="gradientErrorHover"
                                                    x1="1"
                                                    y1="1"
                                                    x2="0"
                                                    y2="0"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor="#FBC1B5"
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor="#FF7F57"
                                                        stopOpacity={1}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient
                                                    id="gradientError"
                                                    x1="1"
                                                    y1="1"
                                                    x2="0"
                                                    y2="0"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor="#FFDBCF"
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="50%"
                                                        stopColor="#FFC7B6"
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor="#FBC1B5"
                                                        stopOpacity={1}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient
                                                    id="GSTCredits"
                                                    x1="1"
                                                    y1="1"
                                                    x2="0"
                                                    y2="0"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor="#D7F3E8"
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor="#3BA36F"
                                                        stopOpacity={1}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <XAxis dataKey={'month'} tick={{ fontSize: 8 }} />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Bar
                                                dataKey="scheduled"
                                                fill="url(#gradientGraph)"
                                                barSize={50}
                                                stackId="a"
                                                cursor={'pointer'}
                                                onMouseEnter={(data, index, e) => {
                                                    e.target.setAttribute(
                                                        'fill',
                                                        'url(#gradientHover)',
                                                    );
                                                }}
                                                onMouseLeave={(data, index, e) => {
                                                    e.target.setAttribute(
                                                        'fill',
                                                        'url(#gradientGraph)',
                                                    );
                                                }}
                                            />
                                            <Bar
                                                dataKey="overdue"
                                                fill="#ffc9b8"
                                                barSize={50}
                                                stackId="a"
                                                cursor={'pointer'}
                                                onMouseEnter={(data, index, e) => {
                                                    e.target.setAttribute(
                                                        'fill',
                                                        'url(#gradientErrorHover)',
                                                    );
                                                }}
                                                onMouseLeave={(data, index, e) => {
                                                    e.target.setAttribute(
                                                        'fill',
                                                        'url(#gradientError)',
                                                    );
                                                }}
                                            >
                                                <LabelList
                                                    dataKey="amount_to_show"
                                                    position="top"
                                                    style={{
                                                        fill: '#2A497E',
                                                        fontFamily: 'Gilroy-Medium',
                                                        fontSize: '9px',
                                                    }}
                                                />
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </span>
                        ) : null}
                        <div className="row">
                            <div className="col-sm-6" style={{ fontWeight: 600 }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <span className={PayType}>{SCHEDULED_PAYMENTS}</span>
                                        <span className={Line}></span>
                                        <span className={PayValue}>
                                            <i className="fa fa-rupee-sign"></i>{' '}
                                            {priceFormat(totalRemainingUpcomingPayment)}
                                        </span>
                                    </div>
                                </div>
                                {isEmpty(upcomingPayments) ? (
                                    <>
                                        <div className="d-flex justify-content-between pay recent-pay">
                                            <p
                                                className="d-flex align-items-center blue-clr no-upcoming"
                                                style={{
                                                    fontSize: '12px',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                <img
                                                    src="/assets/upcoming1.svg"
                                                    alt=""
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                ></img>
                                                No Upcoming Repayments
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        {upcomingPayments.map((item, idx) => (
                                            <div
                                                key={idx}
                                                className={
                                                    'd-flex justify-content-between pay recent-pay overviewcard'
                                                }
                                                // ? UnComment the code when delay charge key come from the API
                                                // style={{ cursor: 'pointer' }}
                                                style={{
                                                    backgroundColor: item?.overdue > 0 && '#FDF0EC',
                                                }}
                                                onClick={() =>
                                                    redirectToTransaction(PAYMENTS, [
                                                        PAYMENT_STATUS.SCHEDULED,
                                                        PAYMENT_STATUS.DELAYED,
                                                    ])
                                                }
                                            >
                                                <span
                                                    className={LeftValue}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src="/assets/upcoming1.svg"
                                                        alt=""
                                                        className="mr-2"
                                                    />
                                                    <span>
                                                        <i className="fa fa-rupee-sign"></i>{' '}
                                                        {priceFormat(item.totalAmount)}
                                                    </span>
                                                    {item?.overdue > 0 && item?.delayCharge > 0 && (
                                                        <span className="d-flex align-items-center ml-1">
                                                            <span style={{ color: '#df666a' }}>
                                                                (incl. delay charges)
                                                            </span>
                                                            <img
                                                                data-for={
                                                                    'payment_info' + item.date
                                                                }
                                                                data-tip=""
                                                                src="/assets/red-info.png"
                                                                alt="delayed_info"
                                                                className="ml-1"
                                                                style={{ width: '12px' }}
                                                            />
                                                            <ReactTooltip
                                                                id={'payment_info' + item.date}
                                                                type="light"
                                                                place="bottom"
                                                                className={DelayTooltip}
                                                                delayShow={50}
                                                                multiline={true}
                                                            >
                                                                <i className="fa fa-rupee-sign"></i>{' '}
                                                                {Math.ceil(item.delayCharge)} delay
                                                                charges included
                                                            </ReactTooltip>
                                                        </span>
                                                    )}
                                                </span>
                                                <span
                                                    className="right-style"
                                                    style={{
                                                        width: 'fit-content',
                                                    }}
                                                >
                                                    {dayjs(item.displayDate, 'DD MM YYYY')
                                                        .utc()
                                                        .utcOffset(330)
                                                        .format('DD MMM YYYY')}
                                                    <img
                                                        src="/assets/chevron-right.png"
                                                        alt=""
                                                        className={
                                                            !item?.overdue > 0 ? 'ml-3' : 'ml-2'
                                                        }
                                                        style={{ width: '6px' }}
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                        {monthlyScheduledPayments.length > 3 ? (
                                            <Button
                                                secondary
                                                withIcon={false}
                                                size="large"
                                                label="View All"
                                                style={{ width: '100%', margin: 'auto' }}
                                                onClick={() =>
                                                    redirectToTransaction(PAYMENTS, [
                                                        PAYMENT_STATUS.SCHEDULED,
                                                        PAYMENT_STATUS.DELAYED,
                                                    ])
                                                }
                                            />
                                        ) : null}
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-6" style={{ fontWeight: 600 }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <span className={PayType}>{PAID_PAYMENTS}</span>
                                    </div>
                                </div>
                                {isEmpty(recentPayments) ? (
                                    <>
                                        <div className="d-flex justify-content-between pay recent-pay">
                                            <p
                                                className="d-flex align-items-center grey-clr no-pay"
                                                style={{
                                                    fontSize: '12px',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    src="/assets/recent-pay1.svg"
                                                    alt=""
                                                ></img>
                                                No Recent Repayments
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        {recentPayments.map((item, idx) => (
                                            <div
                                                key={idx}
                                                className="d-flex justify-content-between pay recent-pay overviewcard"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    redirectToTransaction(PAYMENTS, [
                                                        PAYMENT_STATUS.PAID,
                                                        PAYMENT_STATUS.PAID_DELAYED,
                                                    ])
                                                }
                                            >
                                                <span className={LeftValue}>
                                                    <img
                                                        src="/assets/recent-pay1.svg"
                                                        alt=""
                                                        className="mr-2"
                                                    ></img>
                                                    <i className="fa fa-rupee-sign"></i>{' '}
                                                    {priceFormat(item.total_amount)}
                                                </span>
                                                <span className="right-style">
                                                    {`${dayjs(item.month, 'YYYY-MM-DD')
                                                        .utc()
                                                        .utcOffset(330)
                                                        .format('DD MMM YYYY')}`}
                                                    <img
                                                        src="/assets/chevron-right.png"
                                                        alt=""
                                                        className="ml-3 chevron-right"
                                                        style={{ width: '6px' }}
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                        {monthlyCompletedPayments.length > 3 ? (
                                            <Button
                                                secondary
                                                withIcon={false}
                                                size="large"
                                                label="View All"
                                                style={{ width: '100%', margin: 'auto' }}
                                                onClick={() =>
                                                    redirectToTransaction(PAYMENTS, [
                                                        PAYMENT_STATUS.PAID,
                                                        PAYMENT_STATUS.PAID_DELAYED,
                                                    ])
                                                }
                                            />
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="col-md-4 mb-4 d-flex w-100 flex-column"
                    style={{
                        paddingLeft: '0px',
                    }}
                >
                    <div className="light-round-border fin-padding h-100">
                        <div className={`${Font_Family_Bold} mb-5`}>Payouts</div>
                        <span>
                            {/* <div className={OverviewDropDown} onClick={togglePayoutOption}>
                            <span>{payoutOption}</span>
                            <img src="/assets/DD-arrow.svg"
                                alt=""
                            />
                        </div>
                        {showPayoutOptions && (
                            <div className={OverviewDropDownOpen} onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                                {PAYOUT_OPTIONS.map((option, index) => (
                                    <div key={index} onClick={() => handlePieChartOptionClick(option)}>{option}</div>
                                ))}
                            </div>
                        )}
                        <br /> */}
                            {total_payouts_received ? (
                                <div className="row fin-graph1">
                                    <div className="col-sm-12 p-0">
                                        <PayoutOverviewChart data={pieChartData} />
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-between pay recent-pay">
                                    <p
                                        className="d-flex align-items-center green-clr no-payout"
                                        style={{
                                            fontSize: '12px',
                                            marginBottom: '0px',
                                        }}
                                    >
                                        <img
                                            style={{ marginRight: '10px' }}
                                            src="/assets/trade1.svg"
                                            alt=""
                                        ></img>
                                        No Active Trades
                                    </p>
                                </div>
                            )}
                        </span>
                        <div className="row">
                            <div className="col-sm-12" style={{ position: 'unset' }}>
                                {/* <div className="differ-pay-type dflex-ss">
                                <span>Recent Payouts</span>
                                <span>
                                    <div
                                        className="fs10 fr text-clr2"
                                        style={{ visibility: 'hidden' }}
                                    >
                                        Total Remaining
                                    </div>
                                    <br />
                                    <div
                                        className="fs14 clr34 fw5"
                                        style={{ visibility: 'hidden' }}
                                    >
                                        {'₹ '}
                                        {priceFormat(
                                            totalRemainingUpcomingPayment
                                        )}
                                    </div>
                                </span>
                            </div> */}
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ fontWeight: 600 }}
                                >
                                    <div className="d-flex align-items-center">
                                        <span className={PayType}>{RECENT_PAYOUTS}</span>
                                        <span className={Line}></span>
                                        <span className={PayValue}>
                                            <i className="fa fa-rupee-sign"></i>{' '}
                                            {priceFormat(
                                                investeePayouts.reduce((total, current) => {
                                                    return total + current.transaction_amount;
                                                }, 0),
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {isEmpty(investeePayouts) ? (
                                    <>
                                        <div className="d-flex justify-content-between pay recent-pay">
                                            <p
                                                className="d-flex align-items-center green-clr no-payout"
                                                style={{
                                                    fontSize: '12px',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                <img
                                                    style={{ marginRight: '10px' }}
                                                    src="/assets/trade1.svg"
                                                    alt=""
                                                />
                                                No Recent Payouts
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        {investeePayouts.map((item, idx) => (
                                            <div
                                                key={idx}
                                                className="d-flex justify-content-between pay recent-pay overviewcard"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => redirectToTransaction(PAYOUTS)}
                                            >
                                                <span className={LeftValue}>
                                                    <img
                                                        src="/assets/trade1.svg"
                                                        alt=""
                                                        className="mr-2"
                                                    />
                                                    <span>
                                                        <i className="fa fa-rupee-sign"></i>{' '}
                                                        {priceFormat(item.transaction_amount)}
                                                    </span>
                                                </span>
                                                <span className="right-style">
                                                    {dayjs(item.investor_payment_date)
                                                        .utc()
                                                        .utcOffset(330)
                                                        .format('MMM YYYY')}
                                                    <img
                                                        src="/assets/chevron-right.png"
                                                        alt=""
                                                        className="ml-3 chevron-right"
                                                        style={{ width: '6px' }}
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                        {investeePayouts.length > 3 ? (
                                            <Button
                                                secondary
                                                withIcon={false}
                                                size="large"
                                                label="View All"
                                                style={{ width: '100%', margin: 'auto' }}
                                                onClick={() => redirectToTransaction(PAYOUTS)}
                                            />
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
