import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { USER_TYPE_INVESTOR } from '../../../enums/appEnums';
import storage from '../../../util/storageService';
import { isThereAuthComponents } from '../../../util/utility';

/**
 * @Description this will run if someone lands on /investor only.
 */
const InvestorIndexRoute = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(
            isThereAuthComponents() && storage.get(USER_TYPE_INVESTOR)
                ? '/investor/dashboard'
                : '/investor/login',
        );
        // eslint-disable-next-line
    }, []);
    return null;
};

export default InvestorIndexRoute;
