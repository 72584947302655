import _, { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FinboxModal from '../../../../../../components/FetchBankStatement/FinboxModal';
import callApi from '../../../../../../util/apiCaller';
import {
    getCountOfMonthlyData,
    getMonthlyDataForOnboarding,
    getS3IconLink,
    mixPanelTrack,
    showNotification,
} from '../../../../../../util/utility';
import { getBanks, getEmail, getInvesteeOrganizationInfo } from '../../../../../App/AppReducer';
import finbitBanks from '../../../../../../constants/finbitBanks.json';
import { getFinboxError } from '../../../../InvesteeReducers';
import DisabledUploadCard from '../../../../../../components/DisabledUploadCard/DisabledUploadCard';
import UploadCard from '../../utils/UploadCard/UploadCard';
import styles from './AddBankAccounts.module.scss';
import AddBankCard from './components/AddBankCard';
import SomethingWentWrong from './components/SomethingWentWrong';
import CompletedBankForm from './components/CompletedBankForm';
import AddAccountCard from './components/AddAccountCard';
import BackNextButtons from '../BackNextButtons/BackNextButtons';
import DragDropUpload from '../../../../../../components/DragDropUpload';
import { updateODFDData } from './../../../../common/apiHelpers';
import ODFD from '../../../UploadDataJourney/components/BankAccounts/components/ODFD/ODFD';
import { Button } from '@mui/material';
import SmallMonthlyView from '../SmallMonthlyView/SmallMonthlyView';
import clsx from 'clsx';
import { setIsAppLoading } from '../../../../../App/AppActions';
import { UPLOAD_STAGE } from '../../utils/constants';
import { ASSET_LINKS, ICON_NAMES } from '../../../../../../constants/assetLinks';
import { useInvesteeOnboardingContext } from '../../../../pages/InvesteeOnboardingNew/Context/InvesteeOnboardingContext';
import BankStatementUploadWarning from '../../../../../../components/BankStatementUploadWarning/BankStatementUploadWarning';

export const BANK_NAME = 'bank_name';
export const ACCOUNT_HOLDER_NAME = 'account_holder_name';
export const ACCOUNT_NUMBER = 'account_number';
export const IFSC = 'ifsc';
export const IFSC_CODE = 'ifsc_code';

export const FILLING = 'filling';
export const COMPLETED = 'completed';
export const CONNECTED = 'connected';
export const UPLOAD = 'upload';
export const EDITING = 'editing';
export const ADDED = 'added';
export const EXIT_FINBOX = 'exit-finbox';
export const ZEUS_USER_EMAIL = 'zeus.company@recur.club';
export const STATEMENT_BUTTONS = {
    M1: '1m',
    M3: '3m',
    M6: '6m',
    Y1: '1y',
};
export const isOD = 'isOD';
export const isFD = 'isFD';
export const isFDLien = 'isFDLien';
export const odAmount = 'odAmount';
export const fdAmount = 'fdAmount';
export const fdLienAmount = 'fdLienAmount';
export const pathOnboarding = 'Onboarding';
export const INVESTOR = 'Investor';

export const SAMPLE_FILES = {
    MIS: 'https://sample-file-templates.s3.ap-south-1.amazonaws.com/sample_mis.xlsx',
};

export default function AddBankAccounts({
    onReviewPage,
    lastUpdated,
    isSaveClicked,
    setIsSaveClicked,
    onFinboxSuccess,
    onMetadataSuccess,
    deleteFileSuccess,
    data,
    bankData,
    goToNextStep,
    handleAllBankAccountAdded,
    allBankAccountAdded,
    stage,
    setAllBankAccountAdded,
}: any) {
    const dispatch = useDispatch();
    //@ts-ignore
    const { monthlyViewData } = useInvesteeOnboardingContext();
    const [currentStatus, setCurrentStatus] = useState('');
    const [showAddCard, setShowAddCard] = useState(false);
    const [showFinboxModal, setShowFinbox] = useState(null);
    const [entityId, setEntityId] = useState(null);
    const [bankAccounts, setBankAccounts] = useState<any>({});
    const [accountConnectedCount, setAccountConnectedCount] = useState(0);
    const emailId = useSelector(getEmail);
    const orgId = useSelector(getInvesteeOrganizationInfo)?._id;
    const [bankDetails, setBankDetails] = useState<any>({
        bank_name: '',
        account_holder_name: '',
        account_number: '',
        ifsc_code: '',
    });
    // const banksTabStatusRef = useRef<any>(null);
    const hasEditedBankDetails = useRef(false);
    const initialAccountRef = useRef<any>(null);

    const banks = useSelector((state) => getBanks(state));
    const investeeOnboardingId = useSelector(
        (state) => getInvesteeOrganizationInfo(state).investee_onboarded_id,
    );
    const finboxError = useSelector((state) => getFinboxError(state));

    const showRemoveButton = currentStatus !== null && currentStatus !== ADDED;
    const isFinbitBank = finbitBanks.includes(bankDetails?.bank_name);

    const mixpanelForOdFDChecked = (type: string) => {
        if (type === 'OD') {
            mixPanelTrack({
                id: orgId,
                trackUserObj: {
                    email: emailId,
                },
                trackCategoryName: 'Has Overdraft Facility',
            });
        } else if (type === 'FD') {
            mixPanelTrack({
                id: orgId,
                trackUserObj: {
                    email: emailId,
                },
                trackCategoryName: 'Has Fixed Deposit',
            });
        } else if (type === 'od_supporting_document') {
            mixPanelTrack({
                id: orgId,
                trackUserObj: {
                    email: emailId,
                    odAmount: bankDetails?.od_amount,
                },
                trackCategoryName: 'Uploads OD statement',
            });
        } else if (type === 'FDLien') {
            mixPanelTrack({
                id: orgId,
                trackUserObj: {
                    email: emailId,
                },
                trackCategoryName: 'FD as security',
            });
        } else if (type === 'fd_supporting_document') {
            mixPanelTrack({
                id: orgId,
                trackUserObj: {
                    email: emailId,
                    fdAmount: bankDetails?.fd_amount,
                    fdLienAmount: bankDetails?.fd_amount_lien,
                    fdUsedAsSecurity: bankDetails?.is_fd_lien,
                },
                trackCategoryName: 'Uploads FD Receipt',
            });
        }
    };

    useEffect(() => {
        if (data) {
            initialiseStatus(data);
            const bankAccounts = data.bank_account;
            let count = 0;
            for (let account in bankAccounts) {
                if (bankAccounts[account]) {
                    if (bankAccounts[account]?.isAccountConnected) count++;
                }
            }
            setAccountConnectedCount(count);
        }
        //eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        const { isValid } = validateBankDetails(bankDetails);
        if (isValid) {
            currentStatus === FILLING && setCurrentStatus(COMPLETED);
            if (isFinbitBank && !initialAccountRef.current) setCurrentStatus(UPLOAD);
        }
        //eslint-disable-next-line
    }, [bankDetails]);

    useEffect(() => {
        if (!isEmpty(bankData)) {
            if (bankData?.finbox_entity_ids) setCurrentStatus(CONNECTED);
            else setCurrentStatus(UPLOAD);
            setBankDetails(bankData);
        }
    }, [bankData]);

    const initialiseStatus = (data: any) => {
        const { isValid } = validateBankDetails(bankDetails);
        if (!isEmpty(data?.bank_account)) {
            setBankAccounts(data?.bank_account ?? {});
            if (currentStatus === UPLOAD || currentStatus === EDITING) {
                const updatedBankDetails =
                    data?.bank_account?.[bankDetails?.[ACCOUNT_NUMBER]] ?? null;
                if (updatedBankDetails) setBankDetails({ ...updatedBankDetails });
                else {
                    setBankDetails((data: any) => ({
                        ...data,
                        bills_invoices_bank_transactions: null,
                    }));
                }
            }
            !isValid && currentStatus !== UPLOAD && setCurrentStatus(ADDED);
        } else {
            setBankAccounts({});
            setBankDetails((data: any) => ({
                ...data,
                bills_invoices_bank_transactions: null,
            }));
            if (isValid) setCurrentStatus(COMPLETED);
            else setCurrentStatus(FILLING);
        }
    };

    const handleChange = (keyName: string, keyValue: string) => {
        const obj = { ...bankDetails };
        obj[keyName] = keyValue;
        setBankDetails({ ...obj });
        if (currentStatus === EDITING) hasEditedBankDetails.current = true;
        else if (currentStatus === FILLING) {
            setCurrentStatus(checkIfEverythingIsFilled() ? COMPLETED : FILLING);
        }
    };

    const checkIfNothingIsFilled = () => {
        return [
            bankDetails[BANK_NAME],
            bankDetails[ACCOUNT_HOLDER_NAME],
            bankDetails[ACCOUNT_NUMBER],
            bankDetails[IFSC_CODE],
        ].every((val) => val === '');
    };

    const checkIfEverythingIsFilled = () => {
        return [
            bankDetails[BANK_NAME],
            bankDetails[ACCOUNT_HOLDER_NAME],
            bankDetails[ACCOUNT_NUMBER],
            bankDetails[IFSC_CODE],
        ].every((val) => val !== '');
    };

    const checkIfSameBankAlreadyExists = (accountNumber: string) => {
        const accountNos = Object.keys(data?.bank_account ?? {});
        for (let i = 0; i < accountNos.length; i++) {
            if (accountNos[i] === accountNumber) return true;
        }
        return false;
    };

    const validateBankDetails = (obj: any) => {
        const { bank_name, account_number, account_holder_name, ifsc_code } = obj;
        let isValid = true;
        let message = '';
        if (!bank_name.trim()) {
            message = 'One of the banks must be selected!';
            isValid = false;
        } else if (!account_holder_name.trim()) {
            message = 'Account holder name cannot be empty!';
            isValid = false;
        } else if (!account_holder_name.match(/^[A-Za-z0-9\s]+$/)) {
            message = 'Account holder name can only contain alphanumeric characters!';
            isValid = false;
        } else if (account_holder_name.length < 3) {
            message = 'Account holder name is too short!';
            isValid = false;
        } else if (!account_number.trim()) {
            message = 'Account number cannot be empty!';
            isValid = false;
        } else if (!account_number.match(/^[0-9A-Za-z\s]+$/)) {
            message = 'Account number is invalid!';
            isValid = false;
        } else if (currentStatus !== EDITING && checkIfSameBankAlreadyExists(account_number)) {
            message = 'Account number already exists!';
            isValid = false;
        } else if (!ifsc_code?.trim()) {
            message = 'IFSC code cannot be empty!';
            isValid = false;
        } else if (!ifsc_code.match(/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/)) {
            message = "IFSC code format isn't valid!";
            isValid = false;
        }
        return {
            isValid,
            message,
        };
    };

    const openFinbox = () => {
        const { isValid, message } = validateBankDetails(bankDetails);
        if (!isValid) {
            showNotification('Error', message);
        } else {
            setShowFinbox(bankDetails[ACCOUNT_NUMBER]);
        }
    };

    const resetBankAccountFields = (resetInitialAccount: any = true) => {
        if (resetInitialAccount) {
            setBankDetails({
                bank_name: '',
                account_number: '',
                account_holder_name: '',
                ifsc_code: '',
            });
            initialAccountRef.current = null;
            setCurrentStatus(ADDED);
        }
    };

    // const addDeletedBank = (bankAccounts: any) => {
    //   const obj = JSON.parse(JSON.stringify(bankAccounts));
    //   const tempObj: any = initialAccountRef.current;
    //   tempObj.isEditing && delete tempObj.isEditing;
    //   obj[initialAccountRef.current[ACCOUNT_NUMBER]] = { ...tempObj };
    //   return obj;
    // };

    const addBankAccount = (bankDetails: any, entityId: any = null, isSaveClicked: any = {}) => {
        let substage = 'bank';
        let obj: any = {};
        if (currentStatus === EDITING)
            obj.initial_account_number = initialAccountRef.current[ACCOUNT_NUMBER];
        obj.bank_account = {};
        obj.bank_account[BANK_NAME] = bankDetails[BANK_NAME];
        obj.bank_account[ACCOUNT_HOLDER_NAME] = bankDetails[ACCOUNT_HOLDER_NAME];
        obj.bank_account[ACCOUNT_NUMBER] = bankDetails[ACCOUNT_NUMBER];
        obj.bank_account[IFSC_CODE] = bankDetails[IFSC_CODE];
        obj.bank_account.account_nick_name =
            bankDetails[BANK_NAME] + ' ' + bankDetails[ACCOUNT_NUMBER].slice(-4);
        if (entityId) {
            obj.finbox_entity_ids = entityId;
            obj.account_number = bankDetails[ACCOUNT_NUMBER];
            substage = 'bills_invoices_bank_transactions';
        }
        if (onReviewPage === 'need-info') {
            obj.status = 'Need Information';
        }
        callApi(
            `investeeOnboarding/${investeeOnboardingId}/v3/metadata-update?stage=stage2&substage=${substage}&path=Onboarding`,
            'post',
            obj,
        )
            .then((res) => {
                if (res.status === 'Success') {
                    const obj = res.data?.investeeOnboardingData;
                    initialAccountRef.current = null;
                    onMetadataSuccess(obj);
                    resetBankAccountFields();
                    if (entityId) showNotification('Success', 'Added bank successfully!');
                    else showNotification('Success', 'Updated bank successfully!');
                    setShowAddCard(true);
                } else {
                    const message = res.data?.message ? res.data?.message : 'Some error occurred!';
                    showNotification('Error', message);
                    setShowAddCard(false);
                }
            })
            .catch((err) => {
                if (isSaveClicked) {
                    const { isAddingNewBank, setIsAddingNewBank } = isSaveClicked;
                    isAddingNewBank && setIsAddingNewBank(false);
                    setIsSaveClicked(null);
                    setShowAddCard(false);
                }
                showNotification('Error', 'Some error occurred!');
            });
    };

    const onSuccess = (payload: any) => {
        addBankAccount(bankDetails, payload.entityId);
        setEntityId(payload.entityId);
        setCurrentStatus(CONNECTED);
        setShowFinbox(null);
        if (onFinboxSuccess) onFinboxSuccess();
    };

    const onError = (payload: any) => {};

    const onExit = (payload: any) => {
        currentStatus !== UPLOAD && setCurrentStatus(EXIT_FINBOX);
        setShowFinbox(null);
    };

    const editingBank = (accountNumber: string) => {
        const bankAccountToEdit = bankAccounts[accountNumber];
        initialAccountRef.current = bankAccountToEdit;
        setBankDetails(JSON.parse(JSON.stringify(bankAccountToEdit)));
        setCurrentStatus(EDITING);
    };

    const onNextClick = (onSaveClick = false) => {
        const { isValid, message } = validateBankDetails(bankDetails);
        switch (currentStatus) {
            case FILLING:
                if (checkIfNothingIsFilled()) {
                    goToNextStep();
                } else {
                    if (isValid) {
                        showNotification(
                            'Error',
                            'You need to fetch bank statements before moving forward!',
                        );
                    } else {
                        showNotification('Error', message);
                    }
                }
                break;
            case COMPLETED:
                if (isValid) {
                    showNotification(
                        'Error',
                        'You need to fetch bank statements before moving forward!',
                    );
                } else {
                    showNotification('Error', message);
                }
                break;
            case CONNECTED:
                addBankAccount(bankDetails, entityId, isSaveClicked);
                break;
            case UPLOAD:
                if (isFinbitBank) {
                    if (hasEditedBankDetails.current) {
                        isValid && addBankAccount(bankDetails);
                    }
                } else {
                    const accountNumber = bankDetails[ACCOUNT_NUMBER];
                    if (bankAccounts?.[accountNumber]?.bills_invoices_bank_transactions?.length) {
                        resetBankAccountFields();
                        setCurrentStatus(ADDED);
                    } else {
                    }
                }
                if (!bankDetails?.bills_invoices_bank_transactions?.length) {
                    showNotification(
                        'Error',
                        'You need to either fetch or upload bank statements directly!',
                    );
                } else resetBankAccountFields();
                break;
            case EXIT_FINBOX:
                showNotification(
                    'Error',
                    'You need to either fetch or upload bank statements directly!',
                );
                break;
            case ADDED:
                goToNextStep();
                break;
            case EDITING:
                if (hasEditedBankDetails.current) {
                    isValid && addBankAccount(bankDetails);
                } else {
                    resetBankAccountFields();
                }

                break;
            default:
                console.error(`Invalid currentStatus: ${currentStatus}`);
        }
    };

    const onUploadDocSuccess = (
        uploadFileResponse: any,
        recentlyAddedBankAccountNumber: any,
        isDeleting = false,
    ) => {
        if (uploadFileResponse) {
            onMetadataSuccess(uploadFileResponse);
            setShowAddCard(true);
            resetBankAccountFields(false);
        }
        //   const { bank_account } = uploadFileResponse;
        //   if (currentStatus === UPLOAD) {
        //     for (let accountNo in bank_account) {
        //       if (accountNo === bankDetails[ACCOUNT_NUMBER])
        //         setBankDetails((details: any) => ({
        //           ...details,
        //           bills_invoices_bank_transactions:
        //             bank_account[accountNo]?.bills_invoices_bank_transactions ?? [],
        //         }));
        //     }
        //     onMetadataSuccess(uploadFileResponse);
        //   }

        //   if (currentStatus === EDITING) {
        //     for (let accountNo in bank_account) {
        //       if (accountNo === bankDetails[ACCOUNT_NUMBER]) {
        //         setBankAccounts((accounts: any) => ({
        //           ...accounts,
        //           [bankDetails[ACCOUNT_NUMBER]]: {
        //             ...accounts[bankDetails[ACCOUNT_NUMBER]],
        //             bills_invoices_bank_transactions:
        //               bank_account[accountNo]?.bills_invoices_bank_transactions,
        //           },
        //         }));
        //       }
        //     }
        //   }
        // }
    };

    /**
     * for populating document details locally. Called from mnemosyne flow.
     * Application for delete and upload on review and upload page respectively
     * @param uploadFileResponse
     * @returns
     */
    const onUploadODFDDocSuccess = (uploadFileResponse: any) => {
        if (uploadFileResponse) {
            if (onReviewPage) {
                bankData[uploadFileResponse?.keyName] = uploadFileResponse?.value;
                onMetadataSuccess(bankData);
                return;
            }
            const bank_obj = { ...bankDetails };
            bank_obj[uploadFileResponse.keyName] = uploadFileResponse.value;
            data.bank_account[bankDetails[ACCOUNT_NUMBER]] = {
                ...data.bank_account[bankDetails[ACCOUNT_NUMBER]],
                ...bank_obj,
            };
            onMetadataSuccess(data);
        }
    };

    const checkIfFileUploaded = (accountNumber: string) => {
        if (currentStatus === UPLOAD && bankDetails[ACCOUNT_NUMBER] === accountNumber) {
            return (
                !!bankDetails?.bills_invoices_bank_transactions?.length ||
                !!bankDetails?.finbox_entity_ids?.length
            );
        }
        if (
            bankAccounts &&
            accountNumber in bankAccounts &&
            (!_.isEmpty(bankAccounts[accountNumber].bills_invoices_bank_transactions) ||
                !_.isEmpty(bankAccounts[accountNumber].finbox_entity_ids))
        ) {
            return true;
        }
        return false;
    };

    /**
     * Check if an account number already exists in database
     * Criteria is that if the account number came in the API response or not
     *
     * @param accountNumber to be checked
     * @returns whether the account number exists in database
     */
    const checkIfBankAccountExists = (accountNumber: string) => {
        const existingBankAccounts = data?.bank_account ?? {};
        return Object.keys(existingBankAccounts).includes(accountNumber);
    };

    const checkIfConnectedFinbox = (accountNumber: string) => {
        if (onReviewPage === 'need-info') return false;
        if (currentStatus === EDITING && !onReviewPage) {
            if (initialAccountRef.current && initialAccountRef.current.finbox_entity_ids)
                return true;
            return false;
        }
        return !!bankAccounts?.[accountNumber]?.finbox_entity_ids || bankData?.finbox_entity_ids;
    };

    const getFiles = (bankDetails?: any) => {
        if (bankDetails) return bankDetails?.bills_invoices_bank_transactions ?? [];
        if (onReviewPage === 'need-info')
            return (
                bankAccounts?.[
                    bankDetails[ACCOUNT_NUMBER]
                ]?.bills_invoices_bank_transactions?.filter(
                    (f: any) => new Date(f.created_at) > new Date(lastUpdated),
                ) ?? []
            );
        if (currentStatus === UPLOAD) {
            return bankDetails?.bills_invoices_bank_transactions ?? [];
        }
        if (currentStatus === EDITING) return bankDetails?.bills_invoices_bank_transactions ?? [];

        return bankAccounts && bankDetails[ACCOUNT_NUMBER] in bankAccounts
            ? bankAccounts[bankDetails[ACCOUNT_NUMBER]].bills_invoices_bank_transactions
            : [];
    };

    const getFilesErrors = (bankDetails?: any) => {
        if (bankDetails) return bankDetails?.bankUploadErrors ?? [];
        if (onReviewPage === 'need-info')
            return (
                bankAccounts?.[bankDetails[ACCOUNT_NUMBER]]?.bankUploadErrors?.filter(
                    (f: any) => new Date(f.created_at) > new Date(lastUpdated),
                ) ?? []
            );
        if (currentStatus === UPLOAD) {
            return bankDetails?.bankUploadErrors ?? [];
        }
        if (currentStatus === EDITING) return bankDetails?.bankUploadErrors ?? [];

        return bankAccounts && bankDetails[ACCOUNT_NUMBER] in bankAccounts
            ? bankAccounts[bankDetails[ACCOUNT_NUMBER]].bankUploadErrors
            : [];
    };

    /**
     * shows OD files
     * @returns
     */
    const getFilesForOD = () => {
        if (onReviewPage === 'need-info')
            return (
                bankAccounts?.[bankDetails[ACCOUNT_NUMBER]]?.od_supporting_document?.filter(
                    (f: any) => new Date(f.created_at) > new Date(lastUpdated),
                ) ?? []
            );
        if (currentStatus === UPLOAD) {
            return bankDetails?.od_supporting_document ?? [];
        }
        if (currentStatus === EDITING) return bankDetails?.od_supporting_document ?? [];

        return bankAccounts && bankDetails[ACCOUNT_NUMBER] in bankAccounts
            ? bankAccounts[bankDetails[ACCOUNT_NUMBER]].od_supporting_document
            : [];
    };

    /**
     * shows FD files
     * @returns
     * */
    const getFilesForFD = () => {
        if (onReviewPage === 'need-info')
            return (
                bankAccounts?.[bankDetails[ACCOUNT_NUMBER]]?.fd_supporting_document?.filter(
                    (f: any) => new Date(f.created_at) > new Date(lastUpdated),
                ) ?? []
            );
        if (currentStatus === UPLOAD) {
            return bankDetails?.fd_supporting_document ?? [];
        }
        if (currentStatus === EDITING) return bankDetails?.fd_supporting_document ?? [];

        return bankAccounts && bankDetails[ACCOUNT_NUMBER] in bankAccounts
            ? bankAccounts[bankDetails[ACCOUNT_NUMBER]].fd_supporting_document
            : [];
    };

    // const onDeleteFileWhileCaseIsEditing = (docLink: any) => {
    //   const obj = { ...deletedAccountRef.current };
    //   if (obj?.bills_invoices_bank_transactions) {
    //     let index = 0;
    //     for (let file of obj?.bills_invoices_bank_transactions) {
    //       if (file.doc_link === docLink) {
    //         obj?.bills_invoices_bank_transactions?.splice(index, 1);
    //         break;
    //       }
    //       index++;
    //     }
    //   }
    //   deletedAccountRef.current = { ...obj };
    // };

    const showUploadBankCard = () => {
        const { isValid, message } = validateBankDetails(bankDetails);
        if (!isValid) {
            showNotification('Error', message);
        } else {
            setCurrentStatus(UPLOAD);
            setShowAddCard(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const renderFinboxState = () => {
        switch (currentStatus) {
            case UPLOAD:
                return (
                    <div>
                        <div className="dflex r-mb-4">
                            <div className={styles.UploadBankStmtText}>
                                {` Upload PDF Statement (last ${
                                    stage === UPLOAD_STAGE.ONBOARDING ? 15 : 24
                                } months).`}{' '}
                                <a
                                    href="https://help.recur.club/en/article/how-to-upload-bank-data"
                                    target="_blank"
                                >
                                    Learn More
                                </a>
                            </div>
                            {!checkIfFileUploaded(bankDetails[ACCOUNT_NUMBER]) && !isFinbitBank && (
                                <div className={styles.fetchText} onClick={openFinbox}>
                                    Fetch via NetBanking
                                </div>
                            )}
                        </div>
                        {bankDetails[BANK_NAME] && bankDetails[ACCOUNT_NUMBER] && (
                            <BankStatementUploadWarning
                                textToDisplay={
                                    bankDetails[BANK_NAME] +
                                    ' - ' +
                                    bankDetails[ACCOUNT_NUMBER]?.replace(/\d(?=\d{4})/g, '*')
                                }
                            />
                        )}
                        <UploadCard
                            mainOnboarding={true}
                            category="bills_invoices_bank_transactions"
                            keyName="bills_invoices_bank_transactions"
                            onSuccess={onUploadDocSuccess}
                            onDelete={deleteFileSuccess}
                            files={getFiles(bankDetails)}
                            fileErrors={getFilesErrors(bankDetails)}
                            bankDetails={
                                checkIfBankAccountExists(bankDetails[ACCOUNT_NUMBER])
                                    ? null
                                    : bankDetails
                            }
                            accountNumber={bankDetails[ACCOUNT_NUMBER]}
                            path="Onboarding"
                            isOnboarding={true}
                            // onReviewPage={onReviewPage}
                            renderComponent={<DragDropUpload isBackgroudWhite="true" />}
                            truncateSmallerText={true}
                            hideDeleteIcon
                            fileTypeAllowed={['pdf']}
                            bankData={bankDetails}
                            useMnemosyneUploadFlow={true}
                        />
                    </div>
                );
            case CONNECTED:
                return (
                    <div className={styles.FetchBtnGreen} style={{ cursor: 'default' }}>
                        <span>Fetched Successfully</span>
                    </div>
                );
            case EDITING:
                if (checkIfConnectedFinbox(bankDetails[ACCOUNT_NUMBER])) {
                    return (
                        <div className={styles.FetchBtnGreen} style={{ cursor: 'default' }}>
                            <span>Fetched Successfully</span>
                        </div>
                    );
                }
                return (
                    <UploadCard
                        mainOnboarding
                        category="bills_invoices_bank_transactions"
                        keyName="bills_invoices_bank_transactions"
                        onSuccess={onUploadDocSuccess}
                        onDelete={deleteFileSuccess}
                        mainText="Bank Statement PDF (last 12 months)"
                        files={getFiles(bankDetails)}
                        fileErrors={getFilesErrors(bankDetails)}
                        bankDetails={
                            checkIfBankAccountExists(bankDetails[ACCOUNT_NUMBER])
                                ? null
                                : bankDetails
                        }
                        accountNumber={bankDetails[ACCOUNT_NUMBER]}
                        path="Onboarding"
                        isOnboarding={true}
                        bankData={bankDetails}
                        hideDeleteIcon
                        renderComponent={<DragDropUpload isBackgroudWhite="true" />}
                        useMnemosyneUploadFlow={true}
                    />
                );
            case COMPLETED:
                return (
                    <div className="row">
                        <div className="col-md-6">
                            <button className={styles.netbanking} onClick={openFinbox}>
                                Fetch via NetBanking
                            </button>
                        </div>
                        <div
                            className="col-md-6"
                            onClick={() => {
                                showUploadBankCard();
                            }}
                        >
                            <button className={styles.manually}>Upload manually</button>
                        </div>
                    </div>
                );
            default:
                if (isFinbitBank) return <DisabledUploadCard />;
                else
                    return (
                        <div className="row mt-n2">
                            <div className="col-md-6">
                                <button className={styles.netbanking} disabled={true}>
                                    Fetch via NetBanking
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button className={styles.manually} disabled={true}>
                                    Upload manually
                                </button>
                            </div>
                        </div>
                    );
        }
    };

    const checkIfShowTickWhileEditing = () => {
        if (currentStatus === EDITING) {
            const bankAccDetails = bankAccounts[bankDetails[ACCOUNT_NUMBER]];
            if (bankAccDetails) {
                if ('finbox_entity_ids' in bankAccDetails && bankAccDetails.finbox_entity_ids) {
                    return true;
                }
                if (
                    'bills_invoices_bank_transactions' in bankAccDetails &&
                    !_.isEmpty(bankAccDetails.bills_invoices_bank_transactions)
                ) {
                    return true;
                }
            }
        }
        return false;
    };

    const checkCompleted = (accountNumber: any) => {
        const bankDetail = bankAccounts[accountNumber];
        return bankDetail?.isAccountConnected;
    };

    const deleteBankAccount = async (bankDetails?: any) => {
        try {
            const totalBanks = Object.keys(bankAccounts).length;
            dispatch(setIsAppLoading(true));
            const files = getFiles(bankDetails);
            const body: any = {
                keyname: 'bills_invoices_bank_transactions',
                account_number: bankDetails[ACCOUNT_NUMBER],
            };
            if (Array.isArray(files) && files.length > 0) {
                body.doc_link = files.map((f) => f.doc_link);
            } else if ('finbox_entity_ids' in bankDetails) {
                body.finbox_entity_ids = bankDetails.finbox_entity_ids;
            } else if (checkIfBankAccountExists(bankDetails[ACCOUNT_NUMBER])) {
                body.doc_link = [];
            } else {
                setBankDetails({
                    bank_name: '',
                    account_holder_name: '',
                    account_number: '',
                    ifsc_code: '',
                });
                setCurrentStatus(FILLING);
                dispatch(setIsAppLoading(false));
                return;
            }
            if (totalBanks === 1) {
                const obj = {
                    allBankAccountAdded: false,
                };
                const substage = 'bills_invoices_bank_transactions';
                callApi(
                    `investeeOnboarding/${investeeOnboardingId}/v3/metadata-update?stage=stage2&substage=${substage}&path=Onboarding`,
                    'post',
                    obj,
                )
                    .then((res: any) => {
                        if (res.status === 'Success') {
                            setAllBankAccountAdded(false);
                        }
                    })
                    .catch((err: any) => {
                        showNotification('Error', 'Some error occurred!');
                    });
            }
            const response = await callApi(
                `investee-onboarding/${investeeOnboardingId}/v3/delete-file?path=Onboarding`,
                'post',
                body,
            );
            if (response.status === 'Success') {
                onMetadataSuccess(response?.data?.investeeOnboardingData);
                setBankDetails({
                    bank_name: '',
                    account_holder_name: '',
                    account_number: '',
                    ifsc_code: '',
                });
                setCurrentStatus(FILLING);
                dispatch(setIsAppLoading(false));
            } else {
                const message = response.data?.message ?? 'Some error occurred!';
                showNotification('Error', message);
                dispatch(setIsAppLoading(false));
            }
        } catch (error) {
            dispatch(setIsAppLoading(false));
            console.error(error);
        }
    };

    const removeBankAccount = () => {
        switch (currentStatus) {
            case FILLING: {
                setBankDetails({
                    bank_name: '',
                    account_holder_name: '',
                    account_number: '',
                    ifsc_code: '',
                });
                setCurrentStatus(onReviewPage === 'need-info' ? FILLING : ADDED);
                break;
            }
            case COMPLETED:
            case CONNECTED:
            case EXIT_FINBOX: {
                setBankDetails({
                    bank_name: '',
                    account_holder_name: '',
                    account_number: '',
                    ifsc_code: '',
                });
                setCurrentStatus(FILLING);
                break;
            }
            case UPLOAD: {
                if (
                    bankAccounts &&
                    bankDetails[ACCOUNT_NUMBER] in bankAccounts &&
                    !_.isEmpty(
                        bankAccounts[bankDetails[ACCOUNT_NUMBER]].bills_invoices_bank_transactions,
                    )
                ) {
                    deleteBankAccount();
                } else {
                    setBankDetails({
                        bank_name: '',
                        account_holder_name: '',
                        account_number: '',
                        ifsc_code: '',
                    });
                    setCurrentStatus(FILLING);
                }
                break;
            }
            case EDITING: {
                deleteBankAccount();
                break;
            }
            default:
        }
    };

    const handleAccordianLogic = () => {
        if (currentStatus === EDITING) {
            resetBankAccountFields();
        } else if (currentStatus === ADDED) {
            setCurrentStatus(EDITING);
        }
    };

    /**
     * we want to change the heading to primary bank account details when there is only one bank account
     * the first bank in the DB will be treated primary otherwise.
     * No DB changes will be made for this.
     * @returns
     */
    const getHeadingText = () => {
        if (
            (bankAccounts && Object.keys(bankAccounts).length <= 0) ||
            bankDetails[ACCOUNT_NUMBER] ===
                bankAccounts[Object.keys(bankAccounts)[0]].account_number
        ) {
            return 'Add Bank Statements for last 15 months';
        } else return 'Bank/FD/OD Details ';
    };

    const renderSpecificBankAccount = () => {
        return (
            <>
                <div className={`${styles.BankAccForm} ${onReviewPage ? styles.NoCard : ''}`}>
                    {!onReviewPage ? (
                        <div
                            className={styles.Top}
                            style={{ marginBottom: '20px' }}
                            onClick={() => handleAccordianLogic()}
                        >
                            {_.isEmpty(bankAccounts) ? (
                                <div className={styles.Left}>{getHeadingText()}</div>
                            ) : (
                                <div className={styles.Left}>
                                    {checkCompleted(bankDetails[ACCOUNT_NUMBER]) ? (
                                        <img src="/assets/Done2.svg" alt="Completed" />
                                    ) : (
                                        <img src="/assets/not-done.svg" alt="Not Completed" />
                                    )}
                                    {bankDetails[BANK_NAME].length > 15
                                        ? bankDetails[BANK_NAME].substring(0, 15) + '...'
                                        : bankDetails[BANK_NAME]}{' '}
                                    - {bankDetails[ACCOUNT_NUMBER]}
                                    {bankDetails?.is_od && (
                                        <div className={styles.ODaccount}>
                                            <div>
                                                <p>OD account</p>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className={styles.deleteIcon}
                                        onClick={() => {
                                            deleteBankAccount(bankDetails);
                                        }}
                                    >
                                        <img src={ASSET_LINKS.DELETE_RED_BIN} />
                                    </div>
                                </div>
                            )}

                            <img src="/assets/dropup-grey.svg" alt="" />
                        </div>
                    ) : null}

                    {currentStatus === CONNECTED ||
                    (currentStatus === UPLOAD &&
                        checkIfFileUploaded(bankDetails[ACCOUNT_NUMBER])) ? (
                        <CompletedBankForm bankDetails={bankDetails} />
                    ) : (
                        <AddBankCard
                            handleChange={handleChange}
                            bankDetails={bankDetails}
                            banks={banks}
                            needSpaceFromTop
                        />
                    )}

                    {renderFinboxState()}

                    {!!showFinboxModal && (
                        <FinboxModal
                            showFinbox
                            onSuccess={onSuccess}
                            onExit={onExit}
                            onError={onError}
                        />
                    )}
                    {checkIfFileUploaded(bankDetails[ACCOUNT_NUMBER]) && (
                        <ODFD
                            bankDetails={bankDetails}
                            setBankDetails={setBankDetails}
                            mixpanelForOdFDChecked={mixpanelForOdFDChecked}
                            data={data}
                            getFilesForOD={getFilesForOD}
                            getFilesForFD={getFilesForFD}
                            onUploadODFDDocSuccess={onUploadODFDDocSuccess}
                            onReviewPage={onReviewPage}
                            path={pathOnboarding}
                            isFinbitBank={isFinbitBank}
                            checkIfFileUploaded={checkIfFileUploaded}
                        />
                    )}
                </div>
                {!onReviewPage && !_.isEmpty(bankAccounts) && (
                    <SmallMonthlyView
                        collapsibleMonthlyViewData={[
                            {
                                label: 'Bank Statement',
                                availableMonthsCount: getCountOfMonthlyData(
                                    getMonthlyDataForOnboarding(
                                        bankAccounts[bankDetails[ACCOUNT_NUMBER]]?.bankUploadErrors
                                            ?.missingMonths,
                                        monthlyViewData?.['availableBankTrxMonthlyDates']?.[
                                            bankDetails[ACCOUNT_NUMBER]
                                        ],
                                    ),
                                ),
                                monthlyViewDates: getMonthlyDataForOnboarding(
                                    bankAccounts[bankDetails[ACCOUNT_NUMBER]]?.bankUploadErrors
                                        ?.missingMonths,
                                    monthlyViewData?.['availableBankTrxMonthlyDates']?.[
                                        bankDetails[ACCOUNT_NUMBER]
                                    ],
                                ),
                            },
                        ]}
                        parentHovered={true}
                        isReview={onReviewPage}
                        expanded={true}
                        hover={true}
                        totalmonths={15}
                        smallMonthlyViewLowPad={true}
                    />
                )}
            </>
        );
    };

    const CollapsedBankAccount = ({ accountNumber, bankDetails }: any) => {
        return (
            <div className={styles.BankAccForm} onClick={() => editingBank(accountNumber)}>
                <div className={styles.Top}>
                    <div className={styles.Left}>
                        {checkCompleted(accountNumber) ? (
                            <img src="/assets/Done2.svg" alt="Completed" />
                        ) : (
                            <img src="/assets/not-done.svg" alt="Not Completed" />
                        )}
                        <p
                            className={styles.bankName}
                            title={`${bankAccounts[accountNumber][BANK_NAME]}-${accountNumber}`}
                        >
                            {bankAccounts[accountNumber][BANK_NAME].length > 15
                                ? bankAccounts[accountNumber][BANK_NAME].substring(0, 10) + '...'
                                : bankAccounts[accountNumber][BANK_NAME]}{' '}
                            - {accountNumber}
                        </p>
                        {bankDetails?.is_od && (
                            <div className={styles.ODaccount}>
                                <div>
                                    <p>OD account</p>
                                </div>
                            </div>
                        )}
                        <div
                            className={styles.deleteIcon}
                            onClick={() => {
                                deleteBankAccount(bankDetails);
                            }}
                        >
                            <img src={ASSET_LINKS.DELETE_RED_BIN} />
                        </div>
                    </div>
                    <div
                        className={clsx({
                            [styles.Right]: true,
                            [styles.RightWrapper]: true,
                        })}
                    >
                        <img src="/assets/dropdown-grey.svg" alt="" />
                    </div>
                </div>
            </div>
        );
    };

    const CollapseNSmallMonth = ({ index, accountNumber, bankDetails }: any) => {
        const [hovered, setHovered] = useState(false);
        return (
            <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                <CollapsedBankAccount
                    key={index}
                    accountNumber={accountNumber}
                    bankDetails={bankDetails}
                />
                <SmallMonthlyView
                    collapsibleMonthlyViewData={[
                        {
                            label: 'Bank Statement',
                            availableMonthsCount: getCountOfMonthlyData(
                                getMonthlyDataForOnboarding(
                                    bankDetails?.bankUploadErrors?.missingMonths,
                                    monthlyViewData['availableBankTrxMonthlyDates'][accountNumber],
                                ),
                            ),
                            monthlyViewDates: getMonthlyDataForOnboarding(
                                bankDetails?.bankUploadErrors?.missingMonths,
                                monthlyViewData['availableBankTrxMonthlyDates'][accountNumber],
                            ),
                        },
                    ]}
                    isReview={onReviewPage}
                    parentHovered={hovered}
                    totalmonths={15}
                    smallMonthlyViewLowPad={true}
                />
            </div>
        );
    };
    return (
        <div className={`StepContainer ${onReviewPage && 'ReviewStep'}`}>
            <div className={styles.titleWrapper}>
                <div className="Title">Sync all bank accounts</div>
                {!onReviewPage ? (
                    <div className={styles.noOfAccound}>
                        <p
                            aria-label={accountConnectedCount > 0 ? 'black' : 'grey'}
                        >{`${accountConnectedCount} account connected`}</p>
                    </div>
                ) : null}
            </div>

            {currentStatus !== ADDED && <>{renderSpecificBankAccount()}</>}
            {!!showFinboxModal && (
                <FinboxModal showFinbox onSuccess={onSuccess} onExit={onExit} onError={onError} />
            )}
            {!_.isEmpty(bankAccounts) &&
                !onReviewPage &&
                Object.keys(bankAccounts).map((accountNumber, index) => {
                    if (accountNumber !== 'is_add_bank_account') {
                        if (
                            accountNumber !== initialAccountRef.current?.[ACCOUNT_NUMBER] &&
                            accountNumber !== bankDetails[ACCOUNT_NUMBER]
                        ) {
                            return (
                                <CollapseNSmallMonth
                                    index={index}
                                    accountNumber={accountNumber}
                                    bankDetails={bankAccounts[accountNumber]}
                                />
                            );
                        }
                    }
                })}
            {/* {currentStatus === ADDED || showAddCard ? (
                !_.isEmpty(bankAccounts) ? (
                    <AddAccountCard
                        setCurrentStatus={setCurrentStatus}
                        bankDetails={bankDetails}
                        getFilesForFD={getFilesForFD}
                        setBankDetails={setBankDetails}
                        resetBankAccountFields={resetBankAccountFields}
                        data={data}
                        onUploadODFDDocSuccess={onUploadODFDDocSuccess}
                        onReviewPage={onReviewPage}
                        path={pathOnboarding}
                        handleChange={handleChange}
                        banks={banks}
                        openFinbox={openFinbox}
                        handleAllBankAccountAdded={handleAllBankAccountAdded}
                        allBankAccountAdded={allBankAccountAdded}
                        showUploadBankCard={showUploadBankCard}
                        stage={stage}
                    />
                ) : null
            ) : null} */}
        </div>
    );
}
