/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../../../components/loader/PageLoader';
import RecurTabs from '../../../../components/RecurTabs/RecurTabs';
import { getUserInvesteeOrganizationId } from '../../../App/AppReducer';
import { fetchFinancesOverviewData } from '../../InvesteeActions';
import {
    getIfFetchingFinancesOverviewData,
    getInvesteeMonthlyCompletedPayments,
    getInvesteeMonthlyScheduledPayments,
    getInvesteeMonthlyScheduledPaymentsGraph,
} from '../../InvesteeReducers';
import OverviewPage from './components/Overview/OverviewPage.jsx';
import styles from './styles/Transactions.module.scss';
import { getAmountInLacs } from '../../../../util/utility';
import withRouter from '../../../../common/withRouter';
import { Helmet } from 'react-helmet-async';

dayjs.extend(UTC);
dayjs.extend(customParseFormat);

export const OVERVIEW = 'Overview';
export const PAYOUTS = 'Payouts';
export const PAYMENTS = 'Payments';
export const CUSTOMERS = 'Customers';
export const REPAYMENTS = 'Repayments';
export const PAYMENT_DETAILS = 'Payment Details';
export const REPAYMENT_DETAILS = 'Repayment Details';
export const TRADE_DETAILS = 'Trade Details';

function TransactionsOverview({ navigate }) {
    const tabs = [OVERVIEW, PAYOUTS, PAYMENTS];
    const { Heading, HeadingBox, HeadingText } = styles;
    const dispatch = useDispatch();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [currentTab, setCurrentTab] = useState(OVERVIEW);
    const [recentPayments, setRecentPayments] = useState([]);
    const [upcomingPayments, setUpcomingPayments] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const investeeOrgId = useSelector((state) => getUserInvesteeOrganizationId(state));
    const monthlyCompletedPayments = useSelector((state) =>
        getInvesteeMonthlyCompletedPayments(state),
    );
    const monthlyScheduledPayments = useSelector((state) =>
        getInvesteeMonthlyScheduledPayments(state),
    );
    const monthlyScheduledPaymentsGraph = useSelector((state) =>
        getInvesteeMonthlyScheduledPaymentsGraph(state),
    );
    const isFetchingOverviewData = useSelector((state) => getIfFetchingFinancesOverviewData(state));

    useEffect(() => {
        if (investeeOrgId && !monthlyScheduledPaymentsGraph) {
            fetchOverviewData();
        }
    }, []);

    useEffect(() => {
        if (!isFirstRender) {
            if (currentTab === PAYOUTS) navigate('/company/finances/transactions/payouts');
            else if (currentTab === PAYMENTS) navigate('/company/finances/transactions/payments');
        } else setIsFirstRender(false);
    }, [currentTab]);

    useEffect(() => {
        getOverviewData();
        getPaymentGraphData();
    }, [monthlyCompletedPayments, monthlyScheduledPayments, monthlyScheduledPaymentsGraph]);

    const fetchOverviewData = (input) => {
        dispatch(fetchFinancesOverviewData(investeeOrgId, input));
    };

    const getOverviewData = () => {
        if (!isEmpty(monthlyCompletedPayments)) {
            let index = monthlyCompletedPayments.length;
            let recent_payments;
            if (index >= 3) {
                recent_payments = monthlyCompletedPayments.slice(index - 3, index);
            } else {
                recent_payments = monthlyCompletedPayments.slice(0, index);
            }
            recent_payments.reverse();
            setRecentPayments(recent_payments);
        }
        if (!isEmpty(monthlyScheduledPayments)) {
            let upcoming_payments = monthlyScheduledPayments
                .sort((a, b) => {
                    a = a.displayDate.split('-');
                    b = b.displayDate.split('-');
                    return new Date(a[2], a[1], a[0]) - new Date(b[2], b[1], a[0]);
                })
                .slice(0, 3);
            setUpcomingPayments(upcoming_payments);
        }
    };

    const getPaymentGraphData = () => {
        let b = [];
        let total_payments = [];
        total_payments = !isEmpty(monthlyScheduledPaymentsGraph)
            ? JSON.parse(JSON.stringify(monthlyScheduledPaymentsGraph))
            : [];
        if (!isEmpty(total_payments)) {
            let dateStart = dayjs(total_payments[0].month, 'YYYY-MM');
            let i = total_payments.length;
            let dateEnd = dayjs(total_payments[i - 1].month, 'YYYY-MM');
            let interim = dateStart.clone();
            let timeValues = [];

            while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
                timeValues.push(interim.format('YYYY-MM'));
                interim = interim.add(1, 'month');
            }

            timeValues.forEach((item) => {
                let v = total_payments.findIndex((i) => i.month === item);
                if (v >= 0) {
                    let obj = total_payments[v];
                    obj.month = dayjs(total_payments[v].month, 'YYYY-MM').format('MMM YY');
                    obj.amount_to_show = `₹ ${Number(getAmountInLacs(obj.totalAmount)).toFixed(
                        1,
                    )} lac`;
                    b.push(obj);
                } else {
                    b.push({
                        month: dayjs(item, 'YYYY-MM').format('MMM YY'),
                        total_amount: 0,
                    });
                }
            });
            setGraphData(b);
        } else {
            let graph_data = [];
            let date = new Date();
            for (let i = 0; i < 12; i++) {
                let month = dayjs(date).utc().utcOffset(330).add(i, 'months').format('MMM YY');
                graph_data.push({
                    month: month,
                    total_amount: 0,
                });
            }
            setGraphData(graph_data);
        }
    };

    return (
        <section className={Heading}>
            <div className={HeadingBox}>
                {isFetchingOverviewData && <PageLoader />}
                <Helmet>
                    <title>{'Transactions | Recur Club'}</title>
                </Helmet>
                {currentTab === OVERVIEW && (
                    <OverviewPage
                        graphData={graphData}
                        upcomingPayments={upcomingPayments}
                        recentPayments={recentPayments}
                        fetchOverviewData={fetchOverviewData}
                    />
                )}
            </div>
        </section>
    );
}

export default withRouter(TransactionsOverview);
