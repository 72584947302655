import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { history } from '../common/history';

/** @deprecated Use `React Router hooks` instead */
export interface WithRouterProps {
    location: ReturnType<typeof useLocation>;
    params: Record<string, string>;
    navigate: ReturnType<typeof useNavigate>;
}

/** @deprecated Use `React Router hooks` instead */
const withRouter = <T extends WithRouterProps>(Component: React.ComponentType<T>) => {
    return (props: Omit<T, keyof WithRouterProps>) => {
        const location = useLocation();
        const params = useParams();
        const navigate = useNavigate();

        return (
            <Component
                {...(props as T)}
                location={location}
                params={params}
                navigate={navigate}
                history={history}
            />
        );
    };
};

export default withRouter;
