import React, {
    Component,
    // useEffect,
    // useState
} from 'react';
import '../loader/loader.css';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';

class PageLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="loader-screen">
                <div className="loader-div">
                    <div className="loader letter-first">
                        <div className="text">R</div>
                    </div>
                    <div className="loader letter-mid">
                        <div className="text">E</div>
                    </div>
                    <div className="loader letter-mid">
                        <div className="text">C</div>
                    </div>
                    <div className="loader letter-mid">
                        <div className="text">U</div>
                    </div>
                    <div className="loader letter-last">
                        <div className="text">R</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageLoader;
