import React, { useEffect, useState } from 'react';
import styles from './NavbarHeader.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEmail,
    getInvesteeOrganizationInfo,
    getInvesteeSidebarConfigTab,
    getInvitableRoles,
    getRoleObjMap,
    getUserName,
} from '../../../App/AppReducer';
import { ASSET_LINKS, ICON_NAMES } from '../../../../constants/assetLinks';
import ReactTooltip from 'react-tooltip';
import NotificationTooltipContent from './NotificationTooltipContent';
import UserDropdownTooltipContent from './UserDropdownTooltipContent';
import { useLocation } from 'react-router-dom';
import { returnNavHeading } from '../../../../util/navHeadingMapping';
import { fetchFilteredNotifications } from '../../InvesteeActions';
import { isEmpty } from 'lodash';
import SearchBoxWithDropDown from '../../../../components/SearchBoxWithDropDown/SearchBoxWithDropDown';
import { DRAWER_HEADINGS } from '../../../../DrawerConsts';
import { INVESTEE_ORG_ACTIVE } from '../../../../enums/appEnums';
import { getUnreadNotificationCount } from '../../InvesteeReducers';
import { triggerDrawer } from '../../../App/AppActions';
import { getS3IconLink } from '../../../../util/utility';

interface NavbarHeaderProps {
    headingText: string;
}

function NavbarHeader(props: NavbarHeaderProps) {
    const {
        NavContainer,
        UserNameText,
        SearchNotificationContainer,
        NavSearchBar,
        CloseContainer,
        NotificationsButton,
        UserDropdownBox,
        TruncatedUsername,
        NotificationBox,
        UserNameButtonContainer,
        HideCloseContainer,
        UserNameBlock,
    } = styles;
    const userName = useSelector(getUserName);
    const dispatch = useDispatch();
    const emailId = useSelector(getEmail);
    const [showNotificationTooltip, setShowNotificationTooltip] = useState(false);
    const NotificationCount = useSelector(getUnreadNotificationCount);
    const InvesteeOrgName = useSelector(getInvesteeOrganizationInfo).display_name;
    const InvesteeOrgStatus = useSelector(getInvesteeOrganizationInfo).status;
    const email = useSelector((state) => getEmail(state));
    const sidebarConfig = useSelector(getInvesteeSidebarConfigTab);
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const roleObjectMap = useSelector(getRoleObjMap);
    const invitableRoles = useSelector(getInvitableRoles);
    const [heading, setHeading] = useState<string>('');
    const [showTabs, setShowTabls] = useState<any>({
        'Invite Teammate': true,
        'Customer Support': true,
        Notifications: true,
        Profile: true,
    });
    const fetchNotifications = async () => {
        const res = dispatch(
            fetchFilteredNotifications({ email, pageNum: 0, pageSize: 30, filters: {} }, false),
        );
    };
    useEffect(() => {
        if (isEmpty(NotificationCount)) {
            fetchNotifications();
        }
    }, []);
    const location = useLocation();
    const toggleTooltip = () => {
        // setShowNotificationTooltip(!showNotificationTooltip);
        // setShowUserDropdown(false);
        dispatch(triggerDrawer(DRAWER_HEADINGS.NOTIFICATION));
    };
    const toggleUserTooltip = () => {
        setShowUserDropdown(!showUserDropdown);
        setShowNotificationTooltip(false);
    };
    useEffect(() => {
        setHeading(returnNavHeading(location.pathname));
    }, [location]);
    const setTabConfigs = () => {
        setShowTabls({
            'Invite Teammate': sidebarConfig?.map((config: any) => {
                if (config.label === 'Header') {
                    config.child.map((child: any) => {
                        if (child.label === 'Invite Teammate') {
                            return child.show;
                        }
                    });
                }
            }),
            Notifications: sidebarConfig?.map((config: any) => {
                if (config.label === 'Header') {
                    config.child.map((child: any) => {
                        if (child.label === 'Notifications') {
                            return child.show;
                        }
                    });
                }
            }),
            Profile: true,
            'Customer Support': true,
        });
    };
    useEffect(() => {
        setTabConfigs();
    }, [sidebarConfig]);
    const closeTooltip = () => {
        if (showNotificationTooltip) {
            setShowNotificationTooltip(false);
        }
        if (showUserDropdown) {
            setShowUserDropdown(false);
        }
    };
    const isRecurUser = () => {
        return emailId?.split('@')[1] === 'recur.club';
    };
    return (
        <>
            <div
                onClick={() => closeTooltip()}
                className={
                    showNotificationTooltip || showUserDropdown
                        ? CloseContainer
                        : HideCloseContainer
                }
            />
            <div className={NavContainer}>
                <div className={UserNameText}>
                    {heading === ''
                        ? `Hello ${
                              userName
                                  ? userName.split(' ')[0][0]?.toUpperCase() +
                                    userName.split(' ')[0]?.slice(1)
                                  : ''
                          }!`
                        : heading}
                </div>
                <div className={SearchNotificationContainer}>
                    {InvesteeOrgStatus === INVESTEE_ORG_ACTIVE && <SearchBoxWithDropDown />}
                    {showTabs['Customer Support'] && (
                        <div
                            onClick={() =>
                                dispatch(triggerDrawer(DRAWER_HEADINGS.CUSTOMER_SUPPORT))
                            }
                            className={NotificationsButton}
                        >
                            <img
                                src={getS3IconLink(ICON_NAMES.CUSTOMER_SUPPORT_ICON)}
                                alt="customer support icon"
                                width={24}
                                height={24}
                            />
                            Customer Support
                        </div>
                    )}
                    {showTabs['Notifications'] && (
                        <div style={{ position: 'relative' }}>
                            <div onClick={toggleTooltip} className={NotificationsButton}>
                                <div>
                                    {NotificationCount > 0 && (
                                        <div className={styles.NotificationCount}>
                                            {NotificationCount}
                                        </div>
                                    )}
                                    <img
                                        src={ASSET_LINKS.NOTIFICATION_BELL_ICON}
                                        alt="notification bell icon"
                                        width={24}
                                        height={24}
                                    />
                                </div>
                                Notifications
                            </div>
                            {/* @todo: Depreciating this tooltip component since the product asked to do so */}
                            {/* {showNotificationTooltip && (
                                <div className={NotificationBox}>
                                    <NotificationTooltipContent
                                        setShowNotificationTooltip={setShowNotificationTooltip}
                                    />
                                </div>
                            )} */}
                        </div>
                    )}
                    {showTabs['Profile'] && (
                        <div style={{ position: 'relative' }}>
                            <div
                                data-tip="UserDrowpDownTooltip"
                                data-for={'UserDrowpDownTooltip'}
                                onClick={toggleUserTooltip}
                                className={UserNameButtonContainer}
                            >
                                <div className={UserNameBlock}>
                                    {userName?.split(' ').length > 1
                                        ? userName?.split(' ')?.[0]?.[0]?.toUpperCase() +
                                          userName?.split(' ')?.[1]?.[0]?.toUpperCase()
                                        : userName?.[0].toUpperCase()}
                                </div>
                                <h3 className={TruncatedUsername}>
                                    {isRecurUser()
                                        ? InvesteeOrgName
                                        : userName?.split(' ')?.[0] ?? ''}
                                </h3>
                                <img src={ASSET_LINKS.DOWN_ARROW_ICON} alt="downward-arrow" />
                            </div>
                            {showUserDropdown && (
                                <div className={UserDropdownBox}>
                                    <UserDropdownTooltipContent
                                        isRecurUser={isRecurUser}
                                        investeeOrgName={InvesteeOrgName}
                                        roleObjectMap={roleObjectMap}
                                        invitableRoles={invitableRoles}
                                        setShowUserDropdown={setShowUserDropdown}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="page-head-bottom-border"></div>
        </>
    );
}

export default NavbarHeader;
